import React from 'react'
import { useParams } from 'react-router-dom';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const Myticket = ({ tickets }) => {

  const { id } = useParams();

  const getStatusClass = (status) => {
    switch (status) {
      case 'closed':
        return ' text-green-600 text-base bg-green-200';
      case 'in progress':
        return ' text-red-500 text-base';
      case 'needs attention':
        return ' text-[#EE9D00] text-base';
      default:
        return 'text-base';
    }
  }

  return (
    <>
      <div className="w-full bg-white rounded-b-lg md:px-6 p-3">
        <div className='flex  flex-row place-items-center gap-2 text-[#909090] text-base'><span>Myticket</span> <MdOutlineKeyboardArrowRight /> {id}</div>
        {tickets.map((list, index) => (
          list.ticketId == id && (
            <div className="mt-3" key={index}>
              <h1 className='text-lg font-semibold'>{list.issue}</h1>
              <span className='capitalize text-[#909090] text-sm'>created On&nbsp;{list.createdOn}</span>
              <div className="flex flex-row w-full">
                <div className="w-1/2 flex flex-col gap-3 mt-5">
                  <div className="flex flex-col gap-1">
                    <h1 className='text-sm text-[#909090] capitalize'>status</h1>
                    <span className={`w-fit ${getStatusClass(list.status)} px-4 py-1 rounded-full`}>{list.status}</span>
                  </div>
                  <div className="flex flex-col gap-1">
                    <h1 className='text-sm text-[#909090] capitalize'>Sub Order Number</h1>
                    <span className={`font-medium text-base`}>757900386125_1</span>
                  </div>
                  <div className="flex flex-col gap-1">
                    <h1 className='text-sm text-[#909090] capitalize'>AWB Number</h1>
                    <span className={`font-medium text-base`}>234098117252273</span>
                  </div>
                  <div className="flex flex-col gap-1">
                    <h1 className='text-sm text-[#909090] capitalize'>Callback Number</h1>
                    <span className={`font-medium text-base`}>234098117252273</span>
                  </div>
                  <div className="flex flex-col gap-1">
                    <h1 className='text-sm text-[#909090] capitalize'>Callback Number</h1>
                    <span className={`font-medium text-base`}>234098117252273</span>
                  </div>
                </div>
                <div className="w-1/2 ps-5 border-l-2 border-[#C7C7C7]">
                  <h1 className='font-semibold text-base'>Updates</h1>
                  <div className="flex mt-5 flex-row gap-2 w-full">
                    <div className="flex flex-col gap-3">
                      <div className="flex flex-col h-20 w-20 rounded-lg border border-black bg-[#F8F8F8] justify-center place-items-center">
                        <span className='text-lg font-medium'>12</span>
                        <span className='text-base text-[#909090] font-medium'>Dec</span>
                      </div>
                      <button className='text-base font-medium text-[#2275FC] border border-[#2275FC] bg-[#C6DCFF] rounded-full py-1 text-center capitalize'>alas</button>
                    </div>
                    <div className="flex flex-col">
                      <span className='font-normal text-sm'>{list.lastUpdate}</span>
                      <span className='font-semibold text-xs'>Show more</span>
                      <span className='font-medium text-sm text-[#909090]'>12:44 PM</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        ))}
      </div>
    </>
  )
}

export default Myticket