import React, { useState } from 'react';
import { BsInstagram, BsTelephone } from "react-icons/bs";
import { LuFacebook } from "react-icons/lu";
import { FaXTwitter, FaCcVisa, FaCcMastercard, FaCcPaypal, FaYoutube } from "react-icons/fa6";
import { SiGooglemaps } from "react-icons/si";
import { HiOutlineMail } from "react-icons/hi";
import { Link } from 'react-router-dom';

import white_logo from '../Assets/Images/White.png';
import axios from 'axios';
import notify from '../utiles/Notification';

const base_url = '/pages';

function Footer() {

    const [subs, setSubs] = useState('');
    const baseUrl = process.env.REACT_APP_BASEURL;


    const submit = async () => {
        if (!subs) {
            notify('danger', 'Error', 'Please Enter the Email');
            return;
        }
        try {
            const response = await axios.post(`${baseUrl}/add_subscriber`, { email: subs });
            if (response.data.status) {
                setSubs('')
                notify('success', 'Success', 'Subscribe');
            } else {
                setSubs('')
                notify('success', 'Success', `${response.data.message}`);
            }
        } catch (err) {
            console.log(err)
        }
    }
    return (
        <footer className="bg-black text-white">
            <div className="container mx-auto px-6 py-12">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    <div className="space-y-6">
                        <img src={white_logo} alt="Logo" className="h-12" />
                        <p className="text-sm">If you run an e-commerce store you'll want to make it easy for shoppers to check out a product's specifics.</p>
                        <div className="flex space-x-4">
                            <a href="#" className="text-gray-400 hover:text-white transition-colors">
                                <BsInstagram className="w-6 h-6" />
                            </a>
                            <a href="#" className="text-gray-400 hover:text-white transition-colors">
                                <LuFacebook className="w-6 h-6" />
                            </a>
                            <a href="#" className="text-gray-400 hover:text-white transition-colors">
                                <FaXTwitter className="w-6 h-6" />
                            </a>
                            <a href="#" className="text-gray-400 hover:text-white transition-colors">
                                <FaYoutube className="w-6 h-6" />
                            </a>
                        </div>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
                        <ul className="space-y-2">
                            <li><Link to='/pages/about-us' className="hover:text-white transition-colors">About Us</Link></li>
                            <li><Link to='/pages/brands' className="hover:text-white transition-colors">Brands</Link></li>
                            <li><Link to={`${base_url}/contact-us`} className="hover:text-white transition-colors">Contact Us</Link></li>
                            <li><Link to={`${base_url}/faqs`} className="hover:text-white transition-colors">FAQs</Link></li>
                            <li><Link to={`${base_url}/privacy_policy`} className="hover:text-white transition-colors">Privacy Policy</Link></li>
                            <li><Link to={`${base_url}/press_release`} className="hover:text-white transition-colors">Press Release</Link></li>
                        </ul>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold mb-4">Contact Info</h3>
                        <ul className="space-y-4">
                            <li className="flex items-start">
                                <SiGooglemaps className="w-5 h-5 mr-2 mt-1 flex-shrink-0" />
                                <span className="text-sm">001 Mayuransh Elanza, Nr. Parekh's Hospital, Shyamal Crossroad, 132 Feet Ring Rd, Satellite, Ahmedabad, Gujarat 380015</span>
                            </li>
                            <li className="flex items-center">
                                <HiOutlineMail className="w-5 h-5 mr-2" />
                                <span>abc@gmail.com</span>
                            </li>
                            <li className="flex items-center">
                                <BsTelephone className="w-5 h-5 mr-2" />
                                <span>07000830437</span>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold mb-4">Newsletter</h3>
                        <p className="text-sm mb-4">Stay up to date with our latest news and products & get 10% off.</p>
                        <div className="space-y-2">
                            <input
                                type="email"
                                value={subs}
                                placeholder="Your email address"
                                onChange={(e) => setSubs(e.target.value)}
                                className="w-full px-3 py-2 bg-gray-800 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <button
                                onClick={submit}
                                className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors"
                            >
                                Subscribe
                            </button>
                        </div>
                    </div>
                </div>

                <div className="border-t border-gray-700 mt-12 pt-8">
                    <div className="flex flex-col md:flex-row justify-between items-center">
                        <p className="text-sm">&copy; 2022 July. All rights reserved.</p>
                        <div className="flex space-x-4 mt-4 md:mt-0">
                            <FaCcVisa className="w-8 h-8" />
                            <FaCcMastercard className="w-8 h-8" />
                            <FaCcPaypal className="w-8 h-8" />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;