import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { RxCross2 } from "react-icons/rx";
import { Link } from 'react-router-dom';
import notify from '../../Component/utiles/Notification';
import Loader from '../../Component/utiles/Loader';


const Editaddfaqs = ({ closeaddFaqs, faqID, faqsdata: initialFaqsdata, onFaqsUpdate }) => {

    const baseUrl = process.env.REACT_APP_BASEURL
    const [isLoading, setIsLoading] = useState(false);
    const [faqsdata, setFaqsdata] = useState({
        title: '',
        para: '',
    });

    useEffect(() => {
        if (initialFaqsdata) {
            setFaqsdata(initialFaqsdata);
        }
    }, [initialFaqsdata]);



    const handleupdate = async () => {
        setIsLoading(true);

        let faqurl = '';
        let res = '';

        if (faqID) {
            faqurl = `updatefaqs/${faqID}`;
            res = 'Update';
        } else {
            faqurl = `addFaqs`;
            res = 'Add';
        }

        try {

            const response = await axios.post(
                `${baseUrl}/${faqurl}`,
                faqsdata,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );

            if (response.data.status) {
                notify('success', 'Success', `FAQS ${res} Successfully`);
                onFaqsUpdate();
                closeaddFaqs();
            } else {
                notify('danger', 'Error', `${response.data.message || 'An error occurred'}`);
            }

        } catch (err) {
            notify('danger', 'Error', 'Something went wrong');

        }

        setIsLoading(false);
    }

    return (
        <>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-70">
                <div className="bg-white rounded-lg shadow-lg overflow-hidden md:flex flex-col md:w-1/3 w-3/4" data-aos="fade-top" data-aos-duration="1000">
                    <div className="md:w-full my-auto">
                        <div className="flex flex-row place-items-center justify-between border-b border-gray-300 h-20 px-4">
                            <h1 className='text-xl font-semibold'>{faqID ? 'Edit' : 'Add'} Faqs</h1>
                            <div className='text-lg'>
                                <RxCross2 className='cursor-pointer ' onClick={closeaddFaqs} />
                            </div>
                        </div>

                        <div className='px-4 pt-5'>
                            <span className='text-lg font-medium'>Title</span>
                            <input className='w-full py-3 my-3 rounded-md focus:ring-0 border-gray-300 focus:border-black' name='title' value={faqsdata?.title} onChange={(e) => setFaqsdata({ ...faqsdata, title: e.target.value })} type='text' placeholder='Enter Name' />
                            <span className='text-lg font-medium '>Description</span>
                            <textarea name="para" value={faqsdata?.para} onChange={(e) => setFaqsdata({ ...faqsdata, para: e.target.value })} className='rounded-md w-full mt-3 mb-5 focus:ring-0 border-gray-300 focus:border-black' id="" rows='6'></textarea>
                        </div>
                        <div className="flex flex-row h-20 place-items-center justify-end pe-4 gap-3 border-t border-gray-300 ">
                            <button className='border border-black py-2 rounded-lg text-base px-4' onClick={closeaddFaqs}>Cancel</button>
                            <Link className='text-white w-fit flex justify-center    items-center bg-black px-5 text-base py-2 hover:text-black hover:bg-white hover:border border rounded-lg hover:border-black transition-all duration-200' onClick={handleupdate}>{faqID ? 'Update' : 'Add'}</Link>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading && <Loader />}
        </>
    )
}

export default Editaddfaqs