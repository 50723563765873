import React, { useEffect, useState } from 'react'
import Dropdown from '../../../Component/utiles/Dropdown'
import { IoSearchSharp } from 'react-icons/io5';
import { UserState } from '../../../Context/userProvider';
import { Link } from 'react-router-dom';
import { FileInput, Label, Modal } from "flowbite-react";
import { MdCloudDownload, MdDelete } from 'react-icons/md';
import { ImageUpload } from '../../../Component/utiles/ImageUpload';
import { AiOutlineDelete } from 'react-icons/ai';
import axios from 'axios';
import { FiEdit } from 'react-icons/fi';
import { FaEye } from 'react-icons/fa';
import notify from '../../../Component/utiles/Notification';
import Pagination from '../../../Component/utiles/Pagination';


const Addnews = ({ setLoading }) => {

    const baseUrl = process.env.REACT_APP_BASEURL


    const { user, logout } = UserState();
    const [searchTerm, setSearchTerm] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [edit, setEdit] = useState(false);
    const [refresh, setRefresh] = useState(0);

    const [newsdata, setNewsdata] = useState([])
    const [news, setNews] = useState({
        title: '', sub_title: '', description: '', image: '', status: 'Inactive'
    });

    const multiple = false;
    const { images, handleFileChange, handleRemoveImage } = ImageUpload(multiple, setLoading);

    useEffect(() => {
        setNews(prev => ({
            ...prev,
            image: images.length > 0 ? images[0].url : null,
        }));
    }, [images]);

    const perpageoption = [
        { label: '5' }, { label: '10' }, { label: '15' }, { label: '20' }
    ]

    const searchResults = newsdata.filter(data =>
        data.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        data.newsletter_id.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );

    const [itemsPerPage, setItemsPerPage] = useState(perpageoption[0].label);
    const [currentPage, setCurrentPage] = useState(1);

    const itemsToDisplay = searchTerm ? searchResults : newsdata;


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = itemsToDisplay.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(itemsToDisplay.length / itemsPerPage);

    useEffect(() => {
        if (searchTerm) {
            setCurrentPage(1);
        }
    }, [searchTerm]);

    const removeimg = (index) => {
        handleRemoveImage(index)
        setNews((prev) => ({ ...prev, image: '' }));
    }

    const addnews = async (id) => {
        if (!news.title) {
            notify('danger', 'Error', 'Please Enter Title');
            return false;
        }
        if (!news.sub_title) {
            notify('danger', 'Error', 'Please Enter Sub Title');
            return false;
        }
        if (!news.image) {
            notify('danger', 'Error', 'Please Upload atleast One Image');
            return false;
        }
        if (!news.description) {
            notify('danger', 'Error', 'Please Enter Description');
            return false;
        }
        let payload;
        if (edit) {
            payload = {
                newsletter_id: id,
                title: news.title,
                sub_title: news.sub_title,
                status: news.status,
                image: news.image,
                description: news.description,
            }
        } else {
            payload = {
                title: news.title,
                sub_title: news.sub_title,
                status: news.status,
                image: news.image,
                description: news.description,
            }
        }
        setLoading(true)
        try {
            const response = await axios.post(`${baseUrl}/add_update_newsletter`, {
                ...payload
            });
            if (response.data.status) {
                setRefresh(prev => prev + 1);
                closemodal();
            }
        } catch {

        }
        setLoading(false)
    }

    const closemodal = () => {
        setOpenModal(false)
        setNews({
            title: '', sub_title: '', description: '', image: '', status: 'Inactive'
        })
    }


    useEffect(() => {
        const fetchNewsletterData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${baseUrl}/get_newsletter`);
                if (response.data.status) {
                    setEdit(false)
                    setNewsdata(response.data.news);
                }
            } catch (error) {
                console.error('An error occurred while fetching newsletters.', error);
            }
            setLoading(false);
        };
        fetchNewsletterData();
    }, [refresh]);

    const getStatusClass = (stockStatus) => {
        switch (stockStatus.toLowerCase()) {
            case 'in stock':
            case 'active':
            case 'live':
                return 'bg-green-200 text-green-600 text-xs';
            case 'out of stock':
            case 'inactive':
            case 'hold':
                return 'bg-red-200 text-red-500 text-xs';
            default:
                return 'bg-gray-400 text-white text-xs';
        }
    };

    const editnews = (id) => {
        const data = newsdata.find(list => list.newsletter_id == id);
        setNews((prev) => ({ ...prev, newsletter_id: data.newsletter_id, title: data.title, sub_title: data.sub_title, status: data.status, image: data.image, description: data.description }));
        setOpenModal(true)
        setEdit(true)
    }


    return (
        <>
            <h1 className="xl:text-2xl md:text-lg text-xl mt-4 font-semibold capitalize">News</h1>
            <div className='w-full bg-white rounded-lg mt-5 p-6'>
                <div className='flex flex-row justify-between my-5 p-0'>
                    <div className='flex flex-row items-center md:w-48 w-20 gap-5'>
                        <span className='text-nowrap mt-2 md:block hidden'>items per page</span>
                        <Dropdown
                            label=""
                            options={perpageoption}
                            selectedValues={itemsPerPage}
                            onSelect={setItemsPerPage}
                            onRemove={() => { }}
                            isMultiple={false}
                        />
                    </div>
                    <div className="relative hidden md:flex md:w-6/12 ms-5">
                        <input
                            type="text"
                            placeholder="Search Here"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="bg-gray-200 w-full border-0 rounded-full px-4 py-2 pr-10 focus:outline-none focus:border-gray-400 focus:ring-2 focus:ring-gray-400"
                        />
                        <IoSearchSharp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-black cursor-pointer" />
                    </div>
                    {user?.role === 'superadmin' && (
                        <Link className='text-white flex justify-center items-center font-semibold text-nowrap bg-black ms-5 md:px-5 md:text-sm text-sm md:py-3 py-2 px-3  
                        hover:text-black hover:bg-white hover:border border rounded-lg hover:border-black transition-all duration-200 capitalize' onClick={() => setOpenModal(true)}
                        >add &nbsp;<span className='md:block hidden'>New News</span></Link>
                    )}
                </div>
                <div className='overflow-hidden overflow-x-auto'>
                    <table className="min-w-full table bg-white">
                        <thead className="bg-gray-100 rounded-full">
                            <tr>
                                <th className="py-4 px-4 text-left">ID</th>
                                <th className="py-4 px-4 text-left">Img</th>
                                <th className="py-4 px-4 text-left">Title</th>
                                <th className="py-4 px-4 text-left">Status</th>
                                <th className="py-4 px-4 text-left">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map(item => (
                                <tr key={item.newsletter_id} className="border-b border-gray-200 hover:bg-gray-200 transition-all duration-200 ease-in-out">
                                    <td className="py-4 px-4 capitalize font-normal text-base">{item.newsletter_id}</td>
                                    <td className="py-4 px-4 capitalize font-semibold text-base"><img src={item.image} className='md:h-24 md:w-36 h-10 w-40' alt="" /></td>
                                    <td className="py-4 px-4 capitalize font-normal text-base">{item.title}</td>
                                    <td className="py-4 px-4">
                                        <span className={`px-2 py-1  rounded-lg capitalize font-semibold ${getStatusClass(item.status)}`}>
                                            {item.status}
                                        </span>
                                    </td>
                                    <td className="p-4">
                                        <div className='flex flex-row items-center gap-5 text-xl'>
                                            <Link className='cursor-pointer hover:scale-110 duration-200 transition-all px-3 py-2 text-lg rounded-md bg-neutral-300' onClick={() => editnews(item.newsletter_id)}>
                                                <FiEdit />
                                            </Link>
                                            <div className='px-3 cursor-pointer hover:scale-110 duration-200 transition-all py-2 text-lg rounded-md bg-neutral-300' >
                                                <FaEye />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-between items-center mt-4">
                    <div>
                        <span>Page {currentPage} of {totalPages}</span>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </div>
            <Modal show={openModal} size="6xl" onClose={closemodal} >
                <Modal.Header>News</Modal.Header>
                <Modal.Body>
                    <div className="space-y-2">
                        <div className="flex flex-col pb-2">
                            {images.length > 0 ? (
                                <div>
                                    <div className="mb-5">
                                        <label htmlFor={`dropzone-file-`} className="bg-black text-white rounded-lg text-sm font-semibold px-2 py-1 cursor-pointer">
                                            Replace
                                        </label>
                                        <input
                                            type="file"
                                            id={`dropzone-file-`}
                                            className="hidden"
                                            multiple={multiple}
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                    <div className='images mt-2 flex flex-row items-start w-full flex-wrap gap-2'>
                                        <div className='mt-2 flex flex-wrap gap-2'>
                                            <div className="">
                                                {images.map((image, index) => (
                                                    <div key={index} className='relative'>
                                                        <img src={image.url} alt={`Uploaded ${index}`} className="h-32  rounded-lg" />
                                                        <button
                                                            type="button"
                                                            onClick={() => removeimg(index)}
                                                            className="absolute top-2 right-2 bg-red-500 text-white rounded-full text-xl p-1"
                                                        >
                                                            <AiOutlineDelete />
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                edit ? (
                                    <div className="">
                                        <div className="mb-5">
                                            <label htmlFor={`dropzone-file-`} className="bg-black text-white rounded-lg text-sm font-semibold px-2 py-1 cursor-pointer">
                                                Replace
                                            </label>
                                            <input
                                                type="file"
                                                id={`dropzone-file-`}
                                                className="hidden"
                                                multiple={multiple}
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                        <div className=''>
                                            <img src={news.image} alt={`Uploaded`} className="h-32  rounded-lg" />
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <span className="text-lg font-medium mb-2">Upload Image</span>
                                        <Label
                                            htmlFor={`dropzone-file`}
                                            className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100"
                                        >
                                            <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                                <MdCloudDownload className='text-3xl text-gray-400' />
                                                <p className="mb-2 text-sm text-gray-500">
                                                    <span className="font-semibold">Click to upload</span> or drag and drop
                                                </p>
                                                <p className="text-xs text-gray-500">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                            </div>
                                            <FileInput
                                                id={`dropzone-file`}
                                                className="hidden"
                                                multiple={multiple}
                                                onChange={handleFileChange}
                                            />
                                        </Label>
                                    </>
                                )
                            )}
                        </div>
                        <div className="flex flex-row gap-5 ">
                            <div className="flex flex-col w-2/5">
                                <span className="text-lg font-medium">Title</span>
                                <input type="text"
                                    className="w-full mt-2 mb-1 py-2 rounded-lg focus:ring-0 focus:border-black border-gray-300"
                                    name='title' placeholder='Enter News Title' value={news.title} onChange={(e) => setNews({ ...news, title: e.target.value })} />
                            </div>
                            <div className="flex flex-col w-2/5">
                                <span className="text-lg font-medium">Sub Title</span>
                                <input type="text"
                                    className="w-full mt-2 mb-1 py-2 rounded-lg focus:ring-0 focus:border-black border-gray-300"
                                    name='sub_title' placeholder='Enter Sub Title' value={news.sub_title} onChange={(e) => setNews({ ...news, sub_title: e.target.value })} />
                            </div>
                            <div className="flex flex-col w-1/5">
                                <span className="text-lg font-medium">Status</span>
                                <label className="inline-flex items-center cursor-pointer mt-4">
                                    <span className="text-sm font-medium text-gray-900 dark:text-gray-300 pe-3">{news.status}</span>
                                    <input type="checkbox" className="sr-only peer"
                                        onChange={() =>
                                            setNews((prevState) => ({
                                                ...prevState,
                                                status: prevState.status === "Active" ? "Inactive" : "Active"
                                            }))}
                                        checked={news.status === "Active"}
                                    />
                                    <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600"></div>
                                </label>
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-lg font-medium">Description</span>
                            <textarea value={news.description} onChange={(e) => setNews({ ...news, description: e.target.value })} className='w-full rounded-lg mt-2 focus:border-black border-gray-300 focus:ring-0' rows={8} name="description"
                            ></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='flex justify-end'>
                    <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={() => addnews(news.newsletter_id)}>
                        {!edit ? 'Add' : 'update'} News
                    </button>
                    <button type="button" onClick={closemodal} className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default Addnews