import { Button, Drawer } from "flowbite-react";
import { useState } from "react";

import Navbar from '../LandingPage/Navbar'
import Footer from '../LandingPage/Footer'
import { BsArrowRight } from "react-icons/bs";
import '../../App.css'
import Dropdown from '../utiles/Dropdown';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import logo from '../Assets/Images/adminlogo.png'
import check from '../Assets/Images/check.png'
import uncheck from '../Assets/Images/uncheck.png'
import { Link, useNavigate } from 'react-router-dom';
import { GiHamburgerMenu } from "react-icons/gi";
import notify from "../utiles/Notification";
import PhoneInput from 'react-phone-input-2';
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";


const BecomeInfluencer = () => {

    const [isOpen, setIsOpen] = useState(false);
  const [captchaVal, setCaptchaVal] = useState(null);

  const navigate = useNavigate();


    const handleClose = () => setIsOpen(false);

    const [activeTab, setActiveTab] = useState(0);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [cpasswordVisible, setcPasswordVisible] = useState(false);
    const [ifscDetails, setIfscDetails] = useState('');


    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const togglecPasswordVisibility = () => {
        setcPasswordVisible(!cpasswordVisible);
    };

    const tabs = [
        { title: 'Influencer Information', content: 'Content for Second Tab' },
        { title: 'Bank Details', content: 'Content for Third Tab' },
        { title: 'Verification', content: 'Content for Fifth Tab' },
    ];

    const handlePrevClick = () => {
        if (activeTab > 0) {
            setActiveTab(prev => prev - 1);
        }
    };

    const handleNextClick = () => {
        if (activeTab < tabs.length - 1) {
            setActiveTab(prev => prev + 1);
        }
    };

    const [colour, setColour] = useState('select country');


    const colourOptions = [
        { label: 'India' },
        { label: 'USA' },
    ];


    const handleNextClickInfluencerInfo = () => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{6,}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!influencerInfo.firstName) {
            notify('danger', 'Error', 'Influencer First Name is required');
            return
        }
        if (!influencerInfo.lastName) {
            notify('danger', 'Error', 'Influencer Last Name is required');
            return
        }
        if (!influencerInfo.email) {
            notify('danger', 'Error', 'Influencer Email is required');
            return
        }
        if (!emailRegex.test(influencerInfo.email)) {
            notify('danger', 'Error', 'Invalid Email Format');
            return;
        }
        if (!influencerInfo.mobile) {
            notify('danger', 'Error', 'Influencer Phone is required');
            return
        }
        if (!influencerInfo.password) {
            notify('danger', 'Error', 'Influencer Password is required');
            return
        }
        if (!influencerInfo.confirmPassword) {
            notify('danger', 'Error', 'Influencer Confirm Password is required')
            return
        }
        if (influencerInfo.password !== influencerInfo.confirmPassword) {
            notify('danger', 'Error', 'Confirm Password is Not Matched');
            return
        }
        if (!passwordRegex.test(influencerInfo.password)) {
            notify(
                'danger',
                'Error',
                'Password must be at least 6 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character'
            );
            return;
        }
        if (activeTab < tabs.length - 1) {
            setActiveTab(prev => prev + 1);
        }

        // console.log('influencerInfo===', influencerInfo)
    }

    const handelNextClickBankDetails = () => {
        const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
        if (!influencerBankDetails.accountHoldername) {
            notify('danger', 'Error', 'Please Enter Account Holdername')
            return
        }
        if (!influencerBankDetails.bankAccountNo) {
            notify('danger', 'Error', 'Please Enter Bank Account Number')
            return
        }
        if (!influencerBankDetails.reenterBankAccountNo) {
            notify('danger', 'Error', 'Please Re-Enter Bank Account Number')
            return
        }
        if (!influencerBankDetails.IFSCcode) {
            notify('danger', 'Error', 'Please Enter IFSC Code')
            return
        }
        if (influencerBankDetails.bankAccountNo !== influencerBankDetails.reenterBankAccountNo) {
            notify('danger', 'Error', 'Bank Account No and Re-Enter Bank Account Number Not Match')
            return
        }
        if (!ifscRegex.test(influencerBankDetails.IFSCcode)) {
            notify('danger', 'Error', 'Enter Valid IFSC code')
            return
        }
        if (activeTab < tabs.length - 1) {
            setActiveTab(prev => prev + 1);
        }
        // console.log(influencerBankDetails)
    }

  const baseUrl = process.env.REACT_APP_BASEURL

  
    const handelRegister = async () => {
        const isChecked = document.getElementById('agreeCheckbox').checked;
        if (!isChecked) {
          notify('danger', 'Error', 'You must agree with all documents before registering.');
          return;
        }
      
        if (!captchaVal) {
          notify('danger', 'Error', 'Please complete the CAPTCHA before registering.');
          return;
        }
      
        const payload = {
          influencer_info: influencerInfo,
          bank_details: influencerBankDetails,
        };
      
        try {
          const response = await axios.post(`${baseUrl}/register_as_influencer`, payload);
          if (response.status) {
            notify('success', 'Success', 'Registration successful');
            navigate('../../Admin/login')
          } else {
            notify('danger', 'Error', `Registration failed with status code: ${response.status}`);
          }
        } catch (error) {
        //   console.error('Error during registration:', error);
          notify('danger', 'Error', 'There was an error during registration. Please try again.');
        }
      };

    const [influencerInfo, setInfluencerInfo] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        password: '',
        confirmPassword: ''
    });

    const [influencerBankDetails, setInfluencerBankDetails] = useState({
        accountHoldername: '',
        bankAccountNo: '',
        reenterBankAccountNo: '',
        IFSCcode: '',
    })


    const handleIFSCChange = (e) => {
        const newIFSC = e.target.value.toUpperCase(); // Convert to uppercase for consistency
        setInfluencerBankDetails({ ...influencerBankDetails, IFSCcode: newIFSC });
        handleGetIfscInfo(newIFSC);
    };
    const handleGetIfscInfo = async (ifscCode) => {

        try {
            setIfscDetails('')
            const response = await fetch(`https://ifsc.razorpay.com/${ifscCode}`);
            const details = await response.json();
            if (response.status === 200) {
                setIfscDetails(details)
            }
        } catch (error) {
            console.error('Error fetching IFSC details:', error);
        }
    }

    return (
        <>
            <div className="w-full flex flex-row place-items-center shadow-xl bg-white sticky top-0 md:py-0 py-2">
                <div className="md:hidden block px-2 text-2xl" onClick={() => setIsOpen(true)}>
                    <GiHamburgerMenu />
                </div>
                <div className="md:w-1/3 w-1/2 flex md:justify-center justify-between place-items-center md:px-0 px-4">
                    <img src={logo} alt="logo" className='md:h-20 md:w-20 h-12 w-12' />
                </div>
                <div className="w-1/3 md:block hidden">
                    <div className="flex flex-row gap-7 place-items-center justify-center">
                        <Link to='/' className='font-medium text-lg capitalize hover:scale-105 transition-all duration-300 ease-in-out' >Home</Link>
                        <Link to='/' className='font-medium text-lg capitalize hover:scale-105 transition-all duration-300 ease-in-out' >Help Center</Link>
                        <Link to='/' className='font-medium text-lg capitalize hover:scale-105 transition-all duration-300 ease-in-out' >FAQ</Link>
                        <Link to='/' className='font-medium text-lg capitalize hover:scale-105 transition-all duration-300 ease-in-out' >Contact Us</Link>
                    </div>
                </div>
                <div className="md:w-1/3 w-1/2 flex place-items-center justify-center gap-5 pe-5 md:pe-0">
                    <h1 className='text-lg font-medium md:block hidden'>Already a  User?</h1>
                    <Link to='/admin/login' className='bg-black text-white px-3 py-2 md:ms-0 ms-auto rounded-md text-base font-semibold capitalize'>Log in</Link>
                </div>
            </div>
            <div className="h-full ">
                <div className="container mx-auto md:py-10 py-5 md:px-0 px-5">
                    <span className='text-lg font-semibold block md:hidden'>Steps :-</span>
                    <div className="flex md:flex-row flex-col place-items-start md:justify-evenly justify-start">
                        {tabs.map((tab, index) => (
                            <div key={index} className="flex place-items-start gap-2">
                                <div className={`flex md:flex-col flex-row justify-center place-items-center md:gap-5 ${index <= activeTab ? 'text-black font-bold ' : 'text-gray-400 '}`}>
                                    <span className={`md:border ${index <= activeTab ? 'border-black text-black' : 'border-gray-400 text-gray-400'
                                        } w-10 h-10 flex justify-center items-center md:rounded-full text-lg font-medium`}>
                                        {index + 1}<span className='md:hidden block'>.</span>
                                    </span>
                                    <h1 className="text-sm font-semibold capitalize">{tab.title}</h1>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="mt-8 md:px-10">

                        {tabs[activeTab].title == tabs[0].title &&
                            <div className="flex justify-center place-items-center flex-col ">
                                <h2 className="text-2xl font-semibold capitalize w-full md:w-2/5 flex justify-start place-items-start">{tabs[activeTab].title}</h2>
                                <div className="w-full md:w-2/5 md:p-10 p-0  rounded-2xl  mt-3 flex justify-center place-items-center flex-col md:shadow-[0px_5px_15px_rgba(0,0,0,0.35)]" >
                                    <div className="w-full space-y-5">
                                        <div className="flex md:flex-row flex-col place-items-center gap-5 w-full">
                                            <div className="flex flex-col gap-2 w-full">
                                                <h1 className='text-base font-semibold capitalize text-left'>First Name <span className="text-red-500">*</span></h1>
                                                <input type="text" className='w-full rounded-md border border-gray-300 px-2  focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your First Name'
                                                    value={influencerInfo.firstName}
                                                    onChange={(e) => setInfluencerInfo({ ...influencerInfo, firstName: e.target.value })}
                                                />
                                            </div>
                                            <div className="flex flex-col gap-2 w-full">
                                                <h1 className='text-base font-semibold capitalize text-left'>Last Name <span className="text-red-500">*</span></h1>
                                                <input type="text" className='w-full rounded-md border border-gray-300 px-2  focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your Last Name'
                                                    value={influencerInfo.lastName}
                                                    onChange={(e) => setInfluencerInfo({ ...influencerInfo, lastName: e.target.value })}
                                                />

                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold capitalize text-left'>Email <span className="text-red-500">*</span></h1>
                                            <input type="email" className='w-full rounded-md border border-gray-300 px-2  focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your E-mail'
                                                value={influencerInfo.email}
                                                onChange={(e) => setInfluencerInfo({ ...influencerInfo, email: e.target.value })}
                                            />
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold capitalize text-left'>Mobile <span className="text-red-500">*</span></h1>
                                            <PhoneInput
                                                country={'in'}
                                                value={influencerInfo.mobile}
                                                countryCodeEditable={false}
                                                enableSearch={true}
                                                onChange={(val) => setInfluencerInfo({ ...influencerInfo, mobile: val })}
                                                placeholder="Enter Mobile Number"
                                                inputClass="input-mobile mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-400 focus:ring-1"
                                            />
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold capitalize text-left'>password <span className="text-red-500">*</span></h1>
                                            <div className="flex flex-row border-gray-300 border place-items-center rounded-md overflow-hidden">
                                                <input type={passwordVisible ? "text" : "password"} className='w-full  px-2  no-arrows  border-0 focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your Password'
                                                    value={influencerInfo.password}
                                                    onChange={(e) => setInfluencerInfo({ ...influencerInfo, password: e.target.value })}
                                                />
                                                <div className="pe-2 cursor-pointer" onClick={togglePasswordVisibility}>
                                                    {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold capitalize text-left'>confirm password <span className="text-red-500">*</span></h1>
                                            <div className="flex flex-row border-gray-300 border place-items-center rounded-md overflow-hidden">
                                                <input type={cpasswordVisible ? "text" : "password"} className='w-full  px-2  no-arrows  border-0 focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your Confirm Password'
                                                    value={influencerInfo.confirmPassword}
                                                    onChange={(e) => setInfluencerInfo({ ...influencerInfo, confirmPassword: e.target.value })}
                                                />
                                                <div className="pe-2 cursor-pointer" onClick={togglecPasswordVisibility}>
                                                    {cpasswordVisible ? <FaEyeSlash /> : <FaEye />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-row place-items-center gap-5 ">
                                        {activeTab != 0 &&
                                            <button className='capitalize px-5 text-base md:text-base font-semibold mt-10  py-2 border border-black text-black bg-white rounded-lg' onClick={handlePrevClick}>previous</button>
                                        }
                                        <button className='capitalize px-5 text-base md:text-base font-semibold mt-10  py-2 text-white bg-black rounded-lg' onClick={handleNextClickInfluencerInfo}
                                            disabled={activeTab === tabs.length - 1}>Next</button>
                                    </div>
                                </div>
                            </div>
                        }
                        {tabs[activeTab].title == tabs[1].title &&
                            <div className="flex justify-center place-items-center flex-col">
                                <h2 className="text-2xl font-semibold capitalize w-full flex md:w-2/5 justify-start place-items-start">Add Yout Bank Account</h2>
                                <div className="w-full md:w-2/5 md:p-10 rounded-2xl  mt-3 flex justify-center place-items-center flex-col md:shadow-[0px_5px_15px_rgba(0,0,0,0.35)]" >
                                    <div className="w-full space-y-5">
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold capitalize text-left'>Account Holder Name <span className="text-red-500">*</span></h1>
                                            <input type="text" className='w-full rounded-md border border-gray-300 px-2  focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your Account Holder Name'
                                                value={influencerBankDetails.accountHoldername}
                                                onChange={(e) => setInfluencerBankDetails({ ...influencerBankDetails, accountHoldername: e.target.value })}
                                            />
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold capitalize text-left'>Bank Account Number <span className="text-red-500">*</span></h1>
                                            <input type="text" className='w-full rounded-md border border-gray-300 px-2  focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your Bank Account Number'
                                                value={influencerBankDetails.bankAccountNo}
                                                onChange={(e) => setInfluencerBankDetails({ ...influencerBankDetails, bankAccountNo: e.target.value })}
                                            />
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold capitalize text-left'>Re-enter Bank Account Number <span className="text-red-500">*</span></h1>
                                            <div className="flex flex-row border-gray-300 border place-items-center rounded-md overflow-hidden">
                                                <input type={passwordVisible ? "text" : "password"} className='w-full  px-2  no-arrows  border-0 focus:ring-0 py-1.5 focus:outline-0' placeholder=' Re-enter Bank Account Number'
                                                    value={influencerBankDetails.reenterBankAccountNo}
                                                    onChange={(e) => setInfluencerBankDetails({ ...influencerBankDetails, reenterBankAccountNo: e.target.value })}
                                                />
                                                <div className="pe-2 cursor-pointer" onClick={togglePasswordVisibility}>
                                                    {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold capitalize text-left'>UNIQUE Code <span className="text-red-500">*</span></h1>
                                            <input type="text" className='w-full rounded-md border border-gray-300 px-2 no-arrows focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your Bank UNIQUE Code'
                                                value={influencerBankDetails.IFSCcode}
                                                onChange={handleIFSCChange}
                                                style={{ textTransform: 'uppercase' }}
                                                pattern="[A-Z0-9]*"
                                            />
                                            {ifscDetails && (
                                                <div className="mt-2">
                                                    <div className="flex gap-2">
                                                        <h2 className="text-lg mt-1">Branch:</h2>
                                                        <p className="text-sky-400 font-semibold mt-2 px-1">{ifscDetails.BRANCH}</p>
                                                        <h2 className="text-lg mt-1">Center:</h2>
                                                        <p className="text-sky-400 font-semibold mt-2 px-1">{ifscDetails.CENTRE}</p>
                                                    </div>
                                                    <h2 className="text-lg">Address:</h2>
                                                    <p className="text-sky-400 font-semibold">{ifscDetails.ADDRESS}</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex flex-row place-items-center gap-5 md:ms-5">
                                        <button className='capitalize px-5 text-base md:text-base font-semibold mt-10  py-2 border border-black text-black bg-white rounded-lg' onClick={handlePrevClick}
                                            disabled={activeTab === 0}>previous</button>
                                        <button className='capitalize px-5 text-base md:text-base font-semibold mt-10  py-2 text-white bg-black rounded-lg' onClick={handelNextClickBankDetails}
                                            disabled={activeTab === tabs.length - 1}>Next</button>
                                    </div>
                                </div>
                            </div>
                        }

                        {tabs[activeTab].title == tabs[2].title &&
                            <div className="flex justify-center place-items-center flex-col">
                                <h2 className="text-2xl font-semibold capitalize w-full md:w-2/5 flex justify-start place-items-start">Declaration</h2>
                                <div className="w-full md:w-2/5 md:p-10 rounded-2xl  mt-3 flex justify-center place-items-center flex-col md:shadow-[0px_5px_15px_rgba(0,0,0,0.35)]" >
                                    <div className="w-full space-y-5">
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold capitalize text-left'>Required Documents <span className="text-red-500">*</span></h1>
                                            <div className="flex flex-col gap-3 mt-2">
                                                <div className="flex flex-row justify-between place-items-center">
                                                    <span>GST Certificate</span>
                                                    <img src={check} alt="" className='md:w-7 md:h-7 h-5 w-5' />
                                                </div>
                                                <div className="flex flex-row justify-between place-items-center">
                                                    <span>PAN Card</span>
                                                    <img src={check} alt="" className='md:w-7 md:h-7 h-5 w-5' />
                                                </div>
                                                <label className="flex flex-row gap-3 place-items-center">
                                                    <input type="checkbox" id="agreeCheckbox" className='w-5 h-5 focus:ring-0 text-black' />
                                                    <span className='text-lg capitalize'>I agree with all documents</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <ReCAPTCHA
                                        className="mt-3"
                                        sitekey="6LekFjIqAAAAAC8_a7HDtWUTNtcSX1uxGCTbZptj"
                                        onChange={val => setCaptchaVal(val)}
                                    />
                                    <div className="flex flex-row place-items-center gap-5 ">
                                        <button className='capitalize px-5 text-base md:text-base font-semibold mt-5 py-2 border border-black text-black bg-white rounded-lg' onClick={handlePrevClick}
                                            disabled={activeTab === 0}>previous</button>
                                        <button className='capitalize px-5 text-base md:text-base font-semibold mt-5 py-2 text-white bg-black rounded-lg' onClick={handelRegister}>Register</button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Drawer open={isOpen} onClose={handleClose}>
                <div>
                    <Drawer.Header
                        title={<span className="text-2xl font-medium capitalize">menu</span>}
                        titleText={() => <></>} titleIcon={() => <></>}
                    />
                </div>
                <Drawer.Items>
                    <div className="flex flex-col gap-5 place-items-start justify-center mt-3 ">
                        <Link to='/' className='font-medium text-lg capitalize hover:scale-105 transition-all duration-300 ease-in-out' >Home</Link>
                        <Link to='' className='font-medium text-lg capitalize hover:scale-105 transition-all duration-300 ease-in-out' >Help Center</Link>
                        <Link to='' className='font-medium text-lg capitalize hover:scale-105 transition-all duration-300 ease-in-out' >FAQ</Link>
                        <Link to='' className='font-medium text-lg capitalize hover:scale-105 transition-all duration-300 ease-in-out' >Contact Us</Link>
                    </div>
                </Drawer.Items>
            </Drawer>
        </>
    )
}

export default BecomeInfluencer
