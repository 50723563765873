import React, { useState, useRef, useEffect } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import Dropdown from '../../Component/utiles/Dropdown';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import notify from '../../Component/utiles/Notification';
import Loader from '../../Component/utiles/Loader';
import Editcategorycomponent from '../Component/Editcategorycomponent';

const useSubcategories = (url, body, setState, fallback, arrayKey, reqid, resid, resname, setLoading, again) => {

    useEffect(() => {
        const fetchSubcategories = async () => {
            setLoading(true);
            try {
                const response = await axios.post(url, body, {
                    headers: { 'Content-Type': 'application/json' }
                });

                if (response.data.status) {
                    const subcategories = response.data[arrayKey].map(categoryValue => ({
                        id: categoryValue[resid],
                        label: categoryValue[resname],
                        status: categoryValue.status
                    }));
                    setState(subcategories);
                    setLoading(false);
                } else {
                    setState(fallback);
                    setLoading(false);
                }
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        };

        fetchSubcategories();
    }, [reqid, again]);
};

const Editcategory = ({ setGetctgdata }) => {

    const { id } = useParams();
    const [loading, setLoading] = useState(false);

    const [category_2, setCategory_2] = useState('Select Category');
    const [subcategoryID, setSubcategoryID] = useState('');
    const [subcategoryoption, setSubcategoryoption] = useState([]);

    const [category_3, setCategory_3] = useState('Select Category');
    const [subcategory3ID, setSubcategory3ID] = useState('');
    const [subcategoryoption3, setSubcategoryoption3] = useState([]);


    const [category_4, setCategory_4] = useState('Select Category');
    const [subcategoryoption4, setSubcategoryoption4] = useState([]);

    const [refresh2, setRefresh2] = useState('');
    const [refresh3, setRefresh3] = useState('');
    const [refresh4, setRefresh4] = useState('');

    const [main, setMain] = useState(true);
    const [Level2, setLevel2] = useState(false);
    const [Level3, setLevel3] = useState(false);
    const [Level4, setLevel4] = useState(false);

    const ctgoptions = [
        { label: 'Not Found Category' },
    ];

    const baseUrl = process.env.REACT_APP_BASEURL

    useSubcategories(
        `${baseUrl}/subCategories`,
        { category_id: id },
        setSubcategoryoption,
        ctgoptions,
        'sub_categories_array',
        id,
        'sub_category_1_id',
        'sub_category_1_name',
        setLoading,
        refresh2
    );

    useSubcategories(
        `${baseUrl}/subSubCategories`,
        { sub_category_1: subcategoryID },
        setSubcategoryoption3,
        ctgoptions,
        'sub_sub_categories_array',
        subcategoryID,
        'sub_category_2_id',
        'sub_category_2_name',
        setLoading,
        refresh3
    );

    useSubcategories(
        `${baseUrl}/subSubSubCategories`,
        { sub_category_2: subcategory3ID },
        setSubcategoryoption4,
        ctgoptions,
        'sub_sub_sub_categories_array',
        subcategory3ID,
        'sub_category_3_id',
        'sub_category_3_name',
        setLoading,
        refresh4
    );



    const [category, setCategory] = useState({
        category_name: '',
        home: '',
        description: '',
        category_image: '',
        category_status: ''
    });

    const [category2, setCategory2] = useState({
        sub_category_1_id: '',
        sub_category_1_name: '',
        status: ''
    });

    const [category3, setCategory3] = useState({
        sub_category_2_id: '',
        sub_category_2_name: '',
        status: ''
    });

    const [category4, setCategory4] = useState({
        sub_category_3_id: '',
        sub_category_3_name: '',
        status: ''
    });

    useEffect(() => {
        setLoading(true)
        const allctg = async () => {
            try {
                const response = await axios.post(`${baseUrl}/get_main_category`, { category_id: id });
                if (response.data.status) {
                    const categoryData = response.data.category[0];
                    setCategory({
                        category_name: categoryData.category_name || '',
                        home: categoryData.home || '',
                        description: categoryData.description || '',
                        category_image: categoryData.category_image || '',
                        category_status: categoryData.category_status || '',
                    });
                }
            } catch (err) {
                console.log(err);
            }
        };
        allctg();
        setLoading(false)
    }, [id]);

    const handleselectsubcategory = (val) => {
        const selectedsubCategory = subcategoryoption.find(option => option.label === val);
        if (selectedsubCategory) {
            setCategory_3('Select Category')
            setCategory_2(selectedsubCategory.label)
            setSubcategoryID(selectedsubCategory.id);
            setCategory2({
                sub_category_1_id: selectedsubCategory.id,
                sub_category_1_name: selectedsubCategory.label,
                status: selectedsubCategory.status
            })
            setMain(false);
            setLevel3(false);
            setLevel2(true);
        }
    };
    const handleselectsubcategory3 = (val) => {
        const selectedsubCategory3 = subcategoryoption3.find(option => option.label === val);
        if (selectedsubCategory3) {
            setCategory_4('Select Category')
            setCategory_3(selectedsubCategory3.label)
            setSubcategory3ID(selectedsubCategory3.id);
            setCategory3({
                sub_category_2_id: selectedsubCategory3.id,
                sub_category_2_name: selectedsubCategory3.label,
                status: selectedsubCategory3.status
            })
            setMain(false);
            setLevel2(false);
            setLevel3(true);
            setLevel4(false);

        }
    };
    const handleselectsubcategory4 = (val) => {
        const selectedsubCategory4 = subcategoryoption4.find(option => option.label === val);
        if (selectedsubCategory4) {
            setCategory_4(selectedsubCategory4.label)
            setCategory4({
                sub_category_3_id: selectedsubCategory4.id,
                sub_category_3_name: selectedsubCategory4.label,
                status: selectedsubCategory4.status
            })
            setMain(false);
            setLevel2(false);
            setLevel3(false);
            setLevel4(true);
        }
    };

    const openone = async () => {
        setMain(true);
        setLevel2(false);
        setLevel3(false);
        setLevel4(false);
    }

    return (
        <>
            <h1 className="xl:text-2xl md:text-lg text-xl mt-4 font-semibold">Edit Category</h1>
            <div className="flex lg:flex-row flex-col lg:gap-9 md:gap-5 gap-3 mt-4 lg:m-5 md:m-2">
                <div className="lg:w-6/12 w-full  bg-white rounded-lg md:p-6 p-3">
                    <div className='flex flex-row place-items-center'>
                        <div className="w-full">
                            <span className="text-lg font-medium capitalize">Category Title {!main &&
                                <span className='ms-1 cursor-pointer font-normal text-sm underline text-blue-500' onClick={openone}>Edit</span>
                            }</span>
                            <input
                                className="w-full mt-2 mb-1 rounded-lg py-2 focus:ring-0 focus:border-black border-gray-300"
                                placeholder="Enter Category Title"
                                type="text"
                                name="category_name"
                                value={category.category_name}
                                onChange={(e) => setCategory({ ...category, category_name: e.target.value })}
                            />
                        </div>
                        <div className="flex flex-row gap-2">
                            <label className="inline-flex items-center me-5 cursor-pointer w-full mt-6">
                                <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 px-3">Active</span>
                                <input type="checkbox" className="sr-only peer"
                                    checked={category.category_status === 'Active'}
                                    onChange={(e) => setCategory({ ...category, category_status: e.target.checked ? 'Active' : 'Inactive' })}
                                />
                                <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600"></div>
                            </label>
                            <label className="inline-flex items-center me-5 cursor-pointer w-full mt-6">
                                <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 px-3">Home</span>
                                <input type="checkbox" className="sr-only peer"
                                    checked={category.home === '1'}
                                    onChange={(e) => setCategory({ ...category, home: e.target.checked ? '1' : '0' })}
                                />
                                <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600"></div>
                            </label>
                        </div>
                    </div>
                    <div className="flex flex-col gap-3 mt-3">
                        {category !== 'Select Category' && (
                            <Dropdown
                                label=""
                                options={subcategoryoption}
                                search={true}
                                selectedValues={category_2}
                                onSelect={handleselectsubcategory}
                                onRemove={() => { }}
                                isMultiple={false}
                            />
                        )}
                        {category_2 !== 'Select Category' && subcategoryoption3[0].label !== 'Not Found Category' && (
                            <Dropdown
                                label=""
                                options={subcategoryoption3}
                                search={true}
                                selectedValues={category_3}
                                onSelect={handleselectsubcategory3}
                                onRemove={() => { }}
                                isMultiple={false}
                            />
                        )}
                        {category_3 !== 'Select Category' && subcategoryoption4[0].label !== 'Not Found Category' && (
                            <Dropdown
                                label=""
                                options={subcategoryoption4}
                                selectedValues={category_4}
                                search={true}
                                onSelect={handleselectsubcategory4}
                                onRemove={() => { }}
                                isMultiple={false}
                            />
                        )}

                    </div>
                </div>
                <div className='md:w-full lg:w-6/12 bg-white rounded-lg md:p-6 p-3'>
                    {main &&
                        <Editcategorycomponent id={id} setLoading={setLoading} category={category} setCategory={setCategory} open1={true} setRefresh2={setGetctgdata} />
                    }
                    {Level2 &&
                        <Editcategorycomponent id={id} setLoading={setLoading} category={category2} setCategory={setCategory2} open2={true} setRefresh={setCategory_2} setRefresh2={setRefresh2} />
                    }
                    {Level3 &&
                        <Editcategorycomponent id={id} setLoading={setLoading} category={category3} setCategory={setCategory3} open3={true} setRefresh={setCategory_3} setRefresh2={setRefresh3} />
                    }
                    {Level4 &&
                        <Editcategorycomponent id={id} setLoading={setLoading} category={category4} setCategory={setCategory4} open4={true} setRefresh={setCategory_4} setRefresh2={setRefresh4} />
                    }
                </div>
            </div>
            {loading && <Loader />}
        </>
    )
}

export default Editcategory