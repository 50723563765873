import React from 'react'
import bigsale1 from '../Assets/Images/headphones-png-14981.png'
import bigsale2 from '../Assets/Images/Bidsale-2.png'
import bigsale3 from '../Assets/Images/Mac.png'



const Bigsale = () => {

    const bigsale = [
        {
            title: 'Headphones',
            discount: 'Up to 80% off',
            image: bigsale1,
            bg: ' bg-gray-300',
        },
        {
            title: 'Mobile Phones',
            discount: 'From € 999',
            image: bigsale2,
            bg: 'bg-pink-200',
        },
        {
            title: 'Laptops',
            discount: 'Up to 50% off',
            image: bigsale3,
            bg: 'bg-gray-400',
        },
        {
            title: 'Headphones',
            discount: 'Up to 90% off',
            image: bigsale1,
            bg: ' bg-gray-300',
        },
    ];


    return (
        <>
            <div className="container mx-auto px-4 py-12">
                <h2 className="text-2xl md:text-3xl font-semibold pb-3 text-gray-900">
                    Special Offers
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                    {bigsale.map((offer, index) => (
                        <div key={index} className="group bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
                            <div className="relative h-56 overflow-hidden flex justify-center place-items-center">
                                <img
                                    src={offer.image}
                                    alt={offer.title}
                                    className="w-52 h-52 object-cover object-center transition-transform duration-300 group-hover:scale-105"
                                />
                                <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-0 group-hover:opacity-20 transition-opacity duration-300"></div>
                            </div>
                            <div className="p-4 relative">
                                <h3 className="text-lg font-semibold mb-1 text-gray-800">{offer.title}</h3>
                                <p className="text-xl font-bold text-black group-hover:text-red-600 transition-colors duration-300">{offer.discount}</p>
                                <div className="absolute top-0 right-0 bg-red-600 text-white px-3 py-1 text-sm font-semibold transform translate-y-[-50%] rotate-3">
                                    SALE
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Bigsale
