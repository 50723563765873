import React from 'react'
import bgImage from '../Assets/Images/banner-bg-shoe.png';
import Navbar from '../LandingPage/Navbar';
import ProductImage from '../Assets/Images/shoe.png'
import { MdDelete } from "react-icons/md";
import { Link } from 'react-router-dom';


const WishList = () => {
    const cartItems = [
        { id: 1, name: 'Lorem Ipsum, sometimes', price: 499.00, stock_status: 'In Stocks', image: ProductImage },
        { id: 'In Stocks', name: 'Lorem Ipsum, sometimes', price: 499.00, stock_status: 'Out of Stocks', image: ProductImage },
        { id: 3, name: 'Lorem Ipsum, sometimes', price: 499.00, stock_status: 'In Stocks', image: ProductImage },
      ];
    
      const cartTotal = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
    
      return (
        <>
          <Navbar />
          <div 
            className="relative bg-cover bg-center h-[500px] flex items-center justify-center opacity-90"
            style={{ backgroundImage: `url(${bgImage})` }}
          >
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="relative text-center text-white">
              <h1 className="text-5xl font-bold">WISHLIST</h1>
              <p className="mt-2">
                <Link to="/">Home</Link> <span className="mx-2">›</span> <Link to="/whish-list">Whishlist</Link>
              </p>
            </div>
          </div> 
    
          <div className="container mx-auto px-4 py-8">
            <div className="hidden md:block mb-4">
              <table className="min-w-full bg-white ">
                <thead className="bg-gray-100 rounded-xl">
                  <tr>
                    <th className="py-4 px-4 text-left">Product</th>
                    <th className="py-4 px-4 text-left">Price</th>
                    <th className="py-4 px-4 text-left">Stock Status</th>
                    <th className="py-4 px-4 text-left"></th>
                  </tr>
                </thead>
                <tbody>
                  {cartItems.map(item => (
                    <tr key={item.id} className="border-b border-gray-200">
                      <td className="py-4 px-4  flex items-center gap-4">
                      <MdDelete className="text-xl cursor-pointer text-gray-400" />
                        <img src={item.image} alt={item.name} className="w-16 h-16 mr-4 bg-gray-100 rounded-lg"/>
                        <span>{item.name}</span>
                      </td>
                      <td className="py-4 px-4 ">{item.price.toFixed(2)} €</td>
                      <td className="py-4 px-4">
                        {item.stock_status === 'In Stocks' ? (
                            <span className="bg-green-200 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                            In Stock
                            </span>
                        ) : (
                            <span className="bg-red-200 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                            Out of Stock
                            </span>
                        )}
                        </td>
                      <td className="py-4 px-4 ">
                        <button className="bg-white text-sm border border-black  py-2 px-4 rounded-lg font-medium hover:bg-black hover:text-white">Move to Cart</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            
            <div className="block md:hidden">
              {cartItems.map(item => (
                <div key={item.id} className="bg-white px-4 border-b border-gray-200  mb-4">
                    
                  <div className="flex justify-between mb-2">
                  <img src={item.image} alt={item.name} className="w-24 h-24 bg-gray-100 mt-3"/>
                    <div className='ml-4'>
                        <div className="flex justify-between">
                        <p className='text-lg font-medium'>{item.name}</p>
                      <MdDelete className="text-xl cursor-pointer text-gray-400" />
                      </div>
                      <div className='mt-2'>
                        {item.stock_status === 'In Stocks' ? (
                            <span className="bg-green-200 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                            In Stock
                            </span>
                        ) : (
                            <span className="bg-red-200 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                            Out of Stock
                            </span>
                        )}
                      </div>
                      <div className="flex items-center justify-between mt-3">
                      <div><p className='text-lg font-medium'>{item.price} € </p></div>
                      <button className="bg-white text-xs border border-black  py-1 px-2 rounded-lg font-medium hover:bg-black hover:text-white">Move to Cart</button>
                  </div>
                    </div>
                  </div>
                  
                </div>
              ))}
            </div>
          </div>
        </>
      );
}

export default WishList