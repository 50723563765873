import React, { useState, useRef, useEffect } from 'react'
import { FiEdit } from "react-icons/fi";
import { Link } from 'react-router-dom';
import Dropdown from '../../Component/utiles/Dropdown';
import Pagination from '../../Component/utiles/Pagination';
import Addeditcoupan from '../Component/Addeditcoupan';
import axios from 'axios';
import { TbArrowsExchange2 } from 'react-icons/tb';
import { IoSearchSharp } from 'react-icons/io5';



const Coupon = ({ setLoading }) => {

    const baseUrl = process.env.REACT_APP_BASEURL
    const [refresh, setRefresh] = useState(0)
    const [searchTerm, setSearchTerm] = useState('');



    const [coupons, setCoupons] = useState([]);

    useEffect(() => {
        const fetchAllCoupons = async () => {
            try {
                const response = await axios.get(`${baseUrl}/get_coupon`);
                if (response.data.status) {
                    setCoupons(response.data.coupons);
                }
            } catch (err) {
                console.log(err);
            }
        };

        fetchAllCoupons();
    }, [refresh]);

    const [addcoupan, setAddcoupan] = useState(false);

    const openaddcoupan = () => {
        setAddcoupan(true);
    };
    const closeaddcoupan = () => {
        setAddcoupan(false);
        setEdit(false)
    };

    const perpageoption = [
        { label: '5' }, { label: '10' }, { label: '15' }, { label: '20' }
    ]

    const [itemsPerPage, setItemsPerPage] = useState(perpageoption[0].label);
    const [currentPage, setCurrentPage] = useState(1);

    const searchResults = coupons.filter(item =>
        item.coupon_id.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.coupon_code.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );

    const data = searchTerm ? searchResults : coupons

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(data.length / itemsPerPage);

    useEffect(() => {
        if (searchTerm) {
            setCurrentPage(1);
        }
    }, [searchTerm]);


    const getStatusClass = (stockStatus) => {
        switch (stockStatus.toLowerCase()) {
            case 'in stock':
            case 'active':
            case 'live':
                return 'bg-green-200 text-green-600 text-xs';
            case 'out of stock':
            case 'inactive':
            case 'hold':
                return 'bg-red-200 text-red-500 text-xs';
            default:
                return 'bg-gray-400 text-white text-xs';
        }
    };

    const [onedata, setOnedata] = useState({});
    const [edit, setEdit] = useState(false);


    const editopen = (id) => {
        const Data = coupons.find(list => list.coupon_id === id);
        // console.log(Data)
        setEdit(true)
        setOnedata(Data);
    }

    return (
        <>
            <h1 className="xl:text-2xl md:text-lg text-xl mt-4 font-semibold">Coupon</h1>
            <div className='w-full bg-white rounded-lg mt-5 p-6'>
                <div className="relative flex md:hidden w-full">
                    <input
                        type="text"
                        placeholder="Search Here"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="bg-gray-200 w-full border-0 rounded-full px-4 py-1 pr-10 focus:outline-none focus:border-gray-400 focus:ring-2 focus:ring-gray-400"
                    />
                    <IoSearchSharp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-black cursor-pointer" />
                </div>
                <div className='flex flex-row justify-between my-5 p-0'>
                    <div className='flex flex-row items-center md:w-48 w-20 gap-5'>
                        <span className='text-nowrap mt-2 md:block hidden'>items per page</span>
                        <Dropdown
                            label=""
                            options={perpageoption}
                            selectedValues={itemsPerPage}
                            onSelect={setItemsPerPage}
                            onRemove={() => { }}
                            isMultiple={false}
                        />
                    </div>
                    <div className="relative hidden md:flex w-5/12">
                        <input
                            type="text"
                            placeholder="Search Here"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="bg-gray-200 w-full border-0 rounded-full px-4 py-1 pr-10 focus:outline-none focus:border-gray-400 focus:ring-2 focus:ring-gray-400"
                        />
                        <IoSearchSharp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-black cursor-pointer" />
                    </div>
                    <Link onClick={openaddcoupan} className='text-white flex justify-center items-center font-semibold bg-black ms:px-5 px-3 md:text-base text-xs md:py-2   hover:text-black hover:bg-white hover:border border rounded-lg hover:border-black transition-all duration-200'>Add New Coupon</Link>
                </div>
                <div className='overflow-hidden overflow-x-auto'>
                    <table className="min-w-full table bg-white">
                        <thead className="bg-gray-100 rounded-full">
                            <tr>
                                <th className="py-4 px-4 text-left">Name</th>
                                <th className="py-4 px-4 text-left">Code</th>
                                <th className="py-4 px-4 text-left">Amount</th>
                                <th className="py-4 px-4 text-left">Status</th>
                                <th className="py-4 px-4 text-left">Start</th>
                                <th className="py-4 px-4 text-left">End</th>
                                <th className="py-4 px-4 text-left">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map(item => (
                                <tr key={item.coupon_id} className="border-b border-gray-200 hover:bg-gray-200">
                                    <td className="py-4 px-4 flex items-center gap-4">
                                        <img src={item.coupon_image} alt={item.coupon_code} className="w-16 h-16 mr-4 bg-gray-100 rounded-lg" />
                                        <span className='text-nowrap  pe-16'>{item.coupon_name}</span>
                                    </td>
                                    <td className="py-4 px-4">
                                        <span className='px-2 py-1 bg-neutral-200 rounded-md'>
                                            {item.coupon_code}
                                        </span>
                                    </td>
                                    <td className="py-4 px-4">{item.amount}</td>
                                    <td className="py-4 px-4">
                                        <span className={`px-2 py-1  rounded-lg capitalize font-semibold ${getStatusClass(item.status)}`}>
                                            {item.status}
                                        </span>
                                    </td>
                                    <td className="py-4 px-4 text-nowrap">{item.start_date}</td>
                                    <td className="py-4 px-4 text-nowrap">{item.expire_date}</td>
                                    <td className="p-4">
                                        <div className='flex flex-row items-center gap-5 text-xl'>
                                            <Link onClick={() => editopen(item.coupon_id)} className='cursor-pointer hover:scale-110 duration-200 transition-all px-3 py-2 text-lg rounded-md bg-neutral-300'>
                                                <FiEdit />
                                            </Link>
                                            <div className='px-3 cursor-pointer hover:scale-110 duration-200 transition-all py-2 text-lg rounded-md bg-neutral-300'>
                                                <TbArrowsExchange2 />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-between items-center mt-4">
                    <div>
                        <span>Page {currentPage} of {totalPages}</span>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </div>
            {addcoupan && <Addeditcoupan setRefresh={setRefresh} closeaddcoupan={closeaddcoupan} setLoading={setLoading} />}
            {edit && <Addeditcoupan onedata={onedata} setRefresh={setRefresh} closeaddcoupan={closeaddcoupan} setLoading={setLoading} />}
        </>
    )
}

export default Coupon