import React, { useState } from 'react';
import axios from 'axios';
import LoginImg from '../Assets/Images/login-banner.jpg';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import notify from '../utiles/Notification';
import Loader from '../utiles/Loader'
import { UserState } from '../../Context/userProvider'
import { Link, useNavigate } from 'react-router-dom';


const Login = ({ onClose, onSignUpClick, onisForgotPassClick }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { setUser } = UserState();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!email) {
      newErrors.email = 'E-Mail Address is required';
    }
    if (!password) {
      newErrors.password = 'Password is required';
    }
    return newErrors;
  };

  const baseURL = process.env.REACT_APP_BASEURL

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const newErrors = validateForm();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      const firstError = Object.values(newErrors)[0];
      notify('danger', 'Error', firstError);
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${baseURL}/email_pass_login`,
        {
         email:email,
         password:password
        },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        });

      if (response.data.status) {
        const userData = response.data.customer_data;
        localStorage.setItem('userInfo', JSON.stringify(userData));
        setUser(userData);
        notify('success', 'Success', 'Login successful!');

        onClose();

        setIsLoading(false);
      } else {
        notify('danger', 'Error', response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      notify('danger', 'Error', 'Something went wrong, please try again');
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-70">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden md:flex md:max-w-5xl" data-aos="fade-right" data-aos-duration="1000">
          <div className="md:w-2/4 hidden md:block">
            <img src={LoginImg} alt="Login" className="h-full w-full object-cover" />
          </div>
          <div className="md:w-2/4 p-6 sm:p-12">
            <div className='flex justify-between'>
              <div>
                <h2 className="text-2xl font-bold text-gray-900">Sign In</h2>
                <p className="mt-2 text-gray-600">Please Sign In Below</p>
              </div>
              <div>
                <RxCross2 className='cursor-pointer' onClick={onClose} />
              </div>
            </div>
            <form className="mt-8 space-y-4" onSubmit={handleSubmit}>
              <div>
                <label className="block text-sm font-semibold text-gray-700">E-Mail Addres</label>
                <input
                  type="email"
                  placeholder="Enter E-Mail Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-400 focus:ring-1"
                />
              </div>
              <div>
                <label className="block text-sm font-semibold text-gray-700">Password</label>
                <div className="relative">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    placeholder="Enter Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-400 focus:ring-1"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" onClick={togglePasswordVisibility}>
                    {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                  </div>
                </div>

              </div>
              <div className='text-right'>
                <Link className='text-indigo-500 hover:text-indigo-700 font-medium cursor-pointer' onClick={onisForgotPassClick}>Forgot Password</Link>
              </div>
              <button
                type="submit"
                className="w-full py-2 px-4 bg-black text-white font-semibold rounded-md shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2"
              >
                SIGN IN
              </button>
              <p className="mt-6 text-center text-gray-600">
                Don't have an Account?{' '}
                <button className="text-indigo-500 hover:text-indigo-700 font-semibold" onClick={onSignUpClick}>
                  Sign Up
                </button>
              </p>
            </form>
            <div className="mt-6">
              <button
                type="button"
                className="w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-gray-700 font-medium flex items-center justify-center hover:bg-gray-100 focus:outline-none focus:ring-2"
              >
                <img
                  src="https://img.icons8.com/color/16/000000/google-logo.png"
                  alt="Google"
                  className="mr-2"
                />
                Continue With Google
              </button>
              <button
                type="button"
                className="w-full mt-3 py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-gray-700 font-medium flex items-center justify-center hover:bg-gray-100 focus:outline-none focus:ring-2"
              >
                <img
                  src="https://img.icons8.com/ios-filled/16/000000/mac-os.png"
                  alt="Apple"
                  className="mr-2"
                />
                Continue With Apple
              </button>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default Login;
