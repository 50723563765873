import React from 'react'
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import Privacy_policy from '../Privacy_policy';
import Faqs from '../Faqs';
import Contact_us from '../Contact_us';
import About_us from '../About_Us'
import Navbar from '../../LandingPage/Navbar';
import Footer from '../../LandingPage/Footer';
import Errorpage from '../Errorpage';
import BrandsPage from '../BrandsPage';
import NewsLetter from '../NewsLetter';


const Footer_nav = ({ setLoading }) => {
    return (
        <>
            <Navbar />
            <Routes>
                <Route path="/privacy_policy" element={<Privacy_policy />} />
                <Route path="/press_release" element={<NewsLetter setLoading={setLoading} />} />
                <Route path="/faqs" element={<Faqs />} />
                <Route path="/contact-us" element={<Contact_us />} />
                <Route path="/about-us" element={<About_us />} />
                <Route path='/brands' element={<BrandsPage setLoading={setLoading} />} />
                <Route path="*" element={<Errorpage />} />
            </Routes>
            <Outlet />
            <Footer />
        </>
    )
}

export default Footer_nav
