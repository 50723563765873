import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaSearch } from "react-icons/fa";
import { FaChevronDown, FaChevronUp  } from "react-icons/fa6";
import axios from 'axios';
import Loader from '../utiles/Loader';

const Faqs = () => {
    const baseUrl = process.env.REACT_APP_BASEURL;
    const [faqs, setFaqs] = useState([]);
    const [openIndex, setOpenIndex] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    const contentRefs = useRef([]);

    useEffect(() => {
        setLoading(true);
        axios.get(`${baseUrl}/faqs`)
            .then(response => {
                if (response.data.status) {
                    setFaqs(response.data.faq_array);
                }
                setLoading(false)
            })
            .catch(error => console.log('Error fetching FAQs:', error));
            setLoading(false)
    }, []);

    const filteredFaqs = faqs.filter(faq =>
        faq.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        faq.para.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
        {loading && <Loader />}
        <div className="container mx-auto p-5">
            <div className="flex flex-col space-y-3">
                <h1 className="text-4xl font-medium text-gray-800">FAQs</h1>
                <div className="text-gray-600">
                    <Link to="/" className="hover:text-blue-600">Home</Link>
                    <span className="mx-2">›</span>
                    <span>FAQs</span>
                </div>
            </div>
            <div className='bg-gray-300 text-center lg:p-36 md:p-28 py-20  px-5 mt-3 space-y-7 rounded-lg'>
                <h1 className='lg:text-4xl font-bold'>Frequently Asked Questions</h1>
                <div className="relative">
                    <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                    <input
                        type="text"
                        placeholder="Search frequently asked questions..."
                        className="w-1/2 pl-10 pr-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
            </div>

            <div className="mt-10 max-w-3xl mx-auto">
                <div className="space-y-4">
                    {filteredFaqs.map((faq, index) => (
                        <div
                        key={faq.faq_id}
                        className="border border-gray-200 rounded-lg overflow-hidden"
                    >
                        <button
                            className="w-full px-6 py-4 flex justify-between items-center bg-white hover:bg-gray-50 transition-colors duration-200"
                            onClick={() => handleToggle(index)}
                        >
                            <h3 className="text-lg font-medium text-gray-800 text-left">
                                {faq.title}
                            </h3>
                            <div className={`transform transition-transform duration-300 ${openIndex === index ? 'rotate-180' : ''}`}>
                                <FaChevronDown className="h-5 w-5 text-gray-500" />
                            </div>
                        </button>
                        <div
                            className={`transition-all duration-300 ease-in-out overflow-hidden ${
                                openIndex === index ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
                            }`}
                            ref={el => contentRefs.current[index] = el}
                        >
                            <div className="px-6 py-4 bg-gray-50">
                                <p className="text-gray-600 leading-relaxed">
                                    {faq.para}
                                </p>
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </div>
        </>
    );
};

export default Faqs;