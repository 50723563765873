import React, { useEffect, useState } from 'react'
import rating from "../../Assets/Images/rating2.png";
import { BrowserRouter as Router, Route, Routes, NavLink, Outlet, Navigate } from 'react-router-dom';
import Dashboard from './component/Dashboard';
import Myprofile from './component/Myprofile';
import Myorders from './component/Myorders';
import ProductImage from '../../Assets/Images/shoe.png'
import Myreview from './component/Myreview';
import Myaddress from './component/Myaddress';
import Navbar from '../../LandingPage/Navbar';
import Footer from '../../LandingPage/Footer';
import { UserState } from '../../../Context/userProvider';
import notify from '../../utiles/Notification';
import axios from 'axios';
import Loader from '../../utiles/Loader';
import Mycoupons from './component/Mycoupons';
import { useCart } from '../../../Context/CartContext';



const Profilepage = () => {

    const { user, logout } = UserState();
    const baseUrl = process.env.REACT_APP_BASEURL
    const { updateCartCount } = useCart();


    const [editopen, setEditopen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [review, setReview] = useState([]);
    const [coupons, setCoupons] = useState([]);

    const [custdata, setCustdata] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        gender: '',
        image: '',
        country: '',
        state: '',
        state_name: '',
        country_name: '',
    })

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const firstChar = custdata.first_name.charAt(0)

    useEffect(() => {
        const fetchCustomerData = async () => {
            setLoading(true)
            try {
                const response = await axios.post(
                    `${baseUrl}/customer_data`,
                    { customer_id: user.customer_id },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                );

                if (response.data.status) {
                    setCustdata((prev) => ({
                        ...prev,
                        ...response.data.customer_data
                    }));
                    setCoupons(response.data.coupons)
                    setReview(response.data.my_reviews)
                    setEditopen(false);
                } else {
                    console.log('Failed to fetch customer data');
                }
            } catch (err) {
                console.log('Error fetching customer data:', err);
            }
            setLoading(false)
        };

        fetchCustomerData();
    }, [refresh]);

    if (!user || typeof user !== 'object') {
        // notify('danger', 'danger', 'Please Login');
        return <Navigate to="/" replace />;
    }

    const mypurches = [
        {
            id: '1',
            status: 'pending',
            name: 'Lorem Ipsum, sometimes',
            price: 499.00,
            quantity: 2,
            image: ProductImage,
            size: 'xl', color: 'pink',
            orderid: '9fb6d667-3b55-4bd6-8041-d62e1c4b0b8b',
            orderdate: '29 Feb 2024',
            canceldata: '',
            review: 'lorem ipsum , somethimes lorem',
            rating: '4.5',
        },
        {
            id: '2',
            status: 'completed',
            name: 'Lorem Ipsum, sometimes',
            price: 499.00,
            quantity: 2,
            image: ProductImage,
            size: 'xl', color: 'pink',
            orderid: '9fb6d667-3b55-4bd6-8041-d62e1c4b0b8b',
            orderdate: '29 Feb 2024',
            canceldata: '',
            review: 'lorem ipsum , somethimes lorem',
            rating: '4.5',
        },
        {
            id: '3',
            status: 'cancel pending',
            name: 'Lorem Ipsum, sometimes',
            price: 499.00,
            quantity: 2,
            image: ProductImage,
            size: 'xl', color: 'pink',
            orderid: '9fb6d667-3b55-4bd6-8041-d62e1c4b0b8b',
            orderdate: '29 Feb 2024',
            canceldata: '',
            review: 'lorem ipsum , somethimes lorem',
            rating: '3.5',
        },
        {
            id: '4',
            status: 'canceled',
            name: 'Lorem Ipsum, sometimes',
            price: 499.00,
            quantity: 2,
            image: ProductImage,
            size: 'xl', color: 'pink',
            orderid: '9fb6d667-3b55-4bd6-8041-d62e1c4b0b8b',
            orderdate: '29 Feb 2024',
            canceldata: '29 Feb 2024',
            review: 'lorem ipsum , somethimes lorem',
            rating: '4.5',

        },
    ];




    const cartTotal = mypurches.reduce((total, item) => total + item.price * item.quantity, 0);


    const sidebarLinks = [
        { to: 'dashboard', name: 'dashboard' },
        { to: 'my-profile', name: 'my profile' },
        { to: 'my-orders', name: 'my order' },
        { to: 'my-reviews', name: 'review' },
        { to: 'my-address', name: 'my address' },
        { to: 'my-coupons', name: 'my coupons' },
        // { to: 'logout', name: 'logout' },

    ]

    const log_out = () => {
        updateCartCount(0)
        logout()
    }


    return (
        <>
            <Navbar />
            <div className='container mx-auto px-2 py-6 overflow-hidden '>
                <div className='w-full h-44 flex md:flex-row flex-col place-items-center border-b-2 border-gray-200'>
                    <div className='h-28 w-28 rounded-full overflow-hidden  '>
                        {custdata.image != null && custdata.image !== '0' ? (
                            <img className='w-full h-full' src={custdata.image} alt='my-image' />
                        ) : (
                            <h1 className='text-3xl text-white bg-slate-400 w-full h-full flex justify-center place-items-center'>{firstChar}</h1>
                        )}
                    </div>
                    <div className='ms-5'>
                        <span>Hello,</span>
                        <h1 className='text-2xl font-medium capitalize'>{custdata.first_name}&nbsp;{custdata.last_name}</h1>
                    </div>
                </div>
                <div className='w-full flex md:flex-row flex-col '>
                    <div className='md:w-3/12 w-full'>
                        <div className='menuu w-full bg-gray-200 flex flex-col overflow-hidden'>
                            {sidebarLinks.map((list, index) => (
                                <NavLink key={index} to={list.to} className={({ isActive }) => ` ${isActive || (list.to === 'dashboard' && window.location.pathname === '/my-profile') ? 'text-black shadow-xl scale-1 bg-gray-300 font-semibold' : 'text-neutral-500 font-medium'} capitalize  text-xl  ps-4 py-3 hover:text-black transition-all hover:scale-105 hover:shadow-xl duration-200 hover:bg-gray-300`}  >{list.name}</NavLink>
                            ))}
                                <span className='cursor-pointer capitalize  text-xl  ps-4 py-3 hover:text-black transition-all hover:scale-105 hover:shadow-xl duration-200 hover:bg-gray-300 text-neutral-500 font-medium' onClick={log_out}>Logout</span>
                        </div>
                    </div>
                    <div className='md:w-9/12 w-full md:ps-6 ps-0 pt-4 overflow-hidden'>
                        <Routes>
                            <Route index element={<Dashboard mypurches={mypurches} cartTotal={cartTotal} />} />
                            <Route path='dashboard' element={<Dashboard mypurches={mypurches} cartTotal={cartTotal} />} />
                            <Route path='my-profile' element={<Myprofile custdata={custdata} setCustdata={setCustdata} setLoading={setLoading} setRefresh={setRefresh} editopen={editopen} setEditopen={setEditopen} />} />
                            <Route path='my-orders' element={<Myorders setLoading={setLoading} />} />
                            <Route path='my-reviews' element={<Myreview review={review} />} />
                            <Route path='my-address' element={<Myaddress />} />
                            <Route path='my-coupons' element={<Mycoupons setLoading={setLoading} coupons={coupons} />} />
                        </Routes>
                        <Outlet />
                    </div>
                </div>
            </div>
            <Footer />
            {isLoading && <Loader />}
        </>
    )
}

export default Profilepage