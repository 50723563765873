import { Button, Drawer } from "flowbite-react";
import { useEffect, useState } from "react";
import Navbar from '../LandingPage/Navbar'
import Footer from '../LandingPage/Footer'
import { BsArrowRight } from "react-icons/bs";
import '../../App.css'
import Dropdown from '../utiles/Dropdown';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import logo from '../Assets/Images/adminlogo.png'
import check from '../Assets/Images/check.png'
import uncheck from '../Assets/Images/uncheck.png'
import { Link, useNavigate } from 'react-router-dom';
import { GiHamburgerMenu } from "react-icons/gi";
import ReCAPTCHA from "react-google-recaptcha";
import notify from "../utiles/Notification";
import PhoneInput from 'react-phone-input-2';
import axios from "axios";
import { useCountries, useStates } from "../utiles/CountryStateCity";
import { UserState } from "../../Context/userProvider";
import { Progress } from "flowbite-react";


const BecomeSeller = () => {

  const { setUser } = UserState();


  const [isOpen, setIsOpen] = useState(false);
  const [captchaVal, setCaptchaVal] = useState(null)
  const [countryID, setCountryID] = useState('');

  const navigate = useNavigate();


  const handleClose = () => setIsOpen(false);

  const [activeTab, setActiveTab] = useState(0);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [cpasswordVisible, setcPasswordVisible] = useState(false);
  const [ifscDetails, setIfscDetails] = useState('');

  const [roleOption, setRoleOption] = useState('supplier');


 



  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const togglecPasswordVisibility = () => {
    setcPasswordVisible(!passwordVisible);
  };

  const tabs = [
    // { title: 'Business Information', content: 'Content for Business Information ' },
    { title: 'Select Work ', content: 'Content for Fourth Tab' },
    { title: 'Supplier Information', content: 'Content for Second Tab' },
    { title: 'Bank Details', content: 'Content for Third Tab' },
    { title: 'Verification', content: 'Content for Fifth Tab' },
  ];

  const handlePrevClick = () => {
    if (activeTab > 0) {
      setActiveTab(prev => prev - 1);
    }
  };

  const handleNextClick = () => {
    if (activeTab < tabs.length - 1) {
      setActiveTab(prev => prev + 1);
    }
  };

  const baseUrl = process.env.REACT_APP_BASEURL


  

  const { countryOptions, error: countryError } = useCountries();
  const { stateOptions, error: stateError } = useStates(countryID);
  const [error, setError] = useState(null);


  const [businessInfo, setBusinessInfo] = useState({
    role: '',
    businessName: '',
    companyRegisterNumber: '',
    country: '',
    countryID: '',
    state: '',
    stateID: '',
    city: '',
    apartment: '',
    address: '',
    postalCode: ''
  });

  const [sellerInfo, setSellerInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    password: '',
    confirmPassword: '',
    role: 'supplier'
  });


  const [sellerBankDetails, setSellerBankDetails] = useState({
    accountHoldername: '',
    bankAccountNo: '',
    reenterBankAccountNo: '',
    IFSCcode: '',
  })

  const [sellerStoreDetails, setSellerStoreDetails] = useState({
    storeName: '',
    storeCountry: '',
    storeState: '',
    storeCity: '',
    storeAppartment: '',
    storeAddress: '',
    storePostalCode: '',
    storestateID: '',
    storecountryID: ''

  });

  const handleSelect = (val) => {
    const selectedCountry = countryOptions.find(option => option.label === val);
    if (selectedCountry) {
      setBusinessInfo((prevInfo) => ({
        ...prevInfo,
        countryID: selectedCountry.id,
        country: val,
        state: ''
      }));
      setCountryID(selectedCountry.id);
    }
  };
  const handleSelect_2 = (val) => {
    const selectedCountry = countryOptions.find(option => option.label === val);
    if (selectedCountry) {
      setSellerStoreDetails((prevInfo) => ({
        ...prevInfo,
        storecountryID: selectedCountry.id,
        storeCountry: val,
        storeState: ''

      }));
      setCountryID(selectedCountry.id);
    }
  };


  // const handleIFSCChange = (e) => {
  //   const newIFSC = e.target.value.toUpperCase(); // Convert to uppercase for consistency
  //   setSellerBankDetails({ ...sellerBankDetails, IFSCcode: newIFSC });
  //   handleGetIfscInfo(newIFSC);
  // };
  // const handleGetIfscInfo = async (ifscCode) => {

  //   try {
  //     setIfscDetails('')
  //     const response = await fetch(`https://ifsc.razorpay.com/${ifscCode}`);
  //     const details = await response.json();
  //     if (response.status === 200) {
  //       setIfscDetails(details)
  //     }
  //   } catch (error) {
  //     console.error('Error fetching IFSC details:', error);
  //   }
  // }

  const handleSelect_state = (val) => {
    const selectedState = stateOptions.find(option => option.label === val);
    if (selectedState) {
      setBusinessInfo((prevInfo) => ({
        ...prevInfo,
        state: val,
        stateID: selectedState.id
      }));
      setSellerStoreDetails((prevInfo) => ({
        ...prevInfo,
        storeState: val,
        storestateID: selectedState.id
      }));
    }
  };
  const handleOptionChange = (e) => {
    setRoleOption(e.target.value);
    setSellerInfo(prevInfo => ({
      ...prevInfo,
      role: e.target.value
    }));
    
  };

  const handelRegister = async () => {

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{6,}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isChecked = document.getElementById('agreeCheckbox').checked;
    const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;

    const notifyError = (message) => {
      notify('danger', 'Error', message);
    };

    const validateField = (field, message) => {
      if (!field) {
        notifyError(message);
        return false;
      }
      return true;
    };


    if (
      !validateField(sellerInfo.firstName, 'First Name is required') ||
      !validateField(sellerInfo.lastName, 'Last Name is required') ||
      !validateField(sellerInfo.email, 'Email is required') ||
      !emailRegex.test(sellerInfo.email) && notifyError('Invalid Email Format') ||
      !validateField(sellerInfo.mobile, 'Mobile Number is required') ||
      !validateField(sellerInfo.password, 'Password is required') ||
      !validateField(sellerInfo.confirmPassword, 'Confirm Password is required') ||
      sellerInfo.password !== sellerInfo.confirmPassword && notifyError('Confirm Password is Not Matched') ||
      !passwordRegex.test(sellerInfo.password) && notifyError(
        'Password must be at least 6 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character'
      ) ||
      !validateField(sellerBankDetails.accountHoldername, 'Please Enter Account Holdername') ||
      !validateField(sellerBankDetails.bankAccountNo, 'Please Enter Bank Account Number') ||
      !validateField(sellerBankDetails.reenterBankAccountNo, 'Please Re-Enter Bank Account Number') ||
      !validateField(sellerBankDetails.IFSCcode, 'Please Enter BANK Unique Code') ||
      sellerBankDetails.bankAccountNo !== sellerBankDetails.reenterBankAccountNo && notifyError('Bank Account No and Re-Enter Bank Account Number Not Match') ||
      !isChecked && notifyError('You must agree with all documents before registering.') ||
      !captchaVal && notifyError('Please complete the CAPTCHA before registering.')
    ) {
      return;
    }


    const payload = {
      business_info: businessInfo,
      seller_info: sellerInfo,
      bank_details: sellerBankDetails,
      store_info: sellerStoreDetails,
    };

    try {
      const response = await axios.post(`${baseUrl}/register_as_seller`, payload);
      if (response.data.status) {
        const userData = response.data.user_data;
        localStorage.setItem('userInfo', JSON.stringify(userData));
        setUser(userData);
        notify('success', 'Success', 'Login successful!');
        navigate('/admin/dashboard');
      } else {
        notify('danger', 'Error', `${response.data.message}`);
      }
    } catch (error) {
      console.error('Error during registration:', error);
      notify('danger', 'Error', 'There was an error during registration. Please try again.');
    }
  };
  return (
    <>
      <div className="w-full flex flex-row place-items-center shadow-xl bg-white sticky top-0 md:py-0 py-2">
        <div className="md:hidden block px-2 text-2xl" onClick={() => setIsOpen(true)}>
          <GiHamburgerMenu />
        </div>
        <div className="md:w-1/3 w-1/2 flex md:justify-center justify-between place-items-center md:px-0 px-4">
          <img src={logo} alt="" className='md:h-20 md:w-20 h-12 w-12' />
        </div>
        <div className="w-1/3 md:block hidden">
          <div className="flex flex-row gap-7 place-items-center justify-center">
            <Link to='/' className='font-medium text-lg capitalize hover:scale-105 transition-all duration-300 ease-in-out' >Home</Link>
            <Link to='/' className='font-medium text-lg capitalize hover:scale-105 transition-all duration-300 ease-in-out' >Help Center</Link>
            <Link to='/' className='font-medium text-lg capitalize hover:scale-105 transition-all duration-300 ease-in-out' >FAQ</Link>
            <Link to='/' className='font-medium text-lg capitalize hover:scale-105 transition-all duration-300 ease-in-out' >Contact Us</Link>
          </div>
        </div>
        <div className="md:w-1/3 w-1/2 flex place-items-center justify-center gap-5 pe-5 md:pe-0">
          <h1 className='text-lg font-medium md:block hidden'>Already a  User?</h1>
          <Link to='/admin/login' className='bg-black text-white px-3 py-2 md:ms-0 ms-auto rounded-md text-base font-semibold capitalize'>Log in</Link>
        </div>
      </div>
      <div className="h-full ">
      {/* <Progress progress={45} color="dark"/> */}
        <div className="container mx-auto md:py-10 py-5 md:px-0 px-5">
          <span className='text-lg font-semibold block md:hidden'>Steps :-</span>
          <div className="flex md:flex-row flex-col place-items-start md:justify-evenly justify-start">
            {tabs.map((tab, index) => (
              <div key={index} className="flex place-items-start gap-2">
                <div className={`flex md:flex-col flex-row justify-center place-items-center md:gap-5 ${index <= activeTab ? 'text-black font-bold ' : 'text-gray-400 '}`}>
                  <span className={`md:border ${index <= activeTab ? 'border-black text-black' : 'border-gray-400 text-gray-400'
                    } w-10 h-10 flex justify-center items-center md:rounded-full text-lg font-medium`}>
                    {index + 1}<span className='md:hidden block'>.</span>
                  </span>
                  <h1 className="text-sm font-semibold capitalize">{tab.title} </h1>
                </div>
              </div>
            ))}
          </div>

          <div className="mt-8 md:px-10">
            {/*{tabs[activeTab].title === tabs[0].title &&
              <div className="flex justify-center place-items-center flex-col ">
                <h2 className="text-2xl font-semibold capitalize w-full md:w-4/5 lg:w-4/5 xl:w-2/5 flex justify-start place-items-start">{tabs[activeTab].title}</h2>
                <div className="w-full md:w-4/5 lg:w-4/5 xl:w-2/5 md:p-10 p-0 rounded-2xl  mt-3 flex justify-center md:shadow-[0px_5px_15px_rgba(0,0,0,0.35)] place-items-center flex-col" >
                  <div className="flex flex-col w-full gap-4">
                    <div className="flex flex-col gap-1 w-full">
                      <h1 className='text-base font-semibold capitalize text-left'>Business Name <span className="text-red-500">*</span></h1>
                      <input type="text" className='w-full rounded-md border border-gray-300 px-2  focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your Bussiness Name'
                        value={businessInfo.businessName}
                        onChange={(e) => setBusinessInfo({ ...businessInfo, businessName: e.target.value })}
                      />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                      <h1 className='text-base font-semibold capitalize text-left'>Company Register Number </h1>
                      <input type="text" className='w-full rounded-md border border-gray-300 px-2  focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your Company Register Number'
                        value={businessInfo.companyRegisterNumber}
                        onChange={(e) => setBusinessInfo({ ...businessInfo, companyRegisterNumber: e.target.value })}
                      />
                    </div>
                  </div>

                  <div className="w-full mt-5">
                    <h1 className='text-base font-semibold capitalize text-left'>Registered Business Address </h1>
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
                      <Dropdown
                        label=""
                        labelSelect="Country"
                        isMultiple={false}
                        search={true}
                        options={countryOptions}
                        selectedValues={businessInfo.country}
                        onSelect={handleSelect}
                      />
                      {countryID ? (
                        <Dropdown
                          label=""
                          labelSelect="State"
                          isMultiple={false}
                          options={stateOptions}
                          search={true}
                          selectedValues={businessInfo.state}
                          onSelect={handleSelect_state}
                        />
                      ) : (
                        <div className="w-full rounded-lg flex justify-start place-items-center border border-gray-300 px-2 py-1.5 text-gray-500 text-base  mt-2">Please select a Country</div>
                      )
                      }
                      <input type="text" className=' w-full rounded-md border border-gray-300 px-2 mt-2 focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter City'
                        value={businessInfo.city}
                        onChange={(e) => setBusinessInfo({ ...businessInfo, city: e.target.value })}
                      />
                      <input type="text" className=' w-full rounded-md border border-gray-300 px-2 mt-2 focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Apartment/building/suite'
                        value={businessInfo.apartment}
                        onChange={(e) => setBusinessInfo({ ...businessInfo, apartment: e.target.value })}
                      />
                      <input type="text" className=' w-full rounded-md border border-gray-300 px-2 mt-2 focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Address'
                        value={businessInfo.address}
                        onChange={(e) => setBusinessInfo({ ...businessInfo, address: e.target.value })}
                      />
                      <input type="text" className=' w-full rounded-md border border-gray-300 px-2 mt-2 focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Zip / Postal Code'
                        value={businessInfo.postalCode}
                        onChange={(e) => setBusinessInfo({ ...businessInfo, postalCode: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row place-items-center gap-5 md:ms-5">
                    {activeTab !== 0 &&
                      <button className='capitalize px-5 text-base md:text-base font-semibold mt-10  py-2 border border-black text-black bg-white rounded-lg' onClick={handlePrevClick}>previous</button>
                    }
                    <button className='capitalize px-5 text-base md:text-base font-semibold mt-10  py-2 text-white bg-black rounded-lg' onClick={handleNextClick}
                      disabled={activeTab === tabs.length - 1}>Next</button>
                  </div>
                </div>
              </div>
            }*/}
            {tabs[activeTab].title === tabs[1].title &&
              <div className="flex justify-center place-items-center flex-col ">
                <h2 className="text-2xl font-semibold capitalize w-full md:w-4/5 lg:w-4/5 xl:w-2/5  flex justify-start place-items-start">{tabs[activeTab].title}</h2>
                <div className="w-full md:w-4/5 lg:w-4/5 xl:w-2/5  md:p-10 p-0  rounded-2xl  mt-3 flex justify-center place-items-center flex-col md:shadow-[0px_5px_15px_rgba(0,0,0,0.35)]" >
                  <div className="w-full space-y-5">
                    <div className="flex md:flex-row flex-col place-items-center gap-5 w-full">
                      <div className="flex flex-col gap-2 w-full">
                        <h1 className='text-base font-semibold capitalize text-left'>First Name <span className="text-red-500">*</span></h1>
                        <input type="text" className='w-full rounded-md border border-gray-300 px-2  focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your First Name'
                          value={sellerInfo.firstName}
                          onChange={(e) => setSellerInfo({ ...sellerInfo, firstName: e.target.value })}
                        />
                      </div>
                      <div className="flex flex-col gap-2 w-full">
                        <h1 className='text-base font-semibold capitalize text-left'>Last Name <span className="text-red-500">*</span></h1>
                        <input type="text" className='w-full rounded-md border border-gray-300 px-2  focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your Last Name'
                          value={sellerInfo.lastName}
                          onChange={(e) => setSellerInfo({ ...sellerInfo, lastName: e.target.value })}
                        />

                      </div>
                    </div>
                    <div className="flex flex-col gap-2 w-full">
                      <h1 className='text-base font-semibold capitalize text-left'>Email <span className="text-red-500">*</span></h1>
                      <input type="email" className='w-full rounded-md border border-gray-300 px-2  focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your E-mail'
                        value={sellerInfo.email}
                        onChange={(e) => setSellerInfo({ ...sellerInfo, email: e.target.value })}
                      />
                    </div>
                    <div className="flex flex-col gap-2 w-full">
                      <h1 className='text-base font-semibold capitalize text-left'>Mobile <span className="text-red-500">*</span></h1>
                      <PhoneInput
                        country={'in'}
                        value={sellerInfo.mobile}
                        countryCodeEditable={false}
                        enableSearch={true}
                        onChange={(val) => setSellerInfo({ ...sellerInfo, mobile: val })}
                        placeholder="Enter Mobile Number"
                        inputClass="input-mobile mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-400 focus:ring-1"
                      />
                    </div>
                    <div className="flex flex-col gap-2 w-full">
                      <h1 className='text-base font-semibold capitalize text-left'>password <span className="text-red-500">*</span></h1>
                      <div className="flex flex-row border-gray-300 border place-items-center rounded-md overflow-hidden">
                        <input type={passwordVisible ? "text" : "password"} className='w-full  px-2  no-arrows  border-0 focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your Password'
                          value={sellerInfo.password}
                          onChange={(e) => setSellerInfo({ ...sellerInfo, password: e.target.value })}
                        />
                        <div className="pe-2 cursor-pointer" onClick={togglePasswordVisibility}>
                          {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 w-full">
                      <h1 className='text-base font-semibold capitalize text-left'>confirm password <span className="text-red-500">*</span></h1>
                      <div className="flex flex-row border-gray-300 border place-items-center rounded-md overflow-hidden">
                        <input type={cpasswordVisible ? "text" : "password"} className='w-full  px-2  no-arrows  border-0 focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your Confirm Password'
                          value={sellerInfo.confirmPassword}
                          onChange={(e) => setSellerInfo({ ...sellerInfo, confirmPassword: e.target.value })}
                        />
                        <div className="pe-2 cursor-pointer" onClick={togglecPasswordVisibility}>
                          {cpasswordVisible ? <FaEyeSlash /> : <FaEye />}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row place-items-center gap-5 ">
                    {activeTab !== 0 &&
                      <button className='capitalize px-5 text-base md:text-base font-semibold mt-10  py-2 border border-black text-black bg-white rounded-lg' onClick={handlePrevClick}
                        disabled={activeTab === 0}>previous</button>}
                    <button className='capitalize px-5 text-base md:text-base font-semibold mt-10  py-2 text-white bg-black rounded-lg' onClick={handleNextClick}
                      disabled={activeTab === tabs.length - 1}>Next</button>
                  </div>
                </div>
              </div>
            }
            {tabs[activeTab].title === tabs[2].title &&
              <div className="flex justify-center place-items-center flex-col">
                <h2 className="text-2xl font-semibold capitalize w-full flex md:w-4/5 lg:w-4/5 xl:w-2/5  justify-start place-items-start">Add Yout Bank Account</h2>
                <div className="w-full md:w-4/5 lg:w-4/5 xl:w-2/5  md:p-10 rounded-2xl  mt-3 flex justify-center place-items-center flex-col md:shadow-[0px_5px_15px_rgba(0,0,0,0.35)]" >
                  <div className="w-full space-y-5">
                    <div className="flex flex-col gap-2 w-full">
                      <h1 className='text-base font-semibold capitalize text-left'>Account Holder Name <span className="text-red-500">*</span></h1>
                      <input type="text" className='w-full rounded-md border border-gray-300 px-2  focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your Account Holder Name'
                        value={sellerBankDetails.accountHoldername}
                        onChange={(e) => setSellerBankDetails({ ...sellerBankDetails, accountHoldername: e.target.value })}
                      />
                    </div>
                    <div className="flex flex-col gap-2 w-full">
                      <h1 className='text-base font-semibold capitalize text-left'>Bank Account Number <span className="text-red-500">*</span></h1>
                      <input type="text" className='w-full rounded-md border border-gray-300 px-2  focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your Bank Account Number'
                        value={sellerBankDetails.bankAccountNo}
                        onChange={(e) => setSellerBankDetails({ ...sellerBankDetails, bankAccountNo: e.target.value })}
                      />
                    </div>
                    <div className="flex flex-col gap-2 w-full">
                      <h1 className='text-base font-semibold capitalize text-left'>Re-enter Bank Account Number <span className="text-red-500">*</span></h1>
                      <div className="flex flex-row border-gray-300 border place-items-center rounded-md overflow-hidden">
                        <input type={passwordVisible ? "text" : "password"} className='w-full  px-2  no-arrows  border-0 focus:ring-0 py-1.5 focus:outline-0' placeholder=' Re-enter Bank Account Number'
                          value={sellerBankDetails.reenterBankAccountNo}
                          onChange={(e) => setSellerBankDetails({ ...sellerBankDetails, reenterBankAccountNo: e.target.value })}
                        />
                        <div className="pe-2 cursor-pointer" onClick={togglePasswordVisibility}>
                          {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 w-full">
                      <h1 className='text-base font-semibold capitalize text-left'>BANK Unique Code <span className="text-red-500">*</span></h1>
                      <input type="text" className='w-full rounded-md border border-gray-300 px-2 no-arrows focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your Bank Code'
                        value={sellerBankDetails.IFSCcode}
                        onChange={(e) => setSellerBankDetails({ ...sellerBankDetails, IFSCcode: e.target.value })}
                        // onChange={handleIFSCChange}
                        style={{ textTransform: 'uppercase' }}
                        pattern="[A-Z0-9]*"
                      />
                      {ifscDetails && (
                        <div className="mt-2">
                          <div className="flex gap-2">
                            <h2 className="text-lg mt-1">Branch:</h2>
                            <p className="text-sky-400 font-semibold mt-2 px-1">{ifscDetails.BRANCH}</p>
                            <h2 className="text-lg mt-1">Center:</h2>
                            <p className="text-sky-400 font-semibold mt-2 px-1">{ifscDetails.CENTRE}</p>
                          </div>
                          <h2 className="text-lg">Address:</h2>
                          <p className="text-sky-400 font-semibold">{ifscDetails.ADDRESS}</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-row place-items-center gap-5 md:ms-5">
                    <button className='capitalize px-5 text-base md:text-base font-semibold mt-10  py-2 border border-black text-black bg-white rounded-lg' onClick={handlePrevClick}
                      disabled={activeTab === 0}>previous</button>
                    <button className='capitalize px-5 text-base md:text-base font-semibold mt-10  py-2 text-white bg-black rounded-lg' onClick={handleNextClick}
                      disabled={activeTab === tabs.length - 1}>Next</button>
                  </div>
                </div>
              </div>
            }
            {tabs[activeTab].title === tabs[0].title &&
              <div className="flex justify-center place-items-center flex-col">
                <h2 className="text-2xl font-semibold capitalize w-full md:w-4/5 lg:w-4/5 xl:w-2/5  flex justify-start place-items-start">Select Work</h2>
                <div className="w-full md:w-4/5 lg:w-4/5 xl:w-2/5  md:p-10 rounded-2xl  mt-3 flex justify-center place-items-start flex-col md:shadow-[0px_5px_15px_rgba(0,0,0,0.35)]" >
                  <div className="flex flex-col gap-2">
                    <label className="flex items-center cursor-pointer">
                      <input
                        type="radio"
                        name="roleOption"
                        value="supplier"
                        checked={roleOption === 'supplier'}
                        onChange={handleOptionChange}
                        className="form-radio h-4 w-4 text-black focus:ring-0 "
                      />
                      <span className="ml-2">Supplier</span>
                    </label>
                    <label className="flex items-center cursor-pointer">
                      <input
                        type="radio"
                        name="roleOption"
                        value="supplier-seller"
                        checked={roleOption === 'supplier-seller'}
                        onChange={handleOptionChange}
                        className="form-radio h-4 w-4 text-black focus:ring-0 "
                      />
                      <span className="ml-2">Supplier-Seller</span>
                    </label>
                    <label className="flex items-center cursor-pointer">
                      <input
                        type="radio"
                        name="roleOption"
                        value="supplier-influencer"
                        checked={roleOption === 'supplier-influencer'}
                        onChange={handleOptionChange}
                        className="form-radio h-4 w-4 text-black focus:ring-0 "
                      />
                      <span className="ml-2">Supplier-Influencer</span>
                    </label>
                  </div>
                  <div className="flex flex-col mt-5">
                    <h1 className="text-xl font-semibold">Details</h1>
                    {roleOption == 'supplier' &&
                      <div className="">
                        <p>Only Can add Products For Sell.</p>
                      </div>
                    }
                    {roleOption == 'supplier-seller' &&
                      <div className="">
                        <p>Can Add their Products and Sell only their Own Products </p>
                      </div>
                    }
                    {roleOption == 'supplier-influencer' &&
                      <div className="">
                        <p>Can Add its own products for sell and can sell others(Suppliers) products. </p>
                      </div>
                    }
                  </div>
                  <div className="flex flex-row place-items-center gap-5 justify-center w-full">
                  {activeTab !== 0 &&
                    <button className='capitalize px-5 text-base md:text-base font-semibold mt-10  py-2 border border-black text-black bg-white rounded-lg' onClick={handlePrevClick}
                      disabled={activeTab === 0}>previous</button>}
                    <button className='capitalize px-5 text-base md:text-base font-semibold mt-10  py-2 text-white bg-black rounded-lg' onClick={handleNextClick}
                      disabled={activeTab === tabs.length - 1}>Next</button>
                  </div>
                </div>

              </div>
            }
            {tabs[activeTab].title === tabs[3].title &&
              <div className="flex justify-center place-items-center flex-col">
                <h2 className="text-2xl font-semibold capitalize w-full md:w-4/5 lg:w-4/5 xl:w-2/5  flex justify-start place-items-start">Declaration</h2>
                <div className="w-full md:w-4/5 lg:w-4/5 xl:w-2/5  md:p-10 rounded-2xl  mt-3 flex justify-center place-items-center flex-col md:shadow-[0px_5px_15px_rgba(0,0,0,0.35)]" >
                  <div className="w-full space-y-5">
                    <div className="flex flex-col gap-2 w-full">
                      <h1 className='text-base font-semibold capitalize text-left'>Required Documents <span className="text-red-500">*</span></h1>
                      <div className="flex flex-col gap-3 mt-2">
                        <div className="flex flex-row justify-between place-items-center">
                          <span>GST Certificate</span>
                          <img src={check} alt="" className='md:w-7 md:h-7 h-5 w-5' />
                        </div>
                        <div className="flex flex-row justify-between place-items-center">
                          <span>PAN Card</span>
                          <img src={check} alt="" className='md:w-7 md:h-7 h-5 w-5' />
                        </div>
                        <label className="flex flex-row gap-3 place-items-center">
                          <input type="checkbox" id="agreeCheckbox" className='w-5 h-5 focus:ring-0 text-black' />
                          <span className='text-lg capitalize'>I agree with all documents</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <ReCAPTCHA
                    className="mt-3"
                    sitekey="6LekFjIqAAAAAC8_a7HDtWUTNtcSX1uxGCTbZptj"
                    onChange={val => setCaptchaVal(val)}
                  />
                  <div className="flex flex-row place-items-center gap-5 ">
                    <button className='capitalize px-5 text-base md:text-base font-semibold mt-5 py-2 border border-black text-black bg-white rounded-lg' onClick={handlePrevClick}
                      disabled={activeTab === 0}>previous</button>
                    <button className='capitalize px-5 text-base md:text-base font-semibold mt-5 py-2 text-white bg-black rounded-lg' onClick={handelRegister}>Register</button>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <Drawer open={isOpen} onClose={handleClose}>
        <div >
          <Drawer.Header
            title={<span className="text-2xl font-medium capitalize">menu</span>}
            titleText={() => <></>} titleIcon={() => <></>}
          />
        </div>
        <Drawer.Items>
          <div className="flex flex-col gap-5 place-items-start justify-center mt-3 ">
            <Link to='/' className='font-medium text-lg capitalize hover:scale-105 transition-all duration-300 ease-in-out' >Home</Link>
            <Link to='' className='font-medium text-lg capitalize hover:scale-105 transition-all duration-300 ease-in-out' >Help Center</Link>
            <Link to='' className='font-medium text-lg capitalize hover:scale-105 transition-all duration-300 ease-in-out' >FAQ</Link>
            <Link to='' className='font-medium text-lg capitalize hover:scale-105 transition-all duration-300 ease-in-out' >Contact Us</Link>
          </div>
        </Drawer.Items>
      </Drawer>
    </>
  )
}

export default BecomeSeller