import React, { useEffect, useState } from 'react'
import Card from '../LandingPage/Card'
import { Link } from 'react-router-dom';
import axios from 'axios';

const Similar_product = ({ toggleModal, id, setLoading }) => {
    const similar = [
        {
            id: 1,
            name: 'Wireless Earbuds',
            description: 'A short description about the product.',
            price: 89.00,
            rating: 5,
            product_img: [
                {
                    images: 'http://res.cloudinary.com/duw6f8fm8/image/upload/v1727951130/ctg_shose_zj19qk.png'
                }
            ]
        },
        {
            id: 2,
            name: 'Wireless Earbuds',
            description: 'A short description about the product.',
            price: 89.00,
            rating: 4,
            product_img: [
                {
                    images: 'http://res.cloudinary.com/duw6f8fm8/image/upload/v1727951130/ctg_shose_zj19qk.png'
                }
            ]
        },
        {
            id: 3,
            name: 'Wireless Earbuds',
            description: 'A short description about the product.',
            price: 89.00,
            rating: 3,
            product_img: [
                {
                    images: 'http://res.cloudinary.com/duw6f8fm8/image/upload/v1727951130/ctg_shose_zj19qk.png'
                }
            ]
        },
        {
            id: 4,
            name: 'Wireless Earbuds',
            description: 'A short description about the product.',
            price: 89.00,
            rating: 2,
            product_img: [
                {
                    images: 'http://res.cloudinary.com/duw6f8fm8/image/upload/v1727951130/ctg_shose_zj19qk.png'
                }
            ]
        },
        {
            id: 5,
            name: 'Wireless Earbuds',
            description: 'A short description about the product.',
            price: 89.00,
            rating: 5,
            product_img: [
                {
                    images: 'http://res.cloudinary.com/duw6f8fm8/image/upload/v1727951130/ctg_shose_zj19qk.png'
                }
            ]
        },
        {
            id: 6,
            name: 'Wireless Earbuds',
            description: 'A short description about the product.',
            price: 89.00,
            rating: 4,
            product_img: [
                {
                    images: 'http://res.cloudinary.com/duw6f8fm8/image/upload/v1727951130/ctg_shose_zj19qk.png'
                }
            ]
        },
        {
            id: 8,
            name: 'Wireless Earbuds',
            description: 'A short description about the product.',
            price: 89.00,
            rating: 5,
            product_img: [
                {
                    images: 'http://res.cloudinary.com/duw6f8fm8/image/upload/v1727951130/ctg_shose_zj19qk.png'
                }
            ]
        },
        {
            id: 8,
            name: 'Wireless Earbuds',
            description: 'A short description about the product.',
            price: 89.00,
            rating: 4,
            product_img: [
                {
                    images: 'http://res.cloudinary.com/duw6f8fm8/image/upload/v1727951130/ctg_shose_zj19qk.png'
                }
            ]
        },
        // Add more products as needed
    ]

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const baseUrl = process.env.REACT_APP_BASEURL

    const [productData, setProductData] = useState([]);


    useEffect(() => {
        const getdata = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${baseUrl}/customer_category_data/${id}`);
                if (response.data.status) {
                    setProductData(response.data.MainproductsAllData || '');
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        getdata();
    }, [id]);



    return (
        <>
            {productData.length > 0 &&
                <div className="container mx-auto py-8">
                    <div className='flex justify-between'>
                        <div> </div>
                        <div> </div>

                    </div>
                    <div className='flex justify-between px-2 mb-4'>
                        <h1 className="capitalize text-2xl md:text-4xl  font-medium">Similar product</h1>
                        <Link to={'/category/' + id} className='hover-effect text-md md:text-lg text-gray-400 font-medium'>View All</Link>
                    </div>
                    <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 md:gap-2" >
                        {productData.slice(0, 5).map(products => (
                            <Card key={products.product_id} product={products} path={"../product/" + products.product_id} toggleModal={toggleModal} />
                        ))}
                    </div>
                </div>
            }
        </>
    )
}

export default Similar_product
