import { useState, useEffect } from 'react';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASEURL


// Hook for fetching countries
export const useCountries = () => {
    const [countryOptions, setCountryOptions] = useState([]);
    const [error, setError] = useState(null);



    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get(`${baseUrl}/getAllCountries`)

                if (response.data.status) {
                    const formattedCountries = response.data.allCountries.map(country => ({
                        id: country.country_id,
                        label: country.country_name
                    }));
                    setCountryOptions(formattedCountries);
                } else {
                    setError('Failed to fetch countries');
                }
            } catch (err) {
                setError('An error occurred while fetching data');
            }
        };

        fetchCountries();
    }, []);

    return { countryOptions, error };
};

// Hook for fetching states based on country ID
export const useStates = (countryID) => {
    const [stateOptions, setStateOptions] = useState([]);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchStates = async () => {
            if (!countryID) {
                setStateOptions([{ label: 'Please select a Country' }]);
                return;
            }

            try {
                const response = await axios.post(`${baseUrl}/getStateofACountry`,
                    { country_id: countryID },
                    { headers: { 'Content-Type': 'application/json' } }
                );

                if (response.data.status) {
                    const stateData = response.data.states.map(state => ({
                        id: state.state_subdivision_id,
                        label: state.state_subdivision_name
                    }));
                    setStateOptions(stateData);
                } else {
                    setStateOptions([{ label: 'No states available' }]);
                }
            } catch (err) {
                setError('Error fetching states');
                setStateOptions([{ label: 'Error fetching states' }]);
            }
        };

        fetchStates();
    }, [countryID]);

    return { stateOptions, error };
};
