import React from 'react'
import ProductImage from '../../../Assets/Images/shoe.png'


const Dashboard = ({ mypurches, cartTotal }) => {

    return (
        <>
            <div className="pb-10">
                <h1 className='md:text-2xl text-xl font-medium capitalize'>Dashboard</h1>
                <div className='flex md:flex-row flex-col mt-4 gap-5 justify-center place-items-center md:justify-start'>
                    <div className='border-2 border-gray-200 h-36 w-56 rounded-xl flex justify-center place-items-center flex-col'>
                        <span className='text-3xl font-medium mb-1'>20</span>
                        <h1 className='text-base font-medium text-neutral-500'>My Purches</h1>
                    </div>
                    <div className='border-2 border-gray-200 h-36 w-56 rounded-xl flex justify-center place-items-center flex-col'>
                        <span className='text-3xl font-medium mb-1'>20</span>
                        <h1 className='text-base font-medium text-neutral-500'>My Purches</h1>
                    </div>
                    <div className='border-2 border-gray-200 h-36 w-56 rounded-xl flex justify-center place-items-center flex-col'>
                        <span className='text-3xl font-medium mb-1'>20</span>
                        <h1 className='text-base font-medium text-neutral-500'>My Purches</h1>
                    </div>
                </div>
                <h1 className='md:text-xl text-base font-medium my-6'>My Recent Purches Products</h1>
                <div className='md:block hidden'>
                    <table className="w-full bg-white">
                        <thead className="bg-gray-100 rounded-xl">
                            <tr>
                                <th className="py-4 px-4 text-left">Product</th>
                                <th className="py-4 px-4 text-left">Price</th>
                                <th className="py-4 px-4 text-left">Quantity</th>
                                <th className="py-4 px-4 text-left">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {mypurches.map(item => (
                                <tr key={item.id} className="border-b border-gray-200 hover:bg-gray-200 transition-all duration-200 ease-in-out">
                                    <td className="py-4 px-4  flex items-center gap-4">
                                        <img src={item.image} alt={item.name} className="w-16 h-16 mr-4 bg-gray-100 rounded-lg" />
                                        <span>{item.name}</span>
                                    </td>
                                    <td className="py-4 px-4 ">{item.price.toFixed(2)} €</td>
                                    <td className="py-4 px-4 ">
                                        <div className="flex items-center">
                                            <span className="px-2 mx-3">{item.quantity}</span>
                                        </div>
                                    </td>
                                    <td className="py-4 px-4">{item.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className='md:hidden block'>
                    {mypurches.map(item => (
                        <div key={item.id} className="bg-white px-4 border-b border-gray-200  mb-4">
                            <div className="flex mb-2">
                                <img src={item.image} alt={item.name} className="w-24 h-24 bg-gray-100 mt-3 rounded-lg" />

                                <div className='ml-4 w-full'>

                                    <div className="flex justify-between">
                                        <p className='text-xs text-gray-400'>Category</p>
                                    </div>
                                    <div className='my-1'>
                                        <p className='text-sm font-medium'>{item.name}</p>
                                    </div>
                                    <div className='flex flex-row gap-4 my-1'>
                                        <p className='text-gray-500 text-xs md:text-sm'>Size : {item.size}</p>
                                        <p className='text-gray-500 text-xs md:text-sm'>Color : {item.color}</p>
                                        <p className='text-gray-500 text-xs md:text-sm'>Qty : {item.quantity}</p>
                                    </div>
                                    <div className="flex items-center justify-between mt-3">
                                        <div><p className='text-sm text-nowrap font-medium'>{(item.price * item.quantity).toFixed(2)} € </p></div>
                                        <div className="flex items-center justify-between">
                                            <span className="capitalize text-sm font-medium text-gray-400">{item.status}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Dashboard