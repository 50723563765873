import React from 'react'
import { ImCross } from "react-icons/im";

const Transactiondetails = ({ isVisible, onClose, data }) => {
    return (
        <>
            {isVisible && (
                <div
                    onClick={e => {
                        if (e.target === e.currentTarget) {
                            onClose();
                        }
                    }}
                    className={`fixed inset-0 bg-black transition-opacity duration-500 z-40 ease-in-out ${isVisible ? 'opacity-50' : 'opacity-0 pointer-events-none'
                        }`}
                >
                </div>
            )}
            <div
                className={`fixed w-1/4 transition-all duration-1000 z-50 ease-in-out bg-white rounded-lg shadow-lg ${isVisible ? 'visible top-64 left-1/2 transform -translate-x-24  opacity-100' : 'invisible h-3 left-1/2 top-48 -translate-x-24 opacity-0'
                    }`}
            >
                <div className="flex flex-row place-items-center justify-between border-b border-gray-200 py-2 px-5">
                    <h1 className='font-medium capitalize text-lg'>Transation Details</h1>
                    <button className="text-base text-black" onClick={onClose}>
                        <ImCross />
                    </button>
                </div>
                <div className="">
                    {data && (
                        <div className="flex flex-col gap-3 mt-3">
                            <div className="flex flex-col px-5">
                                <span className='font-medium text-base text-black'>seller :</span>
                                <span className='font-medium text-sm text-gray-400'>{data.seller}</span>
                            </div>
                            <div className="flex flex-col px-5">
                                <span className='font-medium text-base text-black'>Date and Time :</span>
                                <span className='font-medium text-sm text-gray-400'>{data.date}</span>
                            </div>
                            <div className="flex flex-col px-5">
                                <span className='font-medium text-base text-black'>Billing Address :</span>
                                <span className='font-medium text-sm text-gray-400'>{data.seller}</span>
                            </div>
                            <div className="flex flex-col px-5">
                                <span className='font-medium text-base text-black'>Customer ID:</span>
                                <span className='font-medium text-sm text-gray-400'>{data.cusomerID}</span>
                            </div>
                            <div className="flex flex-col px-5">
                                <span className='font-medium text-base text-black'>E-Mail :</span>
                                <span className='font-medium text-sm text-gray-400'>{data.email}</span>
                            </div>
                            <div className="flex flex-col px-5">
                                <span className='font-medium text-base text-black capitalize'>item Purchased :</span>
                                <div className="flex flex-col gap-1 place-items-start ps-3">
                                    {data.itemsPurchased.map((list, index) =>
                                        <div className='font-medium text-sm text-gray-400'>
                                            <span>{index}</span>.&nbsp;<span>{list}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col px-5">
                                <span className='font-medium text-base text-black'>Payment Method :</span>
                                <span className='font-medium text-sm text-gray-400'>{data.seller}</span>
                            </div>
                            <div className="flex justify-between place-items-center border-t border-gray-200 px-5 py-2">
                                <span className='font-medium text-xl text-black capitalize'>paid</span>
                                <span className='font-semibold text-2xl text-black capitalize flex place-items-center gap-1'><small className='text-base font-medium text-gray-400'>Total :</small>499.00 €</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>

        </>
    )
}

export default Transactiondetails