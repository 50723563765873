import React, { useState } from 'react';
import likes from '../../Component/Assets/Images/rating2.png';
import Pagination from '../../Component/utiles/Pagination';
import { Link } from 'react-router-dom';

const Newcomments = () => {
    const comments = [
        { name: 'Helen M', img: likes, rating: 4, review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nec dolor vel est interdum' },
        { name: 'John D', img: likes, rating: 4, review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nec dolor vel est interdum' },
        { name: 'Alice W', img: likes, rating: 3.5, review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nec dolor vel est interdum' },
        { name: 'Robert T', img: likes, rating: 4, review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nec dolor vel est interdum' },
        { name: 'Emily R', img: likes, rating: 4, review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nec dolor vel est interdum' },
        { name: 'Sophia L', img: likes, rating: 3, review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nec dolor vel est interdum' },
        { name: 'Michael B', img: likes, rating: 4.5, review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nec dolor vel est interdum' },
        { name: 'Olivia N', img: likes, rating: 4, review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nec dolor vel est interdum' },
        { name: 'William C', img: likes, rating: 5, review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nec dolor vel est interdum' },
        { name: 'James P', img: likes, rating: 4, review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nec dolor vel est interdum' },
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const commentsPerPage = 5;

    const indexOfLastComment = currentPage * commentsPerPage;
    const indexOfFirstComment = indexOfLastComment - commentsPerPage;
    const currentComments = comments.slice(indexOfFirstComment, indexOfLastComment);

    const totalPages = Math.ceil(comments.length / commentsPerPage);

    return (
        <>
            <div className='w-full h-[46em] rounded-lg bg-white lg:p-6 p-3'>
                <div className="flex justify-between flex-row w-full place-items-center mb-4 px-1">
                    <h1 className='md:text-lg xl:text-xl text-xl  font-semibold'>Customer Reviews</h1>
                    <Link to='../rating-review' className='capitalize text-gray-400 font-medium text-lg'>view all</Link>
                </div>
                <div className='h-[38rem] overflow-hidden overflow-y-auto pe-3'>
                    {currentComments.map((list, index) => (
                        <div key={index} className='flex flex-row items-start gap-3 w-full border-b py-2'>
                            <img className='xl:w-16 xl:h-16 md:w-10 md:h-10 h-9 w-9 rounded-full' src={list.img} alt='customer' />
                            <div className='w-full'>
                                <div className='flex flex-row items-center justify-between w-full'>
                                    <span className='xl:text-xl md:text-lg font-medium capitalize'>{list.name}</span>
                                    <div className='flex flex-row items-center gap-4'>
                                        <button className='text-red-500 cursor-pointer'>Decline</button>
                                        <button className='text-green-500 cursor-pointer'>Accept</button>
                                    </div>
                                </div>
                                <div className='flex flex-row gap-2 my-1'>
                                    {[...Array(5)].map((star, index) => (
                                        <svg
                                            key={index}
                                            className={`md:w-5 md:h-5 w-4 h-4 inline-block ${index < Math.floor(list.rating || 0) ? 'text-yellow-300' : 'text-gray-300'
                                                }`}
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                        >
                                            <path d="M9.049 2.927C9.39 2.029 10.611 2.029 10.951 2.927L12.613 7.03C12.763 7.438 13.146 7.75 13.586 7.833L18.177 8.702C19.135 8.884 19.508 10.086 18.839 10.728L15.514 13.89C15.167 14.213 15.01 14.712 15.111 15.198L16.145 19.655C16.351 20.623 15.308 21.371 14.505 20.842L10.5 18.394C10.197 18.203 9.803 18.203 9.5 18.394L5.495 20.842C4.692 21.371 3.649 20.623 3.855 19.655L4.889 15.198C4.99 14.712 4.833 14.213 4.486 13.89L1.161 10.728C0.492 10.086 0.865 8.884 1.823 8.702L6.414 7.833C6.854 7.75 7.237 7.438 7.387 7.03L9.049 2.927Z" />
                                        </svg>
                                    ))}
                                </div>
                                <span className='text-base'>{list.review}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='flex justify-end mt-4'>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </div>
        </>
    );
};

export default Newcomments;
