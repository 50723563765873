import React, { useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Dropdown from '../../Component/utiles/Dropdown';
import { FaArrowTrendUp } from "react-icons/fa6";


const Salesbycategory = () => {

    const checkdataoption = [
        { label: 'all' }, { label: 'today' }, { label: 'weekly' }, { label: 'monthly' },
    ];

    const [checkData, setCheckData] = useState(checkdataoption[1].label);

    const today = new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });

    // Manually added dates and data
    const chart_data = [
        { name: 'Kids', y: 900, color: '#2377FC', date: '08/08/2024' },
        { name: 'Men', y: 260, color: '#D251FF', date: '08/07/2024' },
        { name: 'Women', y: 340, color: '#FF7676', date: '01/08/2024' },
        { name: 'Kids', y: 1000, color: '#2377FC', date: '07/25/2024' },
        { name: 'Men', y: 150, color: '#D251FF', date: '07/20/2024' },
        { name: 'Women', y: 420, color: '#FF7676', date: '06/28/2024' },
        { name: 'Kids', y: 700, color: '#2377FC', date: '06/15/2024' },
        { name: 'Men', y: 300, color: '#D251FF', date: '06/01/2024' },
        { name: 'Women', y: 500, color: '#FF7676', date: '05/15/2024' },
        { name: 'Kids', y: 800, color: '#2377FC', date: '05/10/2024' },
        { name: 'Men', y: 400, color: '#D251FF', date: '05/05/2024' },
        { name: 'Women', y: 600, color: '#FF7676', date: '04/25/2024' },
        { name: 'Kids', y: 950, color: '#2377FC', date: '04/15/2024' },
        { name: 'Men', y: 500, color: '#D251FF', date: '04/10/2024' },
        { name: 'Women', y: 700, color: '#FF7676', date: '04/05/2024' },
        { name: 'Kids', y: 850, color: '#2377FC', date: '03/30/2024' },
        { name: 'Men', y: 550, color: '#D251FF', date: '03/25/2024' },
        { name: 'Women', y: 900, color: '#FF7676', date: '03/15/2024' },
        { name: 'Kids', y: 1000, color: '#2377FC', date: '03/05/2024' },
        { name: 'Men', y: 600, color: '#D251FF', date: '02/28/2024' },
        { name: 'Kids', y: 1200, color: '#2377FC', date: today },
        { name: 'Men', y: 350, color: '#D251FF', date: today },
        { name: 'Women', y: 400, color: '#FF7676', date: today },
        { name: 'Kids', y: 1300, color: '#2377FC', date: today },
        { name: 'Men', y: 450, color: '#D251FF', date: today },
        { name: 'Women', y: 550, color: '#FF7676', date: today },
    ];

    const now = new Date();

    const filterDataByDateOption = (data) => {
        switch (checkData) {
            case 'today':
                return data.filter(item => {
                    const transactionDate = new Date(item.date);
                    return (
                        transactionDate.getDate() === now.getDate() &&
                        transactionDate.getMonth() === now.getMonth() &&
                        transactionDate.getFullYear() === now.getFullYear()
                    );
                });
            case 'weekly':
                const oneWeekAgo = new Date();
                oneWeekAgo.setDate(now.getDate() - 7);
                return data.filter(item => {
                    const transactionDate = new Date(item.date);
                    return transactionDate >= oneWeekAgo && transactionDate <= now;
                });
            case 'monthly':
                const oneMonthAgo = new Date();
                oneMonthAgo.setMonth(now.getMonth() - 1);
                return data.filter(item => {
                    const transactionDate = new Date(item.date);
                    return transactionDate >= oneMonthAgo && transactionDate <= now;
                });
            default:
                return data;
        }
    };

    const aggregateDataByCategory = (data) => {
        const aggregatedData = data.reduce((acc, item) => {
            const existingCategory = acc.find(category => category.name === item.name);
            if (existingCategory) {
                existingCategory.y += item.y;
            } else {
                acc.push({ name: item.name, y: item.y, color: item.color });
            }
            return acc;
        }, []);
        return aggregatedData;
    };

    const filteredData = filterDataByDateOption(chart_data);
    const aggregatedData = aggregateDataByCategory(filteredData);

    const totalYValue = aggregatedData.reduce((sum, item) => sum + item.y, 0);
    const formattedTotalYValue = new Intl.NumberFormat('en-IN').format(totalYValue);

    const options = {
        chart: {
            type: 'pie',
            custom: {},
            events: {
                render() {
                    const chart = this;
                    let customLabel = chart.options.chart.custom.label;
    
                    if (!customLabel) {
                        customLabel = chart.options.chart.custom.label =
                            chart.renderer.label('')
                            .css({
                                color: '#000',
                                textAnchor: 'middle'
                            })
                            .add();
                    }
    
                    const x = chart.series[0].center[0] + chart.plotLeft;
                    const y = chart.series[0].center[1] + chart.plotTop -
                        (customLabel.attr('height') / 2);
    
                    customLabel.attr({
                        x,
                        y
                    });
    
                    customLabel.css({
                        fontSize: `${chart.series[0].center[2] / 15}px`
                    });
                }
            }
        },
        title: {
            text: '',
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>'
        },
        legend: {
            enabled: true,
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                showInLegend: true,
                point: {
                    events: {
                        mouseOver() {
                            const chart = this.series.chart;
                            const customLabel = chart.options.chart.custom.label;
                            const formattedValue = new Intl.NumberFormat('en-IN').format(this.y);
                            
                            customLabel.attr({
                                text: `Total<br/><strong>${formattedValue}</strong>`
                            });
                            customLabel.show(); // Show the label on hover
                        },
                        mouseOut() {
                            const chart = this.series.chart;
                            const customLabel = chart.options.chart.custom.label;
                            
                            customLabel.hide(); // Hide the label when not hovering
                        }
                    }
                }
            }
        },
        series: [{
            name: 'Sales',
            colorByPoint: true,
            innerSize: '60%',
            data: aggregatedData,
        }],
        credits: {
            enabled: false
        }
    };
    

    return (
        <>
            <div className='bg-white rounded-lg overflow-hidden xl:w-5/12 md:w-5/12'>
                <div className="flex flex-row justify-between place-items-center px-3 pt-1">
                    <h1 className='text-lg md:text-xl font-semibold'>Sale By Category</h1>
                    <div className='md:w-36 w-28'>
                        <Dropdown
                            label=""
                            options={checkdataoption}
                            selectedValues={checkData}
                            onSelect={setCheckData}
                            onRemove={() => { }}
                            isMultiple={false}
                        />
                    </div>
                </div>
                <div className='flex xl:flex-row md:flex-col px-5 w-full xl:my-4 md:my-1 justify-between'>
                    <div className='flex flex-row place-items-start gap-6 mt-5 md:mt-0'>
                        <div className='flex flex-col'>
                            <span className='text-gray-400 text-nowrap text-base'>Total Sale</span>
                            <span className='xl:text-3xl md:text-xl font-medium mt-1'>{formattedTotalYValue}</span>
                        </div>
                        <div className=' flex flex-row items-center gap-3'>
                            <span><FaArrowTrendUp className='xl:text-xl md:text-lg' /></span>
                            <span className='text-nowrap'> 1.54 %</span>
                        </div>
                    </div>
                </div>
                <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
        </>
    );
};

export default Salesbycategory;
