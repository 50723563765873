import React, { useRef, useState } from 'react';
import axios from 'axios';
import LoginImg from '../Assets/Images/login-banner.jpg';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import notify from '../utiles/Notification';
import Loader from '../utiles/Loader'
import { UserState } from '../../Context/userProvider'
import { Link, useNavigate } from 'react-router-dom';

const ForgotPass = ({ onClose }) => {

    const baseUrl = process.env.REACT_APP_BASEURL;

    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [otpValues, setotpValues] = useState(['', '', '', '']);
    const [inputValue, setInputValue] = useState('');
    const { setUser } = UserState();
    const navigate = useNavigate();
    const [otpstatus, setotpStatus] = useState(false);
    const [OTP, setOTP] = useState(false);
    const [searchOption, setSearchOption] = useState('email');
    const [password, setPassword] = useState('');
    const [cpassword, setCpassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [cpasswordVisible, setcPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const togglecPasswordVisibility = () => {
        setcPasswordVisible(!cpasswordVisible);
    };

    const handleOptionChange = (e) => {
        setSearchOption(e.target.value);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const msg = searchOption === 'email' ? 'Email' : 'Mobile Number';

    const validateForm = () => {
        const newErrors = {};
        if (!email) {
            newErrors.email = 'E-Mail Address is required';
        }
        return newErrors;
    };

    const handlesendotp = async () => {

        if (searchOption == 'email') {
            if (!inputValue) {
                notify('danger', 'Error', `Please Enter your ${msg}`);

                return;
            } else if ((!emailRegex.test(inputValue))) {
                notify('danger', 'Error', `Please Enter valid Email`);
                return;
            }
        } else if (!inputValue) {
            notify('danger', 'Error', `Please Enter your ${msg}`);
            return;
        }

        setIsLoading(true);
        try {
            const payload = {
                [searchOption]: inputValue,
                type: "customer"
            };
            const response = await axios.post(
                `${baseUrl}/passwordsendotp`,
                payload,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );

            if (response.data.status === true) {
                notify('success', 'Success', `${response.data.otp}`);
                setOTP(true);
            } else {
                notify('danger', 'Error', `${response.data.message || 'An error occurred'}`);
            }


        } catch (err) {
            notify('danger', 'Error', 'Something went wrong');

        }

        setIsLoading(false);
    }

    const inputRefs = useRef([]);


    inputRefs.current = [];

    const setRef = (el, index) => {
        inputRefs.current[index] = el;
    };

    const handleChange = (e, index) => {
        const value = e.target.value;
        const maxLength = e.target.maxLength;

        const newValues = [...otpValues];
        newValues[index] = value;
        setotpValues(newValues);

        if (value.length === maxLength && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1]?.focus();
        }

        if (value.length === 0 && index > 0) {
            inputRefs.current[index - 1]?.focus();
        }
    };

    const handleVerifyOTP = async () => {
        const allValues = otpValues.join('');
        if (allValues.length != 4) {
            notify('danger', 'Error', `Please Enter valid OTP`);
            return;
        }
        setIsLoading(true);

        try {
            const payload = {
                [searchOption]: inputValue,
                otp: allValues,
                type: "customer"
            };
            const response = await axios.post(`${baseUrl}/passwordverifyotp`,
                payload,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );

            if (response.data.status === true) {
                notify('success', 'Success', `${response.data.message}`);
                setotpStatus(true);
            } else {
                notify('danger', 'Error', `${response.data.message || 'An error occurred'}`);
            }


        } catch (err) {
            notify('danger', 'Error', 'Something went wrong');

        }
        setIsLoading(false);
    };


    const handlesubmit = async () => {

        if (!password) {
            notify('danger', 'Error', `Password is required`);
            return;
        } else if (!passwordRegex.test(password)) {
            notify('danger', 'Error', `Password must be at least 8 characters long, contain at least one capital letter, one number, and one special character`);
            return;
        } else if (password != cpassword) {
            notify('danger', 'Error', `Password is not match to Confirm Password`);
            return;
        }
        setIsLoading(true);

        try {
            const payload = {
                [searchOption]: inputValue,
                password: password,
                type: "customer"
            };
            const response = await axios.post(`${baseUrl}/updatePassword`,
                payload,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );

            if (response.data.status === true) {
                notify('success', 'Success', `${response.data.message}`);
                navigate('../login');
            } else {
                notify('danger', 'Error', `${response.data.message || 'An error occurred'}`);
            }
        } catch (err) {
            notify('danger', 'Error', 'Something went wrong');

        }
        setIsLoading(false);
    }

    return (
        <>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-70">
                <div className="bg-white rounded-lg shadow-lg overflow-hidden md:flex flex-col lg:w-1/3 md:w-2/3 w-3/4" data-aos="fade-right" data-aos-duration="1000">
                    <div className="md:w-full p-6 sm:p-12 my-auto">
                        <div className='flex justify-between gap-5'>
                            <div>
                                <h2 className="text-2xl font-bold text-gray-900">Forgot Password</h2>
                            </div>
                            <div>
                                <RxCross2 className='cursor-pointer' onClick={onClose} />
                            </div>
                        </div>
                        {!OTP &&
                            <div className='flex flex-col gap-5 mb-2'>
                                <div className="flex flex-row place-items-center mb-1 justify-center space-x-4 mt-3 w-full">
                                    <label className="flex items-center cursor-pointer">
                                        <input
                                            type="radio"
                                            name="searchOption"
                                            value="email"
                                            checked={searchOption === 'email'}
                                            onChange={handleOptionChange}
                                            className="form-radio h-4 w-4 text-black focus:ring-0 "
                                        />
                                        <span className="ml-2">Email</span>
                                    </label>
                                    <label className="flex items-center cursor-pointer">
                                        <input
                                            type="radio"
                                            name="searchOption"
                                            value="mobile"
                                            checked={searchOption === 'mobile'}
                                            onChange={handleOptionChange}
                                            className="form-radio h-4 w-4 text-black focus:ring-0"
                                        />
                                        <span className="ml-2">Phone Number</span>
                                    </label>
                                </div>
                                <span className='text-black text-xs text-center '>Enter your {searchOption == 'email' ? 'Email id' : 'Mobile Number'} to get One Time Password on your registered {searchOption == 'email' ? 'Email id' : 'Mobile Number'}</span>
                            </div>
                        }
                        <div className='my-3 w-full'>
                            {otpstatus ? (
                                <div className="flex flex-col gap-3">
                                    <div className="">
                                        <span className='text-black text-sm text-center mt-3'>New Password</span>
                                        <div className="flex flex-row place-items-center border-gray-300 border-b">
                                            <input className='py-1 mt-2 px-1  no-arrows w-full text-black font-medium border-0 focus:ring-0 focus:border-b-gray-300 focus:border-t-0 focus:border-l-0 focus:border-r-0 border-t-0 border-l-0 border-r-0'
                                                type={passwordVisible ? "text" : "password"}
                                                placeholder={`Enter Your Password`}
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                            <div className="pe-2 cursor-pointer" onClick={togglePasswordVisibility}>
                                                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <span className='text-black text-sm text-center mt-3'>Confirm Password</span>
                                        <div className="flex flex-row place-items-center border-gray-300 border-b">
                                            <input className='py-1 mt-2 px-1 border-0 no-arrows w-full text-black font-medium focus:ring-0 focus:border-b-gray-300 focus:border-t-0 focus:border-l-0 focus:border-r-0 border-t-0 border-l-0 border-r-0'
                                                type={cpasswordVisible ? "text" : "password"}
                                                placeholder={`Confirm Password`}
                                                value={cpassword}
                                                onChange={(e) => setCpassword(e.target.value)}
                                            />
                                            <div className="pe-2 cursor-pointer" onClick={togglecPasswordVisibility}>
                                                {cpasswordVisible ? <FaEyeSlash /> : <FaEye />}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            ) : OTP ? (
                                <div className="flex flex-col">
                                    <span className='text-black text-sm text-center mt-3'>Enter the 4-digit verification code that was sent to your {searchOption == 'email' ? 'E-mail' : 'Mobile Number'} </span>
                                    <span className='px-1 font-medium text-neutral-500 mt-2'>Enater OTP</span>
                                    <div className="flex flex-row gap-2 w-full px-5 mt-1">
                                        {[0, 1, 2, 3].map((index) => (
                                            <input
                                                key={index}
                                                type="number"
                                                maxLength={1}
                                                className='no-arrows w-full focus:ring-0 focus:border-black rounded-lg text-center'
                                                ref={(el) => setRef(el, index)}
                                                onChange={(e) => handleChange(e, index)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Backspace' && inputRefs.current[index].value.length === 0 && index > 0) {
                                                        inputRefs.current[index - 1]?.focus();
                                                    }
                                                }}
                                            />
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div className="">
                                    <span className='px-1 font-medium text-neutral-500'>{searchOption == 'email' ? 'Email id' : 'Mobile Number'}</span>
                                    <input className='py-1 mt-2 px-1 border-gray-300 no-arrows w-full text-black font-medium focus:ring-0 focus:border-b-gray-300 focus:border-t-0 focus:border-l-0 focus:border-r-0 border-t-0 border-l-0 border-r-0'
                                        type={`${searchOption == 'email' ? 'email' : 'number'}`}
                                        placeholder={`Enter Your ${searchOption == 'email' ? 'Email id' : 'Mobile Number'}`}
                                        value={inputValue}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            )}
                        </div>
                        {otpstatus ? (
                            <button className='text-white uppercase w-full flex justify-center place-items-center bg-black mt-5 rounded-lg py-3 text-xl xl:text-xl md:text-lg font-semibold hover:bg-white hover:text-black hover:border  transition-all duration-200 ease-in-out border border-black'
                                onClick={handlesubmit}>Submit</button>
                        ) : OTP ? (
                            <button className='text-white uppercase w-full flex justify-center place-items-center bg-black mt-5 rounded-lg py-3 text-xl xl:text-xl md:text-lg font-semibold hover:bg-white hover:text-black hover:border  transition-all duration-200 ease-in-out border border-black'
                                onClick={handleVerifyOTP} >Verify OTP</button>
                        ) : (
                            <button className='text-white uppercase w-full flex justify-center place-items-center bg-black mt-5 rounded-lg py-3 text-xl xl:text-xl md:text-lg font-semibold hover:bg-white hover:text-black hover:border  transition-all duration-200 ease-in-out border border-black'
                                onClick={handlesendotp}>SEND OTP</button>
                        )}
                    </div>
                </div>
            </div>
            {isLoading && <Loader />}
        </>
    );
};

export default ForgotPass;
