import React, { useState, useEffect } from 'react';
import { IoSearchSharp } from "react-icons/io5";
import Pagination from '../../Component/utiles/Pagination';
import Dropdown from '../../Component/utiles/Dropdown';
import { Modal, Tabs, Label, TextInput, Progress, Tooltip } from "flowbite-react";
import { HiUserCircle } from "react-icons/hi";
import PhoneInput from 'react-phone-input-2';
import { FaLocationDot } from "react-icons/fa6";
import { useCountries, useStates } from '../../Component/utiles/CountryStateCity';
import Loader from '../../Component/utiles/Loader';
import notify from '../../Component/utiles/Notification';
import { TbUserEdit } from "react-icons/tb";
import axios from 'axios';




const Wearehouse = () => {
    const baseUrl = process.env.REACT_APP_BASEURL

    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [wearehouse, setWearehouse] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState('10');
    const [openModal, setOpenModal] = useState(false);
    const [countryID, setCountryID] = useState('');
    const { countryOptions, error: countryError } = useCountries();
    const { stateOptions, error: stateError } = useStates(countryID)
    const [isEditing, setIsEditing] = useState(false);
    const [wearehouseUpdateId, setWearhouseUpdateId] = useState();
    const [wearehousInfo, setWearehousInfo] = useState({
        name: '',
        email: '',
        mobile: '',
        telephone: '',
    })
    const [addressData, setAddressData] = useState({
        address: '',
        country: '',
        state: '',
        locality: '',
        pincode: '',
        city: '',
    });
    const [wearehousManager, setWearehousManager] = useState({
        managerName: '',
        managerEmail: '',
        managerMobile: '',
    })
    const handleChange = (e) => {
        const { name, value } = e.target;
        setAddressData((prev) => ({ ...prev, [name]: value }));
    };



    const perpageoption = [
        { label: '5' }, { label: '10' }, { label: '15' }, { label: '20' }
    ];

    const searchResults = wearehouse.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.sku.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastItem = currentPage * parseInt(itemsPerPage);
    const indexOfFirstItem = indexOfLastItem - parseInt(itemsPerPage);
    const currentItems = searchResults.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(searchResults.length / parseInt(itemsPerPage));

    const getStatusClass = (Status) => {
        switch (Status) {
            case 'Inactive':
                return 'bg-red-200 text-red-500 text-xs';
            default:
                return 'bg-green-200 text-green-600 text-xs';
        }

    }


    const allWearehouse = async () => {
        setIsLoading(true)
        try {
            const response = await axios.get(`${baseUrl}/get_wharehouse_list`);
            if (response.data.status) {
                setWearehouse(response.data.wharehouse)
            }
        } catch (err) {
            console.log(err);
        }
        setIsLoading(false)
    };
    useEffect(() => {
        allWearehouse();
    }, []);
    const handelCreate = () => {
        setIsEditing(false)
        setWearehousInfo({
            name: '',
            email: '',
            mobile: '',
            telephone: '',
        });
        setAddressData({
            address: '',
            country: '',
            state: '',
            locality: '',
            pincode: '',
            city: '',
        });
        setWearehousManager({
            managerName: '',
            managerEmail: '',
            managerMobile: '',
        })
        setOpenModal(true)
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    const notifyError = (message) => {
        notify('danger', 'Error', message);
    };
    const validateDetails = (wearehousInfo, addressData, wearehousManager) => {
        if (!wearehousInfo.name) {
            notifyError('Name is required');
            return false;
        }

        if (!wearehousInfo.email) {
            notifyError('Email is required');
            return false;
        }

        if (!emailRegex.test(wearehousInfo.email)) {
            notifyError('Invalid Email Format');
            return false;
        }

        if (!wearehousInfo.mobile) {
            notifyError('Mobile Number is required');
            return false;
        }
        if (!addressData.address) {
            notifyError('Address is required');
            return false;
        }

        if (!addressData.country) {
            notifyError('Country is required');
            return false;
        }

        if (!addressData.state) {
            notifyError('State is required');
            return false;
        }

        if (!addressData.city) {
            notifyError('City is required');
            return false;
        }

        if (!addressData.pincode) {
            notifyError('Pincode is required');
            return false;
        }
        if (!/^\d{6}$/.test(addressData.pincode)) {
            notifyError('Invalid Pincode format. It should be 6 digits.');
            return false;
        }
        if (!wearehousManager.managerName) {
            notifyError('Manager Name is required');
            return false;
        }
        if (!wearehousManager.managerEmail) {
            notifyError('Manager Email is required');
            return false;
        }
        if (!emailRegex.test(wearehousManager.managerEmail)) {
            notifyError('Invalid Manager Email Format');
            return false;
        }
        if (!wearehousManager.managerMobile) {
            notifyError('Manager Mobile Number is required');
            return false;
        }
        return true;
    };
    const handelAddWearehouse = async () => {
        const isValid = validateDetails(wearehousInfo, addressData, wearehousManager);

        if (!isValid) {
            return;
        }

        const payload = {
            ...wearehousInfo,
            ...addressData,
            ...wearehousManager,
        };
        setIsLoading(true)
        try {
            let response;
            if (isEditing) {
                response = await axios.post(`${baseUrl}/update_wharehouse_data`, {
                    ...payload,
                    wharehouse_id: wearehouseUpdateId
                });
            } else {
                response = await axios.post(`${baseUrl}/add_new_wharehouse`, 
                payload);
            }

            if (response.data.status) {
                notify('success', 'Success', `${response.data.message}`);
                setOpenModal(false);
                setIsLoading(false);
                setIsEditing(false);
                await allWearehouse()
            } else {
                notify('danger', 'Error', `${response.data.message}`);
                setIsLoading(false)
            }
        } catch (error) {
            console.error('Error during registration:', error);
            notify('danger', 'Error', 'There was an error during registration. Please try again.');
            setIsLoading(false)
        }
    }

    const handelUpdate = async (wearehouseId) => {
        setIsLoading(true);
        setWearhouseUpdateId(wearehouseId);
        try {
            const response = await axios.post(`${baseUrl}/get_wharehousebyid_list`, {
                wharehouse_id: wearehouseId
            });
            if (response.data.status) {
                const wharehouseData = response.data.wharehouse;
                setWearehousInfo({
                    name: wharehouseData.name,
                    email: wharehouseData.email,
                    mobile: wharehouseData.mobile,
                    telephone: wharehouseData.telephone
                })
                setAddressData({
                    address: wharehouseData.address1,
                    country: wharehouseData.country_id,
                    state: wharehouseData.state_subdivision_id,
                    locality: wharehouseData.locality,
                    pincode: wharehouseData.pin_code,
                    city: wharehouseData.city
                })
                setWearehousManager({
                    managerName: wharehouseData.manager,
                    managerEmail: wharehouseData.manager_email,
                    managerMobile: wharehouseData.manager_mobile
                })
                setCountryID(wharehouseData.country_id)
                setIsEditing(true);
                setOpenModal(true);
                
            } else {
                notify('danger', 'Error', response.data.message);
            }
        } catch (error) {
            console.error('Error during registration:', error);
            notify('danger', 'Error', 'There was an error during registration. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeStatus = async (id, currentStatus) => {
        setIsLoading(true);
        try {
            const nextStatus = currentStatus === 'Active' ? 'Inactive' : 'Active';
    
            const response = await axios.post(`${baseUrl}/update_wharehouse_status`, {
                wharehouse_id: id,
                status: nextStatus
            });
    
            if (response.data.status) {
                notify('success', 'Success', `${response.data.message}`);
                await allWearehouse();
                setIsLoading(false);
            } else {
                notify('danger', 'Error', `${response.data.message}`);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error during status update:', error);
            notify('danger', 'Error', 'There was an error updating the status. Please try again.');
            setIsLoading(false);
        }
    };
    return (
        <>
            {isLoading && <Loader />}
            <h1 className='xl:text-2xl md:text-lg text-xl mt-3 font-semibold capitalize'>Warehouse </h1>
            <div className='w-full bg-white rounded-lg mt-5 md:p-6 p-3'>
                <div className='flex flex-row justify-between my-5 pe-0'>
                    <div className='flex flex-row items-center md:w-64 w-24 gap-1'>
                        <span className='text-nowrap mt-2 md:block hidden text-sm capitalize'>items per page</span>
                        <div className='w-16'>
                            <Dropdown
                                label=""
                                options={perpageoption}
                                selectedValues={itemsPerPage}
                                onSelect={setItemsPerPage}
                                onRemove={() => { }}
                                isMultiple={false}
                            />
                        </div>
                    </div>

                    <div className="relative hidden md:flex w-5/12">
                        <input
                            type="text"
                            placeholder="Search Here"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="bg-gray-200 w-full border-0 rounded-full px-4 py-1 pr-10 focus:outline-none focus:border-gray-400 focus:ring-2 focus:ring-gray-400"
                        />
                        <IoSearchSharp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-black cursor-pointer" />
                    </div>
                    <button onClick={handelCreate} className='text-white flex justify-center items-center font-semibold text-nowrap bg-black ms-5 md:px-5 md:text-sm text-sm md:py-1 py-2 px-3  hover:text-black hover:bg-white hover:border border rounded-lg hover:border-black transition-all duration-200 capitalize'>add &nbsp;<span className='md:block hidden'>Wearehous</span></button>
                </div>
                <div className='overflow-hidden overflow-x-auto mt-6'>
                    <table className="lg:w-full md-w[100%] table bg-white">
                        <thead className="bg-gray-100 rounded-full">
                            <tr>
                                <th className="py-4 px-4 text-left capitalize text-nowrap">Warehouse Name</th>
                                <th className="py-4 px-4 text-left capitalize text-nowrap">Warehouse Mobile</th>
                                <th className="py-4 px-4 text-left capitalize text-nowrap">Manager Name</th>
                                <th className="py-4 px-4 text-left capitalize text-nowrap">Manager Mobile</th>
                                <th className="py-4 px-4 text-left capitalize text-nowrap">Status</th>
                                <th className="py-4 px-4 text-left capitalize text-nowrap">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map(item => (
                                <tr className="border-b border-gray-200 hover:bg-gray-200 transition-all duration-200 ease-in-out">
                                     <td className="py-4 px-4 flex items-center gap-4">
                                        <div className="flex flex-col">
                                            <span className='text-nowrap font-medium text-lg pe-20 lg:pe-0'>{item.name}</span>
                                            <span className='text-nowrap  text-sm text-gray-400 pe-20 lg:pe-0'>{item.email}</span>
                                        </div>
                                    </td>
                                    <td className="py-4 px-4">{item.mobile}</td>
                                    <td className="py-4 px-4 flex items-center gap-4">
                                        <div className="flex flex-col">
                                            <span className='text-nowrap font-medium text-lg pe-20 lg:pe-0'>{item.manager}</span>
                                            <span className='text-nowrap  text-sm text-gray-400 pe-20 lg:pe-0'>{item.manager_email}</span>
                                        </div>
                                    </td>
                                    <td className="py-4 px-4">{item.manager_mobile}</td>
                                    <td className="py-4 px-4 ">
                                        <div className="flex flex-row gap-2 place-items-center ">
                                            <span className={`px-2 py-1  rounded-lg capitalize font-semibold ${getStatusClass(item.status)}`}>
                                                {item.status}
                                            </span>
                                        </div>
                                    </td>
                                    <td className="py-4 px-4 ">
                                        <div className="flex flex-row gap-3 text-black text-xl">
                                            <Tooltip content="Update Details" >
                                            <div className='cursor-pointer bg-neutral-300  rounded-lg p-1.5' onClick={() => handelUpdate(item.wharehouse_id)}><TbUserEdit /></div>
                                            </Tooltip>
                                            <Tooltip content="Change Status">
                                            <label className="inline-flex items-center me-5 cursor-pointer w-full">
                                                <input type="checkbox" className="sr-only peer"
                                                    checked={item.status === 'Active'}
                                                    onChange={() => handleChangeStatus(item.wharehouse_id, item.status)}
                                                />
                                                <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600"></div>
                                            </label>
                                            </Tooltip>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-between items-center mt-4">
                    <div>
                        <span>Page {currentPage} of {totalPages}</span>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </div>
            <Modal show={openModal} onClose={() => setOpenModal(false)}>
                <Modal.Header>{isEditing ? 'Update' : 'Add'} Wearehouse</Modal.Header>
                <Modal.Body>
                <Tabs aria-label="Tabs with icons" className='focus:ring-red-300' variant="underline">
                        <Tabs.Item active title="Profile" icon={HiUserCircle}>
                            <div className="w-full space-y-5">
                                <div className="flex md:flex-row flex-col place-items-center gap-5 w-full">
                                    <div className="flex flex-col gap-2 w-full">
                                        <h1 className='text-base font-semibold capitalize text-left'>Name <span className="text-red-500">*</span></h1>
                                        <input type="text" className='w-full rounded-md border border-gray-300 px-2  focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Name'
                                            value={wearehousInfo.name}
                                            onChange={(e) => setWearehousInfo({ ...wearehousInfo, name: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 w-full">
                                    <h1 className='text-base font-semibold capitalize text-left'>Email <span className="text-red-500">*</span></h1>
                                    <input type="email" className='w-full rounded-md border border-gray-300 px-2  focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter E-mail'
                                        value={wearehousInfo.email}
                                        onChange={(e) => setWearehousInfo({ ...wearehousInfo, email: e.target.value })}
                                    />
                                </div>
                                <div className="flex flex-col gap-2 w-full">
                                    <h1 className='text-base font-semibold capitalize text-left'>Mobile <span className="text-red-500">*</span></h1>
                                    <PhoneInput
                                        country={'in'}
                                        value={wearehousInfo.mobile}
                                        countryCodeEditable={false}
                                        enableSearch={true}
                                        onChange={(val) => setWearehousInfo({ ...wearehousInfo, mobile: val })}
                                        placeholder="Enter Mobile Number"
                                        inputClass="input-mobile mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-400 focus:ring-1"
                                    />
                                </div>
                                <div className="flex flex-col gap-2 w-full">
                                    <h1 className='text-base font-semibold capitalize text-left'>Telephone <span className="text-red-500">*</span></h1>
                                    <input type="text" className='w-full rounded-md border border-gray-300 px-2  focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Telephone'
                                        value={wearehousInfo.telephone}
                                        onChange={(e) => setWearehousInfo({ ...wearehousInfo, telephone: e.target.value })}
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </Tabs.Item>
                        <Tabs.Item title="Address" icon={FaLocationDot}>
                            <div className="mb-4">
                                <Label value="Address" />
                                <TextInput
                                    className="w-full"
                                    name="address"
                                    value={addressData.address}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='flex gap-5'>
                                <div className="mb-4 w-3/4">
                                    <Label htmlFor="country" value="Country" />
                                    <select
                                        id="country"
                                        name="country"
                                        className="w-full p-2 border border-gray-300 rounded-md"
                                        onChange={(e) => {
                                            setCountryID(e.target.value);
                                            handleChange(e);
                                        }}
                                        value={addressData.country}
                                    >
                                        <option value="">Select Country</option>
                                        {countryOptions.map((country) => (
                                            <option key={country.id} value={country.id}>
                                                {country.label}
                                            </option>
                                        ))}
                                    </select>
                                    {countryError && <p className="text-red-600">{countryError}</p>}
                                </div>
                                <div className="mb-4 w-3/4">
                                    <Label htmlFor="state" value="State" />
                                    <select
                                        id="state"
                                        name="state"
                                        className="w-full p-2 border border-gray-300 rounded-md"
                                        disabled={!countryID}
                                        value={addressData.state}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select State</option>
                                        {stateOptions.map((state) => (
                                            <option key={state.id} value={state.id}>
                                                {state.label}
                                            </option>
                                        ))}
                                    </select>
                                    {stateError && <p className="text-red-600">{stateError}</p>}
                                </div>
                            </div>
                            <div className='flex gap-5'>
                                <div className="mb-4 w-3/4">
                                    <Label value="Locality" />
                                    <TextInput
                                        className="w-full"
                                        name="locality"
                                        value={addressData.locality}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-4 w-3/4">
                                    <Label value="City" />
                                    <TextInput
                                        className="w-full"
                                        name="city"
                                        value={addressData.city}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="mb-4 w-1/2">
                                <Label value="Pincode" />
                                <TextInput
                                    className="w-full"
                                    name="pincode"
                                    value={addressData.pincode}
                                    onChange={handleChange}
                                    onKeyPress={(e) => {
                                        if (!/[0-9]/.test(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            </div>
                        </Tabs.Item>
                        <Tabs.Item active title="Wearehouse Manager" icon={HiUserCircle}>
                            <div className="w-full space-y-5">
                                <div className="flex md:flex-row flex-col place-items-center gap-5 w-full">
                                    <div className="flex flex-col gap-2 w-full">
                                        <h1 className='text-base font-semibold capitalize text-left'>Manager Name<span className="text-red-500">*</span></h1>
                                        <input type="text" className='w-full rounded-md border border-gray-300 px-2  focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Manager Name'
                                            value={wearehousManager.managerName}
                                            onChange={(e) => setWearehousManager({ ...wearehousManager, managerName: e.target.value })}
                                        />
                                    </div>

                                </div>
                                <div className="flex flex-col gap-2 w-full">
                                    <h1 className='text-base font-semibold capitalize text-left'> Manager Email <span className="text-red-500">*</span></h1>
                                    <input type="email" className='w-full rounded-md border border-gray-300 px-2  focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Manager E-mail'
                                        value={wearehousManager.managerEmail}
                                        onChange={(e) => setWearehousManager({ ...wearehousManager, managerEmail: e.target.value })}
                                    />
                                </div>
                                <div className="flex flex-col gap-2 w-full">
                                    <h1 className='text-base font-semibold capitalize text-left'>Manager Mobile <span className="text-red-500">*</span></h1>
                                    <PhoneInput
                                        country={'in'}
                                        value={wearehousManager.managerMobile}
                                        countryCodeEditable={false}
                                        enableSearch={true}
                                        onChange={(val) => setWearehousManager({ ...wearehousManager, managerMobile: val })}
                                        placeholder="Enter Manager Mobile Number"
                                        inputClass="input-mobile mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-400 focus:ring-1"
                                    />
                                </div>
                            </div>
                        </Tabs.Item>
                        </Tabs>
                </Modal.Body>
                <Modal.Footer className='flex justify-end'>
                <button type="button" onClick={handelAddWearehouse} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                        {isEditing ? 'Update Wearehouse' : 'Add Wearehouse'}
                    </button>
                    <button type="button" onClick={() => setOpenModal(false)} className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>


        </>
    );
}

export default Wearehouse;