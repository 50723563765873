import React, { useEffect, useRef, useState } from 'react'
import Dropdown from '../../../Component/utiles/Dropdown'
import { Link } from 'react-router-dom';
import Pagination from '../../../Component/utiles/Pagination';
import { FiEdit } from "react-icons/fi";
import { MdCloudDownload, MdDelete } from "react-icons/md";
import axios from 'axios';
import notify from '../../../Component/utiles/Notification';
import { Modal, Tabs, Label, TextInput, Progress, Tooltip, FileInput } from "flowbite-react";
import { ImageUpload } from '../../../Component/utiles/ImageUpload';
import { AiOutlineDelete } from 'react-icons/ai';


const Brands = ({ setLoading }) => {

    const baseUrl = process.env.REACT_APP_BASEURL
    const [openModal, setOpenModal] = useState(false);

    const [brands, setBrands] = useState([]);
    const [refresh, setRefresh] = useState(0);

    const multiple = false;
    const { images, handleFileChange, handleRemoveImage } = ImageUpload(multiple, setLoading);


    const [branddata, setBranddata] = useState({
        brand_id: '',
        brand_image: '',
        brand_name: '',
    });

    useEffect(() => {
        if (images.length > 0) {
            setBranddata((prev) => ({
                ...prev,
                brand_image: images[0].url
            }))
        }
    }, [images])

    const removeimg = (index) => {
        handleRemoveImage(index)
        setBranddata((prev) => ({ ...prev, brand_image: '' }));
    }


    useEffect(() => {
        setLoading(true);
        axios.get(`${baseUrl}/get_brands`)
            .then(response => {
                if (response.data.status) {
                    setBrands(response.data.brands);
                } else {
                    console.log('Failed to load Brands.');
                }
            })
            .catch(error => {
                console.log('An error occurred while fetching Banrds.');
            })
        setLoading(false);
    }, [refresh]);

    const addbrand = async () => {
        if (!branddata.brand_image) {
            notify('danger', 'Error', 'Please Add Brnad Image');
            return;
        }
        if (!branddata.brand_name) {
            notify('danger', 'Error', 'Please Add Brnad Name');
            return;
        }
        setLoading(true);
        try {
            const response = await axios.post(`${baseUrl}/add_brand`,
                {
                    brand_image: branddata.brand_image,
                    brand_name: branddata.brand_name,
                });
            if (response.data.status) {
                notify('success', 'Success', 'Brand Add successfull');
                setRefresh(prev => prev + 1);

                setOpenModal(false);
            } else {
                notify('danger', 'Error', `${response.data.message}`);
            }
        } catch (err) {
            console.log(err)
        }
        setLoading(false);
    }


    const perpageoption = [
        { label: '5' }, { label: '10' }, { label: '15' }, { label: '20' }
    ]

    const [itemsPerPage, setItemsPerPage] = useState(perpageoption[0].label);
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = brands.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(brands.length / itemsPerPage);


    const handleDelete = async (id) => {
        setLoading(true);
        try {
            const response = await axios.get(`${baseUrl}/delete_brand/${id}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );
            if (response.data.status) {
                notify('success', 'Success', `Brand Delete Successfully`);
                setRefresh(prev => prev + 1);
            } else {
                notify('danger', 'Error', `${response.data.message || 'An error occurred'}`);
            }
        } catch {
            notify('danger', 'Error', 'Something went wrong');
        }
        setLoading(false);
    }

    const addnew = () => {
        setBranddata({
            brand_id: '',
            brand_image: '',
            brand_name: ''
        });
        setOpenModal(true)
    }

    const editbrand = async (id) => {
        const data = brands.find(list => list.brand_id == id);
        setBranddata({
            brand_id: data.brand_id,
            brand_image: data.brand_image,
            brand_name: data.brand_name
        });
        setOpenModal(true)
    }

    const fileInputRef = useRef(null);

    const openimage = () => {
        fileInputRef.current.click();
    }

    return (
        <>
            <h1 className="xl:text-2xl md:text-lg text-xl mt-4 font-semibold capitalize">Brands</h1>
            <div className='w-full bg-white rounded-lg mt-5 p-6'>
                <div className='flex flex-row justify-between my-5 p-0'>
                    <div className='flex flex-row items-center md:w-48 w-20 gap-5'>
                        <span className='text-nowrap mt-2 md:block hidden'>items per page</span>
                        <Dropdown
                            label=""
                            options={perpageoption}
                            selectedValues={itemsPerPage}
                            onSelect={setItemsPerPage}
                            onRemove={() => { }}
                            isMultiple={false}
                        />
                    </div>
                    <Link onClick={addnew} className='text-white flex justify-center font-semibold items-center bg-black ms:px-5 px-3 text-base   md:py-2 py-0.5  hover:text-black hover:bg-white hover:border border rounded-lg hover:border-black transition-all duration-200'>Add New Brand</Link>
                </div>
                <div className='overflow-hidden overflow-x-auto'>
                    <table className="min-w-full table bg-white">
                        <thead className="bg-gray-100 rounded-full">
                            <tr>
                                <th className="py-4 px-4 text-left">ID</th>
                                <th className="py-4 px-4 text-left">Logo</th>
                                <th className="py-4 px-4 text-left">Name</th>
                                <th className="py-4 px-4 text-left">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map(item => (
                                <tr key={item.id} className="border-b border-gray-200 hover:bg-gray-200 transition-all duration-200 ease-in-out">
                                    <td className="py-4 px-4 capitalize font-normal text-base">{item.brand_id}</td>
                                    <td className="py-4 px-4 capitalize font-semibold text-base"><img src={item.brand_image} className='md:h-24 md:w-36 h-10 w-40' alt="" /></td>
                                    <td className="py-4 px-4 capitalize font-normal text-base">{item.brand_name}</td>
                                    <td className="p-4">
                                        <div className='flex flex-row items-center gap-5 text-xl'>
                                            <Link className='cursor-pointer hover:scale-110 duration-200 transition-all px-3 py-2 text-lg rounded-md bg-neutral-300' onClick={() => editbrand(item.brand_id)}>
                                                <FiEdit />
                                            </Link>
                                            <div className='px-3 cursor-pointer hover:scale-110 duration-200 transition-all py-2 text-lg rounded-md bg-neutral-300' onClick={() => handleDelete(item.brand_id)}>
                                                <MdDelete />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-between items-center mt-4">
                    <div>
                        <span>Page {currentPage} of {totalPages}</span>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </div>
            <Modal show={openModal} size="2xl" onClose={() => setOpenModal(false)} >
                <Modal.Header>{branddata.brand_id ? 'Update' : 'Add'} Brand</Modal.Header>
                <Modal.Body>
                    {branddata.brand_id ? (
                        <div className="flex justify-center place-items-start mt-2">
                            <img className='h-44 w-44' src={branddata.brand_image} alt='my-image' />

                            <input
                                type="file"
                                ref={fileInputRef}
                                accept="image/*"
                                className="hidden"
                                onChange={handleFileChange}
                            />
                            <button className='text-white bottom-0 bg-black  rounded-lg p-2 text-base' onClick={openimage}>
                                <FiEdit />
                            </button>
                        </div>
                    ) : (
                        <div className='flex items-center flex-col'>
                            {images.length == 0 &&
                                <Label
                                    htmlFor="dropzone-file"
                                    className="flex w-full h-64 my-3 cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                >
                                    <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                        <MdCloudDownload className='text-3xl text-gray-400' />
                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                            <span className="font-semibold">Click to upload</span> or drag and drop
                                        </p>
                                        <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                    </div>
                                    <FileInput id="dropzone-file" className="hidden" multiple={multiple} onChange={handleFileChange} />
                                </Label>
                            }
                            {images.length > 0 &&
                                <div className='images mt-2 flex flex-row items-start w-full flex-wrap gap-2'>
                                    <div className='mt-2 flex flex-wrap gap-2'>
                                        <div className="">
                                            {images.map((image, index) => (
                                                <div key={index} className='relative'>
                                                    <img src={image.url} alt={`Uploaded ${index}`} className="h-32  rounded-lg" />
                                                    <button
                                                        type="button"
                                                        onClick={() => removeimg(index)}
                                                        className="absolute top-2 right-2 bg-red-500 text-white rounded-full text-xl p-1"
                                                    >
                                                        <AiOutlineDelete />
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    )}
                    <div className="mt-5">
                        <span className='text-base font-medium capitalize'>Name</span>
                        <input className='w-full py-2 mt-3 rounded-md focus:ring-0 border-gray-300 focus:border-black' value={branddata.brand_name} onChange={(e) => setBranddata({ ...branddata, brand_name: e.target.value })} type='text' placeholder='Enter Brand Name' />
                    </div>
                </Modal.Body>
                <Modal.Footer className='flex justify-end'>

                    <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={addbrand}>
                        {branddata.brand_id ? 'Update' : 'Add'} Brand
                    </button>
                    <button type="button" onClick={() => setOpenModal(false)} className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Brands