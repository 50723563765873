import React, { useEffect, useRef, useState } from 'react'
import Img1 from '../Assets/Images/banner-bg-shoe.png'
import bgImage from '../Assets/Images/banner-bg-arrivals.png';
import Team from '../Assets/Images/rating2.png'
import { RiTeamFill } from "react-icons/ri";
import { BiLogoFacebook } from "react-icons/bi";
import { LiaLinkedinIn } from "react-icons/lia";
import { AiOutlineInstagram } from "react-icons/ai";
import { Link } from 'react-router-dom';
import axios from 'axios';


const About_Us = () => {


    // const aboutData = [
    //     {
    //         img: Img1,
    //         title: 'About our shop',
    //         description: [
    //             'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries'
    //             , 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries'
    //             , 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries'
    //         ]
    //     },
    //     {
    //         img: Img1,
    //         title: 'What we do',
    //         description: [
    //             'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries'
    //             , 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries'
    //             , 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries'
    //         ]
    //     }
    // ]
    const [aboutData, setaboutData] = useState([]);
    const baseUrl = process.env.REACT_APP_BASEURL


    useEffect(() => {
        axios.get(`${baseUrl}/about`)
            .then(response => {
                const aboutArray = response.data.about_array;
                if (aboutArray && aboutArray.length > 0) {
                    setaboutData(aboutArray);  // Store the first object in the state
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const formatPara = (text) => {
        return text.replace(/\r?\n/g, '<br />');
    };

    const teamDate = [
        {
            img: Team,
            name: 'gautam',
            post: 'ceo',
            socialMedia: [
                {
                    link: 'facebook',
                    icon: BiLogoFacebook,
                },
                {
                    link: 'linkedin',
                    icon: LiaLinkedinIn,
                },
                {
                    link: 'instagram',
                    icon: AiOutlineInstagram,
                },
            ]

        },
        {
            img: Team,
            name: 'gautam',
            post: 'ceo',
            socialMedia: [
                {
                    link: 'facebook',
                    icon: BiLogoFacebook,
                },
                {
                    link: 'linkedin',
                    icon: LiaLinkedinIn,
                },
                {
                    link: 'instagram',
                    icon: AiOutlineInstagram,
                },
            ]

        },
        {
            img: Team,
            name: 'gautam',
            post: 'ceo',
            socialMedia: [
                {
                    link: 'facebook',
                    icon: BiLogoFacebook,
                },
                {
                    link: 'linkedin',
                    icon: LiaLinkedinIn,
                },
                {
                    link: 'instagram',
                    icon: AiOutlineInstagram,
                },
            ]

        },
        {
            img: Team,
            name: 'gautam',
            post: 'ceo',
            socialMedia: [
                {
                    link: 'facebook',
                    icon: BiLogoFacebook,
                },
                {
                    link: 'linkedin',
                    icon: LiaLinkedinIn,
                },
                {
                    link: 'instagram',
                    icon: AiOutlineInstagram,
                },
            ]

        },
        {
            img: Team,
            name: 'gautam',
            post: 'ceo',
            socialMedia: [
                {
                    link: 'facebook',
                    icon: BiLogoFacebook,
                },
                {
                    link: 'linkedin',
                    icon: LiaLinkedinIn,
                },
                {
                    link: 'instagram',
                    icon: AiOutlineInstagram,
                },
            ]

        },
        {
            img: Team,
            name: 'gautam',
            post: 'ceo',
            socialMedia: [
                {
                    link: 'facebook',
                    icon: BiLogoFacebook,
                },
                {
                    link: 'linkedin',
                    icon: LiaLinkedinIn,
                },
                {
                    link: 'instagram',
                    icon: AiOutlineInstagram,
                },
            ]

        },

    ]


    const sliderRef = useRef(null);
    const [isPaused, setIsPaused] = useState(false);

    useEffect(() => {
        const slider = sliderRef.current;
        let startPosition = 0;

        const handleScroll = () => {
            if (slider.scrollLeft >= slider.scrollWidth / 2) {
                slider.scrollLeft = startPosition;
            }
        };

        const scrollInterval = setInterval(() => {
            if (!isPaused) {
                slider.scrollLeft += 1;
                handleScroll();
            }
        }, 10); // Adjust speed by changing the interval value

        return () => clearInterval(scrollInterval);
    }, [isPaused]);

    return (
        <>

            <div
                className="relative bg-cover bg-center h-[500px] flex items-center justify-center opacity-90"
                style={{ backgroundImage: `url(${bgImage})` }}
            >
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="relative text-center text-white">
                    <h1 className="text-5xl font-bold uppercase">about us</h1>
                    <p className="mt-2">
                        <Link to="/">Home</Link> <span className="mx-2">›</span> <Link to='/pages/about-us' className='capitalize'>about us</Link>
                    </p>
                </div>
            </div>
            <div className='container mx-auto md:px-10 px-5 xl:px-0'>
                {aboutData.map((list, index) => (
                    <div className="flex flex-col lg:flex-row md:gap-10 gap-5 w-full md:my-10 my-5" key={index}>
                        <div className={`w-full lg:w-1/2 ${index % 2 === 0 ? 'lg:order-1 order-0' : 'lg:order-2'}`}>
                            <img src={list.about && list.about} alt="" className='rounded-xl h-full' />
                        </div>
                        <div className={`w-full lg:w-1/2 flex flex-col justify-center gap-5 ${index % 2 === 0 ? 'lg:order-2 order-1' : 'lg:order-1 order-1'}`}>
                            <h1 className='md:text-5xl text-2xl font-semibold capitalize'>{list.heading && list.heading}</h1>
                            <p
                                className="font-normal text-black w-full"
                                dangerouslySetInnerHTML={{ __html: formatPara(list.para) }}
                            />
                        </div>
                    </div>
                ))}
                <h1 className='md:text-5xl text-2xl font-semibold capitalize text-center'>Our team</h1>
                <div className="flex justify-center place-items-center md:text-3xl text-xl mt-3">
                    <span className='md:before:w-16 before:w-10 before:bg-black before:absolute md:-left-16 -left-10 relative before:h-0.5'></span><span className='md:before:w-20 before:w-16 top-1.5 before:bg-black before:absolute md:-left-20 -left-16 relative before:h-0.5'></span>
                    <RiTeamFill className='mx-2' />
                    <span className='md:before:w-16 before:w-10 before:bg-black before:absolute -right-0 relative before:h-0.5'></span><span className='md:before:w-20 before:w-16 top-1.5 before:bg-black before:absolute -right-0 relative before:h-0.5'></span>
                </div>
                <div className="w-full overflow-hidden overflow-x-auto md:mt-10 mt-5 teams"
                    ref={sliderRef}
                    onMouseEnter={() => setIsPaused(true)}
                    onMouseLeave={() => setIsPaused(false)}
                >
                    <div className="xl:w-[180vw] md:w-[350vw] w-[760vw]  md:gap-10 gap-5 flex flex-row">
                        {teamDate.concat(teamDate).map((list, index) => (
                            <div className="w-64 h-52 xl:h-64 xl:w-64 md:h-40 md:w-40 relative rounded-lg overflow-hidden group" key={index}>
                                <img src={list.img} alt="" className='w-full h-full' />
                                <div className="absolute z-10 bottom-0 py-3 flex justify-center gap-1 place-items-center flex-col bg-black h-2/3 xl:h-1/2 opacity-50 w-full 
                                    translate-y-full transition-all duration-500 ease-in-out 
                                    group-hover:translate-y-0">
                                </div>
                                <div className="absolute z-10 bottom-0 py-3 flex justify-center gap-1 place-items-center flex-col w-full 
                                    translate-y-full transition-all duration-500 ease-in-out 
                                    group-hover:translate-y-0">
                                    <h1 className='text-2xl text-white capitalize'>{list.name}</h1>
                                    <span className='text-xl text-white'>{list.post}</span>
                                    <div className="flex text-2xl text-white gap-2 w-full justify-center place-items-center">
                                        {list.socialMedia.map((media, i) => (
                                            <a key={i} className='h-7 w-7 rounded-full text-black bg-white flex justify-center place-items-center text-xl' target='_blank' href={media.link}><media.icon /></a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default About_Us