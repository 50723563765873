import { useEffect } from 'react';
import axios from 'axios';

const useSubcategories = (url, body, setState, fallback, arrayKey, reqid, resid, resname ,setLoading) => {
    useEffect(() => {
        const fetchSubcategories = async () => {
            try {
                setLoading(true)
                const response = await axios.post(url, body, {
                    headers: { 'Content-Type': 'application/json' }
                });

                if (response.data.status) {
                    const subcategories = response.data[arrayKey].map(categoryValue => ({
                        id: categoryValue[resid],
                        label: categoryValue[resname]
                    }));
                    setState(subcategories);
                } else {
                    setState(fallback);
                }
            } catch (err) {
                console.log(err);
            }
            setLoading(false);
        };
        fetchSubcategories();
    }, [reqid]);
};

export default useSubcategories;
