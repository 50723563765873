import axios from 'axios';
import React, { useEffect, useState } from 'react'

const NewsLetter = ({ setLoading }) => {

  const baseUrl = process.env.REACT_APP_BASEURL
  const [newsdata, setNewsdata] = useState([])
  const [newnews, setNewnews] = useState([]);

  useEffect(() => {
    const filter = newsdata.filter(list => list.status === 'Active');
    setNewnews(filter);
  })

  useEffect(() => {
    const fetchNewsletterData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseUrl}/get_newsletter`);
        if (response.data.status) {
          setNewsdata(response.data.news);
        }
      } catch (error) {
        console.error('An error occurred while fetching newsletters.', error);
      }
      setLoading(false);
    };
    fetchNewsletterData();
  }, []);


  return (
    <>
      <div className="container mx-auto py-20 xl:px-0 lg:px-20 md:px-10 px-5">
        <h1 className='text-center text-4xl uppercase pb-10'>News</h1>
        <div className="flex flex-col gap-10">
          {newnews.map((list, index) => (
            <div className="flex md:flex-row flex-col gap-5 md:place-items-center place-items-start">
              <img src={list.image} alt="" className={`lg:h-96 lg:w-96 md:w-64 md:h-64 h-56 w-48 ${index % 2 === 0 ? 'order-1 md:order-2' : 'order-1 md:order-1'}`} />
              <div className={`flex flex-col justify-center gap-3 ${index % 2 === 0 ? 'order-2 md:order-1' : 'order-2 md:order-2'}`}>
                <h1 className='lg:text-2xl md:text-xl text-red-500 font-bold capitalize'>{list.title}</h1>
                <span className='lg:text-xl md:text-lg font-semibold capitalize'>{list.sub_title}</span>
                {list.description.split('\n\n').map((data, i) => (
                  <p className='text-base mt-2' key={i}>{data}</p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default NewsLetter