import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../Component/utiles/Loader';
import axios from 'axios';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();


  // useEffect(() => {
  //   const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  //   setUser(userInfo);
  //   setLoading(false); 
  // }, []);

  useEffect(() => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      try {
        const parsedUser = JSON.parse(userInfo);
        setUser(parsedUser);
      } catch (error) {
        setUser('');
      }
    } else {
      setUser('');
      // navigate('/');
    }
    setLoading(false);
  }, [navigate]);

  const logout = () => {
    setUser(null);
    localStorage.removeItem('userInfo');
    // localStorage.removeItem('couponData');
  };

  const [geoLocation, setGeoLocation] = useState({});
  const countryCode =
    // geoLocation ? geoLocation.country :
    null;

  useEffect(() => {
    const fetchIPInfo = async () => {
      try {
        const response = await axios.get(`https://ipinfo.io/?token=ccc055af41b120`, {
        });
        setGeoLocation(response.data);
      } catch (error) {
        console.error('Error fetching IP info:', error);
      }
    };
    fetchIPInfo();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, logout, loading, geoLocation, countryCode }}>
      {loading ? <Loader /> : children}
    </UserContext.Provider>
  );
};

export const UserState = () => {
  return useContext(UserContext);
}

export default UserProvider;
