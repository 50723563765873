import React, { useEffect, useState } from 'react'
import { IoSearchSharp } from "react-icons/io5";
import Dropdown from '../../Component/utiles/Dropdown';
import { Link } from 'react-router-dom';
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import Pagination from '../../Component/utiles/Pagination';
import { UserState } from '../../Context/userProvider';
import axios from 'axios';
import notify from '../../Component/utiles/Notification';
import Loader from '../../Component/utiles/Loader';
import { TbArrowsExchange2 } from "react-icons/tb";



const Categories = ({ allctgdata, setGetctgdata }) => {
  const { user, logout } = UserState();

  const [loading, setLoading] = useState(false);
  const baseURL = process.env.REACT_APP_BASEURL


  const [searchTerm, setSearchTerm] = useState('');

  const perpageoption = [
    { label: '5' }, { label: '10' }, { label: '15' }, { label: '20' }
  ];

  const [itemsPerPage, setItemsPerPage] = useState(perpageoption[0].label);
  const [currentPage, setCurrentPage] = useState(1);

  const searchResults = allctgdata.filter(ctgData =>
    ctgData.category_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    ctgData.category_id.toString().toLowerCase().includes(searchTerm.toLowerCase())
  );

  const itemsToDisplay = searchTerm ? searchResults : allctgdata;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = itemsToDisplay.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(itemsToDisplay.length / itemsPerPage);

  useEffect(() => {
    if (searchTerm) {
      setCurrentPage(1);
    }
  }, [searchTerm]);


  const getStatusClass = (status) => {
    switch (status) {
      case 'Active':
        return 'bg-green-200 text-green-600 text-xs';
      case 'Inactive':
        return 'bg-red-200 text-red-500 text-xs';
      default:
        return 'bg-gray-400 text-white text-xs';
    }
  }

  const handleupdate = async (e, status) => {
    setLoading(true);
    let newStatus;
    if (status === 'Active') {
      newStatus = 'Inactive'
    } else {
      newStatus = 'Active'
    }
    try {
      const response = await axios.post(`${baseURL}/category_status_update`, {
        category_id: e,
        status: newStatus,
      });

      if (response.data.status) {
        notify('success', 'Status Update', `Category status changed to ${newStatus}`);
        setGetctgdata(prev => prev + 1);
      } else {
        notify('danger', 'Error', 'Failed to update category status');
      }
    } catch (error) {
      notify('danger', 'Error', 'Something went wrong!');
    }
    setLoading(false);
  }



  return (
    <>
      <h1 className="xl:text-2xl md:text-lg text-xl mt-4 font-semibold">Category</h1>
      <div className='w-full bg-white rounded-lg mt-5 md:p-6 p-3'>
        <div className="relative flex md:hidden w-full ">
          <input
            type="text"
            placeholder="Search Here"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="bg-gray-200 w-full border-0 rounded-full px-4 py-2 pr-10 focus:outline-none focus:border-gray-400 focus:ring-2 focus:ring-gray-400"
          />
          <IoSearchSharp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-black cursor-pointer" />
        </div>
        <div className='flex flex-row place-items-center justify-between my-5  pe-0'>
          <div className='flex flex-row items-center md:w-40 w-24  gap-2'>
            <span className='text-nowrap mt-2 lg:block hidden text-sm capitalize'>items per page</span>
            <div className='w-16'>
              <Dropdown
                label=""
                options={perpageoption}
                selectedValues={itemsPerPage}
                onSelect={setItemsPerPage}
                onRemove={() => { }}
                isMultiple={false}
              />
            </div>
          </div>
          <div className="relative hidden md:flex md:w-6/12 ms-5">
            <input
              type="text"
              placeholder="Search Here"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="bg-gray-200 w-full border-0 rounded-full px-4 py-2 pr-10 focus:outline-none focus:border-gray-400 focus:ring-2 focus:ring-gray-400"
            />
            <IoSearchSharp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-black cursor-pointer" />
          </div>
          {user?.role === 'superadmin' && (
            <Link to='add-category' className='text-white flex justify-center items-center font-semibold text-nowrap bg-black ms-5 md:px-5 md:text-sm text-sm md:py-3 py-2 px-3  
          hover:text-black hover:bg-white hover:border border rounded-lg hover:border-black transition-all duration-200 capitalize'
            >add &nbsp;<span className='md:block hidden'>New category</span></Link>
          )}
        </div>
        <div className='overflow-hidden overflow-x-auto mt-6'>
          <table className="lg:w-full md-w[100%]  table bg-white">
            <thead className="bg-gray-100 rounded-full">
              <tr>
                <th className="py-4 px-4 text-left text-nowrap capitalize">categories</th>
                <th className="py-4 px-4 text-left text-nowrap capitalize">id</th>
                <th className="py-4 px-4 text-left text-nowrap capitalize">Starting price</th>
                <th className="py-4 px-4 text-left text-nowrap capitalize">product stock</th>
                <th className="py-4 px-4 text-left text-nowrap capitalize">Status</th>
                {user?.role === 'superadmin' && (
                  <th className="py-4 px-4 text-left text-nowrap capitalize">Action</th>
                )}

              </tr>
            </thead>
            <tbody>
              {currentItems.map(item => (
                <tr key={item.category_id} className="border-b border-gray-200 hover:bg-gray-200 transition-all duration-200 ease-in-out">
                  <td className="py-4 px-4 flex items-center gap-4">
                    <img src={item.category_image} alt={item.category_name} className="w-16 h-16 mr-4 bg-gray-100 rounded-lg" />
                    <span className='text-nowrap pe-10 truncate md:pe-20 lg:pe-0 text-sm'>{item.category_name}</span>
                  </td>
                  <td className="py-4 px-4 text-nowrap">{item.category_id}</td>
                  <td className="py-4 px-4">
                    <div className="flex ">
                      <span className="text-nowrap">{item.min_amount == null ? '0' : item.min_amount} to {item.max_amount == null ? '0' : item.max_amount} </span>
                    </div>
                  </td>
                  <td className="py-4 px-4">{item.total_stock == null ? '0' : item.total_stock}</td>
                  <td className="py-4 px-4 text-nowrap">
                    <span className={`px-2 py-1  rounded-lg capitalize font-semibold ${getStatusClass(item.status)}`}>
                      {item.status}
                    </span>
                  </td>
                  {user?.role === 'superadmin' && (
                    <td className="p-4">
                      <div className='flex flex-row items-center gap-5 text-xl'>
                        <Link to={"edit-category/" + item.category_id} className='cursor-pointer hover:scale-110 duration-200 transition-all px-3 py-2 text-lg rounded-md bg-neutral-300'>
                          <FiEdit />
                        </Link>
                        <div className='px-3 cursor-pointer hover:scale-110 duration-200 transition-all py-2 text-xl rounded-md bg-neutral-300' onClick={(e) => handleupdate(item.category_id, item.status)}>
                          <TbArrowsExchange2 />
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between items-center mt-4">
          <div>
            <span>Page {currentPage} of {totalPages}</span>
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
      {loading && <Loader />}
    </>
  )
}

export default Categories