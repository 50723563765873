import React, { useEffect, useState } from 'react'
import { Modal, Tabs, Label, TextInput, Progress, Tooltip, FileInput } from "flowbite-react";
import Dropdown from '../../Component/utiles/Dropdown';
import { MdCloudDownload } from 'react-icons/md';
import { ImageUpload } from '../../Component/utiles/ImageUpload';

const Offereditadd = ({ openModal, setOpenModal, setLoading }) => {

    const [category, setcategory] = useState('Select category');

    const multiple = false;
    const { images, handleFileChange, handleRemoveImage } = ImageUpload(multiple, setLoading);

    const categoryoption = [
        { label: 'clothes' },
        { label: 'watches' },
        { label: 'electronics' },
    ];



    return (
        <>
            <Modal show={openModal} size="2xl" onClose={() => setOpenModal(false)} >
                <Modal.Header>Add Offer</Modal.Header>
                <Modal.Body>
                    <div className="">
                        <span className='text-xl font-medium'>Upload Image</span>
                        <div className='flex items-center flex-col'>
                            <Label
                                htmlFor="dropzone-file"
                                className="flex w-full h-64 my-3 cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                            >
                                <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                    <MdCloudDownload className='text-3xl text-gray-400' />
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                        <span className="font-semibold">Click to upload</span> or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                </div>
                                <FileInput id="dropzone-file" className="hidden" multiple />
                            </Label>
                            <div className='images mt-2 flex flex-row items-start w-full flex-wrap gap-2'>
                                <div className='mt-2 flex flex-wrap gap-2'>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <span className='text-xl font-medium capitalize'>title</span>
                        <input className='w-full py-2 mt-3 rounded-md focus:ring-0 border-gray-300 focus:border-black' type='text' placeholder='Enter Title' />
                        <div className='my-5'>
                            <Dropdown
                                label="category"
                                options={categoryoption}
                                selectedValues={category}
                                onSelect={setcategory}
                                onRemove={() => { }}
                                isMultiple={false}
                                padding={true}
                            />
                        </div>
                        <div className='flex w-full  md:flex-row flex-col md:gap-10 gap-5'>
                            <div className=''>
                                <span className='text-xl font-medium'>Start Date</span>
                                <input className='w-full py-2 mt-3 rounded-md focus:ring-0 border-gray-300 focus:border-black' type='date' placeholder='Enter Name' />
                            </div>
                            <div className=''>
                                <span className='text-xl font-medium'>End Date</span>
                                <input className='w-full py-2 mt-3 rounded-md focus:ring-0 border-gray-300 focus:border-black' type='date' placeholder='Enter Name' />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='flex justify-end'>

                    <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" >
                        Add Offer
                    </button>
                    <button type="button" onClick={() => setOpenModal(false)} className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Offereditadd