import React from 'react'

const Privacy_policy = () => {
    return (
        <>
            <div className='container p-8 mx-auto w-7/12 shadow-lg'>
                <div>
                    <h1 className='text-3xl font-medium'>Privacy Policy</h1>
                </div>

                <div className="mt-5 text-sm space-y-5">
                    <p>We value the trust you place in us and recognize the importance of secure transactions and information privacy. This Privacy Policy describes how Alas Internet Private Limited and its affiliates (collectively “Alas, we, our, us”) collect, use, share or otherwise process your personal data through Alas website www.Alas.com, its mobile application, and m-site (hereinafter referred to as the “Platform”).</p>

                    <p>While you can browse sections of the Platform without the need of sharing any information with us, however, please note we do not offer any product or service under this Platform outside India and your personal data will primarily be stored and processed in India. By visiting this Platform, providing your information or availing out product/service, you expressly agree to be bound by the terms and conditions of this Privacy Policy, the Terms of Use and the applicable service/product terms and conditions, and agree to be governed by the laws of India including but not limited to the laws applicable to data protection and privacy. If you do not agree please do not use or access our Platform.</p>
                </div>

                <h1 className='text-xl font-medium mt-4'>Collection of Your Information </h1>
                
                <div className="mt-5 text-sm space-y-5">
                    <p>When you use our Platform, we collect and store your information which is provided by you from time to time. Once you give us your personal data, you are not anonymous to us. Where possible, we indicate which fields are required and which fields are optional. You always have the option to not provide data by choosing not to use a particular service, product or feature on the Platform.</p>

                    <p>We may track your buying behavior, preferences, and other information that you choose to provide on our Platform. We use this information to do internal research on our users' demographics, interests, and behavior to better understand, protect and serve our users. This information is compiled and analyzed on an aggregated basis. This information may include the URL that you just came from (whether this URL is on our Platform or not), which URL you next go to (whether this URL is on our Platform or not), your computer browser information, and your IP address.</p>

                    <p>We may collect personal data (such as email address, delivery address, name, phone number, credit card/debit card and other payment instrument details or medical or health related information) from you when you set up an account or transact with us or participate in any event or contest. While you can browse some sections of our Platform without being a registered member, certain activities (such as placing an order or consuming our online content or services) do require registration. We use your contact information to send you offers based on your previous orders and your interests.</p>

                    <p>If you choose to post messages on our message boards, chat rooms or other message areas or leave feedback on the Platform or the social media handles maintained by us or if you use voice commands or virtual try and buy or similar features to shop on the Platform, we will collect that information you provide to us. We retain this information as necessary to resolve disputes, provide customer support, troubleshoot problems or for internal research and analysis as permitted by law.</p>

                    <p>If you send us personal correspondence, such as emails or letters, or if other users or third parties send us correspondence about your activities or postings on the Platform, we may collect such information into a file specific to you.</p>

                    <p>If you enroll into our loyalty and membership program, such SuperCoin, VIP or similar programs offered by Alas, we will collect and store your personal data such as name, contact number, email address, communication address, date of birth, gender, zip code, lifestyle information, demographic and work details which is provided by you to Alas or a third-party business partner that operates online/offline establishments or platforms where you can earn loyalty points for purchase of goods and services, and redeem loyalty points. We will also collect your information related to your transactions on Alas platform and such third-party business partner platforms. When such a third-party business partner collects your personal data directly from you, you will be governed by their privacy policies. Additionally, we collect your UPI ID if you choose to pay via our Alas UPI platform. Alas shall not be responsible for the third-party business partner’s privacy practices or the content of their privacy policies, and we request you to read their privacy policies prior to disclosing any information.</p>

                    <p>Alas has onboarded certain third-party business partners on the Platform who specialize in the categories like travel ticket reservations, booking online movie tickets, paying online bills and more (Ultra-Partners). If you use the services of Ultra-Partners, you will be redirected to Ultra-Partners websites/applications and your entry to Ultra-Partners websites/applications will be based on your Alas login credentials after seeking your permissions to share the data further. Alas shall not be responsible for the Ultra-Partner’s privacy practices or the content of their privacy policies, and we request you to read their privacy policies prior to disclosing any information.</p>
                </div>


                <h1 className='text-xl font-medium mt-4'>Use of Demographic / Profile Data / Your Information</h1>

                <div className="mt-5 text-sm space-y-5">
                    <p>We use your personal data to take and fulfill orders, deliver products and services, process payments, and communicate with you about orders, products and services, and promotional offers. To the extent we use your personal data to market to you, we will provide you the ability to opt-out of such uses. We use your personal data to assist sellers and business partners in handling and fulfilling orders; enhancing customer experience; resolve disputes; troubleshoot problems; help promote a safe service; collect money; measure consumer interest in our products and services; inform you about online and offline offers, products, services, and updates; customize and enhance your experience; report to regulatory authorities wherever required, detect and protect us against error, fraud and other criminal activity; enforce our terms and conditions; and as otherwise described to you at the time of collection of information.</p>

                    <p>With your consent, we may have access to your SMS, instant messages, contacts in your directory, location, camera, photo gallery and device information. We may also request you to provide your PAN, credit information report (from credit agencies), GST Number, Government issued ID cards/number and Know-Your-Customer (KYC) details to: (i) check your eligibility for certain products and services like insurance, credit and payment products; (ii) issue GST invoice for the products and services purchased for your business requirements; (iii) enhance your experience on the Platform and provide you access to the products and services being offered by us, sellers, affiliates or lending partners. You understand that your access to these products/services may be affected in the event consent is not provided to us.</p>

                    <p>In our efforts to continually improve our product and service offerings, we and our affiliates collect and analyze demographic and profile data about our users' activity on our Platform. We identify and use your IP address to help diagnose problems with our server, and to administer our Platform. Your IP address is also used to help identify you and to gather broad demographic information.</p>

                    <p>We will occasionally ask you to participate in optional surveys conducted either by us or through a third-party market research agency. These surveys may ask you for personal data, contact information, date of birth, demographic information (like zip code, age, or income level), attributes such as your interests, household or lifestyle information, your purchasing behavior or history, preferences, and other such information that you may choose to provide. The surveys may involve collection of voice data or video recordings, the participation of which would purely be voluntary in nature. We use this data to tailor your experience at our Platform, providing you with content that we think you might be interested in and to display content according to your preferences.</p>
                </div>



                <h1 className='text-xl font-medium mt-4'>Cookies</h1>

                <div className="mt-5 text-sm space-y-5">
                    <p>We use data collection devices such as "cookies" on certain pages of the Platform to help analyze our web page flow, measure promotional effectiveness, and promote trust and safety. "Cookies" are small files placed on your hard drive that assist us in providing our services. Cookies do not contain any of your personal data. We offer certain features that are only available through the use of a "cookie". We also use cookies to allow you to enter your password less frequently during a session. Cookies can also help us provide information that is targeted to your interests. Most cookies are "session cookies," meaning that they are automatically deleted from your hard drive at the end of a session. You are always free to decline/delete our cookies if your browser permits, although in that case you may not be able to use certain features on the Platform and you may be required to re-enter your password more frequently during a session. Additionally, you may encounter "cookies" or other similar devices on certain pages of the Platform that are placed by third parties. We do not control the use of cookies by third parties. We use cookies from third-party partners such as Google Analytics for marketing and analytical purposes. Google Analytics helps us understand how our customers use the site. You can read more about how Google uses your personal data here: https://www.google.com/intl/en/policies/privacy/. You can opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout. You can also control the use of cookies at the individual browser level, but if you choose to disable cookies, it may limit your use of certain features or functions on the services.</p>
                </div>


                <h1 className='text-xl font-medium mt-4'>Sharing of personal data</h1>

                <div className="mt-5 text-sm space-y-5">
                    <p>We may share personal data internally within Alas group companies including Alas Advanz Private Limited, Scapic Innovations Private Limited, and other Alas affiliates, related companies and third parties, including Credit Bureaus and business partners (such as UPI platform), for purposes of providing products and services offered by them, such as, personal loans offered by Scapic Innovations Private Limited through its lending partners, insurance, the deferred payment options, Alas PayLater offered by Alas Advanz Private Limited through its lending partners. These entities and affiliates may share such information with their affiliates, business partners and other third parties for the purpose of conducting the required checks, namely for the purpose of credit underwriting, providing you their products and services and may market to you as a result of such sharing unless you explicitly opt-out.</p>

                    <p>For products that are required to be prescribed by qualified professionals under law, we permit processing of such orders on the Platform only upon confirmation that a valid prescription has been uploaded by you. In cases where the prescriptions uploaded by you are invalid, illegible, or expired or if prescription is not uploaded by you, we may, through third-party vendors connect You with medical practitioners, teleconsultants or third-party professionals where required to rectify the prescription or in case of invalid/illegible/expired prescription or issue a prescription in case prescription is not uploaded by you, based on the information that You provide to them. You agree and acknowledge that you will be redirected to the teleconsultation services only in limited cases where prescription validation or prescription issuance is required.</p>

                    <p>We may disclose your personal data to third parties, such as sellers, business partners. This disclosure may be required for us to provide you access to our products and services; for fulfillment of your orders; for enhancing your experience; for providing feedback on products; to collect payments from you; to comply with our legal obligations; to conduct market research or surveys; to enforce our Terms of Use; to facilitate our marketing and advertising activities; to analyze data; for customer service assistance; to prevent, detect, mitigate, and investigate fraudulent or illegal activities related to our product and services. We may disclose personal data if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may disclose personal data to law enforcement agencies, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to enforce our Terms of Use or Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of our users or the general public.</p>

                    <p>We and our affiliates will share / sell some or all of your personal data with another business entity should we (or our assets) plan to merge with, or be acquired by that business entity, or reorganization, amalgamation, restructuring of business. Should such a transaction occur that another business entity (or the new combined entity) will be required to follow this Privacy Policy with respect to your personal data.</p>
                </div>


                <h1 className='text-xl font-medium mt-4'>Links to Other Sites</h1>

                <div className="mt-5 text-sm space-y-5">
                    <p>Our Platform may provide links to other websites or applications that may collect personal data about you and you will be governed by their privacy policies. Alas shall not be responsible for the privacy practices or the content of their privacy policies, and we request you to read their privacy policies prior to disclosing any information.</p>
                </div>


                <h1 className='text-xl font-medium mt-4'>Security Precautions</h1>

                <div className="mt-5 text-sm space-y-5">
                    <p>We maintain reasonable physical, electronic and procedural safeguards to protect your information. Whenever you access your account information, we offer the use of a secure server. Once your information is in our possession, we adhere to our security guidelines to protect it against unauthorized access. However, by using the Platform, the users accept the inherent security implications of data transmission over the internet and the World Wide Web which cannot always be guaranteed as completely secure, and therefore, there would always remain certain inherent risks regarding use of the Platform. Users are responsible for ensuring the protection of login and password records for their account.</p>
                </div>


                <h1 className='text-xl font-medium mt-4'>Choice/Opt-Out</h1>

                <div className="mt-5 text-sm space-y-5">
                    <p>We provide all users with the opportunity to opt-out of receiving non-essential (promotional, marketing-related) communications after setting up an account with us. If you do not wish to receive promotional communications from us, then please login into the Notification Preference page of Platform [https://www.Alas.com/communication-preferences/email] to unsubscribe/opt-out.</p>
                </div>


                <h1 className='text-xl font-medium mt-4'>Advertisements on Platform</h1>

                <div className="mt-5 text-sm space-y-5">
                    <p>We use third-party advertising companies to serve ads when you visit our Platform. These companies may use information (not including your name, address, email address, or telephone number) about your visits to this and other websites in order to provide personalized advertisements about goods and services of interest to you. You have an option to opt out from tracking of personalized advertising using the “Opt out of Ads Personalization” settings using your device’s settings application. Alas will have no access to your GAID once you select this feature.</p>
                </div>


                <h1 className='text-xl font-medium mt-4'>Children Information</h1>

                <div className="mt-5 text-sm space-y-5">
                    <p>Use of our Platform is available only to persons who can form a legally binding contract under the Indian Contract Act, 1872. We do not knowingly solicit or collect personal data from children under the age of 18 years. If you have shared any personal data of children under the age of 18 years, you represent that you have the authority to do so and permit us to use the information in accordance with this Privacy Policy.</p>
                </div>


                <h1 className='text-xl font-medium mt-4'>Data Retention</h1>

                <div className="mt-5 text-sm space-y-5">
                    <p>We retain your personal data in accordance with applicable laws, for a period no longer than is required for the purpose for which it was collected or as required under any applicable law. However, we may retain data related to you if we believe it may be necessary to prevent fraud or future abuse, to enable Alas to exercise its legal rights and/or defend against legal claims or if required by law or We may continue to retain your data in anonymised form for analytical and research purposes.</p>
                </div>


                <h1 className='text-xl font-medium mt-4'>Your Rights</h1>

                <div className="mt-5 text-sm space-y-5">
                    <p>We take every reasonable step to ensure that your personal data that we process is accurate and, where necessary, kept up to date, and any of your personal data that we process that you inform us is inaccurate (having regard to the purposes for which they are processed) is erased or rectified. You may access, correct, and update your personal data directly through the functionalities provided on the Platform. You may delete certain non-mandatory information by logging into our website and visiting Profile and Settings sections. You can also write to us at the contact information provided below to assist you with these requests.</p>

                    <p>You have an option to withdraw your consent that you have already provided by writing to us at the contact information provided below. Please mention “for withdrawal of consent” in the subject line of your communication. We will verify such requests before acting upon your request. Please note, however, that withdrawal of consent will not be retroactive and will be in accordance with the terms of this Privacy Policy, related Terms of Use and applicable laws. In the event you withdraw consent given to us under this Privacy Policy, such withdrawal may hamper your access to the Platform or restrict provision of our services to you for which we consider that information to be necessary.</p>
                </div>


                <h1 className='text-xl font-medium mt-4'>Your Consent</h1>
                
                <div className="mt-5 text-sm space-y-5">
                    <p>By visiting our Platform or by providing your personal data, you consent to the collection, use, storage, disclosure and otherwise processing of your personal data on the Platform in accordance with this Privacy Policy. If you disclose to us any personal data relating to other people, you represent that you have the authority to do so and to permit us to use the data in accordance with this Privacy Policy.</p>

                    <p>You, while providing your personal data over the Platform or any partner platforms or establishments, consent to us (including our other corporate entities, affiliates, lending partners, technology partners, marketing channels, business partners and other third parties like credit bureaus ) to contact you through SMS, instant messaging apps, call and/or e-mail for the purposes specified in this Privacy Policy.</p>

                    <h1 className='text-xl font-medium mt-4'>Changes to this Privacy Policy</h1>

                    <div className='mt-5 text-sm space-y-5'>
                        <p>Please check our Privacy Policy periodically for changes . We may update this Privacy Policy to reflect changes to our information practices. We will alert you to significant changes by posting the date our policy got last updated, placing a notice on our Platform, or by sending you an email when we are required to do so by applicable law.</p>
                    </div>

                </div>

                <h1 className='text-xl font-medium mt-4'>Grievance Officer</h1>

                <div className="mt-5 text-sm space-y-5">
                    <p>In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:</p>

                    <p>Mr. Shremanth M</p>

                    <p>Designation: Senior Manager</p>

                    <p>Alas Internet Pvt Ltd.</p>

                    <p>Embassy tech village</p>

                    <p>8th floor Block 'B' Devarabeesanahalli Village,</p>

                    <p>Varthur Hobli, Bengaluru East Taluk,</p>

                    <p>Bengaluru District,</p>

                    <p>Karnataka, India, 560103.</p>

                    <p>Email: privacy.grievance@Alas.com</p>
                </div>
                

                <h1 className='text-2xl font-medium mt-4'>Customer Support</h1>

                <div className="mt-5 space-y-5 text-sm">
                    <p>If you have a query, concern, or complaint in relation to collection or usage of your personal data under this Privacy Policy, please contact us at privacy.grievance@Alas.com</p>

                    <p>You can reach our customer support team to address any of your queries or complaints related to product and services by clicking the link, selecting your order and choosing 'Need Help' option: https://www.Alas.com/helpcentre</p>

                    <p>Last Updated: March 2024</p>
                </div>


            </div>
        </>
    )
}

export default Privacy_policy
