import React, { useState } from 'react'
import Dropdown from '../../Component/utiles/Dropdown';
import { Link } from 'react-router-dom';
import bigsale1 from '../../Component/Assets/Images/headphones-png-14981.png'
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import Pagination from '../../Component/utiles/Pagination';
import Offereditadd from '../Component/Offereditadd';

const Offers = ({ setLoading }) => {
    const [images, setImages] = useState([]);
    const [openModal, setOpenModal] = useState(false);

    const [category, setcategory] = useState('Select category');

    const categoryoption = [
        { label: 'clothes' },
        { label: 'watches' },
        { label: 'electronics' },
    ];

    const offersData = [
        {
            img: bigsale1,
            title: "Up to 80% off",
            category: "men",
            amount: '15%',
            status: "active",
            start: "Jan 22,2024",
            expire: "May 22,2024",
        },
        {
            img: bigsale1,
            title: "Up to 80% off",
            category: "men",
            amount: '15%',
            status: "active",
            start: "Jan 22,2024",
            expire: "May 22,2024",
        },
        {
            img: bigsale1,
            title: "Up to 80% off",
            category: "men",
            amount: '15%',
            status: "active",
            start: "Jan 22,2024",
            expire: "May 22,2024",
        },
        {
            img: bigsale1,
            title: "Up to 80% off",
            category: "men",
            amount: '15%',
            status: "active",
            start: "Jan 22,2024",
            expire: "May 22,2024",
        },
        {
            img: bigsale1,
            title: "Up to 80% off",
            category: "men",
            amount: '15%',
            status: "active",
            start: "Jan 22,2024",
            expire: "May 22,2024",
        },
        {
            img: bigsale1,
            title: "Up to 80% off",
            category: "men",
            amount: '15%',
            status: "active",
            start: "Jan 22,2024",
            expire: "May 22,2024",
        },
    ]

    const perpageoption = [
        { label: '5' }, { label: '10' }, { label: '15' }, { label: '20' }
    ]

    const [itemsPerPage, setItemsPerPage] = useState(perpageoption[0].label);
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = offersData.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(offersData.length / itemsPerPage);



    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        const newImages = files.map(file => {
            const reader = new FileReader();
            return new Promise((resolve, reject) => {
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        });
        Promise.all(newImages).then(results => {
            setImages(prevImages => [...prevImages, ...results]);
        });
    };

    const handleRemoveImage = (index) => {
        setImages(prevImages => prevImages.filter((_, i) => i !== index));
    };



    const getStatusClass = (stockStatus) => {
        switch (stockStatus) {
            case 'active':
                return 'bg-green-200 text-green-600 text-xs';
            case 'expired':
                return 'bg-red-200 text-red-500 text-xs';
            default:
                return 'bg-gray-400 text-white text-xs';
        }
    }

    return (
        <>
            <h1 className="xl:text-2xl md:text-lg text-xl mt-4 font-semibold capitalize">Special Offers</h1>
            <div className='w-full bg-white rounded-lg mt-5 p-6'>
                <div className='flex flex-row justify-between my-5 p-0'>
                    <div className='flex flex-row items-center md:w-48 w-20 gap-5'>
                        <span className='text-nowrap mt-2 md:block hidden'>items per page</span>
                        <Dropdown
                            label=""
                            options={perpageoption}
                            selectedValues={itemsPerPage}
                            onSelect={setItemsPerPage}
                            onRemove={() => { }}
                            isMultiple={false}
                        />
                    </div>
                    <Link onClick={() => setOpenModal(true)} className='text-white flex justify-center items-center bg-black ms:px-5 px-3 text-base font-semibold md:py-2 py-0.5  hover:text-black hover:bg-white hover:border border rounded-lg hover:border-black transition-all duration-200'>Add New Special Offers</Link>
                </div>
                <div className='overflow-hidden overflow-x-auto'>
                    <table className="min-w-full table bg-white">
                        <thead className="bg-gray-100 rounded-full">
                            <tr>
                                <th className="py-4 px-4 text-left">Title</th>
                                <th className="py-4 px-4 text-left">Category</th>
                                <th className="py-4 px-4 text-left">Amount</th>
                                <th className="py-4 px-4 text-left">Status</th>
                                <th className="py-4 px-4 text-left">Start</th>
                                <th className="py-4 px-4 text-left">End</th>
                                <th className="py-4 px-4 text-left">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map(item => (
                                <tr key={item.id} className="border-b border-gray-200 hover:bg-gray-200 transition-all duration-200 ease-in-out">
                                    <td className="py-4 px-4 flex items-center gap-4">
                                        <img src={item.img} alt={item.title} className="w-16 h-16 mr-4 bg-gray-100 rounded-lg" />
                                        <span className='text-nowrap  pe-16 font-medium text-lg'>{item.title}</span>
                                    </td>
                                    <td className="py-4 px-4">
                                        <span className='px-2 py-1 rounded-md capitalize font-medium text-sm'>
                                            {item.category}
                                        </span>
                                    </td>
                                    <td className="py-4 px-4 font-normal text-sm">{item.amount}</td>
                                    <td className="py-4 px-4">
                                        <span className={`px-2 py-1  rounded-lg capitalize font-semibold ${getStatusClass(item.status)}`}>
                                            {item.status}
                                        </span>
                                    </td>
                                    <td className="py-4 px-4 text-nowrap font-normal text-sm">{item.start}</td>
                                    <td className="py-4 px-4 text-nowrap font-normal text-sm">{item.expire}</td>
                                    <td className="p-4">
                                        <div className='flex flex-row items-center gap-5 text-xl'>
                                            <Link onClick={() => setOpenModal(true)} className='cursor-pointer hover:scale-110 duration-200 transition-all px-3 py-2 text-lg rounded-md bg-neutral-300'>
                                                <FiEdit />
                                            </Link>
                                            <div className='px-3 cursor-pointer hover:scale-110 duration-200 transition-all py-2 text-lg rounded-md bg-neutral-300'>
                                                <MdDelete />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-between items-center mt-4">
                    <div>
                        <span>Page {currentPage} of {totalPages}</span>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </div>
            {openModal &&
                <Offereditadd openModal={openModal} setLoading={setLoading} setOpenModal={setOpenModal} />
            }

        </>
    )
}

export default Offers