import React, { useState, useEffect } from 'react';
import { TiEdit } from "react-icons/ti";
import { Button, Label, Modal, TextInput, Card } from "flowbite-react";
import { useCountries, useStates } from '../../../utiles/CountryStateCity';
import { UserState } from '../../../../Context/userProvider';
import axios from 'axios';
import notify from '../../../../Component/utiles/Notification';
import Loader from '../../../utiles/Loader';

const Myaddress = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [addressData, setAddressData] = useState({
        customers_delivery_address_id: null,
        address1: '',
        address2: '',
        country: '',
        state: '',
        locality: '',
        pincode: '',
        city: '',
    });
    const [addresses, setAddresses] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [countryID, setCountryID] = useState('');
    const { countryOptions, error: countryError } = useCountries();
    const { stateOptions, error: stateError } = useStates(countryID);
    const [addressType, setAddressType] = useState('');
    const { user } = UserState();
    const [refresh, setRefresh] = useState(0);

    const baseURL = process.env.REACT_APP_BASEURL;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAddressData((prev) => ({ ...prev, [name]: value }));
    };

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            setIsLoading(true)
            try {
                const response = await axios.post(`${baseURL}/get_customers_delivery_address`, {
                    customer_id: user.customer_id,
                });

                if (response.data.status && isMounted) {
                    setAddresses(response.data.data);
                    setIsLoading(false)
                } else if (isMounted) {
                    notify('danger', 'Error', 'Failed to fetch address');
                    setIsLoading(false)
                }
            } catch (error) {
                if (isMounted) {
                    notify('danger', 'Error', 'Something went wrong!');
                    setIsLoading(false)
                }
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [refresh]);


    const handleEditClick = (type) => {
        const address = addresses.find(addr => addr.address_type === type);
        if (address) {
            setAddressData({
                customers_delivery_address_id: address.customers_delivery_address_id,
                address1: address.address1,
                address2: address.address2,
                country: address.country_id,
                state: address.state_subdivision_id,
                locality: address.locality,
                pincode: address.pincode,
                city: address.city,
            });
        }
        setAddressType(type);
        setOpenModal(true);
    };
    const handleupdate = async () => {
        const { address1, address2, country, state, locality, pincode, city, customers_delivery_address_id } = addressData;

        if (!address1 || !address2 || !country || !state || !locality || !pincode || !city) {
            notify('danger', 'Error', 'All fields are mandatory');
            return;
        }
        setIsLoading(true);

        try {
            const response = await axios.post(`${baseURL}/add_delivery_address`, {
                customers_delivery_address_id,
                customer_id: user.customer_id,
                address1,
                address2,
                country_id: country,
                state_subdivision_id: state,
                locality,
                pincode,
                city,
                address_type: addressType
            });

            if (response.data.status) {
                setRefresh((prev) => prev + 1)
                notify('success', 'Success', 'Address updated successfully');
                setOpenModal(false);
                setIsLoading(false);
            } else {
                notify('danger', 'Error', 'Failed to update address');
                setIsLoading(false);
            }
        } catch (error) {
            notify('danger', 'Error', 'Something went wrong!');
            setIsLoading(false);
        }
    };

    const renderAddressCard = (type) => {
        const address = addresses.find(addr => addr.address_type === type);
        if (!address) return <p>No {type} address available</p>;

        return (
            <>
                <p>{address.address1}</p>
                <p>{address.address2}</p>
                <p>{address.locality}, {address.city}</p>
                <p>{address.country_name}, {address.state_subdivision_name || 'N/A'}</p>
                <p>{address.pincode}</p>
            </>
        );
    };

    return (
        <>
            {isLoading && <Loader />}
            <h1 className='md:text-2xl text-xl font-medium capitalize'>My Address</h1>
            <div className='pt-4'>
                <div className='flex flex-row justify-between mt-1 border-gray-400 mb-3'>
                    <div>
                        <Card>
                            <div className='flex justify-between'>
                                <h5 className="text-xl font-bold text-gray-900 dark:text-white capitalize">
                                    Home Address
                                </h5>
                                <TiEdit className='text-3xl cursor-pointer text-gray-400' onClick={() => handleEditClick('home')} />
                            </div>
                            {renderAddressCard('home')}
                        </Card>
                    </div>
                </div>


                <div className='flex flex-row justify-between mt-1 border-gray-400 mb-3'>
                    <div>
                        <Card>
                            <div className='flex justify-between'>
                                <h5 className="text-xl font-bold text-gray-900 dark:text-white capitalize">
                                    Work Address
                                </h5>
                                <TiEdit className='text-3xl cursor-pointer text-gray-400' onClick={() => handleEditClick('work')} />
                            </div>
                            {renderAddressCard('work')}
                        </Card>
                    </div>
                </div>


                <div className='flex flex-row justify-between mt-1 border-gray-400'>
                    <div>
                        <Card>
                            <div className='flex justify-between'>
                                <h5 className="text-xl font-bold text-gray-900 dark:text-white capitalize">
                                    Other Address
                                </h5>
                                <TiEdit className='text-3xl cursor-pointer text-gray-400' onClick={() => handleEditClick('other')} />
                            </div>
                            {renderAddressCard('other')}
                        </Card>
                    </div>
                </div>
            </div>

            <Modal show={openModal} onClose={() => setOpenModal(false)}>
                <Modal.Header>Change Address</Modal.Header>
                <Modal.Body>
                    <div className="mb-4">
                        <Label value="Address-1" />
                        <TextInput
                            className="w-full"
                            name="address1"
                            value={addressData.address1}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-4">
                        <Label value="Address-2" />
                        <TextInput
                            className="w-full"
                            name="address2"
                            value={addressData.address2}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='flex gap-5'>
                        <div className="mb-4 w-3/4">
                            <Label htmlFor="country" value="Country" />
                            <select
                                id="country"
                                name="country"
                                className="w-full p-2 border border-gray-300 rounded-md"
                                onChange={(e) => {
                                    setCountryID(e.target.value);
                                    handleChange(e);
                                }}
                                value={addressData.country}
                            >
                                <option value="">Select Country</option>
                                {countryOptions.map((country) => (
                                    <option key={country.id} value={country.id}>
                                        {country.label}
                                    </option>
                                ))}
                            </select>
                            {countryError && <p className="text-red-600">{countryError}</p>}
                        </div>
                        <div className="mb-4 w-3/4">
                            <Label htmlFor="state" value="State" />
                            <select
                                id="state"
                                name="state"
                                className="w-full p-2 border border-gray-300 rounded-md"
                                disabled={!countryID}
                                value={addressData.state}
                                onChange={handleChange}
                            >
                                <option value="">Select State</option>
                                {stateOptions.map((state) => (
                                    <option key={state.id} value={state.id}>
                                        {state.label}
                                    </option>
                                ))}
                            </select>
                            {stateError && <p className="text-red-600">{stateError}</p>}
                        </div>
                    </div>
                    <div className='flex gap-5'>
                        <div className="mb-4 w-3/4">
                            <Label value="Locality" />
                            <TextInput
                                className="w-full"
                                name="locality"
                                value={addressData.locality}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-4 w-3/4">
                            <Label value="City" />
                            <TextInput
                                className="w-full"
                                name="city"
                                value={addressData.city}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="mb-4 w-1/2">
                        <Label value="Pincode" />
                        <TextInput
                            className="w-full"
                            name="pincode"
                            value={addressData.pincode}
                            onChange={handleChange}
                            onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className='flex justify-end'>
                    <button type="button" onClick={handleupdate} class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                        Update
                    </button>
                    <button type="button" onClick={() => setOpenModal(false)} class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Myaddress;
