import React, { useState } from 'react'
import { PiShoppingBagOpenFill } from "react-icons/pi";
import { FaArrowTrendUp } from "react-icons/fa6";
import { FaHandHoldingDollar } from "react-icons/fa6";
import { LuListOrdered } from "react-icons/lu";
import { IoPeopleSharp } from "react-icons/io5";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Recentorder from '../Component/Recentorder';
import Countriessales from '../Component/Countriessales';
import { IoIosArrowDown } from "react-icons/io";
import Newcomments from '../Component/Newcomments';
import Salesbycategory from '../Component/Salesbycategory';
import Earningsrevenue from '../Component/Earningsrevenue';
import { UserState } from '../../Context/userProvider';

const Dashboard = () => {
    const { user, logout } = UserState();


    const data = [
        {
            name: 'Total Sale',
            value: '34,546',
            percentage: '1.56',
            icon: PiShoppingBagOpenFill,
            bg: '#25DF6A',
            graphColor: '#25DF6A',
            graphData: [1, 4, 2, 3, 1, 4]
        },
        {
            name: 'Total Income',
            value: '34,546',
            percentage: '1.56',
            icon: FaHandHoldingDollar,
            bg: '#FF5200',
            graphColor: '#FF5200',
            graphData: [1, 4, 1, 4, 1, 4]
        },
        {
            name: 'Order',
            value: '34,546',
            percentage: '1.56',
            icon: LuListOrdered,
            bg: '#CBD5E1',
            graphColor: '#CBD5E1',
            graphData: [1, 4, 2, 3, 1, 3]
        },
        {
            name: 'Total Visitor',
            value: '34,546',
            percentage: '1.56',
            icon: IoPeopleSharp,
            bg: '#2377FC',
            graphColor: '#2377FC',
            graphData: [1, 3, 1, 4, 1, 3]

        }

    ]










    const chartOptions = {
        chart: {
            type: 'areaspline',
            backgroundColor: 'transparent',
            height: '50%'
        },
        title: {
            text: ''
        },
        xAxis: {
            visible: false
        },
        yAxis: {
            visible: false
        },

        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        tooltip: {
            enabled: false
        }
    };

    return (
        <>
            <h1 className='xl:text-2xl md:text-lg text-xl font-semibold'>Dashboard</h1>
            <div className='flex xl:flex-row flex-col mt-4 md:gap-5 gap-3 w-full '>
                <div className='xl:w-3/12 w-full'>
                    <div className='grid xl:grid-cols-1 md:grid-cols-2 gap-3 md:gap-5'>
                        {data.map((list, index) => (
                            <div className="" key={index}>
                                <div className='rounded-lg cursor-pointer hover:scale-105 transition-all duration-500 hover:shadow-xl flex-row bg-white shadow-sm  py-4 px-3 items-start'>
                                    <div className='flex '>
                                        <div className={`flex justify-center items-center mt-3 rounded-full text-white p-2`} style={{ backgroundColor: list.graphColor }}>
                                            <list.icon className='xl:text-4xl text-2xl' />
                                        </div>
                                        <div className='flex flex-col ms-5 '>
                                            <sapn className='text-gray-400 text-nowrap'>{list.name}</sapn>
                                            <sapn className='xl:text-3xl text-xl font-medium mt-1'>{list.value}</sapn>
                                        </div>
                                        <div className='ms-auto mt-3 me-5 flex flex-row items-center gap-3'>
                                            <span><FaArrowTrendUp className='text-xl' /></span>
                                            <span> {list.percentage} %</span>
                                        </div>


                                    </div>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={{
                                            ...chartOptions,
                                            series: [{
                                                data: list.graphData, color: list.graphColor,
                                                marker: { enabled: false },
                                                fillColor: {
                                                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                                                    stops: [
                                                        [0, list.graphColor],
                                                        [1, '#ffffff']
                                                    ]
                                                }
                                            }]
                                        }} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='flex md:flex-row flex-col xl:w-9/12 md:w-full md:gap-5 gap-3'>
                    <Salesbycategory />
                    <Earningsrevenue />
                </div>
            </div>
            <Recentorder />
            {user?.role === 'superadmin' && (
                <div className='flex md:flex-row flex-col md:mt-5 mt-3 md:gap-5 gap-3'>
                    <div className='md:w-6/12 w-full'>
                        <Countriessales />
                    </div>
                    <div className='md:w-6/12 w-full'>
                        <Newcomments />
                    </div>
                </div>
            )}
        </>
    )
}

export default Dashboard