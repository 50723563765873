import React, { useState } from 'react';
import { IoIosArrowDown } from "react-icons/io";
import { FaArrowTrendUp } from "react-icons/fa6";

import india from '../../Component/Assets/Images/india.png';
import germany from '../../Component/Assets/Images/germany.png';
import australia from '../../Component/Assets/Images/austrailia.png';
import spain from '../../Component/Assets/Images/spain.png';
import sweden from '../../Component/Assets/Images/sweden.png';
import france from '../../Component/Assets/Images/france.png';
import usa from '../../Component/Assets/Images/usa.png';

import Pagination from '../../Component/utiles/Pagination';

const Countriessales = () => {
    const weeks = ['last Week', 'week', 'month'];
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(weeks[0]);
    

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setDropdownOpen(false);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const countrysales = [
        { name: 'India', img: india, sales: '4444', percentage: '1.5' },
        { name: 'USA', img: usa, sales: '6520', percentage: '2.0' },
        { name: 'Germany', img: germany, sales: '3022', percentage: '1.2' },
        { name: 'France', img: france, sales: '2765', percentage: '1.3' },
        { name: 'Spain', img: spain, sales: '5111', percentage: '1.8' },
        { name: 'Australia', img: australia, sales: '2240', percentage: '1.1' },
        { name: 'Sweden', img: sweden, sales: '4200', percentage: '1.6' },
        { name: 'Sweden', img: sweden, sales: '4200', percentage: '1.6' },
    ];
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 7;

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = countrysales.slice(indexOfFirstRecord, indexOfLastRecord);

    const totalPages = Math.ceil(countrysales.length / recordsPerPage)

    

    return (
        <>
            <div className='w-full h-[46em] rounded-lg bg-white lg:p-6 p-3'>
                <div className='flex flex-row justify-between'>
                    <h1 className='md:text-lg xl:text-xl text-xl  font-semibold'>Top Countries By Sales</h1>
                </div>
                <div className='flex flex-row items-center gap-7'>
                    <span className='md:text-lg xl:text-xl text-xl  font-semibold mt-1'>6,86,698</span>
                    <div className='flex flex-row gap-4 items-center'>
                        <span><FaArrowTrendUp className='text-3xl text-green-500' /></span>
                        <span> 1.54 %</span>
                    </div>
                </div>
                <div className='h-[37rem] xl:h-[35rem]'>
                    {currentRecords.map((list, index) => (
                        <div key={index} className='flex flex-row items-center justify-between mt-6'>
                            <div className='flex flex-row items-center xl:gap-5 gap-2 lg:gap-2'>
                                <img className='lg:h-14 lg:w-14  rounded-full w-9 h-9 md:w-10 md:h-10' src={list.img} alt={list.name} />
                                <span className='capitalize xl:text-xl md:text-lg font-medium'>{list.name}</span>
                            </div>
                            <div className='flex flex-row gap-4 items-center'>
                                <span><FaArrowTrendUp className='xl:text-xl md:text-lg text-green-500' /></span>
                                <span className='xl:text-xl  md:text-lg text-nowrap'> {list.percentage} %</span>
                                <span className='xl:text-xl md:text-lg font-medium ms-5'>€ {list.sales}</span>
                            </div>
                        </div>

                    ))}
                </div>
                <div className='flex justify-end md:-mt-1 lg:mt-6'>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </div>
        </>
    );
};

export default Countriessales;
