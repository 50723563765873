import React, { useState } from 'react'

import { Button, Drawer } from "flowbite-react";
import Faqs from './Websites_setting/Faqs';
import Banners from './Websites_setting/Banners';
import Brands from './Websites_setting/Brands';
import Sizes from './Websites_setting/Sizes';
import Colours from './Websites_setting/Colours';
import Addnews from './Websites_setting/Addnews';

const Settings = ({ setLoading, sizesdata, colordata }) => {

    const tabs = ['faqs', 'banners', 'brands', 'sizes', 'colors', 'news letter'];

    const [selectedStatus, setSelectedStatus] = useState(tabs[0]);
    const [currentPage, setCurrentPage] = useState(1);




    const handleTabClick = (status) => {
        setSelectedStatus(status);
        setCurrentPage(1);
    };

    const renderTabContent = () => {
        switch (selectedStatus) {
            case 'faqs':
                return <Faqs setLoading={setLoading} />;
            case 'banners':
                return <Banners setLoading={setLoading} />;
            case 'brands':
                return <Brands setLoading={setLoading} />;
            case 'sizes':
                return <Sizes setLoading={setLoading} sizesdata={sizesdata} />;
            case 'colors':
                return <Colours setLoading={setLoading} colordata={colordata} />;
            case 'news letter':
                return <Addnews setLoading={setLoading} />;
            // Add more cases for additional tabs
            default:
                return null;
        }
    };

    return (
        <>
            <div className="flex justify-start mt-5 bg-white px-3 h-12 py-2 rounded-md overflow-x-auto overflow-hidden pb-3">
                <div className="tabs tabs-boxed">
                    {tabs.map((status) => (
                        <a
                            key={status}
                            className={`px-4 py-2 transition-all duration-200 ease-in-out font-medium cursor-pointer ${selectedStatus === status ? 'border-b capitalize text-lg border-black text-black' : 'text-gray-900 text-sm'}`}
                            onClick={() => handleTabClick(status)}
                        >
                            {status.charAt(0).toUpperCase() + status.slice(1)}
                        </a>
                    ))}
                </div>
            </div>
            <div className="mt-4">
                {renderTabContent()}
            </div>
        </>
    )
}

export default Settings