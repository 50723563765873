import React, { useState } from 'react';
import watch from '../Assets/Images/ctg_watch.png';
import { FiPackage } from "react-icons/fi";
import { LiaShippingFastSolid } from "react-icons/lia";
import { SlSocialDropbox } from "react-icons/sl";
import { LuCheckSquare } from "react-icons/lu";
import { FaSquareCheck } from "react-icons/fa6";
import { IoCheckboxOutline } from "react-icons/io5";
import Navbar from '../LandingPage/Navbar';
import Footer from '../LandingPage/Footer';

const Tracking_page = () => {
    const [searchOption, setSearchOption] = useState('orderID');
    const [searchInput, setSearchInput] = useState('');
    const [filteredOrder, setFilteredOrder] = useState(null);

    const handleOptionChange = (e) => {
        setSearchOption(e.target.value);
    };

    const handleSearch = (e) => {
        e.preventDefault(); // Prevent default form submission

        if (searchInput.trim() === '') return;

        const result = trackOrder.find(order =>
            (searchOption === 'orderID' && order.order_id === searchInput) ||
            (searchOption === 'trackingID' && order.tracking_id === searchInput)
        );
        
        setFilteredOrder(result);
    };

    const trackOrder = [
        {
            order_id: "ORD123456789",
            tracking_id: "TRK987654321",
            product_img: watch,
            product_name: "Apple Watch Series 8 Altar HD Display",
            product_price: "89.00",
            product_currency: "€",
            product_size: "44",
            product_color: "Pink",
            product_quantity: "1",
            product_availability: "In Delivery",
            delivery: "House No 1 Ground floor amrut nagar near magan Nagar dhanmora char rasta Dhanmora, Katargam SURAT - 395004, Gujarat",
            orderStatus: [
                {
                    status: 'Order In Transit',
                    date: '18 July',
                    time: '02:06 PM',
                    address: '32, Bandra Mumbai 395004',
                },
                {
                    status: 'Order Custom Port',
                    date: '16 July',
                    time: '02:06 PM',
                    address: '32, Bandra Mumbai 395004',
                },
                {
                    status: 'Order Are Shipped',
                    date: '15 July',
                    time: '02:06 PM',
                    address: '32, Bandra Mumbai 395004',
                },
                {
                    status: 'Order In Packing',
                    date: '14 July',
                    time: '10:25 PM',
                    address: '32, Bandra Mumbai 395004',
                },
                {
                    status: 'Verified Payment',
                    date: '14 July',
                    time: '09:30 PM',
                    address: '32, Bandra Mumbai 395004',
                },
            ]
        },
        {
            order_id: "ORD987654321",
            tracking_id: "TRK123456789",
            product_img: watch,
            product_name: "Apple Watch Series 8 Altar HD Display",
            product_price: "89.00",
            product_currency: "€",
            product_size: "44",
            product_color: "Pink",
            product_quantity: "1",
            product_availability: "In Delivery",
            delivery: "House No 1 Ground floor amrut nagar near magan Nagar dhanmora char rasta Dhanmora, Katargam SURAT - 395004, Gujarat",
            orderStatus: [
                {
                    status: 'Order In Transit',
                    date: '18 July',
                    time: '02:06 PM',
                    address: '32, Bandra Mumbai 395004',
                },
                {
                    status: 'Order Custom Port',
                    date: '16 July',
                    time: '02:06 PM',
                    address: '32, Bandra Mumbai 395004',
                },
                {
                    status: 'Order Are Shipped',
                    date: '15 July',
                    time: '02:06 PM',
                    address: '32, Bandra Mumbai 395004',
                },
            ]
        },
        {
            order_id: "ORD1122334455",
            tracking_id: "TRK1122334455",
            product_img: watch,
            product_name: "Apple Watch Series 8 Altar HD Display",
            product_price: "89.00",
            product_currency: "€",
            product_size: "44",
            product_color: "Pink",
            product_quantity: "1",
            product_availability: "In Delivery",
            delivery: "House No 1 Ground floor amrut nagar near magan Nagar dhanmora char rasta Dhanmora, Katargam SURAT - 395004, Gujarat",
            orderStatus: [
                {
                    status: 'Order In Transit',
                    date: '18 July',
                    time: '02:06 PM',
                    address: '32, Bandra Mumbai 395004',
                },
                {
                    status: 'Order Custom Port',
                    date: '16 July',
                    time: '02:06 PM',
                    address: '32, Bandra Mumbai 395004',
                },
                {
                    status: 'Order Are Shipped',
                    date: '15 July',
                    time: '02:06 PM',
                    address: '32, Bandra Mumbai 395004',
                },
                {
                    status: 'Order In Packing',
                    date: '14 July',
                    time: '10:25 PM',
                    address: '32, Bandra Mumbai 395004',
                },
                {
                    status: 'Verified Payment',
                    date: '14 July',
                    time: '09:30 PM',
                    address: '32, Bandra Mumbai 395004',
                },
            ]
        }
    ];


    return (
        <>
            <Navbar />
            <div className="container mx-auto md:mt-20 mt-10 flex justify-center place-items-center">
                <div className="shadow-2xl border-gray-300 border md:w-1/2 w-full p-5 rounded-lg mx-4">
                    <div className="flex items-center space-x-4">
                        <span className='text-lg font-medium'>Search By :</span>

                        <label className="flex items-center">
                            <input
                                type="radio"
                                name="searchOption"
                                value="orderID"
                                checked={searchOption === 'orderID'}
                                onChange={handleOptionChange}
                                className="form-radio h-4 w-4 text-black focus:ring-0 "
                            />
                            <span className="ml-2">Order ID</span>
                        </label>
                        <label className="flex items-center">
                            <input
                                type="radio"
                                name="searchOption"
                                value="trackingID"
                                checked={searchOption === 'trackingID'}
                                onChange={handleOptionChange}
                                className="form-radio h-4 w-4 text-black focus:ring-0"
                            />
                            <span className="ml-2">Tracking ID</span>
                        </label>
                    </div>
                    <form className='w-full'>
                        <div className="flex flex-row place-items-center w-full gap-3 mt-3">
                            <input
                                type="text"
                                className='p-2 focus:ring-0 w-full h-full focus:border-gray-400 border-gray-400 rounded-md'
                                placeholder={`Enter Your ${searchOption === 'orderID' ? 'Order ID' : 'Order Tracking ID'}`}
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                            />
                            <button
                                className='bg-black text-nowrap h-full px-4 py-3 text-sm rounded-md text-white'
                                onClick={handleSearch}
                            >
                                Track order
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {filteredOrder ? (
                <div className='container mx-auto lg:my-12 md:my-12'>
                    <div className='lg:flex '>
                        <div className='mt-5'>
                            <div className="flex flex-col gap-4 p-4">
                                <div className="flex gap-10">
                                    <div className="w-2/6">
                                        <img src={filteredOrder.product_img} alt="" className="bg-gray-200 rounded-lg" />
                                    </div>
                                    <div className="w-1/2 flex flex-col gap-2">
                                        <h2 className="lg:text-2xl font-medium text-sm" >{filteredOrder.product_name}</h2>
                                        <div className="flex gap-5 text-gray-500 lg:text-lg md:text-lg text-xs ">
                                            <span>Size: {filteredOrder.product_size}</span>
                                            <span>Colour: {filteredOrder.product_color}</span>
                                            <span className=' rounded-sm'>Qty: {filteredOrder.product_quantity}</span>
                                        </div>
                                        <span className="bg-gray-200 text-gray-600 text-center font-medium py-2 px-2 rounded w-24 lg:mt-10 text-xs">
                                            {filteredOrder.product_availability}
                                        </span>
                                        <p className="text-2xl font-medium">
                                            {filteredOrder.product_currency} {filteredOrder.product_price}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 lg:w-2/5 mt-5">
                                    <h3 className="text-lg font-medium">Delivery Address</h3>
                                    <p className='text-sm font-normal'>{filteredOrder.delivery}</p>
                                </div>
                            </div>
                        </div>

                        <div className='lg:w-8/12 mt-10 p-4'>
                            <div className='flex gap-20 lg:gap-32 '>
                                <div className='space-y-10 '>
                                    <div><FiPackage className='lg:text-5xl md:text-4xl text-2xl' /></div>
                                    <div className='flex justify-center'><FaSquareCheck className='text-3xl' /></div>
                                </div>
                                <div className='space-y-10 '>
                                    <div><LiaShippingFastSolid className='lg:text-5xl md:text-4xl text-2xl ' /></div>
                                    <div className='flex justify-center'><FaSquareCheck className='text-3xl' /></div>
                                </div>
                                <div className='space-y-10 '>
                                    <div><SlSocialDropbox className='lg:text-5xl md:text-4xl text-2xl' /></div>
                                    <div className='flex justify-center'><IoCheckboxOutline className='text-3xl' /></div>
                                </div>
                                <div className='space-y-10 '>
                                    <div><LuCheckSquare className='lg:text-5xl md:text-4xl text-2xl' /></div>
                                    <div className='flex justify-center'><IoCheckboxOutline className='text-3xl' /></div>
                                </div>
                            </div>
                            <div className=' border-t-2 mt-12 '>
                                <h1 className="text-2xl font-medium mb-4 mt-12">Order Status Details</h1>
                                <ul className="list-none ">
                                    {filteredOrder.orderStatus.map((item, index) => (
                                        <li key={index} className="flex items-center mb-3  space-y-5">
                                            <div className=" ">
                                                <div className='w-4 h-4 rounded-full bg-black me-3'></div>
                                            </div>
                                            <div className='flex gap-40'>
                                                <div>
                                                    <h2 className="text-sm font-normal">{item.status} - {item.date}</h2>
                                                    <p className="text-gray-600">{item.address}</p>
                                                </div>
                                                <div>
                                                    <p className="text-gray-600">{item.time}</p>

                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                searchInput && <p className="text-center text-red-500 mt-10">No order found with the entered ID</p>
            )}

            <Footer />
        </>
    );
}

export default Tracking_page;
