import React, { useState, useEffect } from 'react';
import { RiDeleteBin6Line } from "react-icons/ri";
import { TiEdit } from "react-icons/ti";
import { UserState } from '../../../../Context/userProvider';
import Loader from '../../../utiles/Loader';
import notify from '../../../utiles/Notification';
import axios from 'axios';

const Myreview = ({ review }) => {

    return (
        <>
            <h1 className='md:text-2xl text-xl font-medium'>My Reviews</h1>
            <div className='mt-4'>
                {review.map(list => (
                    <div key={list.product_review_id} className='border border-gray-400 rounded-xl h-36 mb-3'>
                        <h1 className='p-3'>Product Name :- {list.product_name}</h1>
                        <div className='flex flex-row justify-between border-t border-gray-400'>
                            <div className='flex flex-col ps-4 pt-4'>
                                <div className=''>
                                    {[...Array(5)].map((star, index) => (
                                        <svg
                                            key={index}
                                            className={`md:w-5 md:h-5 w-4 h-4 inline-block ${index < Math.floor(list.rating || 0) ? 'text-yellow-500' : 'text-gray-300'
                                                }`}
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                        >
                                            <path d="M9.049 2.927C9.39 2.029 10.611 2.029 10.951 2.927L12.613 7.03C12.763 7.438 13.146 7.75 13.586 7.833L18.177 8.702C19.135 8.884 19.508 10.086 18.839 10.728L15.514 13.89C15.167 14.213 15.01 14.712 15.111 15.198L16.145 19.655C16.351 20.623 15.308 21.371 14.505 20.842L10.5 18.394C10.197 18.203 9.803 18.203 9.5 18.394L5.495 20.842C4.692 21.371 3.649 20.623 3.855 19.655L4.889 15.198C4.99 14.712 4.833 14.213 4.486 13.89L1.161 10.728C0.492 10.086 0.865 8.884 1.823 8.702L6.414 7.833C6.854 7.75 7.237 7.438 7.387 7.03L9.049 2.927Z" />
                                        </svg>
                                    ))}
                                </div>
                                <span className='mt-3 '>{list.comment}</span>
                            </div>
                            <div className=' mt-4 me-4 text-gray-500'>
                                <RiDeleteBin6Line className='text-2xl mb-2' />
                                <TiEdit className='text-2xl' />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default Myreview;
