import React from 'react'
import Adv_1 from '../Assets/Images/adv_3.png'
import { Link } from 'react-router-dom'

import { Swiper, SwiperSlide } from 'swiper/react';



// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper/modules';

const Boardcast = () => {


    const Boardcast = [
        {
            to: 'jj',
            img: Adv_1,
            title: 'Teclast T40S Tablet Full Display',
            price: '123.98',
        },
        {
            to: 'jj',
            img: Adv_1,
            title: 'Teclast T40S Tablet Full Display',
            price: '123.98',
        },
        {
            to: 'jj',
            img: Adv_1,
            title: 'Teclast T40S Tablet Full Display',
            price: '123.98',
        },
    ]
    return (
        <>
            <div className="container mx-auto p-2 my-10">
                <Swiper
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    slidesPerView={2}
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    loop={true}
                    modules={[Autoplay]}
                    breakpoints={{
                        200: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                    }}
                    className="mySwiper" data-aos="fade-up">
                    {Boardcast.map((list, index) => (
                        <SwiperSlide key={index}>
                            <div className="xl:h-64 lg:h-56 md:h-40 h-40 w-full relative" >
                                <img src={list.img} alt="" className='h-full absolute object-fill' />
                                <div className="absolute md:w-2/3 w-1/2 lg:w-1/2 xl:ps-10 lg:ps-5 xl:py-10 lg:py-5 md:ps-3 md:py-3 ps-5 py-5 flex flex-col lg:gap-5 gap-2">
                                    <h1 className='xl:text-3xl lg:text-2xl md:text-lg font-semibold'>{list.title}</h1>
                                    <div className="flex flex-row gap-5 place-items-end">
                                        <span className='xl:text-2xl lg:text-xl text-lg'>From</span>
                                        <span className='xl:text-3xl lg:text-2xl md:text-lg font-semibold'>€ {list.price}</span>
                                    </div>
                                    <Link to={list.to} className='text-white text-sm bg-black px-3 lg:py-2 py-1 capitalize w-fit'>shop now</Link>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </>
    )
}

export default Boardcast