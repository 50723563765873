import React, { useEffect, useRef, useState } from 'react'
import rating from "../../../Assets/Images/rating2.png";
import { BsCamera } from "react-icons/bs";
import { TiEdit } from "react-icons/ti";
import { UserState } from '../../../../Context/userProvider';
import Loader from '../../../utiles/Loader';
import axios from 'axios';
import notify from '../../../utiles/Notification';
import { ImageUpload } from '../../../utiles/ImageUpload';
import Dropdown from '../../../utiles/Dropdown';
import { useCountries, useStates } from '../../../utiles/CountryStateCity';
import { Navigate } from 'react-router-dom';
import { useCart } from '../../../../Context/CartContext';

const Myprofile = ({ custdata, setCustdata, setLoading, setRefresh, editopen, setEditopen }) => {

    const baseUrl = process.env.REACT_APP_BASEURL
    const { user, logout } = UserState();

    const multiple = false;
    const { images, handleFileChange, handleRemoveImage } = ImageUpload(multiple, setLoading);

    const [countryID, setCountryID] = useState('');
    const { countryOptions, error: countryError } = useCountries();
    const { stateOptions, error: stateError } = useStates(countryID);
    const { updateCartCount} = useCart();


    useEffect(() => {
        const updateCustomerImage = async () => {
            if (images.length > 0) {
                setCustdata(prevData => ({
                    ...prevData,
                    image: images[0].url
                }));

            } else {
                setCustdata(prevData => ({
                    ...prevData,
                    image: prevData.image || ''
                }));
            }
        };

        updateCustomerImage();
    }, [images]);

    const handleEditopen = () => {
        setRefresh(prev => prev + 1)
        setEditopen(true);
    }

    const handleEditclose = () => {
        setEditopen(false);
    }


    const handleUpdate = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${baseUrl}/updateCustomer`,
                {
                    customer_id: user.customer_id,
                    ...custdata,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );
            if (response.data.status) {
                notify('success', 'Success', 'Profile Update Successfull');
                setRefresh(prev => prev + 1);
            } else {
                notify('danger', 'Error', `${response.data.message || 'An error occurred'}`);
            }
        } catch {
            notify('danger', 'Error', 'Something went wrong');
        }
        setLoading(false);
    }

    const firstChar = custdata.first_name.charAt(0)

    const fileInputRef = useRef(null);

    const openimage = () => {
        fileInputRef.current.click();
    }

    const handleSelect = (val) => {
        const selectedCountry = countryOptions.find(option => option.label === val);
        if (selectedCountry) {
            setCustdata((prevInfo) => ({
                ...prevInfo,
                country: selectedCountry.id,
                country_name: selectedCountry.label,
                state: '',
                state_name: '',
            }));
            setCountryID(selectedCountry.id);
        }
    };

    const handleSelect_state = (val) => {
        const selectedState = stateOptions.find(option => option.label === val);
        if (selectedState) {
            setCustdata((prevInfo) => ({
                ...prevInfo,
                state: selectedState.id,
                state_name: selectedState.label
            }));
        }
    };

    const logoutDeactivate = async () => {
        try {
            const response = await axios.post(`${baseUrl}/customer_deactive_account`, {
                customer_id: user.customer_id
            });
            if (response.data.status) {
                notify('success', 'Success', 'Your Account is Deactivate');
                logout();
                updateCartCount(0)
                Navigate('/')
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (

        <>
            <h1 className='md:text-2xl text-xl font-medium capitalize'>My Profile</h1>
            <div className='flex lg:flex-row flex-col w-full mt-4'>
                <div className='md:w-3/12 w-full flex justify-center place-items-start py-6'>
                    <div className='h-28 w-28   relative'>
                        {custdata.image != null && custdata.image !== '0' ? (
                            <img className='w-full h-full rounded-full' src={custdata.image} alt='my-image' />
                        ) : (
                            <h1 className='text-3xl text-white bg-slate-400 rounded-full w-full h-full flex justify-center place-items-center'>{firstChar}</h1>
                        )}
                        <input
                            type="file"
                            ref={fileInputRef}
                            accept="image/*"
                            className="hidden"
                            onChange={handleFileChange}
                        />
                        {editopen &&
                            <button className=' absolute -right-1 bottom-0 bg-black p-2 rounded-full' onClick={openimage}>
                                <BsCamera className='text-white text-2xl' />
                            </button>
                        }
                    </div>
                </div>
                <div className='lg:w-9/12 w-full px-2 mb-28'>
                    <div className='flex flex-wrap gap-4'>
                        <div className='md:w-5/12 w-full mt-3'>
                            <label className='w-full text-xl font-medium text-neutral-500'>First Name</label>
                            <div className='bg-gray-200 flex flex-row justify-between place-items-center rounded-lg mt-2'>
                                <input type='text' name='first_name' value={custdata.first_name} className='w-full focus:ring-0 md:text-lg text-base bg-transparent border-0' onChange={(e) => setCustdata({ ...custdata, first_name: e.target.value })} disabled={!editopen} />
                            </div>
                        </div>
                        <div className='md:w-5/12 w-full mt-3'>
                            <label className='w-full text-xl font-medium text-neutral-500'>Last Name</label>
                            <div className='bg-gray-200 flex flex-row justify-between place-items-center rounded-lg mt-2'>
                                <input type='text' name='last_name' value={custdata.last_name} className='w-full focus:ring-0 md:text-lg text-base bg-transparent border-0' onChange={(e) => setCustdata({ ...custdata, last_name: e.target.value })} disabled={!editopen} />
                            </div>
                        </div>
                        <div className='md:w-5/12 w-full mt-3'>
                            <label className='w-full text-xl font-medium text-neutral-500'>Email</label>
                            <div className='bg-gray-200 flex flex-row justify-center place-items-center rounded-lg mt-2'>
                                <input type='email' name='email' value={custdata.email} className='w-full focus:ring-0 md:text-lg text-base bg-transparent border-0' onChange={(e) => setCustdata({ ...custdata, email: e.target.value })} disabled={!editopen} />
                            </div>
                        </div>
                        <div className='md:w-5/12 w-full mt-3'>
                            <label className='w-full text-xl font-medium text-neutral-500'>Phone</label>
                            <div className='bg-gray-200 flex flex-row justify-center place-items-center rounded-lg mt-2'>
                                <input type='number' name='mobile' value={custdata.mobile} className='w-full focus:ring-0 md:text-lg text-base bg-transparent border-0' onChange={(e) => setCustdata({ ...custdata, mobile: e.target.value })} disabled={!editopen} />
                            </div>
                        </div>
                        <div className='md:w-5/12 w-full mt-3'>
                            <label className='w-full text-xl font-medium text-neutral-500'>Gender</label>
                            <div className='bg-gray-200 flex flex-row justify-center place-items-center rounded-lg mt-2'>
                                <input type='text' name='gender' value={custdata.gender} className='w-full focus:ring-0 md:text-lg text-base bg-transparent border-0' onChange={(e) => setCustdata({ ...custdata, gender: e.target.value })} disabled={!editopen} />
                            </div>
                        </div>
                        {!editopen &&
                            <div className='md:w-5/12 w-full mt-3'>
                                <label className='w-full text-xl font-medium text-neutral-500'>State</label>
                                <div className='bg-gray-200 flex flex-row justify-center place-items-center rounded-lg mt-2'>
                                    <span className='w-full focus:ring-0 md:text-lg text-base bg-transparent border-0 py-2 px-3'>{custdata.state_name}</span>
                                </div>
                            </div>
                        }
                        {!editopen &&
                            <div className='md:w-5/12 w-full mt-3'>
                                <label className='w-full text-xl font-medium text-neutral-500'>Country</label>
                                <div className='bg-gray-200 flex flex-row justify-center place-items-center rounded-lg mt-2'>
                                    <span className='w-full focus:ring-0 md:text-lg text-base bg-transparent border-0 py-2 px-3'>{custdata.country_name}</span>
                                </div>
                            </div>
                        }
                        {editopen &&
                            <div className='md:w-5/12 w-full mt-3 flex flex-col'>
                                <label className='w-full text-xl font-medium text-neutral-500'>Country</label>
                                <Dropdown
                                    label=""
                                    labelSelect="Country"
                                    isMultiple={false}
                                    search={true}
                                    options={countryOptions}
                                    selectedValues={custdata.country_name}
                                    onSelect={handleSelect}
                                />
                            </div>
                        }
                        {editopen &&
                            <div className='md:w-5/12 w-full mt-3 flex flex-col'>
                                <label className='w-full text-xl font-medium text-neutral-500'>state</label>
                                <Dropdown
                                    label=""
                                    labelSelect="State"
                                    isMultiple={false}
                                    options={stateOptions}
                                    search={true}
                                    selectedValues={custdata.state_name}
                                    onSelect={handleSelect_state}
                                />
                            </div>
                        }
                    </div>
                    {editopen &&
                        <div className='mt-7 md:w-96 w-full flex flex-row gap-3'>
                            <button className='text-black px-3 w-full py-2 border-black border-2 font-semibold rounded-md shadow-sm transition-all duration-300 ease-in-out' onClick={handleEditclose}>Cancel</button>
                            <button className='text-black px-3 w-full py-2 border-black border-2 font-semibold rounded-md shadow-sm hover:text-white hover:bg-black transition-all duration-300 ease-in-out' onClick={handleUpdate}>Update Profile</button>
                        </div>
                    }
                    {!editopen &&
                        <div className='mt-7 md:w-96 w-full flex flex-row'>
                            <button className='text-black px-3 w-full py-2 border-black border-2 font-semibold rounded-md shadow-sm hover:text-white hover:bg-black transition-all duration-300 ease-in-out' onClick={handleEditopen}>Edit Profile</button>
                            <button className='text-red-600 px-3 w-full py-2  font-semibold capitalize' onClick={logoutDeactivate}>delete account</button>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default Myprofile