import React, { useState } from 'react'
import { FaChevronRight } from 'react-icons/fa6';
import Dropdown from '../../Component/utiles/Dropdown';
import Pagination from '../../Component/utiles/Pagination';
import { Link } from 'react-router-dom';

const Tickets = ({ tickets }) => {



    const getStatusClass = (status) => {
        switch (status) {
            case 'closed':
                return ' text-green-500 text-base text-nowrap';
            case 'in progress':
                return ' text-red-500 text-base text-nowrap';
            case 'needs attention':
                return ' text-[#EE9D00] text-base text-nowrap';
            default:
                return 'text-base';
        }
    }

    const perpageoption = [
        { label: '5' }, { label: '10' }, { label: '15' }, { label: '20' }
    ];

    const [itemsPerPage, setItemsPerPage] = useState(perpageoption[0].label);
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = tickets.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(tickets.length / itemsPerPage);

    return (
        <>
            <h1 className={`text-xl font-semibold  mt-10`}>Recently raised tickets</h1>
            <div className="w-full bg-white rounded-lg mt-5 md:p-6 p-3">
                <div className="flex flex-row place-items-center justify-between">
                    <div className='flex flex-row items-center md:w-40 w-24  gap-2'>
                        <span className='text-nowrap mt-2 lg:block hidden text-sm capitalize'>items per page</span>
                        <div className='w-16'>
                            <Dropdown
                                label=""
                                options={perpageoption}
                                selectedValues={itemsPerPage}
                                onSelect={setItemsPerPage}
                                onRemove={() => { }}
                                isMultiple={false}
                            />
                        </div>
                    </div>
                    <span className='capitalize text-base cursor-pointer font-medium'>sell all tickets</span>
                </div>
                <div className='overflow-x-auto w-full mt-10'>
                    <table className="min-w-full bg-white">
                        <thead className="bg-gray-100 rounded-full">
                            <tr>
                                <th className="py-4 px-4 text-left capitalize">Created On</th>
                                <th className="py-4 px-4 text-left capitalize">Ticket ID</th>
                                <th className="py-4 px-4 text-left capitalize">Issue</th>
                                <th className="py-4 px-4 text-left capitalize">Last Update</th>
                                <th className="py-4 px-4 text-left capitalize">Status</th>
                                <th className="py-4 px-4 text-left capitalize">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((item, index) => (
                                <tr key={index} className="border-b border-gray-200 hover:bg-gray-200 transition-all duration-200 ease-in-out">
                                    <td className="py-4 px-4 text-nowrap">{item.createdOn}</td>
                                    <td className="py-4 px-4 text-nowrap">{item.ticketId}</td>
                                    <td className="py-4 px-4">
                                        <div className="flex items-center">{item.issue}</div>
                                    </td>
                                    <td className="py-4 px-4">
                                        <div className="xl:w-full w-64">
                                            {item.lastUpdate}
                                        </div>
                                    </td>
                                    <td className={`py-4 px-4 capitalize ${getStatusClass(item.status)}`}>{item.status}</td>
                                    <td className="py-4 px-4">
                                        <Link to={"my-ticket/" + item.ticketId} className='border border-[#000000] px-5 py-2 rounded-md cursor-pointer'>view</Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-between items-center mt-4">
                    <div>
                        <span>Page {currentPage} of {totalPages}</span>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </div>
        </>
    )
}

export default Tickets