import React, { useEffect, useState } from 'react'
import Dropdown from '../../Component/utiles/Dropdown';
import { IoSearchSharp } from "react-icons/io5";
import Pagination from '../../Component/utiles/Pagination';
import { Link } from 'react-router-dom';


const Approvals = ({ tickets }) => {

    const tabs = ['all', 'needs attention', 'in progress', 'closed'];

    const [searchTerm, setSearchTerm] = useState('');

    const [selectedStatus, setSelectedStatus] = useState(tabs[0]);

    const handleTabClick = (status) => {
        setSelectedStatus(status);
        setCurrentPage(1);
    };

    const [category, setCategory] = useState('Issue Category');

    const ctgoptions = [
        { label: 'clothes' },
        { label: 'watches' },
        { label: 'electronics' },
    ];

    const perpageoption = [
        { label: '10' }, { label: '15' }, { label: '20' }
    ];

    const [itemsPerPage, setItemsPerPage] = useState(perpageoption[0].label);
    const [currentPage, setCurrentPage] = useState(1);

    const filterByStatus = (purchase) => {
        if (selectedStatus === 'all') return true;
        return purchase.status === selectedStatus;
    };

    const filteredPurchases = tickets.filter(filterByStatus);

    const searchResults = filteredPurchases.filter(purchase =>
        purchase.ticketId.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const itemsToDisplay = searchTerm ? searchResults : filteredPurchases;


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = itemsToDisplay.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(itemsToDisplay.length / itemsPerPage);



    useEffect(() => {
        if (searchTerm) {
            setCurrentPage(1);
        }
    }, [searchTerm]);


    const getStatusClass = (status) => {
        switch (status) {
            case 'closed':
                return ' text-green-500 text-base';
            case 'in progress':
                return ' text-red-500 text-base';
            case 'needs attention':
                return ' text-[#EE9D00] text-base';
            default:
                return 'text-base';
        }
    }


    return (
        <>
            <div className="w-full bg-white rounded-b-lg  md:px-6 md:pb-6 p-3">
                <h1 className='text-xl font-semibold'>Approvals Overview</h1>
                <div className="flex justify-start mt-3 overflow-x-auto flex-row pt-3  ">
                    <div className="tabs tabs-boxed capitalize flex h-10 flex-row text-nowrap w-fit">
                        {tabs.map((status) => (
                            <a
                                key={status}
                                className={`h-full transition-all flex justify-center place-items-center px-4 duration-200 ease-in-out font-medium cursor-pointer rounded-t-lg ${selectedStatus === status ? 'border-x border-t text-base border-black text-black' : 'border-black border-b text-[#A4A4A4] text-sm'}`}
                                onClick={() => handleTabClick(status)}>
                                {status.charAt(0).toUpperCase() + status.slice(1)}
                            </a>
                        ))}
                    </div>
                    <div className="w-full "><div className="w-full border-black border-b h-full pt-9"></div></div>
                </div>
                <div className="w-full flex flex-row gap-3 mt-5 place-items-center justify-between">
                    <div className="flex flex-row gap-3 place-items-center w-1/3">
                        <span className='text-base text-[#7D7D7D] text-nowrap mt-1'>Filter by :</span>
                        <Dropdown
                            label=""
                            options={ctgoptions}
                            selectedValues={category}
                            onSelect={setCategory}
                            onRemove={() => { }}
                            isMultiple={false}
                        />
                        <Dropdown
                            label=""
                            options={ctgoptions}
                            selectedValues={category}
                            onSelect={setCategory}
                            onRemove={() => { }}
                            isMultiple={false}
                        />
                    </div>
                    <div className="w-3/4">
                        <div className="relative flex ms-auto xl:w-3/5 md:w-7/12 w-full mt-1">
                            <input
                                type="text"
                                placeholder="Search by Sub Order ID or Ticket ID"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="bg-gray-200 w-full border-0 rounded-full px-4 py-2 pr-10 focus:outline-none focus:border-gray-400 focus:ring-2 focus:ring-gray-400"
                            />
                            <IoSearchSharp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-black cursor-pointer" />
                        </div>
                    </div>
                </div>
                <div className='overflow-x-auto w-full mt-10'>
                    <table className="min-w-full bg-white">
                        <thead className="bg-gray-100 rounded-full">
                            <tr>
                                <th className="py-4 px-4 text-left capitalize">Created On</th>
                                <th className="py-4 px-4 text-left capitalize">Ticket ID</th>
                                <th className="py-4 px-4 text-left capitalize">Issue</th>
                                <th className="py-4 px-4 text-left capitalize">Last Update</th>
                                <th className="py-4 px-4 text-left capitalize">Status</th>
                                <th className="py-4 px-4 text-left capitalize">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((item, index) => (
                                <tr key={index} className="border-b border-gray-200 hover:bg-gray-200 transition-all duration-200 ease-in-out">
                                    <td className="py-4 px-4 text-nowrap">{item.createdOn}</td>
                                    <td className="py-4 px-4 text-nowrap">{item.ticketId}</td>
                                    <td className="py-4 px-4">
                                        <div className="flex items-center">{item.issue}</div>
                                    </td>
                                    <td className="py-4 px-4">
                                        <div className="xl:w-full w-64">
                                            {item.lastUpdate}
                                        </div>
                                    </td>
                                    <td className={`py-4 px-4 capitalize text-nowrap ${getStatusClass(item.status)}`}>{item.status}</td>
                                    <td className="py-4 px-4">
                                        <Link to={"my-ticket/" + item.ticketId} className='border border-[#000000] px-5 py-2 rounded-md cursor-pointer'>view</Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-between items-center mt-4">
                    <div>
                        <span>Page {currentPage} of {totalPages}</span>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </div>
        </>
    )
}

export default Approvals