import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaShoppingCart, FaTimes, FaTrash } from 'react-icons/fa';
import { UserState } from '../../Context/userProvider';
import notify from '../utiles/Notification';

const CartSidebar = ({ isOpen, onClose, cart, deleteProduct, cartTotal }) => {

  const { user, logout } = UserState()
  const navigate = useNavigate();

  const myCart = () => {
    if (!user) {
      notify('danger', 'Error', 'Please login to All cart Products');
      return;
    }
    navigate('/my-cart');
  };

  const symbol = cart.length > 0 ? cart[0].symbol : '';

  return (
    <>
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
          }`}
        onClick={onClose}
      ></div>

      <div className={`fixed inset-y-0 right-0 w-96 bg-white shadow-2xl transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out z-50`}>
        <div className="flex justify-between items-center p-6 bg-black text-white">
          <h2 className="text-2xl font-bold flex items-center">
            <FaShoppingCart className="mr-3" />
            Your Cart
          </h2>
          <button onClick={onClose} className="text-white hover:text-gray-300 transition duration-150">
            <FaTimes size={24} />
          </button>
        </div>
        <div className="overflow-y-auto h-[calc(100vh-240px)] p-6">
          {cart.length > 0 ? (
            cart.map((item, index) => (
              <div key={index} className="flex items-center mb-6 pb-6 border-b border-gray-200 last:border-b-0">
                <img src={item.images} alt={item.name} className="w-20 h-20 object-cover rounded-lg shadow-md mr-4" />
                <div className="flex-grow">
                  <h3 className="text-lg font-semibold text-gray-800 mb-1">{item.name}</h3>
                  <p className="text-sm text-gray-600 mb-1">Qty: {item.added_quantity}</p>
                  <p className="text-lg font-bold text-gray-800">{item.symbol ? item.symbol : '$'}&nbsp;{item.price}</p>
                </div>
                <button onClick={() => deleteProduct('Single', item.product_cart_id, item.product_id)} className="text-gray-500 hover:text-black transition duration-150">
                  <FaTrash size={18} />
                </button>
              </div>
            ))
          ) : (
            <div className="flex flex-col items-center justify-center h-full text-gray-500">
              <FaShoppingCart size={48} className="mb-4 text-gray-400" />
              <p className="text-xl font-semibold">Your cart is empty</p>
              <p className="mt-2 text-sm">Add some items to get started!</p>
            </div>
          )}
        </div>
        <div className="absolute bottom-0 left-0 right-0 p-6 border-t border-gray-200 bg-gray-100">
          <div className="flex justify-between items-center mb-4">
            <span className="text-xl font-semibold text-gray-800">Total:</span>
            <span className="text-2xl font-bold text-gray-800">{symbol ? symbol : '$'}&nbsp;{(cartTotal).toFixed(2)}</span>
          </div>
          <Link to='/my-cart' className="block w-full bg-black text-white text-center py-3 rounded-lg font-semibold hover:bg-gray-800 transition duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-0.5">
            View Full Cart
          </Link>
        </div>
      </div>
    </>
  );
};

export default CartSidebar;