import React, { useState, useEffect, useRef } from 'react';
import { FaLongArrowAltRight, FaStar, FaRegStar, FaStarHalfAlt } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { IoIosArrowUp } from 'react-icons/io';
import { MdOutlineAddShoppingCart } from "react-icons/md";
import { IoShareSocial } from "react-icons/io5";
import { FaRegHeart } from "react-icons/fa";
import { Link } from 'react-router-dom';
import axios from 'axios';
import notify from './Notification';
import { UserState } from '../../Context/userProvider';
import { useCart } from '../../Context/CartContext';


const ProductModal = ({ isOpen, toggleModal, product, setLoading }) => {

    const baseUrl = process.env.REACT_APP_BASEURL;
    const id = product ? product.product_id : null

    const [products, setProduct] = useState({});
    const [variants, setVariants] = useState([]);
    const [selectedVariant, setSelectedVariant] = useState(null);
    const [selectedSize, setSelectedSize] = useState(null);
    const [selected, setSelected] = useState({
        color: '', size: '', variant_id: '', product_id: '', size_id: ''
    })
    const [review, setReview] = useState([])
    const { user, logout } = UserState();
    const { updateCartCount } = useCart();

    useEffect(() => {
        const fetchProducts = async () => {
            if (isOpen) {
                setLoading(true);
                try {
                    const response = await axios.post(`${baseUrl}/get_one_product`, { product_id: id });

                    if (response.data.status) {
                        setProduct(response.data.products);
                        setVariants(response.data.variants);
                        setSelectedVariant(null);
                        setSelectedSize(null);
                        setReview(response.data.reviews);
                    }

                    setLoading(false);
                } catch (err) {
                    console.log(err);
                    setLoading(false);
                }
            }
        };
        fetchProducts();
    }, [isOpen]);


    useEffect(() => {
        const setvariant = async () => {
            if (variants && variants.length > 0 && variants[0].sizeStock.length > 0) {
                setSelectedVariant(variants[0]);
                setSelectedSize(variants[0].sizeStock[0].size_name);
                setSelected({
                    color: variants[0].colour,
                    size: variants[0].sizeStock[0].size_name,
                    variant_id: variants[0].product_varient_id,
                    product_id: products.product_id,
                    size_id: variants[0].sizeStock[0].size_id
                });
            }
        };

        setvariant();
    }, [variants]);

    const handleColorSelect = (variant) => {
        setSelected({
            size: variant.sizeStock[0].size_name,
            size_id: variant.sizeStock[0].size_id,
            color: variant.colour,
            variant_id: variant.product_varient_id,
            product_id: products.product_id,
        });
        setSelectedVariant(variant);
        setSelectedSize(variant.sizeStock[0].size_name);
        setCurrentSlide(0);
    };

    const sizeselect = (a, b) => {
        setSelectedSize(a);
        setSelected((prev) => ({
            ...prev,
            size: a,
            size_id: b
        }));

    }

    const getAllUniqueSizes = () => {
        const allSizes = [];
        const sizeSet = new Set();

        variants.forEach((variant) => {
            variant.sizeStock.forEach((sizeObj) => {
                if (!sizeSet.has(sizeObj.size_name) && sizeObj.stock && parseInt(sizeObj.stock) > 0) {
                    sizeSet.add(sizeObj.size_name);
                    allSizes.push(sizeObj.size_name);
                }
            });
        });

        return allSizes;

    };



    const getSizesForSelectedVariant = () => {
        if (!selectedVariant) return getAllUniqueSizes();

        return selectedVariant.sizeStock
            .filter((sizeObj) => sizeObj.stock && parseInt(sizeObj.stock) > 0)
            .map((sizeObj) => ({
                size: sizeObj.size_name,
                size_id: sizeObj.size_id
            }));
    };

    const getAllImages = () => {
        return variants.reduce((allImages, variant) => {
            return [...allImages, ...variant.images];
        }, []);
    };


    const getImagesForSelectedVariant = () => {
        if (selectedVariant) {
            return selectedVariant.images;
        }
        return getAllImages();
    };

    const availableSizes = getSizesForSelectedVariant();
    const imagesToDisplay = getImagesForSelectedVariant();

    const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
    const [isZoomed, setIsZoomed] = useState(false);
    const [startX, setStartX] = useState(null);

    const [currentSlide, setCurrentSlide] = useState(0);
    const slidesContainerRef = useRef(null);
    const [imageKey, setImageKey] = useState(0);
    const totalSlides = imagesToDisplay.length;
    const mainImageRef = useRef(null);



    const goToSlide = (index) => {
        setCurrentSlide(index);
        setImageKey((prevKey) => prevKey + 1);
        if (slidesContainerRef.current) {
            slidesContainerRef.current.children[index].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    };

    const goToPrevSlide = () => {
        const prevSlide = (currentSlide - 1 + totalSlides) % totalSlides;
        goToSlide(prevSlide);
    };

    const goToNextSlide = () => {
        const nextSlide = (currentSlide + 1) % totalSlides;
        goToSlide(nextSlide);
    };

    const [activeTab, setActiveTab] = useState("description");

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const [ratingStats, setRatingStats] = useState({
        totalRatings: 0,
        averageRating: 0,
        ratingCounts: [0, 0, 0, 0, 0],
    });

    const handleTouchStart = (e) => {
        setStartX(e.touches[0].clientX);
    };

    const handleTouchMove = (e) => {
        if (startX !== null) {
            const currentX = e.touches[0].clientX;
            const diffX = startX - currentX;

            if (diffX > 50) {
                goToNextSlide();
                setStartX(null);
            } else if (diffX < -50) {
                goToPrevSlide();
                setStartX(null);
            }
        }
    };

    const handleMouseDown = (e) => {
        setStartX(e.clientX);
    };

    const handleMouseMove = (e) => {
        if (startX !== null) {
            const currentX = e.clientX;
            const diffX = startX - currentX;

            if (diffX > 50) {
                goToNextSlide();
                setStartX(null);
            } else if (diffX < -50) {
                goToPrevSlide();
                setStartX(null);
            }
        }
    };

    const handleMouseUp = () => {
        setStartX(null);
    };

    useEffect(() => {
        const mainImageElement = mainImageRef.current;

        if (mainImageElement) {
            mainImageElement.addEventListener('touchstart', handleTouchStart);
            mainImageElement.addEventListener('touchmove', handleTouchMove);
            mainImageElement.addEventListener('mousedown', handleMouseDown);
            mainImageElement.addEventListener('mousemove', handleMouseMove);
            mainImageElement.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            if (mainImageElement) {
                mainImageElement.removeEventListener('touchstart', handleTouchStart);
                mainImageElement.removeEventListener('touchmove', handleTouchMove);
                mainImageElement.removeEventListener('mousedown', handleMouseDown);
                mainImageElement.removeEventListener('mousemove', handleMouseMove);
                mainImageElement.removeEventListener('mouseup', handleMouseUp);
            }
        };
    }, [startX]);


    const handleMouseEnter = () => {
        setIsZoomed(true);
    };

    const handleMouseLeave = () => {
        setIsZoomed(false);
    };

    const handleMouseMoveZoom = (e) => {
        const rect = mainImageRef.current.getBoundingClientRect();
        const x = ((e.clientX - rect.left) / rect.width) * 100;
        const y = ((e.clientY - rect.top) / rect.height) * 100;
        setZoomPosition({ x, y });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getPriceAndAmount = () => {
        if (selectedVariant) {
            return {
                totalAmount: selectedVariant.total_amount,
                price: selectedVariant.price,
            };
        } else {
            const firstVariant = variants[0] || {};
            return {
                totalAmount: firstVariant.total_amount,
                price: firstVariant.price,
            };
        }
    };

    const AddToCart = async (product, variants) => {
        setLoading(true);

        if (!user) {
            notify('danger', 'Error', 'Please login to add product to cart');
            setLoading(false);
            return
        }

        try {
            const response = await axios.post(`${baseUrl}/add_cart`, {
                product_id: product.product_id,
                customer_id: user.customer_id,
                product_varient_id: variants.variant_id,
                size_id: variants.size_id,
            });

            if (response.data.status) {
                notify('success', 'Success', `${response.data.message}`);
                updateCartCount(response.data.cartCount);
            } else {
                notify('danger', 'Error', `${response.data.message}`);

            }
        } catch (error) {
            console.error('Error during status update:', error);
            notify('danger', 'Error', 'There was an error. Please try again.');

        }

        setLoading(false);
    }

    const { totalAmount, price } = getPriceAndAmount();
    const totalReviews = review.length;
    const averageRating = totalReviews > 0
        ? (review.reduce((acc, review) => acc + review.rating, 0) / totalReviews).toFixed(1)
        : 0;

    if (!product) return null;

    const close = ()=> {
        setVariants([]);
        setSelectedVariant(null);
        toggleModal(null);
    }

    return (
        <>
            <div className={`fixed inset-0 ${isOpen ? 'flex' : 'hidden'} items-center justify-center z-50`}>
                {/* Backdrop with blur effect */}
                <div className="absolute inset-0 bg-black/30 backdrop-blur-sm" onClick={close} />

                {/* Modal Container */}
                <div className={`relative w-11/12 md:w-4/5 max-w-7xl max-h-[90vh] bg-white rounded-2xl shadow-2xl overflow-y-auto transform transition-all duration-300 ease-out ${isOpen ? 'scale-100 opacity-100' : 'scale-95 opacity-0'}`}>
                    {/* Close Button */}
                    <button
                        onClick={close}
                        className="absolute right-4 top-4 p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
                    >
                        <ImCross className="w-4 h-4 text-gray-500" />
                    </button>

                    {/* Modal Content */}
                    <div className="p-6 md:p-8">
                        <div className='flex md:flex-row flex-col gap-8'>
                            {/* Left Side - Image Gallery */}
                            <div className='md:w-1/2 w-full'>
                                <div className='bg-gray-50 rounded-2xl p-6 relative'>
                                    {/* Main Image Container */}
                                    <div className="relative aspect-square flex items-center justify-center mb-4">
                                        <img
                                            ref={mainImageRef}
                                            src={imagesToDisplay[currentSlide]}
                                            alt={`Main`}
                                            key={imageKey}
                                            className="object-contain max-h-full rounded-lg transition-all duration-300"
                                        />

                                        {/* Action Buttons */}
                                        <div className="absolute right-4 top-4 flex flex-col gap-2">
                                            <button className="p-3 bg-white rounded-full shadow-md hover:shadow-lg transition-shadow">
                                                <FaRegHeart className="w-5 h-5 text-gray-700" />
                                            </button>
                                            <button className="p-3 bg-white rounded-full shadow-md hover:shadow-lg transition-shadow">
                                                <IoShareSocial className="w-5 h-5 text-gray-700" />
                                            </button>
                                        </div>
                                    </div>

                                    {/* Thumbnail Gallery */}
                                    <div className="hidden md:grid grid-flow-col gap-4 overflow-x-auto py-4 px-2">
                                        {imagesToDisplay.map((item, index) => (
                                            <div
                                                key={index}
                                                onClick={() => goToSlide(index)}
                                                className={`cursor-pointer rounded-lg overflow-hidden transition-all duration-200 ${index === currentSlide
                                                    ? 'ring-2 ring-black ring-offset-2'
                                                    : 'hover:ring-2 hover:ring-gray-300 hover:ring-offset-2'
                                                    }`}
                                            >
                                                <img
                                                    src={item}
                                                    alt={`Thumbnail ${index}`}
                                                    className="w-20 h-20 object-cover"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/* Right Side - Product Details */}
                            <div className='md:w-1/2 w-full space-y-6'>
                                <div className="space-y-2">
                                    <h2 className="text-2xl md:text-3xl font-semibold text-gray-900">{product.product_name}</h2>
                                    <p className="text-gray-600">{product.sub_text}</p>
                                </div>

                                {/* Rating and Reviews */}
                                <div className="flex items-center gap-2">
                                    <div className="flex items-center">
                                        <FaStar className="w-5 h-5 text-yellow-400" />
                                        <span className="ml-1 font-medium">{averageRating}</span>
                                    </div>
                                    <span className="text-gray-500">({totalReviews} Reviews)</span>
                                </div>

                                {/* Price */}
                                <div className="space-y-1">
                                    <div className="flex items-center gap-3">
                                        <span className="text-3xl font-bold text-gray-900">$ {price}</span>
                                        <span className="text-xl text-gray-400 line-through">$ {totalAmount}</span>
                                        <span className="px-2 py-1 text-sm font-semibold text-green-600 bg-green-50 rounded-full">
                                            {product.discount}% OFF
                                        </span>
                                    </div>
                                </div>

                                {/* Color Selection */}
                                <div className="space-y-3">
                                    <label className="block text-sm font-medium text-gray-700">Color</label>
                                    <div className="flex flex-wrap gap-3">
                                        {variants.map((variant, index) => (
                                            <div key={index} className="relative">
                                                <input
                                                    id={`color-${index}`}
                                                    type="radio"
                                                    className="sr-only"
                                                    name="color"
                                                    value={variant.colour}
                                                    checked={selectedVariant?.colour === variant.colour}
                                                    onChange={() => handleColorSelect(variant)}
                                                />
                                                <label
                                                    htmlFor={`color-${index}`}
                                                    className={`block w-8 h-8 rounded-full cursor-pointer transition-all duration-200 ${selectedVariant?.colour === variant.colour
                                                        ? 'ring-2 ring-black ring-offset-2'
                                                        : 'hover:ring-2 hover:ring-gray-300 hover:ring-offset-2'
                                                        }`}
                                                    style={{ backgroundColor: variant.colour }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {/* Size Selection */}
                                <div className="space-y-3">
                                    <label className="block text-sm font-medium text-gray-700">Size</label>
                                    <div className="flex flex-wrap gap-2">
                                        {availableSizes.map((size, index) => (
                                            <div key={index}>
                                                <input
                                                    id={`size-${index}`}
                                                    type="radio"
                                                    className="sr-only"
                                                    name="size"
                                                    value={size.size}
                                                    checked={selectedSize === size.size}
                                                    onChange={() => sizeselect(size.size, size.size_id)}
                                                />
                                                <label
                                                    htmlFor={`size-${index}`}
                                                    className={`cursor-pointer px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200 ${selectedSize === size.size
                                                        ? 'bg-black text-white'
                                                        : 'bg-white text-gray-900 border border-gray-300 hover:bg-gray-50'
                                                        }`}
                                                >
                                                    {size.size}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {/* Action Buttons */}
                                <div className="space-y-3 pt-4">
                                    <button
                                        onClick={() => AddToCart(products, selected)}
                                        className="w-full py-3 px-4 flex items-center justify-center gap-2 bg-white border-2 border-black text-black hover:bg-black hover:text-white transition-colors duration-200 rounded-lg font-medium"
                                    >
                                        <MdOutlineAddShoppingCart className="w-5 h-5" />
                                        Add to Cart
                                    </button>
                                    <button className="w-full py-3 px-4 bg-black text-white hover:bg-gray-900 transition-colors duration-200 rounded-lg font-medium">
                                        Buy Now
                                    </button>
                                </div>

                                {/* View Details Link */}
                                <Link
                                    to={`product/${product.product_id}`}
                                    onClick={close}
                                    className="inline-flex items-center gap-2 text-gray-600 hover:text-black transition-colors duration-200"
                                >
                                    View Full Product Details
                                    <FaLongArrowAltRight className="w-5 h-5" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductModal;

