import React, { useState } from 'react'
import { FaEye } from 'react-icons/fa6'
import notify from '../../../utiles/Notification';

const Mycoupons = ({ coupons }) => {

  const ctg = [
    {
      category_color_code: 'bg-cyan-300',
    },
    {
      category_color_code: 'bg-purple-300',
    },
    {
      category_color_code: 'bg-yellow-100',
    },

    {
      category_color_code: ' bg-red-300 ',
    },

    {
      category_color_code: 'bg-green-200',
    },
    {
      category_color_code: ' bg-amber-100',
    },
    {
      category_color_code: ' bg-blue-200',
    },
  ];

  const handleCopy = (coupon_code) => {
    navigator.clipboard.writeText(coupon_code).then(
      () => {
        notify('success', 'Success', 'Code Copied!');
        setTimeout(() => 2000);
      },
    );
  };

  return (
    <>
      <h1 className='md:text-2xl text-xl font-medium capitalize'>My coupons</h1>
      <div className="mt-5 grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-5 pb-10">
        {coupons.map((list, index) => (
          <div className={`group relative overflow-hidden gap-2  rounded-xl flex flex-col place-items-center shadow-xl`} key={index}>
            <img src={list.coupon_image} alt={list.coupon_code} className='h-48 ' />
            <div className="flex flex-col space-y-1 place-items-center">
              <div className="flex flex-row place-items-center box-border">
                <h1 className='font-normal text-sm border-y border-s border-gray-300 px-2 py-1 '>{list.coupon_code}</h1>
                <span className='bg-black text-white text-sm px-2 py-1 h-full cursor-pointer' onClick={() => handleCopy(list.coupon_code)}>Copy Code</span>
              </div>
              <span>Less {list.amount} $</span>
              <span>Amount will be more then {list.cart_min_amount}</span>
            </div>
            <div className="absolute right-2 top-4 flex items-center gap-2 opacity-0 transform translate-y-4 group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-300">
              <button className="p-3 bg-white rounded-full shadow-lg hover:bg-gray-50 transition-colors">
                <FaEye className="w-4 h-4 text-gray-700" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default Mycoupons