import React, { useState, useRef, useEffect } from 'react';
import Dropdown from '../../Component/utiles/Dropdown';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { HiOutlineArrowSmDown } from "react-icons/hi";
import useSubcategories from '../Component/Categoriesoption';
import axios from 'axios';
import Addmoreproduct from '../Component/Addmoreproduct';
import notify from '../../Component/utiles/Notification';


const Editproduct = ({ setProductID, categoryoption, setLoading, sizes, colours, fields, setFields, mainproduct, setMainproduct, varientsdata, variants, setVariants }) => {

    const { id } = useParams();
    const navigate = useNavigate();
    const editProduct = true;

    const baseUrl = process.env.REACT_APP_BASEURL

    const [more, setMore] = useState(false);
    const [colour, setColour] = useState('');
    const [stockStatus, setStockStatus] = useState('');

    // console.log(variants)

    // console.log(existingImages)

    useEffect(() => {
        setProductID(id)
        if (mainproduct) {
            setColour(mainproduct.colour);
            setStockStatus(mainproduct.stock_status)
        } else {
            setColour('');
        }
    }, [mainproduct]);

    useEffect(() => {
        if (varientsdata.length > 0) {
            setMore(true)
        } else {
            setMore(false)
        }
    }, [varientsdata]);


    const [editctg, setEditctg] = useState(false);

    const [category, setCategory] = useState('Select Category');
    const [categoryID, setCategoryID] = useState('');

    const [category_2, setCategory_2] = useState('Select Category');
    const [subcategoryID, setSubcategoryID] = useState('');
    const [subcategoryoption, setSubcategoryoption] = useState([]);

    const [category_3, setCategory_3] = useState('Select Category');
    const [subcategory3ID, setSubcategory3ID] = useState('');
    const [subcategoryoption3, setSubcategoryoption3] = useState([]);


    const [category_4, setCategory_4] = useState('Select Category');
    const [subcategory4ID, setSubcategory4ID] = useState('');
    const [subcategoryoption4, setSubcategoryoption4] = useState([]);

    const ctgoptions = [
        { label: 'Not Found Category' },
    ];

    const handleColorSelect = (selectedColor) => {
        setColour(selectedColor);
        setMainproduct(prevProduct => ({
            ...prevProduct,
            colour: selectedColor
        }));
    };

    const handlestockstatusSelect = (stockstatus) => {
        setStockStatus(stockstatus);
        setMainproduct(prevProduct => ({
            ...prevProduct,
            stock_status: stockstatus
        }));
    };


    useSubcategories(
        `${baseUrl}/subCategories`,
        { category_id: categoryID },
        setSubcategoryoption,
        ctgoptions,
        'sub_categories_array',
        categoryID,
        'sub_category_1_id',
        'sub_category_1_name',
        setLoading,
    );

    useSubcategories(
        `${baseUrl}/subSubCategories`,
        { sub_category_1: subcategoryID },
        setSubcategoryoption3,
        ctgoptions,
        'sub_sub_categories_array',
        subcategoryID,
        'sub_category_2_id',
        'sub_category_2_name',
        setLoading,
    );


    useSubcategories(
        `${baseUrl}/subSubSubCategories`,
        { sub_category_2: subcategory3ID },
        setSubcategoryoption4,
        ctgoptions,
        'sub_sub_sub_categories_array',
        subcategory3ID,
        'sub_category_3_id',
        'sub_category_3_name',
        setLoading,
    );

    const stockStatusOptions = [
        { label: 'Live' },
        { label: 'Hold' },
    ];

    const editclose = () => {
        setEditctg(false);
    }

    const handleselectcategory = (val) => {
        const selectedCategory = categoryoption.find(option => option.label === val);
        if (selectedCategory) {
            setCategory_2('Select Category')
            setCategory_3('Select Category')
            setCategory_4('Select Category')
            setCategory(selectedCategory.label)
            setCategoryID(selectedCategory.id);
            setMainproduct(prevProduct => ({
                ...prevProduct,
                category_name: selectedCategory.label,
                category_id: selectedCategory.id,
                sub_category_1_name: null,
                sub_category_2_name: null,
                sub_category_3_name: null,
                sub_category_1_id: null,
                sub_category_2_id: null,
                sub_category_3_id: null
            }));
        }
    };

    const handleselectsubcategory = (val) => {
        const selectedsubCategory = subcategoryoption.find(option => option.label === val);
        if (selectedsubCategory) {
            setCategory_3('Select Category')
            setCategory_2(selectedsubCategory.label)
            setSubcategoryID(selectedsubCategory.id);
            setMainproduct(prevProduct => ({
                ...prevProduct,
                sub_category_1_name: selectedsubCategory.label,
                sub_category_1_id: selectedsubCategory.id,
                sub_category_2_name: null,
                sub_category_3_name: null,
                sub_category_2_id: null,
                sub_category_3_id: null
            }));
        }
    };
    const handleselectsubcategory3 = (val) => {
        const selectedsubCategory3 = subcategoryoption3.find(option => option.label === val);
        if (selectedsubCategory3) {
            setCategory_4('Select Category')
            setCategory_3(selectedsubCategory3.label)
            setSubcategory3ID(selectedsubCategory3.id);
            setMainproduct(prevProduct => ({
                ...prevProduct,
                sub_category_2_name: selectedsubCategory3.label,
                sub_category_2_id: selectedsubCategory3.id,
                sub_category_3_name: null,
                sub_category_3_id: null
            }));
        }
    };

    const handleselectsubcategory4 = (val) => {
        const selectedsubCategory4 = subcategoryoption4.find(option => option.label === val);
        if (selectedsubCategory4) {
            setCategory_4(selectedsubCategory4.label)
            setSubcategory4ID(selectedsubCategory4.id);
            setMainproduct(prevProduct => ({
                ...prevProduct,
                sub_category_3_name: selectedsubCategory4.label,
                sub_category_3_id: selectedsubCategory4.id,
            }));
        }
    };


    const value = 'Product Color Varient';


    const handleupdateproduct = async () => {

        let newVarient = more ? variants : null;

        if (editctg) {
            if (category === 'Select Category') {
                notify('danger', 'Error', 'Please Select Category 1');
                return;
            }
            if (category_2 === 'Select Category') {
                if (subcategoryoption[0].label !== 'Not Found Category') {
                    notify('danger', 'Error', 'Please Select Category 2');
                    return;
                }
            }
            if (category_3 === 'Select Category') {
                if (subcategoryoption3[0].label !== 'Not Found Category') {
                    notify('danger', 'Error', 'Please Select Category 3');
                    return;
                }
            }
            if (category_4 === 'Select Category') {
                if (subcategoryoption4[0].label !== 'Not Found Category') {
                    notify('danger', 'Error', 'Please Select Category 4');
                    return;
                }
            }
        }
        const requiredFields = [
            { field: mainproduct.product_name, message: 'Please Enter Product Name' },
            { field: mainproduct.sub_text, message: 'Please Enter Sub Text' },
            { field: mainproduct.description, message: 'Please Enter Product Description' },
            { field: mainproduct.net_weight, message: 'Please Enter Net Weight' },
            { field: mainproduct.discount, message: 'Please Enter Discount' },
            // { field: mainproduct.manage_by, message: 'Please Select The Delivery Type' },
        ];

        for (let { field, message } of requiredFields) {
            if (!field) {
                notify('danger', 'Error', message);
                return false;
            }
        }

        if (mainproduct.discount < 1 || mainproduct.discount > 100) {
            notify('danger', 'Error', 'Discount should be between 1 and 100');
            return false;
        }
        // if (mainproduct.brand_name === 'Select Brand') {
        //     notify('danger', 'Error', 'Please Select a Brand');
        //     return false;
        // }
        if (mainproduct.stock_status === 'Select Stock Status') {
            notify('danger', 'Error', 'Please Select Stock Status');
            return false;
        }
        if (mainproduct.colour === 'select color') {
            notify('danger', 'Error', 'Please Select a Colour');
            return false;
        }
        for (let entry of fields) {
            if (!entry.field) {
                notify('danger', 'Error', 'Please Enter The Field Name');
                return false;
            }
            if (!entry.value) {
                notify('danger', 'Error', `Please Enter the Value of ${entry.field} This Field`);
                return false;
            }
        }
        if (more) {
            const colorSet = new Set();

            for (let entry of variants) {
                if (entry.var_images.length == 0) {
                    notify('danger', 'Error', `Please upload at least one image of ${entry.color} color variant`);
                    return false;
                }

                if (!entry.color) {
                    notify('danger', 'Error', 'Please select the Color');
                    return false;
                }

                if (colorSet.has(entry.color)) {
                    notify('danger', 'Error', 'Duplicate colors found in variants. Please select different colors.');
                    return false;
                } else {
                    colorSet.add(entry.color);
                }

                if (!entry.price) {
                    notify('danger', 'Error', `Please Enter the Price of ${entry.color} color variant`);
                    return false;
                }

                if (!entry.sizes || entry.sizes.length === 0) {
                    notify('danger', 'Error', 'Please add at least one size');
                    return false;
                }

                for (let sizeEntry of entry.sizes) {
                    if (!sizeEntry.size || sizeEntry.size.trim() === '') {
                        notify('danger', 'Error', `Please select the Size of ${entry.color} color variant`);
                        return false;
                    }
                    if (sizeEntry.quantity <= 0) {
                        notify('danger', 'Error', `Quantity must be greater than zero of ${entry.color} color variant`);
                        return false;
                    }
                }
            }
        }

        setLoading(true);
        try {

            const response = await axios.post(`${baseUrl}/update_product`,
                {
                    product_info: mainproduct,
                    varient_data: newVarient,
                    add_info: fields
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
            if (response.data.status) {
                notify('success', 'Success', `Product Update Successfully`);
                navigate('../products')
            } else {
                navigate('../products')
                notify('danger', 'Error', `${response.data.message}`);
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    const handleAddMorefields = () => {
        setFields([...fields, { product_about_id: null, field: '', value: '' }]);
    };

    const handleRemovefields = (index) => {
        const newFields = fields.filter((_, i) => i !== index);
        setFields(newFields);
    };

    const handleInputChangefields = (index, event) => {
        const newFields = [...fields];
        newFields[index][event.target.name] = event.target.value;
        setFields(newFields);
    };


    return (
        <>
            <h1 className="xl:text-2xl md:text-lg text-xl mt-3 font-semibold">Edit Product </h1>
            <div className="flex lg:flex-row flex-col  gap-5 lg:m-5 md:m-2">
                <div className="lg:w-6/12 w-full bg-white rounded-lg p-6">
                    <div className='mb-3'>
                        <div className="flex flex-row justify-between place-items-center">
                            <span className="text-lg font-medium">Category</span>
                            {!editctg &&
                                <button className='bg-black text-nowrap text-white lg:text-base text-sm rounded-lg px-2 h-fit w-fit py-1' onClick={(e) => setEditctg(true)}>Edit</button>
                            }
                            {editctg &&
                                <div className="flex flex-row gap-3">
                                    <button className='bg-red-500 text-nowrap text-white lg:text-base text-sm rounded-lg px-2 h-fit w-fit py-1' onClick={editclose}>close</button>
                                </div>
                            }
                        </div>
                        {!editctg &&
                            <div className="flex flex-row gap-2 place-items-center mt-1">
                                {mainproduct.category_name &&
                                    <span className='text-lg font-medium'>{mainproduct.category_name}</span>
                                }
                                {mainproduct.sub_category_1_name &&
                                    <span className='-rotate-90'>
                                        <HiOutlineArrowSmDown />
                                    </span>
                                }
                                {mainproduct.sub_category_1_name &&
                                    <span className='text-lg font-medium'>{mainproduct.sub_category_1_name}</span>
                                }
                                {mainproduct.sub_category_2_name &&
                                    <span className='-rotate-90'>
                                        <HiOutlineArrowSmDown />
                                    </span>
                                }
                                {mainproduct.sub_category_2_name &&
                                    <span className='text-lg font-medium'>{mainproduct.sub_category_2_name}</span>
                                }
                                {mainproduct.sub_category_3_name &&
                                    <span className='-rotate-90'>
                                        <HiOutlineArrowSmDown />
                                    </span>
                                }
                                {mainproduct.sub_category_3_name &&
                                    <span className='text-lg font-medium'>{mainproduct.sub_category_3_name}</span>
                                }
                            </div>
                        }
                        {editctg &&
                            <div className="w-full rounded-lg  grid md:grid-cols-2 grid-cols-1 gap-5">
                                <Dropdown
                                    label=""
                                    options={categoryoption}
                                    search={true}
                                    selectedValues={category}
                                    onSelect={handleselectcategory}
                                    onRemove={() => { }}
                                    isMultiple={false}
                                />
                                {category !== 'Select Category' && subcategoryoption[0].label !== 'Not Found Category' && (
                                    <Dropdown
                                        label=""
                                        options={subcategoryoption}
                                        search={true}
                                        selectedValues={category_2}
                                        onSelect={handleselectsubcategory}
                                        onRemove={() => { }}
                                        isMultiple={false}
                                    />
                                )}
                                {category_2 !== 'Select Category' && subcategoryoption3[0].label !== 'Not Found Category' && (
                                    <Dropdown
                                        label=""
                                        options={subcategoryoption3}
                                        search={true}
                                        selectedValues={category_3}
                                        onSelect={handleselectsubcategory3}
                                        onRemove={() => { }}
                                        isMultiple={false}
                                    />
                                )}
                                {category_3 !== 'Select Category' && subcategoryoption4[0].label !== 'Not Found Category' && (
                                    <Dropdown
                                        label=""
                                        options={subcategoryoption4}
                                        selectedValues={category_4}
                                        search={true}
                                        onSelect={handleselectsubcategory4}
                                        onRemove={() => { }}
                                        isMultiple={false}
                                    />
                                )}
                            </div>
                        }
                    </div>
                    <div className=''>
                        <span className="text-lg font-medium">Product name </span>
                        <input
                            className="w-full mt-2 mb-1 rounded-lg py-2 focus:ring-0 focus:border-black border-gray-300"
                            placeholder="Enter Product Name"
                            type="text"
                            name='product_name'
                            value={mainproduct.product_name || ''}
                            onChange={(e) => setMainproduct({ ...mainproduct, product_name: e.target.value })}
                        />
                        <span className="text-gray-500">
                            Do not exceed 20 characters when entering the product name.
                        </span>
                    </div>
                    <div className="mt-4">
                        <span className="text-lg font-medium">Sub text</span>
                        <input
                            className="w-full mt-2 mb-1 py-2 rounded-lg focus:ring-0 focus:border-black border-gray-300"
                            placeholder="Enter Sub Text"
                            type="text"
                            name='sub_text'
                            value={mainproduct.sub_text || ''}
                            onChange={(e) => setMainproduct({ ...mainproduct, sub_text: e.target.value })}
                        />
                    </div>
                    <div className="grid xl:grid-cols-3 md:grid-cols-2 mt-5 gap-5">
                        <div className="">
                            <span className="text-lg font-medium">Discount ( % )</span>
                            <input
                                className="w-full mt-2 mb-1 py-2 rounded-lg no-arrows focus:ring-0 focus:border-black border-gray-300"
                                placeholder="Enter Discount"
                                type="number"
                                name='discount'
                                value={mainproduct.discount || ''}
                                onChange={(e) => setMainproduct({ ...mainproduct, discount: e.target.value })}
                            />
                        </div>
                        <div className="">
                            <span className="text-lg font-medium">Net Weight (gms)</span>
                            <input
                                className="w-full mt-2 mb-1 py-2 rounded-lg no-arrows focus:ring-0 focus:border-black border-gray-300"
                                placeholder="Enter Discount"
                                type="number"
                                name='net_weight'
                                value={mainproduct.net_weight || ''}
                                onChange={(e) => setMainproduct({ ...mainproduct, net_weight: e.target.value })}
                            />
                        </div>
                        <div className="">
                            <Dropdown
                                label="Stock status"
                                options={stockStatusOptions}
                                selectedValues={stockStatus}
                                onSelect={handlestockstatusSelect}
                                onRemove={() => { }}
                                isMultiple={false}
                            />
                        </div>
                    </div>
                    {/*
                    <div className="w-full mt-4">
                        <Dropdown
                            label="Brand"
                            options={brand_options}
                            selectedValues={barndValue}
                            onSelect={setBarndValue}
                            onRemove={() => { }}
                            isMultiple={false}
                        />

                    </div>
                    */}
                </div>
                <div className='lg:w-6/12 w-full bg-white rounded-lg p-6'>
                    <div className=''>
                        <span className="text-lg font-medium">Description</span>
                        <textarea className='w-full rounded-lg mt-2 focus:border-black border-gray-300 focus:ring-0' rows={8} name="description"
                            value={mainproduct.description || ''}
                            onChange={(e) => setMainproduct({ ...mainproduct, description: e.target.value })}></textarea>
                    </div>
                    <div className={`flex flex-col mt-2 md:col-span-2 xl:col-span-1 ${fields.length > 3 ? 'md:h-64 h-56' : 'h-full'}  overflow-hidden overflow-y-auto`}>
                        <div className="flex flex-row justify-between mb-2 sticky top-0 bg-white">
                            <span className="text-lg font-medium">Additional Information</span>
                            <button className='bg-black text-white px-2 py-1 rounded-md capitalize' onClick={handleAddMorefields}>Add More</button>
                        </div>
                        {fields.map((list, index) => (
                            <div className="flex flex-row gap-3 mb-3" key={index}>
                                <div className="">
                                    <span className="text-base font-medium">Filed</span>
                                    <input
                                        className="w-full mt-2 mb-1 py-1 md:py-2 rounded-lg focus:ring-0 focus:border-black border-gray-300"
                                        placeholder="Enter Filed"
                                        type="text"
                                        name='field'
                                        value={list.field}
                                        onChange={(e) => handleInputChangefields(index, e)}
                                    />
                                </div>
                                <div className="">
                                    <span className="text-base font-medium">Value</span>
                                    <input
                                        className="w-full mt-2 mb-1 py-1 md:py-2 rounded-lg focus:ring-0 focus:border-black border-gray-300"
                                        placeholder="Enter Value"
                                        type="text"
                                        name='value'
                                        value={list.value}
                                        onChange={(e) => handleInputChangefields(index, e)}
                                    />
                                </div>
                                {index != 0 &&
                                    <button
                                        className="bg-red-500 text-white px-2 py-2 h-fit rounded-md capitalize mt-8"
                                        onClick={() => handleRemovefields(index)}
                                    >
                                        Remove
                                    </button>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {more &&
                <div className="flex lg:flex-row flex-col lg:gap-9 gap-5 lg:m-5 md:m-2">
                    <div className="w-full bg-white rounded-lg p-6">
                        <Addmoreproduct edit={editProduct} value={value} sizeOptions={sizes} colourOptions={colours} variants={variants} setVariants={setVariants} setLoading={setLoading} />
                        <div className='mt-20 flex justify-evenly items-center'>
                            <button className='text-white bg-black px-5 text-base py-2 hover:text-black hover:bg-white hover:border border rounded-lg hover:border-black transition-all duration-200' onClick={handleupdateproduct}>Update Product</button>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default Editproduct