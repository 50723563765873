import React, { useEffect, useState } from 'react'
import influ1 from '../Assets/Images/image.png'
import product from '../Assets/Images/ctg_shose.png'
import { FaStar } from "react-icons/fa6";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper/modules';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { UserState } from '../../Context/userProvider';


const Influencers = ({ setLoading }) => {

    const baseUrl = process.env.REACT_APP_BASEURL;
    const [data, setData] = useState([])

    const { countryCode } = UserState();


    useEffect(() => {
        const getAllProducts = async () => {
            setLoading(true);
            try {
                const response = await axios.post(`${baseUrl}/influencer_bag_data`, {
                    countryCode: countryCode
                });
                if (response.data.status) {
                    setData(response.data.bags);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };
        getAllProducts();
    }, [countryCode]);

    const extractedProducts = data.flatMap((bag) =>
        bag.products.map((product) => ({
            product_name: product.productData.product_name,
            discount: product.productData.discount,
            net_weight: product.productData.net_weight,
            product_id: product.product_variant.product_id,
            product_img: product.product_variant.product_img,
            price: product.product_variant.price,
            total_amount: product.product_variant.total_amount,
            symbol: product.product_variant.symbol,
            user_image: bag.influencerData.user_image || influ1,
            review_avg: product.review_avg,
            review_count: product.review_count,
        }))
    );


    return (
        <>
            <div className="container mx-auto px-4 py-8">
                <div className='flex justify-between mb-6'>
                    <h1 className="text-2xl md:text-3xl font-semibold text-gray-900 capitalize">our influencers</h1>
                    {/* <Link to='/all-categories' className='hover-effect text-md md:text-lg text-gray-400 font-medium lg:px-3 md:px-3 px-4'>View All</Link> */}
                </div>
                <Swiper
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    slidesPerView={4}
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    loop={true}
                    modules={[Autoplay]}
                    breakpoints={{
                        200: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                        },
                    }}
                    className="mySwiper rounded-lg" data-aos="fade-up">
                    {extractedProducts.map((list, index) => (
                        <SwiperSlide key={index} className="shadow-xl md:w-1/4 w-1/2 md:h-[50vh] h-[40vh] rounded-lg overflow-hidden  mb-10" >
                            <Link to={`/product/${list.product_id}`} className=''>
                                <div className="h-3/4 relative">
                                    <img src={list.user_image} alt="" className='h-full w-full' />
                                    <img src={list.product_img} alt="" className='absolute shadow-xl md:-bottom-9 -bottom-6 left-5 rounded-lg md:w-20 md:h-20 w-14 h-14 bg-white' />
                                </div>
                                <div className="h-1/4 md:pt-12 pt-8 md:px-5 px-3 space-y-1">
                                    <h1 className='md:text-lg text-base font-semibold line-clamp-1'>{list.product_name}</h1>
                                    <div className="flex flex-row place-items-center gap-3 justify-between">
                                        <div className=" flex-row flex gap-2">
                                            <span className='font-medium md:text-base text-sm'>{list.symbol}&nbsp;{list.price}</span>
                                            <span className='font-medium md:text-base text-sm line-through text-neutral-400'>{list.symbol ? list.symbol : '$'}&nbsp;{list.total_amount}</span>
                                        </div>
                                        <div className='flex flex-row place-items-center gap-1 pb-0 mb-0 md:text-base text-sm font-normal'>
                                            <FaStar className='text-yellow-300 md:text-xl text-lg' />
                                            <span className='md:pt-2'>{list.review_avg}</span>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </>
    )
}

export default Influencers