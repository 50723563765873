import React from 'react';
import { Link } from 'react-router-dom';
import Card from './Card';

const Items = ({ toggleModal, productData }) => {
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center justify-between mb-8">
        <div className="flex flex-col">
          <h2 className="text-2xl md:text-3xl font-semibold text-gray-900">
            New Arrivals!
          </h2>
          <p className="mt-1 text-sm text-gray-500 hidden md:block">
            Discover our latest collection of trendsetting products
          </p>
        </div>
        <Link 
          to="/new-arrivals"
          className="inline-flex items-center gap-2 text-sm font-medium text-gray-900 hover:text-gray-700 transition-colors"
        >
          View All
          <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </Link>
      </div>

      <div 
        className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 md:gap-6 lg:gap-8"
        data-aos="fade-up"
      >
        {productData.slice(0, 10).map(product => (
          <Card
            key={product.product_id}
            product={product}
            path={`/product/${product.product_id}`}
            toggleModal={toggleModal}
          />
        ))}
      </div>
    </div>
  );
};

export default Items;