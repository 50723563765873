import React, { useEffect, useState } from 'react'
import ProductImage from '../../Component/Assets/Images/shoe.png';
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from 'react-router-dom';
import Dropdown from '../../Component/utiles/Dropdown';
import Pagination from '../../Component/utiles/Pagination';
import { IoSearchSharp } from "react-icons/io5";
import { UserState } from '../../Context/userProvider';
import axios from 'axios';
import { TbArrowsExchange2 } from 'react-icons/tb';
import notify from '../../Component/utiles/Notification';
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { FaEye } from "react-icons/fa";
import Productviewmodal from '../Component/Productviewmodal';
import { FileInput, Label } from "flowbite-react";
import { FaTimes } from 'react-icons/fa';
import { MdCloudDownload } from "react-icons/md";
import { AiOutlineDelete } from 'react-icons/ai';
import { HiMiniRocketLaunch } from "react-icons/hi2";


const ProductManage = ({ setLoading, clickProductID, varientsdata, mainproduct, variants, existingImages }) => {

  const productDATA = {
    varientsdata,
    mainproduct,
    variants,
    existingImages,

  };


  const { user, logout } = UserState();

  const [searchTerm, setSearchTerm] = useState('');
  const [products, setproducts] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const baseUrl = process.env.REACT_APP_BASEURL

  const role = user.role;
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const allproducts = async () => {
      setLoading(true)
      try {
        const response = await axios.post(`${baseUrl}/get_products_list`,
          { role: role, user_id: user.user_id },
          {
            headers: {
              'Content-Type': 'application/json',
            }
          });
        if (response.data.status) {
          const validProducts = response.data.products.filter(
            (product) => product && Object.keys(product).length > 0
          );
          setproducts(validProducts);
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false)
    };
    allproducts();
  }, [refresh]);

  const perpageoption = [
    { label: '5' }, { label: '10' }, { label: '15' }, { label: '20' }
  ];

  const total_products = products.filter(list => list.status === 'Active').length;

  const searchResults = products.filter(productitem =>
    // productitem.product_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    // productitem.category_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    productitem.product_id.toString().toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [categoryvalue, setcategoryValue] = useState('all category');

  const getUniqueCategories = (items) => {

    if (!items) return [];
    const categories = new Set(items
      .filter(item => item.category_name)
      .map(item => item.category_name)
    );

    return Array.from(categories);
  };

  const ctgoption = [{ label: 'all category', value: 'all category' }, ...getUniqueCategories(products).map(category => ({
    label: category,
    value: category
  }))];


  const [itemsPerPage, setItemsPerPage] = useState(perpageoption[0].label);
  const [currentPage, setCurrentPage] = useState(1);

  const filteredItems = categoryvalue === 'all category' ? searchResults : searchResults.filter(item => item.category_name === categoryvalue);

  const itemsToDisplay = searchTerm ? searchResults : filteredItems;


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = itemsToDisplay.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(itemsToDisplay.length / itemsPerPage);

  useEffect(() => {
    if (searchTerm) {
      setCurrentPage(1);
    }
  }, [searchTerm]);

  const handleupdate = async (e, status) => {
    setLoading(true);
    let newStatus;
    if (status === 'Active') {
      newStatus = 'Inactive'
    } else {
      newStatus = 'Active'
    }
    try {
      const response = await axios.post(`${baseUrl}/update_product_status`, {
        product_id: e,
        status: newStatus,
      });

      if (response.data.status) {
        notify('success', 'Status Update', `Product status changed to ${newStatus}`);
        setRefresh(prev => prev + 1);
        setOpenModal(false)
      } else {
        notify('danger', 'Error', 'Failed to update Product status');
      }
    } catch (error) {
      notify('danger', 'Error', 'Something went wrong!');
    }
    setLoading(false);
  }


  const getStatusClass = (stockStatus) => {
    switch (stockStatus.toLowerCase()) {
      case 'in stock':
      case 'active':
      case 'live':
        return 'bg-green-200 text-green-600 text-xs';
      case 'out of stock':
      case 'inactive':
      case 'hold':
        return 'bg-red-200 text-red-500 text-xs';
      default:
        return 'bg-gray-400 text-white text-xs';
    }
  };

  const [edit, setEdit] = useState(false);

  const handleClick = (product_id) => {
    setOpenModal(true);
    clickProductID(product_id);
    setEdit(true)
  };


  return (
    <>
      <h1 className='xl:text-2xl md:text-lg text-xl mt-3 font-semibold'>Product Management</h1>
      <div className='w-full bg-white rounded-lg mt-5 md:p-6 p-3'>
        <div className="relative flex md:hidden w-full ">
          <input
            type="text"
            placeholder="Search Here"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="bg-gray-200 w-full border-0 rounded-full px-4 py-2 pr-10 focus:outline-none focus:border-gray-400 focus:ring-2 focus:ring-gray-400"
          />
          <IoSearchSharp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-black cursor-pointer" />
        </div>
        <div className='flex flex-row place-items-center justify-between my-5  pe-0'>
          <div className='flex flex-row items-center md:w-40 w-24  gap-2'>
            <span className='text-nowrap mt-2 lg:block hidden text-sm capitalize'>items per page</span>
            <div className='w-16'>
              <Dropdown
                label=""
                options={perpageoption}
                selectedValues={itemsPerPage}
                onSelect={setItemsPerPage}
                onRemove={() => { }}
                isMultiple={false}
              />
            </div>
          </div>
          <div className='flex flex-row items-center md:w-64 w-36'>
            <div className='w-full'>
              <Dropdown
                label=""
                options={ctgoption}
                selectedValues={categoryvalue}
                onSelect={setcategoryValue}
                onRemove={() => { }}
                isMultiple={false}
              />
            </div>
          </div>
          <div className="relative hidden md:flex md:w-5/12 ms-5">
            <input
              type="text"
              placeholder="Search Here"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="bg-gray-200 w-full border-0 rounded-full px-4 py-2 pr-10 focus:outline-none focus:border-gray-400 focus:ring-2 focus:ring-gray-400"
            />
            <IoSearchSharp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-black cursor-pointer" />
          </div>
          <span className='text-base font-normal ms-4'>(Active Product -{total_products})</span>
          {(user?.role === 'superadmin' || user?.role === 'manager' || user?.role === 'supervisor') ? (
            <Link className='text-white flex justify-center items-center font-semibold text-nowrap bg-black ms-5 md:px-5 md:text-sm text-sm md:py-3 py-2 px-3 
           hover:text-black hover:bg-white hover:border border rounded-lg hover:border-black transition-all duration-200 capitalize'
            >Download CSV</Link>
          ) : (
            <Link to='add-product' className='text-white flex justify-center items-center font-semibold text-nowrap bg-black ms-5 md:px-5 md:text-sm text-sm md:py-3 py-2 px-3 
            hover:text-black hover:bg-white hover:border border rounded-lg hover:border-black transition-all duration-200 capitalize'
            > Add New Product</Link>
          )}
        </div>
        <div className='overflow-hidden overflow-x-auto mt-6'>
          <table className="lg:w-full md-w[100%]  table bg-white">
            <thead className="bg-gray-100 rounded-full text-nowrap">
              <tr>
                <th className="py-4 px-4 text-left capitalize">Product</th>
                <th className="py-4 px-4 text-left capitalize">Product ID</th>
                <th className="py-4 px-4 text-left capitalize">category</th>
                <th className="py-4 px-4 text-left capitalize">Price</th>
                <th className="py-4 px-4 text-left capitalize">Quantity</th>
                <th className="py-4 px-4 text-left capitalize">Approve By Alas</th>
                <th className="py-4 px-4 text-left capitalize">Stock</th>
                <th className="py-4 px-4 text-left capitalize">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map(item => (
                <tr key={item.product_id} className="border-b border-gray-200 hover:bg-gray-200 transition-all duration-200 ease-in-out">
                  <td className="py-4 px-4 flex items-center gap-4">
                    <img src={item.product_img} alt={item.product_name} className="w-16 h-16 mr-4 bg-gray-100 rounded-lg" />
                    <span className='text-nowrap pe-10 truncate md:pe-20 lg:pe-0 text-sm'>{item.product_name}</span>
                  </td>
                  <td className="py-4 px-4">{item.product_id}</td>
                  <td className="py-4 px-4 text-nowrap">{item.category_name}</td>
                  <td className="py-4 px-4 text-nowrap">$ {item.total_amount}</td>
                  <td className="py-4 px-4">
                    <div className="flex items-center">
                      <span className="px-2 mx-3">{item.quantity}</span>
                    </div>
                  </td>
                  <td className="py-4 px-4">
                    <span className={`px-2 py-1  rounded-lg capitalize font-semibold ${getStatusClass(item.status)}`}>
                      {item.status}
                    </span>
                  </td>
                  <td className="py-4 px-4 text-nowrap">
                    <span className={`px-2 py-1  rounded-lg capitalize font-semibold ${getStatusClass(item.stock_status ? item.stock_status : 'Null')}`}>
                      {item.stock_status ? item.stock_status : 'null'}
                    </span>
                  </td>
                  <td className="p-4">
                    <div className='flex flex-row items-center gap-5 text-xl'>

                      {(user?.role === 'supplier') && (
                        <>
                          <Link to={"edit-product/" + item.product_id} onClick={() => handleClick(item.product_id)} className='cursor-pointer hover:scale-110 duration-200 transition-all px-3 py-2 text-lg rounded-md bg-neutral-300'>
                            <FiEdit />
                          </Link>
                          {item.boost &&
                            <Link className='cursor-pointer hover:scale-110 duration-200 transition-all px-3 py-2 text-lg rounded-md bg-neutral-300'>
                              <HiMiniRocketLaunch />
                            </Link>
                          }
                        </>
                      )}
                      {(user?.role === 'superadmin' || user?.role === 'manager') && (
                        <div className="flex flex-row gap-3">
                          <div className='px-3 cursor-pointer hover:scale-110 duration-200 transition-all py-2 text-lg rounded-md bg-neutral-300' onClick={() => handleClick(item.product_id)}>
                            <FaEye />
                          </div>
                          <div className='px-3 cursor-pointer hover:scale-110 duration-200 transition-all py-2 text-xl rounded-md bg-neutral-300' onClick={(e) => handleupdate(item.product_id, item.status)}>
                            <TbArrowsExchange2 />
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between items-center mt-4">
          <div>
            <span>Page {currentPage} of {totalPages}</span>
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
      <Productviewmodal {...productDATA} type={'product'} setLoading={setLoading} openModal={openModal} setOpenModal={setOpenModal} handleupdate={handleupdate} />
    </>
  );
};

export default ProductManage;
