import React, { useState } from 'react'
import Pagination from '../../Component/utiles/Pagination'
import img from '../../Component/Assets/Images/shoe.png';
import { FaCaretUp } from "react-icons/fa6";


const Overview = ({ overViewdata, currentItems, currentPage, totalPages, setCurrentPage }) => {


    return (
        <>
            <div className="">
                <div className="grid grid-cols-2 md:grid-cols-4  gap-5">
                    {overViewdata.map((list, index) => (
                        <div className="w-full flex flex-col gap-3 border rounded-lg border-[#C0BDBD] p-2 md:p-5" key={index}>
                            <h1 className='md:text-xl text-base font-normal'>{list.title}</h1>
                            <div className="flex flex-row place-items-center gap-3">
                                <h1 className='text-[#979797] font-normal md:text-xl text-base'>{list.total}</h1>
                                <div className="flex flex-row place-items-center gap-1 text-[#00CB4B]">
                                    <span className=' font-semibold md:text-sm text-xs'>{list.percentage} %</span>
                                    <FaCaretUp className='md:text-lg text-sm' />
                                </div>
                            </div>
                            <span className='text-xs font-medium'>{list.text}</span>
                        </div>
                    ))}
                </div>
                <div className="mt-7">
                    <div className='overflow-hidden overflow-x-auto mt-6'>
                        <table className="lg:w-full md-w[100%]  table bg-white">
                            <thead className="bg-gray-100 rounded-full">
                                <tr className='text-nowrap'>
                                    <th className="py-4 px-4 text-left capitalize">Product</th>
                                    <th className="py-4 px-4 text-left text-nowrap capitalize">Order ID</th>
                                    <th className="py-4 px-4 text-left capitalize">created date</th>
                                    <th className="py-4 px-4 text-left capitalize">return by</th>
                                    <th className="py-4 px-4 capitalize text-center">quantity</th>
                                    <th className="py-4 px-4 capitalize text-center">status</th>
                                    <th className="py-4 px-4 text-left capitalize">return reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map(item => (
                                    <tr key={item.id} className="border-b border-gray-200 hover:bg-gray-200 transition-all duration-200 ease-in-out">
                                        <td className="py-4 px-4 flex place-items-center gap-4">
                                            <img src={item.img} alt={item.product} className="w-16 h-16 mr-4 bg-gray-100 rounded-lg" />
                                            <span className='text-nowrap pe-10 truncate md:pe-20 lg:pe-0 text-sm'>{item.product}</span>
                                        </td>
                                        <td className="py-4 px-4 text-nowrap">{item.orderId}</td>
                                        <td className="py-4 px-4">
                                            {item.createdDate}
                                        </td>
                                        <td className="py-4 px-4">{item.returnBy}</td>
                                        <td className="py-4 px-4 ">
                                            <div className="flex justify-center place-items-center">
                                                {item.quantity}
                                            </div>
                                        </td>
                                        <td className="py-4 px-4 text-center">{item.status}</td>
                                        <td className="py-4 px-4 ">{item.returnReason}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex justify-between items-center mt-4">
                        <div>
                            <span>Page {currentPage} of {totalPages}</span>
                        </div>
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Overview