import React, { useState, useEffect, useRef } from 'react'
import Dropdown from '../../Component/utiles/Dropdown';
import { FileInput, Label } from 'flowbite-react';
import profile from '../../Component/Assets/Images/rating2.png'
import { IoCameraOutline } from "react-icons/io5";
import { UserState } from '../../Context/userProvider';
import { useCountries, useStates } from '../../Component/utiles/CountryStateCity';
import PhoneInput from 'react-phone-input-2';
import Loader from '../../Component/utiles/Loader';
import axios from 'axios';
import notify from '../../Component/utiles/Notification';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ImageUpload } from '../../Component/utiles/ImageUpload';

const Adminprofile = ({ userData, setGetuserdata, setLoading }) => {

    const { user, logout } = UserState();
    const baseURL = process.env.REACT_APP_BASEURL

    const multiple = false;
    const { images, handleFileChange, handleRemoveImage } = ImageUpload(multiple, setLoading);

    const [firstName, ...lastNameParts] = userData?.name?.split(' ') || [];
    const lastName = lastNameParts.join(' ');

    const [otpValues, setotpValues] = useState(['', '', '', '']);
    const [isLoading, setIsLoading] = useState(false);
    const [resetpass, setResetpass] = useState(false);
    const [otpstatus, setOTPstatus] = useState(false);
    const [countryID, setCountryID] = useState('');

    const [activeEdit, setActiveEdit] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [cpasswordVisible, setcPasswordVisible] = useState(false);
    const [password, setPassword] = useState('');
    const [cpassword, setCpassword] = useState('');
    const [passwordstatus, setPasswordstatus] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [counter, setCounter] = useState(0);
    const [OTP, setOTP] = useState(false);


    const typeoptions = [
        { label: 'email' },
        { label: 'mobile' },
    ];

    const [searchOption, setSearchOption] = useState([typeoptions[0].label]);

    const { countryOptions, error: countryError } = useCountries();
    const { stateOptions, error: stateError } = useStates(countryID);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        address: '',
        user_id: '',
        role: '',
        gender: '',
        country: '',
        country_name: '',
        state: '',
        state_name: '',
        city: '',
        locality: ''
    });

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const togglecPasswordVisibility = () => {
        setcPasswordVisible(!cpasswordVisible);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleEditChange = () => {
        setActiveEdit(true);
    };

    const handleResetpass = () => {
        setResetpass(true);
    };
    const handleCancelChange = () => {
        setActiveEdit(false);
    };
    const handleCancel = () => {
        setResetpass(false);
        setOTPstatus(false);
        setPasswordstatus(false);
    };

    const data = searchOption == 'email' ? userData.email : userData.mobile;

    const handleOTPsend = async () => {
        setIsLoading(true);
        try {
            const payload = {
                [searchOption]: data,
                role: userData.role,
                user_id: user?.user_id
            };
            const response = await axios.post(`${baseURL}/mobile_otp_user`,
                payload,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
            if (response.data.status) {
                notify('success', 'Success', `OTP Send on your Register ${searchOption}`);
                setOTPstatus(true);
                setResetpass(false);
                setOTP(true);
                setCounter(30);

            } else {
                notify('danger', 'Error', `${response.data.message || 'An error occurred'}`);
            }

        } catch {

        }
        setIsLoading(false);
    };

    const handleOTPverify = () => {
        setOTPstatus(false);
        setPasswordstatus(true);
    };

    const handlesubmit = () => {
        setResetpass(false);
        setOTPstatus(false);
        setPasswordstatus(false);
    };

    const genderoptions = [
        { label: 'male' },
        { label: 'female' },
    ];

    const handleSelect = (val) => {
        const selectedCountry = countryOptions.find(option => option.label === val);
        if (selectedCountry) {
            setFormData((prevInfo) => ({
                ...prevInfo,
                country: selectedCountry.id,
                country_name: selectedCountry.label,
                state: ''
            }));
            setCountryID(selectedCountry.id);
        }
    };

    const handleSelect_state = (val) => {
        const selectedState = stateOptions.find(option => option.label === val);
        if (selectedState) {
            setFormData((prevInfo) => ({
                ...prevInfo,
                state: selectedState.id,
                state_name: selectedState.label
            }));
        }
    };

    const handleupdate = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${baseURL}/updateProfile`,
                { user_info: formData },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );

            if (response.data.status === true) {
                notify('success', 'Success', `${response.data.message}`);
                setGetuserdata(prev => prev + 1);
                setActiveEdit(false);
            } else {
                notify('danger', 'Error', `${response.data.message || 'An error occurred'}`);
            }
        } catch (err) {
            notify('danger', 'Error', 'Something went wrong');

        }
        setIsLoading(false);
    }

    useEffect(() => {
        setFormData({
            firstName: firstName || '',
            lastName: lastName || '',
            email: userData.email || '',
            user_id: user.user_id || '',
            mobile: userData.mobile || '',
            address: userData.address || '',
            locality: userData.locality || '',
            gender: userData.gender || '',
            city: userData.city || '',
            role: userData.role || '',
            // country_name:userData.
        });
    }, [userData]);

    const inputRefs = useRef([]);

    inputRefs.current = [];

    const setRef = (el, index) => {
        inputRefs.current[index] = el;
    };

    const handleChange = (e, index) => {
        const value = e.target.value;
        const maxLength = e.target.maxLength;

        const newValues = [...otpValues];
        newValues[index] = value;
        setotpValues(newValues);

        if (value.length === maxLength && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1]?.focus();
        }

        if (value.length === 0 && index > 0) {
            inputRefs.current[index - 1]?.focus();
        }
    };
    // setOTP(false);

    useEffect(() => {
        let timer;
        if (counter > 0) {
            timer = setInterval(() => {
                setCounter(prevCounter => prevCounter - 1);
            }, 1000);
        } else if (counter === 0) {
            setOTP(false);
        }

        return () => {
            clearInterval(timer);
        };
    }, [counter]);


    const fileInputRef = useRef(null);

    const openimage = () => {
        fileInputRef.current.click();
    }

    useEffect(() => {
        // if (images.length > 0) {
        const fetchUserData = async () => {
            try {
                setLoading(true);
                const response = await axios.post(`${baseURL}/update_user_image`,
                    {
                        user_id: user.user_id,
                        image: images[0].url
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
                if (response.data.status) {
                    setGetuserdata(prev => prev + 1);
                    notify('success', 'Success', `${response.data.massage}`);
                } else {
                    notify('danger', 'Error', `${response.data.massage || 'An error occurred'}`);
                }
            } catch (err) {
                console.log(err);
            }
            setLoading(false)
        };
        fetchUserData();
        // }
    }, [images]);

    return (
        <>
            <h1 className='xl:text-2xl md:text-lg text-xl mt-3 font-semibold capitalize'>my profile</h1>
            <div className="flex lg:flex-row flex-col lg:gap-9 md:gap-5 gap-3 mt-4 lg:m-5 md:m-2 pb-36">
                <div className="lg:w-6/12 ">
                    <div className="w-full bg-white rounded-lg md:p-6 p-3">
                        <div className='flex flex-col'>
                            <span className="text-lg font-medium capitalize">First name</span>
                            {activeEdit ? (
                                <input
                                    className="w-full mt-2 mb-1 rounded-lg py-2  focus:ring-0 focus:border-black border-gray-300"
                                    placeholder="Enter First Name"
                                    type="text"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleInputChange}
                                />
                            ) : (
                                <span>{firstName || ''}</span>

                            )}
                        </div>
                        <div className="mt-4 flex flex-col">
                            <span className="text-lg font-medium capitalize">Last name</span>
                            {activeEdit ? (

                                <input
                                    className="w-full mt-2 mb-1 py-2  rounded-lg focus:ring-0 focus:border-black border-gray-300"
                                    placeholder="Enter Last Name"
                                    name="lastName"
                                    type="text"
                                    value={formData.lastName}
                                    onChange={handleInputChange}
                                />
                            ) : (
                                <span>{lastName || ''}</span>
                            )}
                        </div>
                        <div className="mt-4 flex flex-col">
                            <span className="text-lg font-medium capitalize">E-mail</span>
                            {activeEdit ? (
                                <input
                                    className="w-full mt-2 mb-1 py-2  rounded-lg focus:ring-0 focus:border-black border-gray-300"
                                    placeholder="Enter E-mail"
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                />
                            ) : (
                                <span>{userData?.email}</span>
                            )}
                        </div>
                        <div className="mt-4 flex flex-col">
                            <span className="text-lg font-medium capitalize">phone number</span>
                            {activeEdit ? (
                                <PhoneInput
                                    country={'in'}
                                    value={formData.mobile}
                                    countryCodeEditable={false}
                                    enableSearch={true}
                                    onChange={(val) => setFormData({ ...formData, mobile: val })}
                                    placeholder="Enter Mobile Number"
                                    inputClass="input-mobile mt-1 block border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-400 focus:ring-1"
                                />
                            ) : (
                                <span>{userData?.mobile}</span>
                            )}
                        </div>

                        <div className="grid grid-cols-2 mt-4 gap-10">
                            {activeEdit ? (
                                <div className="flex flex-col">
                                    <span className="text-lg font-medium capitalize">Gender</span>

                                    <Dropdown
                                        label=""
                                        options={genderoptions}
                                        selectedValues={formData.gender}
                                        onSelect={(val) => setFormData({ ...formData, gender: val })}
                                        onRemove={() => { }}
                                        isMultiple={false}
                                    />
                                </div>

                            ) : (
                                <div className=" flex flex-col w-full">
                                    <span className="text-lg font-medium capitalize">Gender</span>
                                    <span>{userData?.gender}</span>
                                </div>
                            )}
                            <div className="flex flex-col">
                                <span className="text-lg font-medium capitalize">address</span>
                                {activeEdit ? (
                                    <input
                                        className="w-full mt-2 mb-1 py-2  rounded-lg focus:ring-0 focus:border-black border-gray-300"
                                        placeholder="Enter Address"
                                        name='address'
                                        type="text"
                                        value={formData.address}
                                        onChange={handleInputChange}
                                    />
                                ) : (
                                    <span>{userData?.address}</span>
                                )}
                            </div>
                            <div className="flex flex-col">
                                <span className="text-lg font-medium capitalize">locality</span>
                                {activeEdit ? (
                                    <input
                                        className="w-full mt-2 mb-1 py-2  rounded-lg focus:ring-0 focus:border-black border-gray-300"
                                        placeholder="Enter Locality"
                                        name='locality'
                                        type="text"
                                        value={formData.locality}
                                        onChange={handleInputChange}
                                    />
                                ) : (
                                    <span>{userData?.locality}</span>
                                )}
                            </div>
                            {activeEdit ? (

                                <Dropdown
                                    label="country"
                                    labelSelect="Country"
                                    isMultiple={false}
                                    search={true}
                                    options={countryOptions}
                                    selectedValues={formData.country_name}
                                    onSelect={handleSelect}
                                />
                            ) : (
                                <div className="mt-4 flex flex-col w-full">
                                    <span className="text-lg font-medium capitalize">country</span>
                                    <span>{userData?.country_name}</span>
                                </div>
                            )}
                            {activeEdit ? (
                                countryID ? (
                                    <Dropdown
                                        label="State"
                                        labelSelect="State"
                                        isMultiple={false}
                                        options={stateOptions}
                                        search={true}
                                        selectedValues={formData.state_name}
                                        onSelect={handleSelect_state}
                                    />
                                ) : (
                                    <div className="w-full flex flex-col">
                                        <span className='text-lg font-medium'>State</span>
                                        <div className="rounded-lg flex justify-start place-items-center border border-gray-300 px-2 py-2 text-gray-500 text-base  mt-2">
                                            Please select a Country
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div className="mt-4 flex flex-col w-full">
                                    <span className="text-lg font-medium capitalize">State</span>
                                    <span>{userData?.state_subdivision_name}</span>
                                </div>
                            )}
                            <div className="flex flex-col">
                                <span className="text-lg font-medium capitalize">City</span>
                                {activeEdit ? (
                                    <input
                                        className="w-full mt-2 mb-1 py-2  rounded-lg focus:ring-0 focus:border-black border-gray-300"
                                        placeholder="Enter City"
                                        type="text"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleInputChange}
                                    />
                                ) : (
                                    <span>{userData?.city}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row mt-5 justify-end gap-5">
                        {activeEdit ? (
                            <>
                                <button className='capitalize border border-neutral-300 rounded-md font-semibold text-lg text-gray-400 bg-white w-36 h-10' onClick={handleCancelChange}>cancel</button>
                                <button className='capitalize  rounded-md w-36 text-white font-semibold text-base h-10 bg-blue-500' onClick={handleupdate}>update</button>
                            </>
                        ) : (
                            <button className='capitalize  rounded-md w-36 text-white font-semibold text-base h-10 bg-blue-500' onClick={handleEditChange}>Edit</button>
                        )}
                    </div>
                </div>
                <div className='md:w-full lg:w-6/12 bg-white rounded-lg md:p-6 p-3'>
                    <div className="flex justify-center place-items-center my-10 flex-row gap-3">
                        <div className="w-fit  relative h-fit">
                            <input
                                type="file"
                                ref={fileInputRef}
                                accept="image/*"
                                className="hidden"
                                onChange={handleFileChange}
                            />
                            <img src={userData.user_image} alt="" className="h-44 w-44 rounded-full" />
                            <div className="absolute bottom-5 right-0 bg-black rounded-full h-10 w-10 flex justify-center place-items-center cursor-pointer text-white text-2xl" onClick={openimage}>
                                <IoCameraOutline />
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 border border-gray-300 capitalize rounded-lg p-3">
                        <div className="">
                            <div className={`flex ${resetpass ? 'flex-col' : 'flex-row'}  place-items-start w-full`}>
                                <h1 className='text-base font-medium text-black w-full'>password</h1>

                                {resetpass ? (
                                    <div className="flex flex-row place-items-center gap-2 w-1/2">
                                        <span className="text-base font-medium capitalize mt-2">OTP send on </span>
                                        <div className="w-3/6">
                                            <Dropdown
                                                label=""
                                                options={typeoptions}
                                                selectedValues={searchOption}
                                                onSelect={setSearchOption}
                                                onRemove={() => { }}
                                                isMultiple={false}
                                            />
                                        </div>
                                    </div>
                                ) : otpstatus ? (
                                    <h1></h1>
                                ) : !passwordstatus ? (
                                    <button className='md:block hidden capitalize border border-gray-300 rounded-md font-semibold text-lg text-black bg-white w-32 h-10' onClick={handleResetpass}>change</button>
                                ) : (
                                    <h1></h1>
                                )}
                            </div>
                            <span className='capitalize text-xs font-normal text-gray-400 mt-2'>You Can Reset or Change your password By Clicking Here</span>
                        </div>
                        <button className='capitalize border md:hidden border-gray-300 rounded-md font-semibold text-lg text-black bg-white w-32 h-10 mt-3' onClick={handleResetpass}>change</button>
                        {resetpass &&
                            <div className="flex flex-row justify-end gap-3">
                                <button className='capitalize border  border-gray-300 rounded-md font-semibold text-base text-black bg-white w-32 h-10 mt-3' onClick={handleCancel}>cancel</button>
                                <button className='capitalize border  border-gray-300 rounded-md font-semibold text-base text-white bg-black w-32 h-10 mt-3' onClick={handleOTPsend}>SEND OTP</button>
                            </div>
                        }
                        {otpstatus &&
                            <div className="">
                                <span className="text-lg font-medium capitalize">Enter OTP</span>
                                <div className="flex flex-row gap-2 w-2/6 mt-1">
                                    {[0, 1, 2, 3].map((index) => (
                                        <input
                                            key={index}
                                            type="number"
                                            maxLength={1}
                                            className='no-arrows w-full focus:ring-0 focus:border-black rounded-lg text-center'
                                            ref={(el) => setRef(el, index)}
                                            onChange={(e) => handleChange(e, index)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Backspace' && inputRefs.current[index].value.length === 0 && index > 0) {
                                                    inputRefs.current[index - 1]?.focus();
                                                }
                                            }}
                                        />
                                    ))}
                                </div>
                                <div className="flex flex-row justify-end gap-3">
                                    <button className='capitalize border  border-gray-300 rounded-md font-semibold text-base text-black bg-white w-32 h-10 mt-3' onClick={handleCancel}>cancel</button>
                                    <button className='capitalize border  border-gray-300 rounded-md font-semibold text-base text-black bg-white w-32 h-10 mt-3' onClick={handleOTPsend} disabled={OTP}>{OTP ? `${counter}s` : otpstatus ? 'Resend' : 'Send OTP'}</button>
                                    <button className='capitalize border  border-gray-300 rounded-md font-semibold text-base text-white bg-black w-32 h-10 mt-3' onClick={handleOTPverify}>Verify OTP</button>
                                </div>
                            </div>
                        }
                        {passwordstatus &&
                            <div className="space-y-3 mt-3">
                                <div className="flex flex-col gap-1">
                                    <span className="text-lg font-medium capitalize">New Password</span>
                                    <div className="flex flex-row w-full rounded-lg border place-items-center border-gray-300 overflow-hidden">
                                        <input
                                            className="w-full py-2   focus:ring-0 focus:border-black border-0"
                                            placeholder="Enter Password"
                                            type={passwordVisible ? "text" : "password"}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <div className="pe-2 cursor-pointer" onClick={togglePasswordVisibility}>
                                            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-1">
                                    <span className="text-lg font-medium capitalize">Confirm Password</span>
                                    <div className="flex flex-row w-full rounded-lg border place-items-center border-gray-300 overflow-hidden">
                                        <input
                                            className="w-full py-2   focus:ring-0 focus:border-black border-0"
                                            type={cpasswordVisible ? "text" : "password"}
                                            placeholder={`Confirm Password`}
                                            value={cpassword}
                                            onChange={(e) => setCpassword(e.target.value)}
                                        />
                                        <div className="pe-2 cursor-pointer" onClick={togglecPasswordVisibility}>
                                            {cpasswordVisible ? <FaEyeSlash /> : <FaEye />}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row justify-end gap-3">
                                    <button className='capitalize border  border-gray-300 rounded-md font-semibold text-base text-black bg-white w-32 h-10 mt-3' onClick={handleCancel}>cancel</button>
                                    <button className='capitalize border  border-gray-300 rounded-md font-semibold text-base text-white bg-black w-fit text-nowrap px-3 h-10 mt-3' onClick={handlesubmit}>Reset Password</button>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="mt-5 border border-gray-300 capitalize rounded-lg p-3">
                        <div className="">
                            <div className="flex flex-row justify-between place-items-start">
                                <h1 className='text-base font-medium text-black'>Deactivate account</h1>
                                <button className='capitalize border md:block hidden border-gray-300 rounded-md font-semibold text-lg text-red-600 bg-white w-fit px-4 h-10'>deactivate account</button>
                            </div>
                            <span className='capitalize text-xs font-normal text-gray-400'>Once you delete your account, there is no going back. Please be certain.</span>
                        </div>
                        <button className='capitalize border md:hidden mt-3 border-gray-300 rounded-md font-semibold text-lg text-red-600 bg-white w-fit px-4 h-10'>deactivate account</button>
                    </div>
                </div>
            </div >
            {isLoading && <Loader />
            }
        </>
    )
}

export default Adminprofile