import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import bannerrr from '../Assets/Images/banner-3.png'
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper/modules';
import '../Assets/css/Styles.css';
import axios from 'axios';

const Carousel_banner = () => {

    const [images, setImages] = useState([]);

  const baseUrl = process.env.REACT_APP_BASEURL


    useEffect(() => {
        axios.get(`${baseUrl}/banners`)
            .then(response => {
                const sliderBanners = response.data.slider_array || [];
                setImages(sliderBanners);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    // console.log(images);

    return (
        <>
            <div className="">
                <Swiper
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    loop={true}
                    modules={[Autoplay]}
                    className="mySwiper xl:h-[80vh]"
                >
                    {images.map((banner, index) => (
                        <SwiperSlide key={index} className=' h-full w-full'>
                            <img className='h-full w-full' src={banner.banner} alt={banner.title} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </>
    )
}

export default Carousel_banner;