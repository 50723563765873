import React, { useState, useEffect } from 'react';
import { LuSettings2 } from "react-icons/lu";
import { IoMdArrowDropdown } from "react-icons/io";
import bgImage from '../Assets/Images/banner-bg-arrivals.png';
import { Link } from 'react-router-dom';
import Filters from '../utiles/Filters';
// import productData from '../utiles/ProductsData';
import Navbar from '../LandingPage/Navbar';
import Footer from '../LandingPage/Footer';
import Allcard from '../utiles/Allcard';


const NewArrivals = ({ toggleModal, productData }) => {
    
    const [filters, setFilters] = useState({
        showInStockOnly: false,
        priceFrom: '',
        priceTo: '',
        inStockCount: 0,
        highestPrice: 0,
        selectedColors: [],
        selectedSizes: [],
        selectedCategories: [],
        selectedRatings: [],
    });

    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [uniqueColors, setUniqueColors] = useState([]);
    const [uniqueSizes, setUniqueSizes] = useState([]);
    const [uniqueCategories, setUniqueCategories] = useState([]);



    const filteredProducts = productData.filter(product => {
        const meetsStockCriteria = filters.showInStockOnly ? product.quantity > 0 : true;
        const meetsPriceCriteria = product.price >= (filters.priceFrom) && product.price <= (filters.priceTo || Infinity);
        const meetsColorCriteria = filters.selectedColors.length === 0 || product.colors.some(color => filters.selectedColors.includes(color));
        const meetsSizeCriteria = filters.selectedSizes.length === 0 || product.sizes.some(size => filters.selectedSizes.includes(size));
        const meetsCategoryCriteria = filters.selectedCategories.length === 0 || filters.selectedCategories.includes(product.category_name);
        // const meetsRatingCriteria = filters.selectedRatings.length === 0 || filters.selectedRatings.includes(product.rating.toString());
        return meetsStockCriteria && meetsPriceCriteria && meetsColorCriteria && meetsSizeCriteria && meetsCategoryCriteria;
        //  && meetsRatingCriteria;
    });

    const onFilterChange = (newFilters) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            ...newFilters,
            inStockCount: productData.filter(product => product.quantity > 0).length,
            highestPrice: Math.max(...productData.map(product => product.price))
        }));
    };

    const toggleFilter = () => {
        setIsFilterOpen(prev => !prev);
    };

    const inStockCount = productData.filter(product => product.quantity > 0).length;
    const highestPrice = Math.max(...productData.map(product => product.price));

    useEffect(() => {
        const uniqueColorsSet = new Set();
        productData.forEach(product => {
            product.colors.forEach(color => uniqueColorsSet.add(color));
        });
        setUniqueColors(Array.from(uniqueColorsSet));
    }, []);

    useEffect(() => {
        const uniqueSizesSet = new Set();
        productData.forEach(product => {
            product.sizes.forEach(size => uniqueSizesSet.add(size));
        });
        setUniqueSizes(Array.from(uniqueSizesSet));

    }, []);

    useEffect(() => {
        const uniqueCategoriesSet = new Set();
        productData.forEach(product => {
            uniqueCategoriesSet.add(product.category_name);
        });
        setUniqueCategories(Array.from(uniqueCategoriesSet));
    }, []);

    const filterProps = {
        onFilterChange,
        showInStockOnly:filters.showInStockOnly,
        inStockCount,
        highestPrice,
        uniqueColors,
        priceFrom:filters.priceFrom,
        priceTo:filters.priceTo,
        selectedColors: filters.selectedColors,
        uniqueSizes,
        selectedSizes: filters.selectedSizes,
        uniqueCategories,
        selectedCategories: filters.selectedCategories,
        selectedRatings: filters.selectedRatings,
        toggleFilter,
    };

    return (
        <>
            <Navbar />
            <div
                className="relative bg-cover bg-center h-[500px] flex items-center justify-center opacity-90"
                style={{ backgroundImage: `url(${bgImage})` }}>
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="relative text-center text-white">
                    <h1 className="text-5xl font-bold">NEW ARRIVALS</h1>
                    <p className="mt-2">
                        <Link to="/">Home</Link> <span className="mx-2">›</span> <a href="#">shop</a>
                    </p>
                </div>
            </div>
            <div className="container mx-auto py-5 flex flex-row">
                <div
                    className={`fixed inset-0 bg-black md:hidden block bg-opacity-75 z-50 transform ${isFilterOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-100 ease-in-out`}>
                    <div className={`fixed inset-y-0 left-0 bg-white text-black w-3/4 z-50 transform ${isFilterOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}>
                        {isFilterOpen && <Filters {...filterProps} />}
                    </div>
                </div>
                <div className={`filter-section overflow-hidden md:block hidden sticky top-5 transition-all duration-700 ${isFilterOpen ? 'lg:w-3/12 md:w-3/12 xl:me-7 md:me-3 lg:ms-3 md:ms-2 xl:ms-0 W' : 'w-0'}`}>
                    {isFilterOpen && <Filters {...filterProps} />}
                </div>
                <div className={`product-section transition-all duration-700 ${isFilterOpen ? 'lg:w-9/12 md:w-9/12 ' : 'w-full'}`}>
                    <div className='flex justify-between px-2 mb-4'>
                        <button className="text-xl md:text-xl font-medium flex flex-row place-items-center text-gray-400 gap-2" onClick={toggleFilter}>
                            <LuSettings2 /> Filter By
                        </button>
                        <a href="#" className='hover-effect text-md md:text-lg text-gray-400 font-medium flex flex-row place-items-center gap-2'>
                            Sort By <IoMdArrowDropdown />
                        </a>
                    </div>
                    <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 md:gap-2" data-aos="fade-up">
                        {filteredProducts.map(product => (
                            <Allcard key={product.product_id} product={product}  path={`/product/${product.product_id}`}  toggleModal={toggleModal} />
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default NewArrivals;
