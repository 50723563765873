import React, { useState, useRef, useEffect } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';

const Dropdown = ({ label, options, selectedValues, onSelect, onRemove, isMultiple, padding, labelSelect, search, color }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');


    const toggleDropdown = () => {
        setIsOpen(prev => !prev);
    };

    const handleOptionClick = (option) => {
        if (isMultiple) {
            if (!selectedValues.includes(option)) {
                onSelect(option);
            }
        } else {
            onSelect(option);
            setIsOpen(false);
        }
    };

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const displayValues = isMultiple ? selectedValues : [selectedValues];

    const filteredOptions = options.filter(option =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );


    return (
        <div className="w-full capitalize" ref={dropdownRef}>
            <span className="text-lg font-medium capitalize">{label}</span>
            <div className="relative w-full mt-2">
                <button
                    className={`w-full px-2 ${padding ? 'py-2' : 'py-1'}  text-left bg-white border capitalize ${isOpen ? 'border-black ' : 'border-gray-300 '} rounded-lg shadow-sm text-sm focus:outline-none flex justify-between items-center`}
                    onClick={toggleDropdown}
                    aria-haspopup="listbox"
                    aria-expanded={isOpen}
                    aria-controls={`${label.replace(/\s+/g, '-').toLowerCase()}-dropdown`}
                >
                    <div className="flex flex-wrap gap-1">
                        {(!displayValues || displayValues.length === 0 || !displayValues[0]) ? (
                            <span className="block text-gray-500 truncate py-1.5">Select {labelSelect}</span>
                        ) : (
                            displayValues.map((value, index) => (
                                <span key={index} className={`flex items-center gap-1  px-2 py-1.5 rounded ${isMultiple ? 'bg-gray-200' : ''}`}>
                                    {value}
                                    {isMultiple && (
                                        <button
                                            type="button"
                                            onClick={() => onRemove(value)}
                                            className="text-red-500 hover:text-red-700"
                                        >
                                            <AiOutlineClose size={12} />
                                        </button>
                                    )}
                                </span>
                            ))
                        )}
                    </div>
                    <BsChevronDown className={`transition-transform ms-1 ${isOpen ? 'rotate-180' : ''}`} />
                </button>
                <ul
                    className={`absolute ${options.length > 5 ? 'h-52' : ''} overflow-y-auto  z-10 w-full mt-2 bg-white border border-gray-300 rounded shadow-lg transform duration-300 transition-transform origin-top ${isOpen ? 'scale-y-100 opacity-100 visible' : 'scale-y-0 opacity-0 invisible'}`}
                    role="listbox"
                    id={`${label.replace(/\s+/g, '-').toLowerCase()}-dropdown`}
                >
                    {search ? (
                        <>
                            <li className="p-2 pt sticky top-0 bg-white ">
                                <input
                                    type="text"
                                    className="w-full p-2 border border-gray-300 rounded-md focus:ring-0 focus:border-black"
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </li>
                            {filteredOptions.map((option, index) => (
                                <li
                                    key={index}
                                    role="option"
                                    className={`cursor-pointer capitalize flex items-center gap-3 p-2 ${color ? 'flex-row justify-between' : ''} text-black hover:bg-gray-200`}
                                    onClick={() => handleOptionClick(option.label)}
                                >
                                    {option.label}
                                    {color &&
                                        <span className={`h-5 w-5  rounded-full`} style={{ backgroundColor: option.hexa }}></span>
                                    }
                                </li>
                            ))}
                            {filteredOptions.length === 0 && (
                                <li className="p-2 text-center text-gray-500">Data Not Found</li>
                            )}
                        </>

                    ) : (
                        options.map((option, index) => (
                            <li
                                key={index}
                                role="option"
                                className={`cursor-pointer capitalize flex items-center ${color ? 'flex-row justify-between' : ''} gap-3 p-2 text-black hover:bg-gray-200`}
                                onClick={() => handleOptionClick(option.label)}
                            >
                                {option.label}
                                {color &&
                                    <span className={`h-5 w-5 bg-[${option.hexa}]`} style={{ backgrounnd: option.hexa }}></span>
                                }
                            </li>
                        ))
                    )}
                </ul>
            </div>
        </div>
    );
};

export default Dropdown;
