import React, { useState } from 'react';
import ProductImage from '../../Component/Assets/Images/shoe.png';
import { MdDelete } from "react-icons/md";
import Pagination from '../../Component/utiles/Pagination';
import { Link } from 'react-router-dom';


const Recentorder = () => {
    const items = [
        { id: 1, name: 'Running Shoes', price: 499.00, quantity: 2, image: ProductImage, productid: '#24868636', date: '23-7-2024' },
        { id: 2, name: 'Basketball Shoes', price: 599.00, quantity: 1, image: ProductImage, productid: '#24868637', date: '24-7-2024' },
        { id: 3, name: 'Soccer Cleats', price: 699.00, quantity: 2, image: ProductImage, productid: '#24868638', date: '25-7-2024' },
        { id: 4, name: 'Tennis Shoes', price: 399.00, quantity: 1, image: ProductImage, productid: '#24868639', date: '26-7-2024' },
        { id: 5, name: 'Hiking Boots', price: 799.00, quantity: 1, image: ProductImage, productid: '#24868640', date: '27-7-2024' },
        { id: 6, name: 'Sandals', price: 299.00, quantity: 3, image: ProductImage, productid: '#24868641', date: '28-7-2024' },
        { id: 7, name: 'Formal Shoes', price: 899.00, quantity: 2, image: ProductImage, productid: '#24868642', date: '29-7-2024' },
        { id: 8, name: 'Casual Sneakers', price: 199.00, quantity: 4, image: ProductImage, productid: '#24868643', date: '30-7-2024' },
        { id: 9, name: 'Loafers', price: 349.00, quantity: 1, image: ProductImage, productid: '#24868644', date: '31-7-2024' },
        { id: 10, name: 'Flip Flops', price: 149.00, quantity: 5, image: ProductImage, productid: '#24868645', date: '1-8-2024' },
        { id: 11, name: 'Winter Boots', price: 999.00, quantity: 1, image: ProductImage, productid: '#24868646', date: '2-8-2024' },
        { id: 12, name: 'Running Shoes', price: 499.00, quantity: 2, image: ProductImage, productid: '#24868647', date: '3-8-2024' },
        { id: 13, name: 'Basketball Shoes', price: 599.00, quantity: 1, image: ProductImage, productid: '#24868648', date: '4-8-2024' },
        { id: 14, name: 'Soccer Cleats', price: 699.00, quantity: 2, image: ProductImage, productid: '#24868649', date: '5-8-2024' },
        { id: 15, name: 'Tennis Shoes', price: 399.00, quantity: 1, image: ProductImage, productid: '#24868650', date: '6-8-2024' },
        { id: 16, name: 'Hiking Boots', price: 799.00, quantity: 1, image: ProductImage, productid: '#24868651', date: '7-8-2024' },
        { id: 17, name: 'Sandals', price: 299.00, quantity: 3, image: ProductImage, productid: '#24868652', date: '8-8-2024' },
        { id: 18, name: 'Formal Shoes', price: 899.00, quantity: 2, image: ProductImage, productid: '#24868653', date: '9-8-2024' },
        { id: 19, name: 'Casual Sneakers', price: 199.00, quantity: 4, image: ProductImage, productid: '#24868654', date: '10-8-2024' },
        { id: 20, name: 'Loafers', price: 349.00, quantity: 1, image: ProductImage, productid: '#24868655', date: '11-8-2024' },
    ];

    const itemsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(items.length / itemsPerPage);






    return (
        <>
            <div className='w-full bg-white p-6 md:mt-5 mt-3 rounded-lg'>
                <div className="flex justify-between flex-row w-full place-items-center mb-4 px-1">
                    <h1 className='md:text-lg xl:text-xl text-xl  font-semibold'>Recent orders</h1>
                    <Link to='../orders' className='capitalize text-gray-400 font-medium text-lg'>view all</Link>
                </div>
                <div className='overflow-hidden overflow-x-auto'>
                    <table className="min-w-full bg-white">
                        <thead className="bg-gray-100 rounded-xl">
                            <tr>
                                <th className="py-4 px-4 text-left">Product</th>
                                <th className="py-4 px-4 text-left">Price</th>
                                <th className="py-4 px-4 text-left">Quantity</th>
                                <th className="py-4 px-4 text-left">Product ID</th>
                                <th className="py-4 px-4 text-left">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map(item => (
                                <tr key={item.id} className="border-b border-gray-200">
                                    <td className="py-4 px-4 flex items-center gap-4">
                                        <img src={item.image} alt={item.name} className="w-16 h-16 mr-4 bg-gray-100 rounded-lg" />
                                        <span className='text-nowrap pe-16 truncate'>{item.name}</span>
                                    </td>
                                    <td className="py-4 px-4 text-nowrap">{item.price.toFixed(2)} €</td>
                                    <td className="py-4 px-4">
                                        <div className="flex items-center">
                                            <span className="px-2 mx-3">{item.quantity}</span>
                                        </div>
                                    </td>
                                    <td className="py-4 px-4 text-nowrap">{item.productid}</td>
                                    <td className="py-4 px-4 text-nowrap">{item.date}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-end mt-4">
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </div>
        </>
    );
}

export default Recentorder;
