import axios from 'axios';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { UserState } from './userProvider';
import notify from '../Component/utiles/Notification';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartCount, setCartCount] = useState(0);
  const [mainctg, setMainctg] = useState(0);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);


  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  const { user, logout } = UserState();
  const baseUrl = process.env.REACT_APP_BASEURL;


  useEffect(() => {
    const getAllProducts = async () => {
      try {
        if (cart && cart.length > 0) {
          const productPromises = cart.map(async (item) => {
            const response = await axios.post(`${baseUrl}/add_cart`, {
              product_id: item.product_id,
              customer_id: user.customer_id,
              product_varient_id: item.product_varient_id,
            });

            if (response.data.status) {
              localStorage.removeItem('cart', JSON.stringify(cart));
              // notify('success', 'Success', `${response.data.message}`);
              updateCartCount(response.data.cartCount);
            } else {
              // notify('danger', 'Error', `${response.data.message}`);
            }
          });
          await Promise.all(productPromises);
        }
      } catch (error) {
        console.error('Error during status update:', error);
        // notify('danger', 'Error', 'There was an error. Please try again.');
      }
    };

    if (user && cart.length > 0) {
      getAllProducts();
    }
  }, [user, cart, baseUrl]);

  useEffect(() => {
    if (cart && cart.length > 0) {
      localStorage.setItem('cart', JSON.stringify(cart));
    }
    setCartCount(cart.length);
  }, [cart]);


  const AddToCart = async (product, product_id, variants) => {
    setLoading(true);

    if (!user) {
      if (cart && cart.length > 0) {
        const isProductInCart = cart.some(item => item.product_id === product_id);

        if (isProductInCart) {
          notify('success', 'Success', `Product Already In Cart`);
          setLoading(false);
          return;
        };
      }
      setCart((prev) => [
        ...(Array.isArray(prev) ? prev : []),
        {
          product_id: product_id,
          product_name: product.product_name,
          added_quantity: 1,
          product_varient_id: variants,
          price: Math.round(product.price - (product.price * (product.discount / 100))),
          images: product.product_img[0].images,
          symbol: product.symbol
        }
      ]);
      notify('success', 'Success', `Product Added to Cart`);
      setLoading(false);
      return
    }

    try {
      const response = await axios.post(`${baseUrl}/add_cart`, {
        product_id: product_id,
        customer_id: user.customer_id,
        product_varient_id: variants,
      });

      if (response.data.status) {
        notify('success', 'Success', `${response.data.message}`);
        updateCartCount(response.data.cartCount);
      } else {
        notify('danger', 'Error', `${response.data.message}`);

      }
    } catch (error) {
      console.error('Error during status update:', error);
      notify('danger', 'Error', 'There was an error. Please try again.');

    }
    setLoading(false);
  }



  const updateCartCount = (count) => {
    setCartCount(count);
  };

  return (
    <CartContext.Provider value={{ cart, setCart, cartCount, updateCartCount, mainctg, setMainctg, setSearch, search, AddToCart, loading, setLoading }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);