import React, { useEffect, useState } from 'react'
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { Link, useParams } from 'react-router-dom';
import Dropdown from '../../../Component/utiles/Dropdown';
import Pagination from '../../../Component/utiles/Pagination';
import { IoSearchSharp } from "react-icons/io5";
import axios from 'axios';
import Productviewmodal from '../../Component/Productviewmodal';
import { TbArrowsExchange2 } from 'react-icons/tb';
import { FaEye } from 'react-icons/fa';
import notify from '../../../Component/utiles/Notification';
import Profileui from '../../Component/Profileui';

const Supplierprofile = ({ setLoading, clickProductID, productdata, varientsdata, mainproduct, variants, existingImages }) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [refresh, setRefresh] = useState(0);


    const productDATA = {
        productdata,
        varientsdata,
        mainproduct,
        variants,
        existingImages,

    };


    const { id } = useParams();

    const baseUrl = process.env.REACT_APP_BASEURL


    const [products, setProducts] = useState([]);
    const [userData, setUserData] = useState('');


    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setLoading(true);
                const response = await axios.post(`${baseUrl}/user_data`,
                    { user_id: id },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
                setUserData(response.data.data[0]);

                setProducts(response.data.products)
                setLoading(false)
            } catch (err) {
                console.log(err);
            }
        };

        fetchUserData();
    }, [refresh]);


    const perpageoption = [
        { label: '5' }, { label: '10' }, { label: '15' }, { label: '20' }
    ];


    const searchResults = products
        ? products.filter(productitem =>
            productitem.product_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            productitem.category_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            productitem.product_id.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
        : [];


    const [categoryvalue, setcategoryValue] = useState('all category');

    const getUniqueCategories = (items) => {

        if (!items) return [];
        const categories = new Set(items
            .filter(item => item.category_name) // filter out null/undefined categories
            .map(item => item.category_name)
        );

        return Array.from(categories);
    };

    const ctgoption = [{ label: 'all category', value: 'all category' }, ...getUniqueCategories(products).map(category => ({
        label: category,
        value: category
    }))];

    // console.log(ctgoption)

    const [itemsPerPage, setItemsPerPage] = useState(perpageoption[0].label);
    const [currentPage, setCurrentPage] = useState(1);

    const filteredItems = categoryvalue === 'all category' ? searchResults : searchResults.filter(item => item.category_name === categoryvalue);

    const allproduct = searchTerm ? searchResults : filteredItems;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = allproduct.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(allproduct.length / itemsPerPage);

    const getStatusClass = (stockStatus) => {
        switch (stockStatus.toLowerCase()) {
            case 'in stock':
            case 'active':
            case 'live':
                return 'bg-green-200 text-green-600 text-xs';
            case 'out of stock':
            case 'inactive':
            case 'hold':
                return 'bg-red-200 text-red-500 text-xs';
            default:
                return 'bg-gray-400 text-white text-xs';
        }
    };


    const handleupdate = async (e, status) => {
        setLoading(true);
        let newStatus;
        if (status === 'Active') {
            newStatus = 'Inactive'
        } else {
            newStatus = 'Active'
        }
        try {
            const response = await axios.post(`${baseUrl}/update_product_status`, {
                product_id: e,
                status: newStatus,
            });

            if (response.data.status) {
                notify('success', 'Status Update', `Product status changed to ${newStatus}`);
                setRefresh(prev => prev + 1);
                setOpenModal(false)
            } else {
                notify('danger', 'Error', 'Failed to update Product status');
            }
        } catch (error) {
            notify('danger', 'Error', 'Something went wrong!');
        }
        setLoading(false);
    }


    const handleClick = (product_id) => {
        clickProductID(product_id);
        setOpenModal(true);
    };


    return (
        <>
            <h1 className='xl:text-2xl md:text-lg text-xl mt-3 font-semibold capitalize'>supplier profile</h1>
            <Profileui userData={userData} />
            {products == null &&
                <div className="flex justify-center place-items-center">
                    <h1 className='xl:text-2xl md:text-lg text-xl mt-3 font-semibold'>Products Not Availabel !</h1>
                </div>
            }
            {products != null &&
                <div className="">
                    <h1 className='xl:text-2xl md:text-lg text-xl mt-3 font-semibold'>Products</h1>
                    <div className='w-full bg-white rounded-lg mt-5 md:p-6 p-3'>
                        <div className="relative flex md:hidden w-full ">
                            <input
                                type="text"
                                placeholder="Search Here"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="bg-gray-200 w-full border-0 rounded-full px-4 py-2 pr-10 focus:outline-none focus:border-gray-400 focus:ring-2 focus:ring-gray-400"
                            />
                            <IoSearchSharp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-black cursor-pointer" />
                        </div>
                        <div className='flex flex-row place-items-center justify-between my-5  pe-0'>
                            <div className='flex flex-row items-center md:w-40 w-24  gap-2'>
                                <span className='text-nowrap mt-2 lg:block hidden text-sm capitalize'>items per page</span>
                                <div className='w-16'>
                                    <Dropdown
                                        label=""
                                        options={perpageoption}
                                        selectedValues={itemsPerPage}
                                        onSelect={setItemsPerPage}
                                        onRemove={() => { }}
                                        isMultiple={false}
                                    />
                                </div>
                            </div>
                            <div className='flex flex-row items-center md:w-64 w-36'>
                                <div className='w-full'>
                                    <Dropdown
                                        label=""
                                        options={ctgoption}
                                        selectedValues={categoryvalue}
                                        onSelect={setcategoryValue}
                                        onRemove={() => { }}
                                        isMultiple={false}
                                    />
                                </div>
                            </div>
                            <div className="relative hidden md:flex md:w-6/12 ms-5">
                                <input
                                    type="text"
                                    placeholder="Search Here"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="bg-gray-200 w-full border-0 rounded-full px-4 py-2 pr-10 focus:outline-none focus:border-gray-400 focus:ring-2 focus:ring-gray-400"
                                />
                                <IoSearchSharp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-black cursor-pointer" />
                            </div>
                            <h1 className='text-xl capitalize font-normal'>(total products {products.length})</h1>
                        </div>
                        <div className='overflow-hidden overflow-x-auto mt-6'>
                            <table className="lg:w-full md-w[100%]  table bg-white">
                                <thead className="bg-gray-100 rounded-full">
                                    <tr>
                                        <th className="py-4 px-4 text-left capitalize">Product</th>
                                        <th className="py-4 px-4 text-left capitalize">Product ID</th>
                                        <th className="py-4 px-4 text-left capitalize">category</th>
                                        <th className="py-4 px-4 text-left capitalize">Price</th>
                                        <th className="py-4 px-4 text-left capitalize">Quantity</th>
                                        <th className="py-4 px-4 text-left capitalize">Approve By Alas</th>
                                        <th className="py-4 px-4 text-left capitalize">Stock</th>
                                        <th className="py-4 px-4 text-left capitalize">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentItems.map(item => (
                                        <tr key={item.product_id} className="border-b border-gray-200 hover:bg-gray-200 transition-all duration-200 ease-in-out">
                                            <td className="py-4 px-4 flex items-center gap-4">
                                                <img src={item.product_img} alt={item.product_name} className="w-16 h-16 mr-4 bg-gray-100 rounded-lg" />
                                                <span className='text-nowrap pe-10 truncate md:pe-20 lg:pe-0 text-sm'>{item.product_name}</span>
                                            </td>
                                            <td className="py-4 px-4">{item.product_id}</td>
                                            <td className="py-4 px-4 text-nowrap">{item.category_name}</td>
                                            <td className="py-4 px-4 text-nowrap">$ {item.total_amount}</td>
                                            <td className="py-4 px-4">
                                                <div className="flex items-center">
                                                    <span className="px-2 mx-3">{item.quantity}</span>
                                                </div>
                                            </td>
                                            <td className="py-4 px-4">
                                                <span className={`px-2 py-1  rounded-lg capitalize font-semibold ${getStatusClass(item.status)}`}>
                                                    {item.status}
                                                </span>
                                            </td>
                                            <td className="py-4 px-4 text-nowrap">
                                                <span className={`px-2 py-1  rounded-lg capitalize font-semibold ${getStatusClass(item.stock_status)}`}>
                                                    {item.stock_status}
                                                </span>
                                            </td>
                                            <td className="p-4">
                                                <div className='flex flex-row items-center gap-5 text-xl'>
                                                    <div className="flex flex-row gap-3">
                                                        <div className='px-3 cursor-pointer hover:scale-110 duration-200 transition-all py-2 text-lg rounded-md bg-neutral-300' onClick={() => handleClick(item.product_id)}>
                                                            <FaEye />
                                                        </div>
                                                        <div className='px-3 cursor-pointer hover:scale-110 duration-200 transition-all py-2 text-xl rounded-md bg-neutral-300' onClick={(e) => handleupdate(item.product_id, item.status)}>
                                                            <TbArrowsExchange2 />
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="flex justify-between items-center mt-4">
                            <div>
                                <span>Page {currentPage} of {totalPages}</span>
                            </div>
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={(page) => setCurrentPage(page)}
                            />
                        </div>
                    </div>
                </div>
            }

            <Productviewmodal {...productDATA} type={'product'} openModal={openModal} setOpenModal={setOpenModal} handleupdate={handleupdate} />
        </>
    )
}

export default Supplierprofile