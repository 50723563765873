import React, { useEffect, useState } from 'react'
import { IoSearchSharp } from "react-icons/io5";
import Dropdown from '../../Component/utiles/Dropdown';
import Pagination from '../../Component/utiles/Pagination';
// import Transactiondetails from './Transactiondetails';
import { ImCross } from "react-icons/im";
import Transactiondetails from './Transactiondetails';


const Recenttransactions = () => {

    const [visibleModal, setVisibleModal] = useState(null);

    const handleToggleVisibility = (id) => {
        setVisibleModal(visibleModal === id ? null : id);
    };

    const handleBackdropClick = (e) => {
        if (e && e.target === e.currentTarget) {
            setVisibleModal(null);
        }
    };
    const handleCloseModal = () => {
        setVisibleModal(null);
    };

    const perpageoption = [
        { label: '5' }, { label: '10' }, { label: '15' }, { label: '20' }
    ];

    const checkdataoption = [
        { label: 'all' }, { label: 'today' }, { label: 'weekly' }, { label: 'monthly' },
    ];

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(perpageoption[0].label);
    const [checkData, setCheckData] = useState(checkdataoption[0].label);


    const transactionsData = [
        {
            id: 1,
            transactionID: "#1251",
            customerID: "#12537640",
            amount: 399.00,
            paymentMethod: "Visa",
            date: "8-9-2024, 10:15",
            seller: "John Doe",
            billingAddress: "123 Elm Street, Apt 4",
            itemsPurchased: ["Laptop", "Mouse"],
            email: "johndoe@example.com"
        },
        {
            id: 2,
            transactionID: "#1252",
            customerID: "#12537641",
            amount: 250.00,
            paymentMethod: "PayPal",
            date: "8-7-2024, 11:30",
            seller: "Jane Smith",
            billingAddress: "456 Oak Avenue, Suite 2",
            itemsPurchased: ["Headphones"],
            email: "janesmith@example.com"
        },
        {
            id: 3,
            transactionID: "#1253",
            customerID: "#12537642",
            amount: 499.00,
            paymentMethod: "Master Card",
            date: "8-8-2023, 14:42",
            seller: "John Doe",
            billingAddress: "789 Pine Road",
            itemsPurchased: ["Smartphone", "Case"],
            email: "johndoe@example.com"
        },
        {
            id: 4,
            transactionID: "#1254",
            customerID: "#12537643",
            amount: 120.00,
            paymentMethod: "Visa",
            date: "1-8-2024, 09:20",
            seller: "Alice Johnson",
            billingAddress: "101 Maple Lane",
            itemsPurchased: ["Keyboard"],
            email: "alicejohnson@example.com"
        },
        {
            id: 5,
            transactionID: "#1255",
            customerID: "#12537644",
            amount: 320.00,
            paymentMethod: "American Express",
            date: "7-7-2024, 12:05",
            seller: "Jane Smith",
            billingAddress: "202 Birch Blvd",
            itemsPurchased: ["Tablet", "Charger"],
            email: "janesmith@example.com"
        },
        {
            id: 6,
            transactionID: "#1256",
            customerID: "#12537645",
            amount: 150.00,
            paymentMethod: "PayPal",
            date: "7-8-2023, 13:50",
            seller: "John Doe",
            billingAddress: "303 Cedar Street",
            itemsPurchased: ["Mouse"],
            email: "johndoe@example.com"
        },
        {
            id: 7,
            transactionID: "#1257",
            customerID: "#12537646",
            amount: 450.00,
            paymentMethod: "Master Card",
            date: "5-8-2024, 15:10",
            seller: "Alice Johnson",
            billingAddress: "404 Willow Way",
            itemsPurchased: ["Monitor"],
            email: "alicejohnson@example.com"
        },
        {
            id: 8,
            transactionID: "#1258",
            customerID: "#12537647",
            amount: 299.00,
            paymentMethod: "Visa",
            date: "6-7-2024, 16:45",
            seller: "Jane Smith",
            billingAddress: "505 Oakwood Dr",
            itemsPurchased: ["Printer", "Ink Cartridge"],
            email: "janesmith@example.com"
        },
        {
            id: 9,
            transactionID: "#1259",
            customerID: "#12537648",
            amount: 520.00,
            paymentMethod: "American Express",
            date: "3-8-2023, 17:30",
            seller: "John Doe",
            billingAddress: "606 Pinecrest Blvd",
            itemsPurchased: ["Smartwatch"],
            email: "johndoe@example.com"
        },
        {
            id: 10,
            transactionID: "#1260",
            customerID: "#12537649",
            amount: 340.00,
            paymentMethod: "PayPal",
            date: "2-8-2024, 18:15",
            seller: "Alice Johnson",
            billingAddress: "707 Elmwood Lane",
            itemsPurchased: ["Router"],
            email: "alicejohnson@example.com"
        },
        {
            id: 11,
            transactionID: "#1261",
            customerID: "#12537650",
            amount: 230.00,
            paymentMethod: "Master Card",
            date: "5-7-2024, 19:00",
            seller: "Jane Smith",
            billingAddress: "808 Maple Street",
            itemsPurchased: ["Webcam"],
            email: "janesmith@example.com"
        },
        {
            id: 12,
            transactionID: "#1262",
            customerID: "#12537651",
            amount: 610.00,
            paymentMethod: "Visa",
            date: "9-8-2023, 20:20",
            seller: "John Doe",
            billingAddress: "909 Oak Avenue",
            itemsPurchased: ["Gaming Laptop"],
            email: "johndoe@example.com"
        },
        {
            id: 13,
            transactionID: "#1263",
            customerID: "#12537652",
            amount: 740.00,
            paymentMethod: "American Express",
            date: "1-8-2024, 21:45",
            seller: "Alice Johnson",
            billingAddress: "1010 Birch Road",
            itemsPurchased: ["Camera", "Lens"],
            email: "alicejohnson@example.com"
        },
        {
            id: 14,
            transactionID: "#1264",
            customerID: "#12537653",
            amount: 870.00,
            paymentMethod: "PayPal",
            date: "2-7-2024, 22:30",
            seller: "John Doe",
            billingAddress: "1111 Cedar Lane",
            itemsPurchased: ["Smartphone", "Headphones"],
            email: "johndoe@example.com"
        },
        {
            id: 15,
            transactionID: "#1265",
            customerID: "#12537654",
            amount: 920.00,
            paymentMethod: "Master Card",
            date: "8-8-2023, 23:15",
            seller: "Jane Smith",
            billingAddress: "1212 Willow Way",
            itemsPurchased: ["Laptop", "Mouse"],
            email: "janesmith@example.com"
        },
        {
            id: 16,
            transactionID: "#1266",
            customerID: "#12537655",
            amount: 380.00,
            paymentMethod: "Visa",
            date: "6-8-2024, 14:00",
            seller: "Alice Johnson",
            billingAddress: "1313 Oakwood Dr",
            itemsPurchased: ["Keyboard"],
            email: "alicejohnson@example.com"
        },
        {
            id: 17,
            transactionID: "#1267",
            customerID: "#12537656",
            amount: 560.00,
            paymentMethod: "American Express",
            date: "4-7-2024, 12:35",
            seller: "John Doe",
            billingAddress: "1414 Elm Street",
            itemsPurchased: ["Tablet", "Charger"],
            email: "johndoe@example.com"
        },
        {
            id: 18,
            transactionID: "#1268",
            customerID: "#12537657",
            amount: 490.00,
            paymentMethod: "PayPal",
            date: "3-8-2023, 13:15",
            seller: "Jane Smith",
            billingAddress: "1515 Birch Blvd",
            itemsPurchased: ["Mouse", "Keyboard"],
            email: "janesmith@example.com"
        },
        {
            id: 19,
            transactionID: "#1269",
            customerID: "#12537658",
            amount: 210.00,
            paymentMethod: "Master Card",
            date: "4-8-2024, 13:50",
            seller: "Alice Johnson",
            billingAddress: "1616 Maple Lane",
            itemsPurchased: ["Webcam"],
            email: "alicejohnson@example.com"
        },
        {
            id: 20,
            transactionID: "#1270",
            customerID: "#12537659",
            amount: 640.00,
            paymentMethod: "Visa",
            date: "3-7-2024, 16:25",
            seller: "John Doe",
            billingAddress: "1717 Oak Avenue",
            itemsPurchased: ["Smartwatch"],
            email: "johndoe@example.com"
        }
    ];


    // "7-8-2024, 10:15" mm-dd-yyyy


    // Function to filter transactions based on the selected checkData option
    const filterByDateOption = (transactions) => {
        const now = new Date();

        switch (checkData) {
            case 'today':
                return transactions.filter(item => {
                    const transactionDate = new Date(item.date);
                    return (
                        transactionDate.getDate() === now.getDate() &&
                        transactionDate.getMonth() === now.getMonth() &&
                        transactionDate.getFullYear() === now.getFullYear()
                    );
                });
            case 'weekly':
                const oneWeekAgo = new Date();
                oneWeekAgo.setDate(now.getDate() - 7);
                return transactions.filter(item => {
                    const transactionDate = new Date(item.date);
                    return transactionDate >= oneWeekAgo && transactionDate <= now;
                });
            case 'monthly':
                const oneMonthAgo = new Date();
                oneMonthAgo.setMonth(now.getMonth() - 1);
                return transactions.filter(item => {
                    const transactionDate = new Date(item.date);
                    return transactionDate >= oneMonthAgo && transactionDate <= now;
                });
            case 'all':
            default:
                return transactions;
        }
    };

    // Function to search transactions
    const searchTransactions = (transactions) => {
        return transactions.filter(item =>
            item.transactionID.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.customerID.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.date.includes(searchTerm)
        );
    };

    // Apply both filters and search
    const filteredTransactions = searchTransactions(filterByDateOption(transactionsData));

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredTransactions.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(filteredTransactions.length / itemsPerPage);

    const modaldata = transactionsData.find(item => item.id === visibleModal);

    useEffect(() => {
        if (searchTerm) {
            setCurrentPage(1);
        }
    }, [searchTerm]);

    return (
        <>
            <div className='w-full bg-white rounded-lg  md:p-6 my-5 p-3'>
                <div className='flex flex-row place-items-center justify-between my-5  pe-0'>
                    <div className='flex flex-row items-center md:w-40 w-24  gap-5'>
                        <div className="flex flex-row place-items-center gap-2">
                            <span className='text-nowrap mt-2 lg:block hidden text-sm capitalize'>items per page</span>
                            <div className='w-16'>
                                <Dropdown
                                    label=""
                                    options={perpageoption}
                                    selectedValues={itemsPerPage}
                                    onSelect={setItemsPerPage}
                                    onRemove={() => { }}
                                    isMultiple={false}
                                />
                            </div>
                        </div>
                        <div className="flex flex-row place-items-center gap-1">
                            <span className='text-nowrap mt-2 lg:block hidden text-sm capitalize'>selected by</span>
                            <div className='w-32'>
                                <Dropdown
                                    label=""
                                    options={checkdataoption}
                                    selectedValues={checkData}
                                    onSelect={setCheckData}
                                    onRemove={() => { }}
                                    isMultiple={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="relative hidden md:flex md:w-6/12 ms-5">
                        <input
                            type="text"
                            placeholder="Search Here"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="bg-gray-200 w-full border-0 rounded-full px-4 py-2 pr-10 focus:outline-none focus:border-gray-400 focus:ring-2 focus:ring-gray-400"
                        />
                        <IoSearchSharp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-black cursor-pointer" />
                    </div>
                </div>
                <div className='overflow-hidden overflow-x-auto mt-6'>
                    <table className="lg:w-full md-w[100%]  table bg-white">
                        <thead className="bg-gray-100 rounded-full">
                            <tr>
                                <th className="py-4 px-4 text-left capitalize">transaction <span>ID</span></th>
                                <th className="py-4 px-4 text-left capitalize">customer <span>ID</span></th>
                                <th className="py-4 px-4 text-left capitalize">Paid</th>
                                <th className="py-4 px-4 text-left capitalize">method</th>
                                <th className="py-4 px-4 text-left capitalize">date</th>
                                <th className="py-4 px-4 text-left capitalize">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map(item => (
                                <tr key={item.id} className="border-b border-gray-200 hover:bg-gray-200 transition-all duration-200 ease-in-out">
                                    <td className="py-4 px-4 flex items-center gap-4">
                                        <span className='text-nowrap pe-10 truncate md:pe-20 lg:pe-0 text-sm'>{item.transactionID}</span>
                                    </td>
                                    <td className="py-4 px-4 text-nowrap">{item.customerID}</td>
                                    <td className="py-4 px-4 text-nowrap">{item.amount.toFixed(2)} €</td>
                                    <td className="py-4 px-4">
                                        <div className="flex items-center">
                                            <span className="px-2 mx-3">{item.paymentMethod}</span>
                                        </div>
                                    </td>
                                    <td className="py-4 px-4">{item.date}</td>

                                    <td className="p-4">
                                        <div className='flex flex-row gap-5 text-xl'>
                                            <button
                                                className='cursor-pointer hover:scale-110 duration-200 transition-all px-5 py-1 text-lg rounded-md border border-neutral-400'
                                                onClick={() => handleToggleVisibility(item.id)}
                                            >
                                                View
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Transactiondetails isVisible={!!visibleModal} data={modaldata} onClose={handleCloseModal}/>
                <div className="flex justify-between items-center mt-4">
                    <div>
                        <span>Page {currentPage} of {totalPages}</span>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </div>
        </>
    )
}

export default Recenttransactions