import React, { useEffect, useState } from 'react'
import Dropdown from '../../../Component/utiles/Dropdown'
import { Link } from 'react-router-dom';
import Pagination from '../../../Component/utiles/Pagination';
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import axios from 'axios';
import notify from '../../../Component/utiles/Notification';
import Editaddfaqs from '../../Component/Editaddfaqs';


const Faqs = ({ setLoading }) => {

    const baseUrl = process.env.REACT_APP_BASEURL
    const [isFaqsedit, setIsEditfaqs] = useState(false);

    const [faqs, setFaqs] = useState([]);
    const [refresh, setRefresh] = useState(0);


    const [faqID, setFaqID] = useState(null);

    const [faqsdata, setFaqsdata] = useState({
        title: '',
        para: '',
    });


    useEffect(() => {
        setLoading(true);
        axios.get(`${baseUrl}/faqs`)
            .then(response => {
                if (response.data.status) {
                    setFaqs(response.data.faq_array);
                } else {
                    console.log('Failed to load FAQs.');
                }
            })
            .catch(error => {
                console.log('An error occurred while fetching FAQs.');
            })
        setLoading(false);
    }, [refresh]);

    const handleRefresh = () => {
        setRefresh(prev => !prev);
    };


    const perpageoption = [
        { label: '5' }, { label: '10' }, { label: '15' }, { label: '20' }
    ]

    const [itemsPerPage, setItemsPerPage] = useState(perpageoption[0].label);
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = faqs.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(faqs.length / itemsPerPage);

    const openeditFaqs = (id) => {
        setFaqID(id)
        const data = faqs.find(list => list.faq_id === id);
        setFaqsdata(data);
        setIsEditfaqs(true);

    };
    const openaddFaqs = () => {
        setFaqID(null)
        setFaqsdata(null);
        setIsEditfaqs(true);
    };

    const closeaddFaqs = () => {
        setFaqsdata(null);
        setIsEditfaqs(false);
    };

    const handleDelete = async (id) => {
        setLoading(true);
        try {
            const response = await axios.post(`${baseUrl}/deletefaqs/${id}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );
            if (response.data.status) {
                notify('success', 'Success', `FAQS Delete Successfully`);
                setRefresh(prev => !prev);
            } else {
                notify('danger', 'Error', `${response.data.message || 'An error occurred'}`);
            }
        } catch {
            notify('danger', 'Error', 'Something went wrong');
        }
        setLoading(false);
    }

    return (
        <>
            <h1 className="xl:text-2xl md:text-lg text-xl mt-4 font-semibold capitalize">Faqs</h1>
            <div className='w-full bg-white rounded-lg mt-5 p-6'>
                <div className='flex flex-row justify-between my-5 p-0'>
                    <div className='flex flex-row items-center md:w-48 w-20 gap-5'>
                        <span className='text-nowrap mt-2 md:block hidden'>items per page</span>
                        <Dropdown
                            label=""
                            options={perpageoption}
                            selectedValues={itemsPerPage}
                            onSelect={setItemsPerPage}
                            onRemove={() => { }}
                            isMultiple={false}
                        />
                    </div>
                    <Link onClick={openaddFaqs} className='text-white flex justify-center font-semibold items-center bg-black ms:px-5 px-3 text-base   md:py-2 py-0.5  hover:text-black hover:bg-white hover:border border rounded-lg hover:border-black transition-all duration-200'>Add New FAQS</Link>
                </div>
                <div className='overflow-hidden overflow-x-auto'>
                    <table className="min-w-full table bg-white">
                        <thead className="bg-gray-100 rounded-full">
                            <tr>
                                <th className="py-4 px-4 text-left">ID</th>
                                <th className="py-4 px-4 text-left">Title</th>
                                <th className="py-4 px-4 text-left">Description</th>
                                <th className="py-4 px-4 text-left">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map(item => (
                                <tr key={item.id} className="border-b border-gray-200 hover:bg-gray-200 transition-all duration-200 ease-in-out">
                                    <td className="py-4 px-4 capitalize font-normal text-base">{item.faq_id}</td>
                                    <td className="py-4 px-4 capitalize font-semibold text-base">{item.title}</td>
                                    <td className="py-4 px-4 capitalize font-normal text-base">{item.para}</td>
                                    <td className="p-4">
                                        <div className='flex flex-row items-center gap-5 text-xl'>
                                            <Link onClick={() => openeditFaqs(item.faq_id)} className='cursor-pointer hover:scale-110 duration-200 transition-all px-3 py-2 text-lg rounded-md bg-neutral-300'>
                                                <FiEdit />
                                            </Link>
                                            <div className='px-3 cursor-pointer hover:scale-110 duration-200 transition-all py-2 text-lg rounded-md bg-neutral-300' onClick={() => handleDelete(item.faq_id)}>
                                                <MdDelete />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-between items-center mt-4">
                    <div>
                        <span>Page {currentPage} of {totalPages}</span>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </div>
            {isFaqsedit && <Editaddfaqs closeaddFaqs={closeaddFaqs} faqID={faqID} faqsdata={faqsdata} onFaqsUpdate={handleRefresh} />}
        </>
    )
}

export default Faqs