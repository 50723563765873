import React, { useEffect, useState } from 'react';
import { Carousel } from "flowbite-react";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import '../Assets/css/Styles.css';
import axios from 'axios';

const Advbanner = () => {
    const [images, setImages] = useState([]);
    
  const baseUrl = process.env.REACT_APP_BASEURL


    useEffect(() => {
        axios.get(`${baseUrl}/banners`)
            .then(response => {
                const sliderBanners = response.data.discount_array || [];
                setImages(sliderBanners);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);


    return (
        <>
            <div className="">
                <Swiper
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    loop={true}
                    modules={[Autoplay]}
                    className="mySwiper"
                >
                    {images.map((banner, index) => (
                        <SwiperSlide key={index} className='object-contain h-full w-full'>
                            <img className='h-full w-full' src={banner.banner} alt={banner.title} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

        </>
    );
};

export default Advbanner;
