import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../../Component/Assets/Images/adminlogo.png';
import notify from '../../Component/utiles/Notification';
import { UserState } from '../../Context/userProvider';
import Loader from '../../Component/utiles/Loader';
import { FaEye, FaEyeSlash } from "react-icons/fa";


const Login = () => {

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { user, logout } = UserState();
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { setUser } = UserState();

    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

  const baseURL = process.env.REACT_APP_BASEURL


    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();

        try {
            const response = await axios.post(`${baseURL}/user_login`,
                { email, password },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

            if (response.data.status) {
                const userData = response.data.user_data;
                localStorage.setItem('userInfo', JSON.stringify(userData));
                setUser(userData);
                notify('success', 'Success', 'Login successfull');
                navigate('/admin/dashboard');
                setIsLoading(false);
            } else {
                notify('danger', 'Error', response.data.message);
                setIsLoading(false);
            }
        } catch (error) {
            notify('danger', 'Error', 'Something went wrong, please try again');
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className='h-[100vh] bg-gray-200 flex flex-col  justify-start place-items-center p-5 md:p-0'>
                <img src={logo} className='md:h-40 h-20 mix-blend-multiply md:mt-28 ' alt='logo' />
                <form onSubmit={handleSubmit}>
                    <div className='md:w-96 w-full bg-white shadow-xl p-3 rounded-lg flex flex-col justify-center place-items-center'>
                        <span className='text-black xl:text-2xl font-semibold md:text-lg text-xl'>Sign In</span>
                        <div className='mt-5 w-full'>
                            <span className='px-1 font-medium text-neutral-500'>E-Mail</span>
                            <input
                                className='py-1  px-1 border-gray-300 w-full text-black font-medium focus:ring-0 focus:border-b-gray-300 focus:border-t-0  focus:border-r-0 border-t-0 border-l-0 border-r-0'
                                type='email'
                                placeholder='Enter Your Email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <div className='mt-5'>
                                <span className='px-1 font-medium text-neutral-500'>Password</span>
                                <div className="flex flex-row  place-items-center  overflow-hidden border-gray-300 border-b">
                                    <input
                                        className='py-1  px-1 w-full text-black font-medium focus:ring-0 focus:border-0  border-0'
                                        type={passwordVisible ? "text" : "password"}
                                        placeholder='Enter Your Password'
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <div className="pe-2 cursor-pointer" onClick={togglePasswordVisibility}>
                                        {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                                    </div>
                                </div>

                            </div>
                            <Link to='../forgot-password' className='md:text-lg text-sm font-semibold float-end mt-3 capitalize'>forgot password ?</Link>
                        </div>
                        <button type='submit'
                            className='text-white uppercase w-full flex justify-center place-items-center bg-black mt-5 rounded-lg py-2 text-xl xl:text-xl md:text-lg font-semibold hover:bg-white hover:text-black hover:border transition-all duration-200 ease-in-out border border-black'
                        >
                            Sign &nbsp;in
                        </button>
                        {error && <p className="text-red-500 mt-4">{error}</p>}
                    </div>
                </form>
                <div className="flex flex-col justify-center mt-5 md:w-96 w-full">
                    <div className="flex flex-row place-items-center justify-center gap-2">
                        <div className="md:w-24 w-16 h-0.5 bg-gray-500"></div>
                        <span className='text-gray-500 font-medium text-sm md:text-base'>New To Alas Partner ?</span>
                        <div className="md:w-24 w-16 h-0.5 bg-gray-500"></div>
                    </div>
                    <Link to='/become-seller' className='text-sm font-medium text-center py-3 rounded-lg text-black border border-black mt-4'>Create Your Seller Account</Link>
                    <Link to='/' className='text-sm font-medium text-center py-3 rounded-lg text-black border border-black mt-4'>Create Your Influencers Account</Link>
                </div>
            </div>
            {isLoading && <Loader />}
        </>
    );
};

export default Login;
