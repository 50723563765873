import React, { useState } from 'react'
import Dropdown from '../../../Component/utiles/Dropdown'
import { Link } from 'react-router-dom';
import Pagination from '../../../Component/utiles/Pagination';
import { MdDelete } from 'react-icons/md';
import { FiEdit } from 'react-icons/fi';

const Colours = ({ colordata }) => {

    const perpageoption = [
        { label: '5' }, { label: '10' }, { label: '15' }, { label: '20' }
    ]

    const [itemsPerPage, setItemsPerPage] = useState(perpageoption[0].label);
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = colordata.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(colordata.length / itemsPerPage);


    return (
        <>
            <h1 className="xl:text-2xl md:text-lg text-xl mt-4 font-semibold capitalize">Colours</h1>
            <div className='w-full bg-white rounded-lg mt-5 p-6'>

                <div className='flex flex-row justify-between my-5 p-0'>
                    <div className='flex flex-row items-center md:w-48 w-20 gap-5'>
                        <span className='text-nowrap mt-2 md:block hidden'>items per page</span>
                        <Dropdown
                            label=""
                            options={perpageoption}
                            selectedValues={itemsPerPage}
                            onSelect={setItemsPerPage}
                            onRemove={() => { }}
                            isMultiple={false}
                        />
                    </div>
                    <Link className='text-white flex justify-center font-semibold items-center bg-black ms:px-5 px-3 text-base   md:py-2 py-0.5  hover:text-black hover:bg-white hover:border border rounded-lg hover:border-black transition-all duration-200'>Add New Colours</Link>
                </div>
                <div className='overflow-hidden overflow-x-auto'>
                    <table className="min-w-full table bg-white">
                        <thead className="bg-gray-100 rounded-full">
                            <tr>
                                <th className="py-4 px-4 text-left">ID</th>

                                <th className="py-4 px-4 text-left">Name</th>
                                <th className="py-4 px-4 text-left">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map(item => (
                                <tr key={item.id} className="border-b border-gray-200 hover:bg-gray-200 transition-all duration-200 ease-in-out">
                                    <td className="py-4 px-4 capitalize font-normal text-base">{item.colour_id}</td>
                                    <td className="py-4 px-4 capitalize font-normal text-base">{item.colour_name}</td>
                                    <td className="p-4">
                                        <div className='flex flex-row items-center gap-5 text-xl'>
                                            <Link className='cursor-pointer hover:scale-110 duration-200 transition-all px-3 py-2 text-lg rounded-md bg-neutral-300' >
                                                <FiEdit />
                                            </Link>
                                            <div className='px-3 cursor-pointer hover:scale-110 duration-200 transition-all py-2 text-lg rounded-md bg-neutral-300' >
                                                <MdDelete />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-between items-center mt-4">
                    <div>
                        <span>Page {currentPage} of {totalPages}</span>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </div>
        </>

    )
}

export default Colours