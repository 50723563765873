import React, { useEffect, useState } from 'react'
import Pagination from '../../Component/utiles/Pagination';

const ReturnTracking = ({ returnOrders, itemsPerPage, searchTerm }) => {

    const tabs = ['in transit', 'out of delivery', 'delivered', 'lost'];

    const [selectedStatus, setSelectedStatus] = useState(tabs[0]);
    const [currentPage, setCurrentPage] = useState(1);


    const handleTabClick = (status) => {
        setSelectedStatus(status);
        setCurrentPage(1);
    };
    const filterByStatus = (purchase) => {
        if (selectedStatus === 'all') return true;
        return purchase.status === selectedStatus;
    };


    const filteredRTO = returnOrders.filter(filterByStatus);

    const searchResults = filteredRTO.filter(productitem =>
        productitem.product.toLowerCase().includes(searchTerm.toLowerCase()) ||
        productitem.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
        productitem.returnBy.toLowerCase().includes(searchTerm.toLowerCase()) ||
        productitem.orderId.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const itemsToDisplay = searchTerm ? searchResults : filteredRTO;


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = itemsToDisplay.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(itemsToDisplay.length / itemsPerPage);


    useEffect(() => {
        if (searchTerm) {
            setCurrentPage(1);
        }
    }, [searchTerm]);

    return (

        <>
            <div className="flex justify-start mt-5 overflow-x-auto  pb-3 h-10">
                <div className="tabs tabs-boxed w-full text-nowrap">
                    {tabs.map((status) => (
                        <a
                            key={status}
                            className={`px-4 py-2 transition-all duration-200 ease-in-out font-medium cursor-pointer ${selectedStatus === status ? 'border-b capitalize text-sm md:text-base border-black text-black' : 'text-[#A4A4A4] text-xs md:text-sm'}`}
                            onClick={() => handleTabClick(status)}>
                            {status.charAt(0).toUpperCase() + status.slice(1)}
                        </a>
                    ))}
                </div>
            </div>
            <div className='overflow-hidden overflow-x-auto mt-6'>
                <table className="lg:w-full md-w[100%]  table bg-white">
                    <thead className="bg-gray-100 rounded-full">
                        <tr>
                            <th className="py-4 px-4 text-left capitalize">Product</th>
                            <th className="py-4 px-4 text-left capitalize text-nowrap">Order ID</th>
                            <th className="py-4 px-4 text-left capitalize text-nowrap">created date</th>
                            <th className="py-4 px-4 text-left capitalize">return by</th>
                            <th className="py-4 px-4 capitalize text-center">quantity</th>
                            <th className="py-4 px-4 capitalize text-center">status</th>
                            <th className="py-4 px-4 text-left capitalize text-nowrap">return reason</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map(item => (
                            <tr key={item.id} className="border-b border-gray-200 hover:bg-gray-200 transition-all duration-200 ease-in-out">
                                <td className="py-4 px-4 flex place-items-center gap-4">
                                    <img src={item.img} alt={item.product} className="w-16 h-16 mr-4 bg-gray-100 rounded-lg" />
                                    <span className='text-nowrap pe-10 truncate md:pe-20 lg:pe-0 text-sm'>{item.product}</span>
                                </td>
                                <td className="py-4 px-4 text-nowrap">{item.orderId}</td>
                                <td className="py-4 px-4">
                                    {item.createdDate}
                                </td>
                                <td className="py-4 px-4">{item.returnBy}</td>
                                <td className="py-4 px-4 ">
                                    <div className="flex justify-center place-items-center">
                                        {item.quantity}
                                    </div>
                                </td>
                                <td className="py-4 px-4 text-center">{item.status}</td>
                                <td className="py-4 px-4 ">{item.returnReason}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="flex justify-between items-center mt-4">
                <div>
                    <span>Page {currentPage} of {totalPages}</span>
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(page) => setCurrentPage(page)}
                />
            </div>
        </>
    )
}

export default ReturnTracking