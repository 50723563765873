import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SignUpimg from '../Assets/Images/sign-up-banner.jpg';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import PhoneInput from 'react-phone-input-2';
import { RxCross2 } from "react-icons/rx";
import 'react-phone-input-2/lib/style.css';
import '../Assets/css/Styles.css';
import notify from '../utiles/Notification';
import Loader from '../utiles/Loader'
import { UserState } from '../../Context/userProvider'


const SignUp = ({ onClose, onLoginClick }) => {


  const [passwordVisible, setPasswordVisible] = useState(false);
  const [otpdata, setotpData] = useState('');
  const [otpstatus, setotpStatus] = useState(false);
  const [phone, setPhone] = useState('');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: ''
  });
  const [errors, setErrors] = useState({});
  const [geoLocation, setGeoLocation] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { setUser } = UserState();
  const [otpvalue, setotpValue] = useState('');
  const [OTP, setOTP] = useState(false);
  const [counter, setCounter] = useState(0);
  const [hasSentOTP, setHasSentOTP] = useState(false);

  const baseUrl = process.env.REACT_APP_BASEURL



  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: '' });
    }
  };

  const handlePhoneChange = (phone) => {
    setPhone(phone);
    if (errors.phone) {
      setErrors({ ...errors, phone: '' });
    }
  };



  const handleOTPChange = (event) => {
    const { value } = event.target;
    setotpValue(value);

    if (errors.otp) {
      setErrors((prevErrors) => ({ ...prevErrors, otp: '' }));
    }
  };



  const handleOTPsend = async () => {
    setIsLoading(true);
    setotpValue('');
    if (phone && phone.trim() !== '') { 
      try {
        const otpResponse = await axios.post(
          `${baseUrl}/open_otp`,
          { mobile: phone },
          {
            headers: {
              'Content-Type': 'application/json',
            }
          }
        );

        if (otpResponse.data && otpResponse.data.status) {

          setOTP(true);
          setCounter(30);
          setHasSentOTP(true);

          setotpData(otpResponse.data.otp);
          notify('success', 'Success', 'OTP send Successful!');
          setIsLoading(true);

        } else {
          notify('danger', 'Error', otpResponse.data.message || 'Unknown error occurred');
        }
      } catch (error) {
        console.error(error); 
        notify('danger', 'Error', 'Failed to send OTP, please try again.');
      }

  

    } else {
      notify('danger', 'Error', 'Phone number is required');
    }
    setIsLoading(false);

  };

  const handleOTPverify = async () => {
    setIsLoading(true);

    try {
      const otp_validate = await axios.post(
        `${baseUrl}/open_otp_validate`,
        {
          mobile: phone,
          otp: otpvalue
        },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );
      setIsLoading(false);

      if (otp_validate.data && otp_validate.data.status) {

        notify('success', 'Success', 'OTP verify Successful');
        setOTP(false);
        setHasSentOTP(false);
        setCounter(0);
        setotpStatus(true);

      } else {
        notify('danger', 'Error', 'Please Enter valid OTP');
      }
    } catch (error) {
      notify('danger', 'Error', 'Please Enter valid OTP');
    }

  };


  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setInterval(() => {
        setCounter(prevCounter => prevCounter - 1);
      }, 1000);
    } else if (counter === 0) {
      setOTP(false); 
    }

    return () => {
      clearInterval(timer);
    };
  }, [counter]);


  const validateForm = () => {
    const newErrors = {};
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!formData.firstName) {
      newErrors.firstName = 'First name is required';
    } else if (!formData.lastName) {
      newErrors.lastName = 'Last name is required';
    } else if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Enter a valid email';
    } else if (!formData.password) {
      newErrors.password = 'Password is required';
    } else if (!passwordRegex.test(formData.password)) {
      newErrors.password = 'Password must be at least 8 characters long, contain at least one capital letter, one number, and one special character';
    } else if (!phone) {
      newErrors.phone = 'Phone number is required';
    } else if (!otpstatus) {
      newErrors.phone = 'Please verify your Mobile Number';
    }
    return newErrors;
  };



  const handleSubmit = async (e) => {
    setIsLoading(true);

    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      const errorMessages = Object.values(newErrors)[0];
      notify('danger', 'Error', errorMessages);
      setIsLoading(false);
      return;
    }


    try {
      const response = await axios.post(`${baseUrl}/register_customer`,
        {
          ...formData,
          "mobile": phone,
          // geoLocation : geoLocation
        }, {
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*'
        }
      });

      if (response.data.status) {
        notify('success', 'Success', 'Sign up successful!');
        onLoginClick();
        onClose();
        setIsLoading(false);
      } else {
        notify('danger', 'Error', response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      notify('danger', 'Error', 'Something went wrong, please try again');
      setIsLoading(false);

    }
  };

  // const fetchIPInfo = async () => {
  //   try {
  //     const response = await axios.get(`https://ipinfo.io/?token=ccc055af41b120`, {
  //     });
  //     setGeoLocation(response.data);
  //   } catch (error) {
  //     console.error('Error fetching IP info:', error);
  //   }
  // };
  // fetchIPInfo()

  return (
    <>
      <div className="fixed inset-0 z-50 min-h-screen flex items-center justify-center bg-gray-900 bg-opacity-70">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden md:flex md:max-w-5xl" data-aos="fade-left" data-aos-duration="1000">
          <div className="md:w-1/2 hidden md:block">
            <img src={SignUpimg} alt="Sign Up" className="h-full w-full object-cover" />
          </div>
          <div className="md:w-2/2 p-6 sm:p-12">
            <div className='flex justify-between'>
              <div>
                <h2 className="text-2xl font-bold text-gray-900">Sign Up</h2>
                <p className="mt-2 text-gray-600">Please Create an Account Below</p>
              </div>
              <div>
                <RxCross2 className='cursor-pointer' onClick={onClose} />
              </div>
            </div>
            <form className="mt-8 space-y-4" onSubmit={handleSubmit}>
              <div className='flex gap-2'>
                <div className='relative'>
                  <label className="block text-sm font-semibold text-gray-700">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    placeholder="First Name"
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-400 focus:ring-1"
                  />

                </div>
                <div>
                  <label className="block text-sm font-semibold text-gray-700">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    placeholder="Last Name"
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-400 focus:ring-1"
                  />

                </div>
              </div>
              <div>
                <label className="block text-sm font-semibold text-gray-700">E-Mail Address</label>
                <input
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="E-Mail Address"
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-400 focus:ring-1"
                />

              </div>
              <div>
                <label className="block text-sm font-semibold text-gray-700 mb-2">Mobile Number</label>
                <div className='flex flex-row gap-3'>
                  <div className=" w-full">
                    <PhoneInput
                      country={'in'}
                      value={phone}
                      countryCodeEditable={false}
                      enableSearch={true}
                      onChange={handlePhoneChange}
                      placeholder="Enter Mobile Number"
                      inputClass="input-mobile mt-1 block border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-400 focus:ring-1"
                    />
                  </div>
                  {!otpstatus &&
                    <div className="">
                      <button
                        type="button"
                        className={`px-3 ${counter ? 'w-16' : 'w-full'}  py-1 h-full  text-xs text-nowrap font-semibold text-center text-black bg-neutral-300 rounded-lg hover:bg-neutral-300 focus:ring-4 focus:outline-none focus:ring-neutral-300 dark:bg-neutral-300 dark:hover:bg-neutral-300 dark:focus:ring-neutral-300`}
                        onClick={handleOTPsend}
                        disabled={OTP}
                      >
                        {OTP ? `${counter}s` : hasSentOTP ? 'Resend' : 'Send OTP'}
                      </button>
                    </div>
                  }
                </div>
                {hasSentOTP && (
                  <div className="mt-5" data-aos="fade-up">
                    <span>{otpdata}</span>
                    <label className="block text-sm font-semibold text-gray-700">
                      Enter OTP
                    </label>
                    <div className="flex flex-row gap-3">

                      <input
                        type="number"
                        name="otp"
                        value={otpvalue}
                        onChange={handleOTPChange}
                        placeholder="Enter OTP"
                        className="mt-1 p-2 block w-full no-arrows border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-400 focus:ring-1"
                      />
                      <button
                        type="button"
                        onClick={handleOTPverify}
                        className="px-4 py-1 mt-1 text-xs font-medium text-center text-white bg-green-500 rounded-lg hover:bg-green-500 focus:ring-4 focus:outline-none focus:ring-green-500 dark:bg-green-500 dark:hover:bg-green-500 dark:focus:ring-green-500">
                        Verify
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <label className="block text-sm font-semibold text-gray-700">Password</label>
                <div className="relative">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    placeholder="Password"
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-400 focus:ring-1"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" onClick={togglePasswordVisibility}>
                    {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                  </div>
                </div>

              </div>
              <button
                type="submit"
                className="w-full py-2 px-4 bg-black text-white font-semibold rounded-md shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2"
              >
                SIGN UP
              </button>
              <p className="mt-6 text-center text-gray-600">
                Already have an Account?{' '}
                <button onClick={onLoginClick} className="text-indigo-500 hover:text-indigo-700 font-semibold">
                  Log In
                </button>
              </p>
            </form>
            <div className="mt-6">
              <button
                type="button"
                className="w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-gray-700 font-medium flex items-center justify-center hover:bg-gray-100 focus:outline-none focus:ring-2"
              >
                <img
                  src="https://img.icons8.com/color/16/000000/google-logo.png"
                  alt="Google"
                  className="mr-2"
                />
                Continue With Google
              </button>
              <button
                type="button"
                className="w-full mt-3 py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-gray-700 font-medium flex items-center justify-center hover:bg-gray-100 focus:outline-none focus:ring-2"
              >
                <img
                  src="https://img.icons8.com/ios-filled/16/000000/mac-os.png"
                  alt="Apple"
                  className="mr-2"
                />
                Continue With Apple
              </button>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default SignUp;
