import React, { useState, useMemo } from 'react';
import { FaArrowTrendUp } from "react-icons/fa6";
import Dropdown from '../../Component/utiles/Dropdown';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


const processChartData = (data, period) => {
    const now = new Date();
    let startDate;

    switch (period) {
        case 'last 3 month':
            startDate = new Date(now.setMonth(now.getMonth() - 3));
            break;
        case 'last 6 month':
            startDate = new Date(now.setMonth(now.getMonth() - 6));
            break;
        case 'last 1 year':
            startDate = new Date(now.setFullYear(now.getFullYear() - 1));
            break;
        default:
            startDate = new Date(0);
    }

    const filteredData = data.filter(item => new Date(item.date) >= startDate);

    const groupByMonth = (data) => {
        const grouped = data.reduce((acc, item) => {
            const [month, day, year] = item.date.split('/');
            const monthYear = new Date(year, month - 1);

            const monthYearStr = monthYear.toLocaleString('default', { month: 'short' }) + '-' + year;

            if (!acc[monthYearStr]) {
                acc[monthYearStr] = { bar_1value: 0, bar_2value: 0 };
            }
            acc[monthYearStr].bar_1value += item.bar_1value;
            acc[monthYearStr].bar_2value += item.bar_2value;
            return acc;
        }, {});

        return Object.keys(grouped).map(monthYear => ({
            date: monthYear,
            bar_1: grouped[monthYear].bar_1value,
            bar_2: grouped[monthYear].bar_2value
        }));
    };

    const getMonthNamesInRange = (startDate, endDate) => {
        const months = [];
        const currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            const monthName = currentDate.toLocaleString('default', { month: 'short' });
            months.push(`${monthName}-${currentDate.getFullYear()}`);
            currentDate.setMonth(currentDate.getMonth() + 1);
        }

        return months;
    };

    const endDate = new Date();
    const monthRange = getMonthNamesInRange(startDate, endDate);
    const grouped = groupByMonth(filteredData);

    return monthRange.map(monthYear => {
        const saleData = grouped.find(item => item.date === monthYear) || { bar_1: 0, bar_2: 0 };
        return {
            date: monthYear,
            bar_1: saleData.bar_1,
            bar_2: saleData.bar_2
        };
    });





};

const Barchart = ({ data, period ,label}) => {

    const filteredData = useMemo(() => processChartData(data, period), [data, period]);

    const options = {
        chart: {
            type: 'column'
        },
        title: {
            text: '',
            align: 'left'
        },
        xAxis: {
            categories: filteredData.map(data => {
                const [month] = data.date.split('-');
                return month;
            }),
            crosshair: true,
            accessibility: {
                description: 'Dates'
            }
        },
        tooltip: {
            valueSuffix: ''
        },
        yAxis: {
            min: 0,
            title: {
                text: ''
            }
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: [
            {
                name: `<span class='capitalize'>${label[0]}</span>`,
                data: filteredData.map(data => data.bar_1)
            },
            {
                name: `<span class='capitalize'>${label[1]}</span>`,
                data: filteredData.map(data => data.bar_2)
            }
        ],
        legend: {
            enabled: true,
        },
        credits: {
            enabled: false
        }
    };

    const totalYValue2 = filteredData.reduce((sum, item) => sum + item.bar_1, 0);
    const formattedTotalYValue2 = new Intl.NumberFormat('en-IN').format(totalYValue2);

    const totalYValue3 = filteredData.reduce((sum, item) => sum + item.bar_2, 0);
    const formattedTotalYValue3 = new Intl.NumberFormat('en-IN').format(totalYValue3);


    return (
        <>
            <div className="w-full overflow-x-auto">
                <div className="lg:w-full md:w-[100vw] w-[150vw]">
                    <HighchartsReact highcharts={Highcharts} options={options} />
                </div>
            </div>
            <div className='flex xl:flex-row w-full flex-col px-5 my-5 gap-5 justify-evenly'>
                <div className='flex flex-row place-items-start gap-8'>
                    <div className='flex flex-col '>
                        <span className='text-gray-400 text-nowrap capitalize'>Total {label[0]}</span>
                        <span className='xl:text-3xl md:text-xl font-medium mt-1'>{formattedTotalYValue2}</span>
                    </div>
                    <div className='flex flex-row items-center gap-3'>
                        <span><FaArrowTrendUp className='text-xl' /></span>
                        <span> 1.54 %</span>
                    </div>
                </div>
                <div className='flex flex-row place-items-start gap-8'>
                    <div className='flex flex-col'>
                        <span className='text-gray-400 text-nowrap capitalize'>Total {label[1]}</span>
                        <span className='xl:text-3xl md:text-xl font-medium mt-1'>{formattedTotalYValue3}</span>
                    </div>
                    <div className='flex flex-row items-center gap-3'>
                        <span><FaArrowTrendUp className='text-xl' /></span>
                        <span> 1.54 %</span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Barchart