import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

import { Autoplay } from 'swiper/modules';

import { FaEye, FaRegHeart, FaShoppingCart } from 'react-icons/fa';
import { MdAddShoppingCart } from "react-icons/md";

import ProductImg from '../Assets/Images/grid_product.png'
import { FaStar, FaRegStar, FaStarHalfAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { UserState } from '../../Context/userProvider';
import { useCart } from '../../Context/CartContext';
import Loader from '../utiles/Loader';
import notify from '../utiles/Notification';




const ProductCard = ({ product, path }) => {

  const baseUrl = process.env.REACT_APP_BASEURL;

  const { updateCartCount, AddToCart } = useCart();
  const { user } = UserState();
  const [loading, setLoading] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);


  const handleMouseEnter = () => {
    if (product.product_img.length > 1) {
      setCurrentImageIndex(1);
    }
  };

  const handleMouseLeave = () => {
    setCurrentImageIndex(0);
  };

  return (
    <>
      {loading && <Loader />}
      <div className="group relative bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
        <div className="relative overflow-hidden aspect-square">
          {product.discount > 0 && (
            <span className="absolute top-4 left-4 z-10 bg-red-500 text-white px-2 py-1 text-xs font-medium rounded">
              -{product.discount}% OFF
            </span>
          )}

          <div
            className="w-full h-full flex items-center justify-center p-4 bg-gray-100"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Link to={path}>

              <img
                src={product.product_img[currentImageIndex].images}
                alt={product.product_name}
                className="object-contain w-full h-full transform transition-transform duration-300 group-hover:scale-105"
                loading="lazy"
              />

            </Link>
          </div>

          <div className="absolute right-4 top-4 flex flex-col gap-2 transform translate-x-12 opacity-0 group-hover:opacity-100 group-hover:translate-x-0 transition-all duration-300">
            {/* <button className="p-2 bg-white rounded-full shadow-md hover:bg-gray-50 transition-colors">
                <FaRegHeart className="w-5 h-5 text-gray-600" />
              </button> */}
            <button className="p-2 bg-white rounded-full shadow-md hover:bg-gray-50 transition-colors" onClick={() => AddToCart(product, product.product_id, product.product_varient_id)}>
              <FaShoppingCart className="w-5 h-5 text-gray-600" />
            </button>
            {/* <button className="p-2 bg-white rounded-full shadow-md hover:bg-gray-50 transition-colors" onClick={() => toggleModal(product)}>
                <FaEye className="w-5 h-5 text-gray-600" />
              </button> */}
          </div>
        </div>

        <div className="p-4">
          <span className="text-xs text-gray-500 uppercase tracking-wider">
            {product.category_name}
          </span>

          <h3 className="mt-1 text-sm font-medium text-gray-900 line-clamp-2">
            {product.product_name}
          </h3>

          <div className="mt-2 flex items-center justify-between">
            <span className="text-lg font-semibold text-gray-900 transition-all duration-300 group-hover:text-emerald-600">
            {product.symbol}&nbsp;{product.price}
            </span>

            {product.discount > 0 && (
              <span className="text-sm text-gray-500 line-through">
                {product.symbol}&nbsp;{(product.price / (1 - product.discount / 100)).toFixed(2)}
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};


const Featuredproduct = ({ productData }) => {

  // const featuredProducts = productData.filter(product => product.feature === 1);



  return (
    <div className="container mx-auto  bestseller my-10 px-4 py-8">
      <div className='flex justify-between mb-6'>
        <h1 className="text-2xl md:text-3xl font-semibold pb-3 text-gray-900">Featured Products</h1>
        <a href="#" className='hover-effect text-md md:text-lg text-gray-400 font-medium'>View All</a>
      </div>
      <div className='xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-rows-1 xl:gap-7 md:gap-5 gap-5 px-2 md:grid hidden ' data-aos="fade-up">
        {productData.slice(0, 8).map((product, index) => (
          <ProductCard key={index} product={product} path={`/product/${product.product_id}`} />
        ))}
      </div>
      <div className='md:hidden block px-2'>
        <Swiper
          slidesPerView={5}
          loop={true}
          spaceBetween={10}
          breakpoints={{
            200: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}

          modules={[Autoplay]}
          className="mySwiper"
        >
          {productData.slice(0, 8).map((product) => (
            <SwiperSlide className='' key={product.product_id}>
              <ProductCard key={product.id} product={product} path={`/product/${product.product_id}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default Featuredproduct