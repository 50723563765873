import React, { useState, useMemo } from 'react';
import { FaArrowTrendUp } from "react-icons/fa6";
import Dropdown from '../../Component/utiles/Dropdown';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Barchart from './Barchart';

const sale_revenue_data = [
    { bar_1value: 300, bar_2value: 500, date: '08/08/2024' },
    { bar_1value: 260, bar_2value: 450, date: '07/08/2024' },
    { bar_1value: 340, bar_2value: 600, date: '08/01/2024' },
    { bar_1value: 1000, bar_2value: 800, date: '07/25/2024' },
    { bar_1value: 150, bar_2value: 200, date: '07/20/2024' },
    { bar_1value: 420, bar_2value: 700, date: '06/28/2024' },
    { bar_1value: 700, bar_2value: 650, date: '06/15/2024' },
    { bar_1value: 300, bar_2value: 400, date: '06/01/2024' },
    { bar_1value: 500, bar_2value: 800, date: '05/15/2024' },
    { bar_1value: 800, bar_2value: 750, date: '05/10/2024' },
    { bar_1value: 400, bar_2value: 500, date: '05/05/2024' },
    { bar_1value: 600, bar_2value: 850, date: '04/25/2024' },
    { bar_1value: 950, bar_2value: 900, date: '04/15/2024' },
    { bar_1value: 500, bar_2value: 650, date: '04/10/2024' },
    { bar_1value: 700, bar_2value: 950, date: '04/05/2024' },
    { bar_1value: 850, bar_2value: 800, date: '03/30/2024' },
    { bar_1value: 550, bar_2value: 700, date: '03/25/2024' },
    { bar_1value: 900, bar_2value: 1100, date: '03/15/2024' },
    { bar_1value: 1000, bar_2value: 950, date: '03/05/2024' },
    { bar_1value: 600, bar_2value: 750, date: '02/28/2024' },
    { bar_1value: 550, bar_2value: 800, date: '02/20/2024' },
    { bar_1value: 750, bar_2value: 700, date: '02/10/2024' },
    { bar_1value: 300, bar_2value: 400, date: '01/25/2024' },
    { bar_1value: 650, bar_2value: 900, date: '01/10/2024' },
    { bar_1value: 900, bar_2value: 850, date: '12/15/2023' },
    { bar_1value: 400, bar_2value: 550, date: '12/05/2023' },
    { bar_1value: 700, bar_2value: 950, date: '11/30/2023' },
    { bar_1value: 800, bar_2value: 750, date: '11/15/2023' },
    { bar_1value: 450, bar_2value: 600, date: '11/05/2023' },
    { bar_1value: 500, bar_2value: 750, date: '10/25/2023' },
    { bar_1value: 950, bar_2value: 900, date: '10/10/2023' },
    { bar_1value: 350, bar_2value: 500, date: '09/30/2023' },
    { bar_1value: 600, bar_2value: 850, date: '09/15/2023' }
];


const Earningsrevenue = () => {
    const checkdataoption = [
        { label: 'last 3 month' },
        { label: 'last 6 month' },
        { label: 'last 1 year' }
    ];

    const [checkData, setCheckData] = useState(checkdataoption[0].label);

    const labels = ['sale','revenue'];

    return (
        <>
            <div className='md:w-7/12  w-full rounded-lg bg-white  overflow-hidden'>
                <div className="flex flex-row justify-between place-items-center px-3 pt-1 mb-28">
                    <h1 className='text-lg md:text-xl font-semibold'>Earnings revenue</h1>
                    <div className='w-36'>
                        <Dropdown
                            label=""
                            options={checkdataoption}
                            selectedValues={checkData}
                            onSelect={setCheckData}
                            onRemove={() => { }}
                            isMultiple={false}
                        />
                    </div>
                </div>

                <Barchart data={sale_revenue_data} period={checkData} label={labels}/>
            </div>
        </>
    );
}

export default Earningsrevenue;
