import React from 'react';
import '../Assets/css/Loader.css';
import { FaCartShopping } from "react-icons/fa6";
const Loader = () => {
  return (
    <div className="loader-overlay">
      <div className="loader">
      </div>
         
    </div>
  );
};

export default Loader;
