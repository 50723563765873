import React, { useEffect, useState } from 'react'

import { Link, useParams } from 'react-router-dom';
import Profileui from '../../Component/Profileui';
import axios from 'axios';

const Infulancersprofile = ({setLoading}) => {

    const { id } = useParams();

    const baseUrl = process.env.REACT_APP_BASEURL


    const [userData, setUserData] = useState('');
    const [refresh, setRefresh] = useState(0);


    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setLoading(true);
                const response = await axios.post(`${baseUrl}/user_data`,
                    { user_id: id },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
                setUserData(response.data.data[0]);
                setLoading(false)
            } catch (err) {
                console.log(err);
            }
        };

        fetchUserData();
    }, [refresh]);

    return (
        <>
            <h1 className='xl:text-2xl md:text-lg text-xl mt-3 font-semibold capitalize'>infulancer profile</h1>
            <Profileui userData={userData} />
        </>
    )
}

export default Infulancersprofile