import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaStar, FaStarHalfAlt, FaRegStar, FaEye } from 'react-icons/fa';
import axios from 'axios';
import { UserState } from '../../Context/userProvider';
import { useCart } from '../../Context/CartContext';
import Loader from '../utiles/Loader';
import notify from './Notification';


const Allcard = ({ product, toggleModal, path }) => {
  const baseUrl = process.env.REACT_APP_BASEURL;


  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { user } = UserState();
  const { updateCartCount, AddToCart } = useCart();
  const [loading, setLoading] = useState(false);

  const handleMouseEnter = () => {
    if (product.product_img.length > 1) {
      setCurrentImageIndex(1);
    }
  };

  const handleMouseLeave = () => {
    setCurrentImageIndex(0);
  };
  const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;

    for (let i = 1; i <= 5; i++) {
      if (i <= fullStars) {
        stars.push(<FaStar key={i} className="text-yellow-400" />);
      } else if (i === fullStars + 1 && hasHalfStar) {
        stars.push(<FaStarHalfAlt key={i} className="text-yellow-400" />);
      } else {
        stars.push(<FaRegStar key={i} className="text-gray-300" />);
      }
    }

    return stars;
  };

  return (
    <>
      {loading && <Loader />}
      <div className="bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl">
        <div className="relative group">
          <Link
            to={path}
            className="block aspect-square overflow-hidden"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img
              loading="lazy"
              src={product.product_img[currentImageIndex].images}
              alt={product.product_name}
              className="w-full h-90% object-cover transition-transform duration-300 group-hover:scale-105"
            />
          </Link>
          <div className="absolute top-2 right-2 flex flex-col gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
            {/* <button className="p-2 bg-white rounded-full shadow-md hover:bg-gray-100 transition-colors">
            <FaRegHeart className="text-gray-700" />
          </button> */}
            <button className="p-2 bg-white rounded-full shadow-md hover:bg-gray-100 transition-colors" onClick={() => toggleModal(product)}>
              <FaEye className="text-gray-700" />
            </button>
          </div>
        </div>
        <div className="p-4">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-sm font-semibold text-gray-800 truncate">{product.product_name}</h3>
            <span className="text-lg font-bold text-green-600">{product.symbol}&nbsp;{product.price}</span>
          </div>
          <p className="text-xs text-gray-500 mb-2 truncate">{product.sub_text}</p>
          <div className="flex items-center mb-2">
            <div className="flex text-yellow-400 mr-1">
              {renderStars(product.reviews_avg || 0)}
            </div>
            <span className="text-xs text-gray-500">({product.review_count || 0} reviews)</span>
          </div>
          <button className="w-full py-2 bg-black text-white rounded-full hover:bg-opacity-90 transition-colors" onClick={() => AddToCart(product, product.product_id, product.product_varient_id)}>
            Add to Cart
          </button>
        </div>
      </div>
    </>
  );
};

export default Allcard;


