import React from 'react'
import { Link } from 'react-router-dom'

const Userprofile = ({ user, updatestatus , bgColor}) => {

    const firstChar = user.name.charAt(0)

    const getStatusClass = (stockStatus) => {
        switch (stockStatus.toLowerCase()) {
          case 'in stock':
          case 'active':
          case 'live':
            return 'bg-green-200 text-green-600 text-xs';
          case 'out of stock':
          case 'inactive':
          case 'hold':
            return 'bg-red-200 text-red-500 text-xs';
          default:
            return 'bg-gray-400 text-white text-xs';
        }
      };


    return (
        <>
            <div className="border h-full w-full border-neutral-300 rounded-lg overflow-hidden flex flex-row md:flex-col">
                <div className="md:w-full w-20 px-3 bg-neutral-300 md:h-24 relative ">
                    {user.user_image != null && user.user_image !== '0' ? (
                        <img src={user.user_image} alt={user.name} className='md:h-20 md:w-20 h-16 w-16 top-1/4 left-1/2 md:left-1/3  md:top-1/2 rounded-full absolute ' />
                    ) : (
                        <h1 className={`${bgColor} text-3xl capitalize text-black  md:h-20 md:w-20 h-16 w-16 top-1/4 left-1/2 md:left-1/3  md:top-1/2 rounded-full absolute flex justify-center place-items-center`}>{firstChar}</h1>
                    )}
                </div>
                <div className="flex flex-col justify-center md:h-52 place-items-center md:gap-3 gap-2 w-full md:pt-8 md:py-0 py-2">
                    <h1 className='text-base font-semibold capitalize'>{user.name}</h1>
                    <span className='text-xs font-normal text-neutral-500 capitalize'>supplier <span className='uppercase'>id :&nbsp;</span> {user.unique_id}</span>
                    <span className='text-xs font-normal text-neutral-500'>{user.email}</span>
                    <span className='text-xs font-normal text-neutral-500'>status : <small className={`${getStatusClass(user.status)} rounded-lg px-2 py-1`}>{user.status}</small></span>
                    <div className="flex flex-row gap-5">
                        <label className="inline-flex items-center cursor-pointer text-nowrap ">
                            <span className=" text-sm font-medium text-gray-900 dark:text-gray-300 pe-3">status</span>
                            <input type="checkbox" className="sr-only peer" value='active'
                                checked={user.status === "Active"}
                                onClick={() => updatestatus(user.user_id, user.status, "user")}
                            />
                            <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600"></div>
                        </label>
                        <Link to={`profile/` + user.user_id} className='text-black font-medium capitalize flex justify-center place-items-center  h-7 md:h-10 md:text-base text-sm border border-neutral-300 w-28 rounded-md'>profile</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Userprofile