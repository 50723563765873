import React from 'react'

const Profileui = ({ userData}) => {
    return (
        <>
            <div className="w-full overflow-hidden rounded-lg my-5">
                <div className="h-36 bg-neutral-300  w-full relative">
                    <img src={userData.user_image} alt="" className='rounded-md h-40 w-40 top-1/2 left-6 absolute' />
                </div>
                <div className="w-full h-full px-6 pb-6 bg-white">
                    <div className="flex flex-col ps-44 h-28 pt-6 border-b border-neutral-300">
                        <h1 className='text-2xl font-semibold capitalize'>{userData.name}</h1>
                        <span className='text-base font-normal text-neutral-500 capitalize'>{userData.role} <span className='uppercase'>id :&nbsp;</span> {userData.unique_id}</span>
                    </div>
                    <div className="mt-5 flex flex-row gap-5">
                        <div className="border border-neutral-300 rounded-lg flex flex-col p-5">
                            <span className='text-xl text-neutral-400'>Total sales :</span>
                            <h1 className="text-xl text-green-500 font-semibold">4583</h1>
                            <span className='text-xl text-neutral-400'>Total revenue :</span>
                            <h1 className="text-xl text-green-500 font-semibold">49945.00 €</h1>
                        </div>
                        <div className="border border-neutral-300 rounded-lg flex flex-col p-5 gap-1">
                            <span className='text-xl text-black font-medium capitalize'>contact</span>
                            <span className='text-xl text-neutral-400'>{userData.email}</span>
                            <span className='text-xl text-neutral-400'>+{userData.mobile}</span>
                        </div>
                        <div className="border border-neutral-300 rounded-lg flex flex-col p-5 gap-1">
                            <span className='text-xl text-black font-medium capitalize'>address</span>
                            <span className='text-xl text-neutral-400 font-normal capitalize'>country : <span>{userData.country_name}</span></span>
                            <span className='text-xl text-neutral-400 font-normal capitalize'>address : <span>{userData.address}, {userData.city}, {userData.state_subdivision_name} </span></span>
                            <span className='text-xl text-neutral-400 font-normal capitalize'>Postal Code : <span>{userData.pin_code}</span></span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profileui