import React, { useState, useEffect } from 'react';
import { BrowserRouter, Router, Route, Routes } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component';
import AOS from 'aos';
import 'aos/dist/aos.css';
import LandingPage from './Component/LandingPage/LandingPage';
import MyCart from './Component/Pages/MyCart';
import WishList from './Component/Pages/WishList';
import SignUp from './Component/Pages/SignUp';
import ProductPage from './Component/Pages/Product_Page';
import CheckoutPage from './Component/Pages/Checkout_page';
import AllBestSeller from './Component/Pages/AllBestSeller';
import NewArrivals from './Component/Pages/NewArrivals';
import Footer from './Component/LandingPage/Footer';
import Navbar from './Component/LandingPage/Navbar';
import ProductModal from './Component/utiles/ProductModal';
import Profilepage from './Component/Pages/Profile_pages/Profilepage';
import Dashboard from './Component/Pages/Profile_pages/component/Dashboard';
import Home from './Admin/Home';
import FooterNav from './Component/Pages/Footer_nav/Footer_nav';
import AdminLogin from './Admin/Pages/Login';
import ForgotPassword from './Admin/Pages/Forgotpassword';
import ErrorPage from './Component/Pages/Errorpage';
import TrackingPage from './Component/Pages/Tracking_page'
import ProtectedRoute from '../src/Admin/ProtectedRoutes'
import BecomeSeller from './Component/Pages/BecomeSeller';
import BecomeInfluencer from './Component/Pages/BecomeInfluencer';
import AddProduct from './Admin/Pages/Addproduct';
import Support from './Admin/Pages/Support';
import CustomerRoutes from './Component/CustomerRoutes/CustomerRoutes';
import axios from 'axios';
import Loader from './Component/utiles/Loader';
import { useCart } from './Context/CartContext';
import { UserState } from './Context/userProvider';
import Categorproduct from './Component/Pages/Categorproduct';
import Search from './Component/Pages/Search';
import Success from './Component/Pages/Success';

function App() {
  AOS.init({
    duration: 2000,
    easing: 'ease',
  });

  const [isOpen, setIsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { updateCartCount, loading, setLoading } = useCart();

  const { user, logout, geoLocation, countryCode } = UserState();


  const toggleModal = (product = null) => {
    setIsOpen(!isOpen);
    setSelectedProduct(product);
  };



  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const baseUrl = process.env.REACT_APP_BASEURL;

  const [productData, setProductData] = useState([]);


  useEffect(() => {
    const fetchAllProducts = async () => {
      try {
        const response = await axios.post(`${baseUrl}/get_all_product_data`, {
          countryCode: countryCode
        });
        if (response.data.status) {
          setProductData(response.data.MainproductsAllData);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchAllProducts();
  }, [geoLocation]);

  const rand = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  // const [randint, setRand] = useState(rand(11, 99));

  useEffect(() => {
    const getAllProducts = async () => {
      setLoading(true);
      try {
        const response = await axios.post(`${baseUrl}/get_cart`, {
          customer_id: user.customer_id,
        });

        if (response.data.status) {
          updateCartCount(response.data.cartCount);
          setLoading(false);
        } else {
          updateCartCount(0)
          setLoading(false);
        }
      } catch (error) {
        console.error('Error during status update:', error);
        setLoading(false);
      }
    };
    getAllProducts();
  }, [user]);

  return (
    <>

      <ReactNotifications />
      <Routes>
        <Route path="/" element={<LandingPage productData={productData} />} />
        <Route path="/become-seller" element={<BecomeSeller />} />
        <Route path="/become-influencer" element={<BecomeInfluencer />} />
        <Route path="/product/:id" element={<ProductPage toggleModal={toggleModal} setLoading={setLoading} geoLocation={geoLocation} />} />
        <Route path="/tracking-order" element={<CustomerRoutes><TrackingPage /></CustomerRoutes>} />
        <Route path="/my-cart" element={<CustomerRoutes><MyCart /></CustomerRoutes>} />
        <Route path="/wish-list" element={<CustomerRoutes><WishList /></CustomerRoutes>} />
        <Route path="/checkout" element={<CustomerRoutes><CheckoutPage setLoading={setLoading} /></CustomerRoutes>} />
        <Route path="/success" element={<CustomerRoutes><Success setLoading={setLoading} /></CustomerRoutes>} />
        <Route path="/all-bestseller" element={<AllBestSeller toggleModal={toggleModal} productData={productData} />} />
        <Route path="/new-arrivals" element={<NewArrivals toggleModal={toggleModal} productData={productData} />} />
        <Route path="/my-profile/*" element={<CustomerRoutes><Profilepage /></CustomerRoutes>} />
        <Route path="/admin/*" element={<Adminroutes />} />
        <Route path="/pages/*" element={<FooterNav setLoading={setLoading} />} />
        <Route path="/category/:id" element={<Categorproduct toggleModal={toggleModal} setLoading={setLoading} />} />
        <Route path="/search/:data" element={<Search toggleModal={toggleModal} setLoading={setLoading} />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <ProductModal isOpen={isOpen} toggleModal={toggleModal} product={selectedProduct} setLoading={setLoading} />
      {loading && <Loader />}
    </>
  );
}

const Adminroutes = () => {
  return (
    <>
      <Routes>
        <Route
          path='/*'
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route path='/login' element={<AdminLogin />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
      </Routes>
    </>
  )
}

export default App;
