import React, { useState } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { RxCross2 } from "react-icons/rx";

const Filters = ({ onFilterChange, inStockCount = 0, priceFrom, priceTo,showInStockOnly, highestPrice = 0, uniqueColors = [], uniqueSizes = [], selectedColors, selectedSizes, selectedCategories, uniqueCategories = [], selectedRatings = [], toggleFilter }) => {

    const [isAvailabilityOpen, setIsAvailabilityOpen] = useState(true);
    const [isPriceOpen, setIsPriceOpen] = useState(true);
    const [isColorOpen, setIsColorOpen] = useState(true);
    const [isSizeOpen, setIsSizeOpen] = useState(true);
    const [isCategoryOpen, setIsCategoryOpen] = useState(true);
    const [isRatingOpen, setIsRatingOpen] = useState(true);



    const toggleAvailability = () => setIsAvailabilityOpen(prev => !prev);
    const togglePrice = () => setIsPriceOpen(prev => !prev);
    const toggleColor = () => setIsColorOpen(prev => !prev);
    const toggleSize = () => setIsSizeOpen(prev => !prev);
    const toggleCategory = () => setIsCategoryOpen(prev => !prev);
    const toggleRating = () => setIsRatingOpen(prev => !prev);

    const handleCheckboxChange = () => {
        const newShowInStockOnly = !showInStockOnly;
        onFilterChange({
            showInStockOnly: newShowInStockOnly,
        });
    };

    const handlePriceFromChange = (e) => {
        const newPriceFrom = e.target.value;
        onFilterChange({
            priceFrom: newPriceFrom,
        });
    };

    const handlePriceToChange = (e) => {
        const newPriceTo = e.target.value;
        onFilterChange({
            priceTo: newPriceTo,
        });
    };

    const handleColorChange = (color) => {
        const newSelectedColors = selectedColors.includes(color)
            ? selectedColors.filter(s => s !== color)
            : [...selectedColors, color];
        onFilterChange({
            selectedColors: newSelectedColors,
        });
    };
    const handleSizeChange = (size) => {
        const newSelectedSizes = selectedSizes.includes(size)
            ? selectedSizes.filter(c => c !== size)
            : [...selectedSizes, size];
        onFilterChange({
            selectedSizes: newSelectedSizes,
        });
    };

    const handleCategoryChange = (category) => {
        const newSelectedCategories = selectedCategories.includes(category)
            ? selectedCategories.filter(c => c !== category)
            : [...selectedCategories, category];
        onFilterChange({
            selectedCategories: newSelectedCategories,
        });
    };
    const handleRatingChange = (rating) => {
        const newSelectedRatings = selectedRatings.includes(rating)
            ? selectedRatings.filter(r => r !== rating)
            : [...selectedRatings, rating];
        onFilterChange({
            selectedRatings: newSelectedRatings,
        });
    };

    const ratingss = [
        {
            rtg: '1',
        },
        {
            rtg: '2',
        },
        {
            rtg: '3',
        },
        {
            rtg: '4',
        },
    ]

    // console.log("Unique Sizes:", Array.from(uniqueSizes));

    return (
        <>

            <div className='filters shd rounded-md px-3 py-5 text-gray-600 '>
                <div className='flex justify-between mb-5'>
                    <h1 className='font-medium text-xl ps-2'>Filter :</h1>
                    <button className='text-3xl me-2' onClick={toggleFilter}><RxCross2 /></button>
                </div>
                <div className='all_filters'>
                    <div className='px-2 mt-5'>
                        <button className='text-4xl w-full flex justify-between place-items-center' onClick={toggleAvailability}>
                            <span className='xl:text-md lg:text-base lg:font-medium md:font-semibold md:text-sm text-xl'>Availability</span>
                            <span className={`transition-all duration-200 ${isAvailabilityOpen ? 'rotate-180' : ''}`}>
                                <RiArrowDropDownLine />
                            </span>
                        </button>
                        <div className={`flex gap-2 items-center overflow-hidden transition-all ps-4 duration-500 ${isAvailabilityOpen ? 'h-auto' : 'h-0'}`}>
                            <input className="focus:ring-0 rounded-md checked:bg-black" type="checkbox" id="stock_box" checked={showInStockOnly} onChange={handleCheckboxChange} />
                            <label className="checkbox-label xl:text-md md:text-sm text-nowrap font-medium text-sm mt-2" htmlFor="checkbox">In Stock ({inStockCount})</label>
                        </div>
                    </div>
                    <div className='px-2 mt-3'>
                        <button className='text-4xl w-full flex justify-between place-items-center' onClick={toggleCategory}>
                            <span className='xl:text-md lg:text-base lg:font-medium md:font-semibold md:text-sm text-xl'>Category</span>
                            <span className={`transition-all duration-200 ${isCategoryOpen ? 'rotate-180' : ''}`}>
                                <RiArrowDropDownLine />
                            </span>
                        </button>
                        <div className={` overflow-hidden transition-all ps-4 duration-500 ${isCategoryOpen ? 'h-auto' : 'h-0'}`}>
                            <div className='grid float-left grid-cols-1 gap-2 Sizess mt-2'>
                                {uniqueCategories.map((category, index) => (
                                    <div className="flex items-center mb-2" key={index}>
                                        <input
                                            type="checkbox"
                                            checked={selectedCategories.includes(category)}
                                            onChange={() => handleCategoryChange(category)}
                                            className="focus:ring-0 rounded-md checked:bg-black"
                                        />
                                        <label className="ml-2 text-gray-700">{category}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <button className='text-4xl w-full flex justify-between place-items-center px-2' onClick={togglePrice}>
                            <span className='xl:text-md lg:text-base lg:font-medium md:font-semibold md:text-sm text-xl'>Price <span className='lg:text-xl md:text-md lg:hidden'>€</span></span>
                            <span className={`transition-all duration-200 ${isPriceOpen ? 'rotate-180' : ''}`}>
                                <RiArrowDropDownLine />
                            </span>
                        </button>
                        <div className={`mt-1 overflow-hidden transition-all duration-500 ${isPriceOpen ? 'h-auto' : 'h-0'}`}>
                            <span className='mt-1 ps-2 xl:flex xl:place-items-center'>The Highest Price is <h1 className='text-yellow-500 font-medium text-lg md:ps-2 xl:ps-3'>€ &nbsp;{highestPrice}</h1></span>
                            <div className="flex flex-col lg:flex-row xl:flex-row justify-center lg:gap-2 gap-0 place-items-center mt-2 px-2">
                                <span className='lg:text-xl md:text-md hidden xl:block'>€</span>
                                <div className='flex-col lg:flex-row justify-center'>
                                    <input type="number" id="price_from" value={priceFrom} onChange={handlePriceFromChange} className="border xl:w-5/12 lg:w-20 md:w-16 w-20 border-gray-300 focus:ring-0 focus:border-black rounded px-1 py-1" placeholder='From' />
                                    <span className='mx-1 font-semibold xl:text-xl md:text-ms'>-</span>
                                    <input type="number" id="price_to" value={priceTo} onChange={handlePriceToChange} className="border xl:w-5/12 lg:w-20 md:w-14 w-20 border-gray-300 focus:ring-0 focus:border-black rounded px-1 py-1" placeholder='To' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='px-2 mt-3'>
                        <button className='text-4xl w-full flex justify-between place-items-center' onClick={toggleColor}>
                            <span className='xl:text-md lg:text-base lg:font-medium md:font-semibold md:text-sm text-xl'>Colors</span>
                            <span className={`transition-all duration-200 ${isColorOpen ? 'rotate-180' : ''}`}>
                                <RiArrowDropDownLine />
                            </span>
                        </button>
                        <div className={` overflow-hidden transition-all ps-4 duration-500 ${isColorOpen ? 'h-auto' : 'h-0'}`}>
                            <div className='grid gap-2 float-left grid-rows-4 colorss'>
                                <style>
                                    {uniqueColors.map(color => `
                                .color-checkbox-${color}::before {
                                    background-color: ${color};
                                }
                            `)}
                                </style>
                                {uniqueColors.map(color => (
                                    <div key={color} className='flex flex-row justify-start place-items-center mt-1'>
                                        <input type="checkbox" className={`h-5 w-5 focus:ring-0 checked:grid color-checkbox-${color}  checked:place-items-center before:rounded-full border-0 rounded-full`} style={{ backgroundColor: selectedColors.includes(color) ? 'transparent' : color, }} id={color} value={color} checked={selectedColors.includes(color)} onChange={() => handleColorChange(color)} />
                                        <label className="checkbox-label xl:text-md md:text-sm text-nowrap font-medium ms-2 capitalize" htmlFor={color}>{color}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='px-2 mt-3'>
                        <button className='text-4xl w-full flex justify-between place-items-center' onClick={toggleSize}>
                            <span className='xl:text-md lg:text-base lg:font-medium md:font-semibold md:text-sm text-xl'>Sizes</span>
                            <span className={`transition-all duration-200 ${isSizeOpen ? 'rotate-180' : ''}`}>
                                <RiArrowDropDownLine />
                            </span>
                        </button>
                        <div className={` overflow-hidden transition-all ps-4 duration-500 ${isSizeOpen ? 'h-auto' : 'h-0'}`}>
                            <div className='grid float-left grid-cols-3 gap-5 Sizess'>
                                {uniqueSizes.map(size => (
                                    <div key={size} className='flex sizes flex-row justify-start place-items-center mt-1'>
                                        <input type="checkbox" className={` focus:ring-0 rounded-md checked:bg-black`} id={size} value={size} checked={selectedSizes.includes(size)} onChange={() => handleSizeChange(size)} />
                                        <label className="checkbox-label xl:text-md md:text-sm text-nowrap font-medium ms-2 uppercase " htmlFor={size}>{size}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='px-2 mt-3'>
                        <button className='text-4xl w-full flex justify-between place-items-center' onClick={toggleRating}>
                            <span className='xl:text-md lg:text-base lg:font-medium md:font-semibold md:text-sm text-xl'>Ratings</span>
                            <span className={`transition-all duration-200 ${isRatingOpen ? 'rotate-180' : ''}`}>
                                <RiArrowDropDownLine />
                            </span>
                        </button>
                        <div className={` overflow-hidden transition-all ps-4 duration-500 ${isRatingOpen ? 'h-auto' : 'h-0'}`}>
                            <div className='grid float-left grid-cols-1 gap-5 Sizess'>
                                {ratingss.map(({ rtg }) => (
                                    <div key={rtg} className='flex sizes flex-row justify-start place-items-center mt-1'>
                                        <input type="checkbox" className="focus:ring-0 rounded-md checked:bg-black" id={rtg} value={rtg} checked={selectedRatings.includes(rtg)}
                                            onChange={() => handleRatingChange(rtg)} />
                                        <label className="checkbox-label xl:text-md flex place-items-center md:text-sm text-nowrap font-medium ms-2 uppercase" htmlFor={rtg}>{rtg}
                                            <div className='rtgg ms-2 '>
                                                {[...Array(5)].map((star, index) => (
                                                    <svg
                                                        key={index}
                                                        className={`md:w-5 md:h-5 w-4 h-4 inline-block pb-1 ${index < rtg ? 'text-yellow-300' : 'text-gray-300'}`}
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <path d="M9.049 2.927C9.39 2.029 10.611 2.029 10.951 2.927L12.613 7.03C12.763 7.438 13.146 7.75 13.586 7.833L18.177 8.702C19.135 8.884 19.508 10.086 18.839 10.728L15.514 13.89C15.167 14.213 15.01 14.712 15.111 15.198L16.145 19.655C16.351 20.623 15.308 21.371 14.505 20.842L10.5 18.394C10.197 18.203 9.803 18.203 9.5 18.394L5.495 20.842C4.692 21.371 3.649 20.623 3.855 19.655L4.889 15.198C4.99 14.712 4.833 14.213 4.486 13.89L1.161 10.728C0.492 10.086 0.865 8.884 1.823 8.702L6.414 7.833C6.854 7.75 7.237 7.438 7.387 7.03L9.049 2.927Z" />
                                                    </svg>
                                                ))}
                                            </div>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Filters;
