import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { UserState } from '../../Context/userProvider';
import notify from '../utiles/Notification';

const Success = ({ setLoading }) => {

    const hasFetchedData = useRef(false);
    const location = useLocation();
    const [address, setAddress] = useState({});

    const { user, logout, geoLocation, countryCode } = UserState();

    const baseUrl = process.env.REACT_APP_BASEURL

    useEffect(() => {
        const fetchCustomerData = async () => {
            setLoading(true)
            try {
                const response = await axios.post(
                    `${baseUrl}/checkout`,
                    {
                        customer_id: user.customer_id,
                        countryCode: countryCode
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
                if (response.data.status) {
                    const foundAddress = response.data.delivery_address.find(list => list.address_type === 'home');
                    setAddress(foundAddress)
                } else {
                    console.log('Failed to fetch customer data');
                }
            } catch (err) {
                console.log('Error fetching customer data:', err);
            }
            setLoading(false)
        };

        fetchCustomerData();
    }, [!geoLocation?.country]);

    const navigate = useNavigate();


    useEffect(() => {
        if (hasFetchedData.current) return; // Prevents multiple calls
        console.log(address.customers_delivery_address_id)

        const params = new URLSearchParams(location.search);
        const sessionId = params.get('session_id');

        if (sessionId) {
            const getData = async () => {
                const response = await axios.post(`${baseUrl}/create-checkout-response`, {
                    customer_id: user.customer_id,
                    session_id: sessionId,
                    status: true,
                });

                if (response.data.status) {
                    notify('success', 'Success', 'Order Place Successfully');
                    navigate('/my-profile/my-orders')
                }
            };

            getData();
            hasFetchedData.current = true; // Set to true after the call is made
        }
    }, [address]);


    return (
        <div>Success</div>
    )
}

export default Success