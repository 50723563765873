import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaEye, FaRegHeart, FaShoppingCart } from 'react-icons/fa';
import axios from 'axios';
import notify from '../utiles/Notification';
import { UserState } from '../../Context/userProvider';
import { useCart } from '../../Context/CartContext';
import Loader from '../utiles/Loader';
import { FaStar, FaRegStar, FaStarHalfAlt } from 'react-icons/fa';


const Card = ({ product, toggleModal, path }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const { user } = UserState();
  const { updateCartCount, setCart, cart, AddToCart } = useCart();
  const baseUrl = process.env.REACT_APP_BASEURL;

  const handleMouseEnter = () => {
    if (product.product_img.length > 1) {
      setCurrentImageIndex(1);
    }
  };

  const handleMouseLeave = () => {
    setCurrentImageIndex(0);
  };

  return (
    <>
      {loading && <Loader />}
      <div className="group">
        <div className="relative overflow-hidden rounded-2xl bg-gradient-to-b from-gray-400 to-white">
          <div
            className="relative aspect-[3/4] p-4 transition-transform duration-500 ease-out group-hover:scale-95"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Link to={path} className="block w-full h-full">
              <img
                src={product.product_img[currentImageIndex].images}
                alt={product.product_name}
                className="w-full h-full object-contain transform transition-all duration-500 ease-out"
              />
            </Link>

            <div className="absolute top-6 left-6">
              <span className="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-emerald-50 text-emerald-600">
                New
              </span>
            </div>

            <div className="absolute left-1/2 -translate-x-1/2 bottom-4 flex items-center gap-2 opacity-0 transform translate-y-4 group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-300">
              <button
                onClick={() => toggleModal(product)}
                className="p-3 bg-white rounded-full shadow-lg hover:bg-gray-50 transition-colors"
              >
                <FaEye className="w-4 h-4 text-gray-700" />
              </button>
              <button className="p-3 bg-black rounded-full shadow-lg hover:bg-gray-900 transition-colors" onClick={() => AddToCart(product, product.product_id, product.product_varient_id)}>
                <FaShoppingCart className="w-4 h-4 text-white" />
              </button>
            </div>
          </div>
        </div>

        <div className="px-2 pt-4 pb-2">
          <div className="flex flex-col">
            <h3 className="text-base font-medium text-gray-900 line-clamp-1 mb-1">
              {product.product_name}
            </h3>
            <p className="text-sm text-gray-500 line-clamp-2 mb-2">
              {product.sub_text}
            </p>
            <div className="flex items-center justify-between mt-auto">
              <div className="relative overflow-hidden">
                <span className="inline-block text-xl font-normal text-red-500">
                  %{product.discount}
                </span>
                <span className="relative ml-2 text-xl font-semibold text-gray-900 transition-all duration-300 group-hover:text-emerald-600">
                {product.symbol}&nbsp;{(product.price - product.price * (product.discount / 100)).toFixed(2)}
                  <span className="absolute bottom-0 left-0 w-full h-0.5 bg-emerald-500 transform scale-x-0 transition-transform duration-300 group-hover:scale-x-100" />
                </span>
              </div>

              <span className="text-base text-gray-400 line-through">
              {product.symbol}&nbsp;{Number(product.price).toFixed(2)}
              </span>
            </div>

            <div className="mt-1">
              <span className="text-base font-medium text-emerald-600">
                Save {product.symbol}&nbsp;{(product.price * (product.discount / 100)).toFixed(2)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;