import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserState } from '../../Context/userProvider';
import Loader from '../../Component/utiles/Loader';
import notify from '../utiles/Notification';

const CustomerRoutes = ({ children }) => {
  const { user, loading } = UserState();

  if (loading) {
    return <Loader />;
  }

  if (!user || typeof user !== 'object') {
    // notify('danger', 'Error', 'Please login to Continue');
    return <Navigate to="/" replace />;
  }

  return children;
};

export default CustomerRoutes;