import React, { useState } from 'react';
import { FaChevronRight } from 'react-icons/fa6';
import Tickets from '../Component/Tickets';
import Approvals from '../Component/Approvals';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Myticket from './Myticket';
import Help from '../Component/Help';

const Support = () => {
  const tabs = ['help', 'my tickets'];
  const [selectedStatus, setSelectedStatus] = useState(tabs[0]);
  const [searchTerm, setSearchTerm] = useState('');

  const navigate = useNavigate();

  const [openSection, setOpenSection] = useState(null);

  const handleTabClick = (status) => {
    setSelectedStatus(status);
    if (status === 'help') {
      navigate('/admin/support');
    } else{
      navigate('/admin/support');
    }
  };

  const toggleSection = (index) => {
    setOpenSection(openSection === index ? null : index);
  };

  const supportData = [
    {
      title: 'Returns/RTO & Exchange',
      sub_data: [
        {
          title: 'I have received wrong return',
        },
        {
          title: 'I have received wrong return',
        },
        {
          title: 'I have received wrong return',
        },
        {
          title: 'I have received wrong return',
        },
      ]
    },
    {
      title: 'Returns/RTO & Exchange',
      sub_data: [
        {
          title: 'I have received wrong return',
        },
        {
          title: 'I have received wrong return',
        },
        {
          title: 'I have received wrong return',
        },
        {
          title: 'I have received wrong return',
        },
      ]
    },
    {
      title: 'Returns/RTO & Exchange',
      sub_data: [
        {
          title: 'I have received wrong return',
        },
        {
          title: 'I have received wrong return',
        },
        {
          title: 'I have received wrong return',
        },
        {
          title: 'I have received wrong return',
        },
      ]
    },
    {
      title: 'Returns/RTO & Exchange',
      sub_data: [
        {
          title: 'I have received wrong return',
        },
        {
          title: 'I have received wrong return',
        },
        {
          title: 'I have received wrong return',
        },
        {
          title: 'I have received wrong return',
        },
      ]
    },
  ]


  const tickets = [
    {
      createdOn: "15 Dec'23 | 01:21 PM",
      ticketId: "8702626717015",
      issue: "I want to block / deprioritize my courier partner",
      lastUpdate: "Dear Supplier, We wanted to inform you about our updated prioritization policy for your forward pick-up.",
      status: "closed",
    },
    {
      createdOn: "15 Dec'23 | 01:21 PM",
      ticketId: "8702626717030",
      issue: "I want to block / deprioritize my courier partner",
      lastUpdate: "Dear Supplier, We wanted to inform you about our updated prioritization policy for your forward pick-up.",
      status: "needs attention",
    },
    {
      createdOn: "15 Dec'23 | 01:21 PM",
      ticketId: "8702626717030",
      issue: "I want to block / deprioritize my courier partner",
      lastUpdate: "Dear Supplier, We wanted to inform you about our updated prioritization policy for your forward pick-up.",
      status: "in progress",
    },
    {
      createdOn: "15 Dec'23 | 01:21 PM",
      ticketId: "8702626717030",
      issue: "I want to block / deprioritize my courier partner",
      lastUpdate: "Dear Supplier, We wanted to inform you about our updated prioritization policy for your forward pick-up.",
      status: "closed",
    },
    {
      createdOn: "15 Dec'23 | 01:21 PM",
      ticketId: "8702626717030",
      issue: "I want to block / deprioritize my courier partner",
      lastUpdate: "Dear Supplier, We wanted to inform you about our updated prioritization policy for your forward pick-up.",
      status: "closed",
    },
    {
      createdOn: "15 Dec'23 | 01:21 PM",
      ticketId: "8702626717030",
      issue: "I want to block / deprioritize my courier partner",
      lastUpdate: "Dear Supplier, We wanted to inform you about our updated prioritization policy for your forward pick-up.",
      status: "closed",
    },
    {
      createdOn: "15 Dec'23 | 01:21 PM",
      ticketId: "8702626717030",
      issue: "I want to block / deprioritize my courier partner",
      lastUpdate: "Dear Supplier, We wanted to inform you about our updated prioritization policy for your forward pick-up.",
      status: "closed",
    },
    {
      createdOn: "15 Dec'23 | 01:21 PM",
      ticketId: "8702626717030",
      issue: "I want to block / deprioritize my courier partner",
      lastUpdate: "Dear Supplier, We wanted to inform you about our updated prioritization policy for your forward pick-up.",
      status: "closed",
    }
  ];


  return (
    <>
      <h1 className={`xl:text-2xl md:text-lg text-xl mt-3 font-semibold capitalize`}>Support</h1>
      <div className={`w-full bg-white mt-5 md:p-6 p-3 rounded-t-lg `}>
        <div className="flex justify-start mt-5 overflow-x-auto overflow-hidden pb-3 h-10">
          <div className="tabs tabs-boxed">
            {tabs.map((status) => (
              <a
                key={status}
                className={`px-4 py-2 transition-all duration-200 ease-in-out font-medium cursor-pointer ${selectedStatus === status ? 'border-b capitalize text-lg border-black text-black' : 'text-[#A4A4A4] text-sm'}`}
                onClick={() => handleTabClick(status)}
              >
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="">
        {selectedStatus === tabs[0] && (
          <Routes>
              <Route path='/' element={<Help tickets={tickets} supportData={supportData} openSection={openSection} toggleSection={toggleSection}/>} />
              <Route path='/my-ticket/:id' element={<Myticket tickets={tickets} />} />
            </Routes>
          
        )}
        <div className="w-full ">
          {selectedStatus === tabs[1] && (
            <Routes>
              <Route path='/' element={<Approvals tickets={tickets} />} />
              <Route path='/my-ticket/:id' element={<Myticket tickets={tickets} />} />
            </Routes>

          )}
        </div>
      </div>
    </>
  );
};

export default Support;
