import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserState } from '../Context/userProvider';
import Loader from '../Component/utiles/Loader';

const ProtectedRoute = ({ children }) => {
  const { user, loading } = UserState();

  if (loading) {
    return <Loader />;
  }

  if (!user || typeof user !== 'object') {
    return <Navigate to="/admin/login" replace />;
  }

  if (!user.role) {
    return <Navigate to="/admin/login" replace />;
  }

  return children;
};

export default ProtectedRoute;