import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Loader from '../utiles/Loader';

const BrandsPage = ({ setLoading }) => {
    const baseUrl = process.env.REACT_APP_BASEURL;

    // const [loading, setLoading] = useState(false);
    const [brands, setBrands] = useState([])

    // useEffect(() => {
    //     setLoading(true);
    //     axios.get(`${baseUrl}/get_supplier_seller_list`)
    //         .then(response => {
    //             if (response.data.status) {
    //                 setBrands(response.data);
    //             }
    //             setLoading(false)
    //         })
    //         .catch(error => console.log('Error fetching FAQs:', error));
    //     setLoading(false)
    // }, []);


    useEffect(() => {
        setLoading(true);
        axios.get(`${baseUrl}/get_brands`)
            .then(response => {
                if (response.data.status) {
                    setBrands(response.data.brands);
                } else {
                    console.log('Failed to load Brands.');
                }
            })
            .catch(error => {
                console.log('An error occurred while fetching Banrds.');
            })
        setLoading(false);
    }, []);




    return (
        <>
            <div className="container mx-auto my-5">
                {brands.length > 0 ? (
                    <div className="grid grid-cols-5">
                        {brands.map((list, index) => (
                            <div className="flex flex-col place-items-center justify-center" key={index}>
                                <img src={list.brand_image} className='w-32 h-24' loading='lazy' alt={list.brand_name} />
                                <span>{list.brand_name}</span>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="">
                        <h1>Brands Not Availabel</h1>
                    </div>
                )}
            </div>
        </>
    )
}

export default BrandsPage