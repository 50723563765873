import React from 'react';
import { NavLink } from 'react-router-dom';
import { LuLayoutDashboard } from "react-icons/lu";
import { MdOutlineStore } from "react-icons/md";
import { PiMailboxBold } from "react-icons/pi";
import { PiTruckTrailer } from "react-icons/pi";
import { MdPeopleOutline } from "react-icons/md";
import { TbTransactionDollar } from "react-icons/tb";
import { MdOutlineRateReview, MdOutlineCategory } from "react-icons/md";
import { RiCoupon4Line } from "react-icons/ri";
import { FaNetworkWired } from "react-icons/fa";
import { IoIosPeople } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { BiSolidOffer } from "react-icons/bi";
import { TbMailQuestion } from "react-icons/tb";
import { MdLogout } from "react-icons/md";
import { UserState } from '../Context/userProvider';
import { TbTruckReturn } from "react-icons/tb";
import { MdOutlineSupportAgent } from "react-icons/md";
import { GrUserManager } from "react-icons/gr";
import { LuWarehouse } from "react-icons/lu";

const Sidebar = ({ isExpanded, toggleMenu }) => {

  const { user, logout } = UserState();

  const links = [
    // (user?.role === 'superadmin' || user?.role === 'manager' || user?.role === 'supervisor' || user?.role === 'suppplier') && 
    {
      name: 'dashboard',
      to: 'dashboard',
      icon: LuLayoutDashboard,
    },
    (user?.role === 'superadmin' || user?.role === 'manager' || user?.role === 'supervisor' || user?.role === 'supplier') && {
      name: 'products',
      to: 'products',
      icon: MdOutlineStore,
    },
    (user?.role === 'superadmin' || user?.role === 'manager' || user?.role === 'supervisor') && {
      name: 'categories',
      to: 'categories',
      icon: MdOutlineCategory,
    },
    (user?.role === 'superadmin' || user?.role === 'supervisor' || user?.role === 'supplier') && {
      name: 'orders',
      to: 'orders',
      icon: PiMailboxBold,
    },
    (user?.role === 'superadmin' || user?.role === 'manager' || user?.role === 'supervisor') && {
      name: 'supplier',
      to: 'supplier',
      icon: FaNetworkWired,
    },
    (user?.role === 'superadmin' || user?.role === 'manager') && {
      name: 'influencers',
      to: 'infulancers',
      icon: IoIosPeople,
    },
    (user?.role === 'superadmin' || user?.role === 'manager' || user?.role === 'supervisor') && {
      name: 'supplier seller',
      to: 'supplier-seller',
      icon: IoIosPeople,
    },
    (user?.role === 'superadmin' || user?.role === 'manager' || user?.role === 'supervisor') && {
      name: 'supplier influencers',
      to: 'supplier-influencers',
      icon: IoIosPeople,
    },
    (user?.role === 'superadmin' || user?.role === 'supervisor') && {
      name: 'shippments',
      to: 'shippments',
      icon: PiTruckTrailer,
    },
    (user?.role === 'superadmin' || user?.role === 'manager' || user?.role === 'supervisor') && {
      name: 'return orders',
      to: 'return-orders',
      icon: TbTruckReturn,
    },
    (user?.role === 'superadmin' || user?.role === 'manager' || user?.role === 'supervisor' || user?.role === 'supplier') && {
      name: 'support',
      to: 'support',
      icon: MdOutlineSupportAgent,
    },
    (user?.role === 'superadmin' || user?.role === 'manager' || user?.role === 'supervisor') && {
      name: 'customers',
      to: 'customers',
      icon: MdPeopleOutline,
    },
    (user?.role === 'superadmin' || user?.role === 'manager' || user?.role === 'supervisor' || user?.role === 'supplier') && {
      name: 'transactions',
      to: 'transactions',
      icon: TbTransactionDollar,
    },
    (user?.role === 'superadmin' || user?.role === 'supervisor') && {
      name: 'manager',
      to: 'manager',
      icon: GrUserManager,
    },
    (user?.role === 'superadmin') && {
      name: 'supervisor',
      to: 'supervisor',
      icon: GrUserManager,
    },
    (user?.role === 'superadmin' || user?.role === 'supervisor') && {
      name: 'warehouses',
      to: 'warehouses',
      icon: LuWarehouse,
    },
    (user?.role === 'superadmin' || user?.role === 'manager' || user?.role === 'supervisor') && {
      name: 'rating review',
      to: 'rating-review',
      icon: MdOutlineRateReview,
    },
    (user?.role === 'superadmin' || user?.role === 'manager' || user?.role === 'supervisor') && {
      name: 'offers',
      to: 'offers',
      icon: BiSolidOffer,
    },
    (user?.role === 'superadmin' || user?.role === 'manager' || user?.role === 'supervisor') && {
      name: 'coupons',
      to: 'coupons',
      icon: RiCoupon4Line,
    },
    (user?.role === 'superadmin' || user?.role === 'supervisor') && {
      name: 'settings',
      to: 'settings',
      icon: IoSettingsOutline,
    },
    (user?.role === 'superadmin' || user?.role === 'manager' || user?.role === 'supervisor') && {
      name: 'inquiries',
      to: 'inquiries',
      icon: TbMailQuestion,
    },
  ].filter(Boolean);


  return (
    <>
      <div className="mt-5 pb-28 md:pb-10 px-3 space-y-2 ">
        {links.map((list, index) => (
          <NavLink
            to={list.to}
            key={index}
            className={({ isActive }) => `${isActive || (list.to === 'dashboard' && window.location.pathname === '/admin') ? 'bg-slate-200 text-black ' : ''} flex flex-row items-center mb-3 overflow-hidden text-nowrap rounded-xl hover:scale-105 hover:shadow-2xl hover:bg-slate-300  transition-all duration-75 ease-in-out  md:py-3 py-2 md:px-1`}
            onClick={() => {
              if (window.innerWidth <= 999) toggleMenu();
            }}
          >
            <div className={`flex justify-center transition-all duration-100 ${isExpanded ? 'px-2' : 'w-full'}`}>
              <list.icon className='md:text-xl text-sm' />
            </div>
            {isExpanded && <span className='text-base capitalize font-medium transition-all duration-100'>{list.name}</span>}
          </NavLink>
        ))}
      </div>
    </>
  );
}

export default Sidebar;
