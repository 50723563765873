import React, { useState } from 'react'
import Dropdown from '../../Component/utiles/Dropdown'
import { IoSearchSharp } from "react-icons/io5";
import Pagination from '../../Component/utiles/Pagination';
import Recenttransactions from '../Component/Recenttransactions';
import { FaArrowTrendUp } from "react-icons/fa6";
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';



const Transactions = () => {

  const perpageoption = [
    { label: '5' }, { label: '10' }, { label: '15' }, { label: '20' }
  ];

  const checkdataoption = [
    { label: 'all' }, { label: 'today' }, { label: 'weekly' }, { label: 'monthly' },
  ];

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(perpageoption[0].label);
  const [checkData, setCheckData] = useState(checkdataoption[0].label);

  const data = [
    {
      name: 'income',
      value: '34,546',
      percentage: '1.56',
      bg: '#25DF6A',
      graphColor: '#25DF6A',
      graphData: [1, 4, 2, 3, 1, 4]
    },
    {
      name: 'spending',
      value: '34,546',
      percentage: '1.56',
      bg: '#FF5200',
      graphColor: '#FF5200',
      graphData: [1, 4, 1, 4, 1, 4]
    },
  ];


  const chartOptions = {
    chart: {
      type: 'areaspline',
      backgroundColor: 'transparent',
      height: '25%'
    },
    title: {
      text: ''
    },
    xAxis: {
      visible: false
    },
    yAxis: {
      visible: false
    },

    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    tooltip: {
      enabled: false
    }
  };


  return (
    <>
      <h1 className="xl:text-2xl md:text-lg text-xl mt-3 font-semibold">Transactions</h1>
      <div className="flex md:flex-row flex-col gap-5 my-5">
        {data.map((list, index) => (
          <div key={index} className="w-full bg-white rounded-lg p-3">
            <span className='text-lg font-normal text-gray-400 capitalize'>{list.name}</span>
            <div className="flex flex-row gap-5 place-items-center">
              <h1 className='text-2xl font-semibold'>{list.value}</h1>
              <span className='text-lg'><FaArrowTrendUp /></span>
              <span className='text-sm text-gray-400 font-medium'>{list.percentage} %</span>
            </div>
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                ...chartOptions,
                series: [{
                  data: list.graphData, color: list.graphColor,
                  marker: { enabled: false },
                  fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                      [0, list.graphColor],
                      [1, '#ffffff']
                    ]
                  }
                }]
              }} />
          </div>
        ))}
      </div>

      <Recenttransactions />
    </>
  )
}

export default Transactions;
