import React, { useEffect, useState } from 'react'
import reviewImg from '../../Component/Assets/Images/rating2.png';
import Dropdown from '../../Component/utiles/Dropdown';
import { IoSearchSharp } from "react-icons/io5";
import { FaStar, FaRegStar, FaStarHalfAlt, FaEye } from 'react-icons/fa';
import Pagination from '../../Component/utiles/Pagination';
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbArrowsExchange2, TbUserEdit } from "react-icons/tb";
import India from '../../Component/Assets/Images/india.png';
import axios from 'axios';
import Productviewmodal from '../Component/Productviewmodal';


const Customers = ({ updatestatus, userlist, setLoading }) => {

    const baseUrl = process.env.REACT_APP_BASEURL;

    const [searchTerm, setSearchTerm] = useState('');

    const [customerss, setCustomers] = useState([]);

    const customers = [
        {
            id: '#1290976',
            name: "Helen M.",
            email: 'helenm@gmail.com',
            country: 'india',
            countryImg: India,
            totalorder: "15",
            totalspent: 1290.00,
            dislikes: 21,
            img: reviewImg,
        },
        {
            id: '#1290976',
            name: "Helen M.",
            email: 'helenm@gmail.com',
            country: 'india',
            countryImg: India,
            totalorder: "15",
            totalspent: 1290.00,
            dislikes: 21,
            img: reviewImg,
        },
        {
            id: '#1290976',
            name: "Helen M.",
            email: 'helenm@gmail.com',
            country: 'india',
            countryImg: India,
            totalorder: "15",
            totalspent: 1290.00,
            dislikes: 21,
            img: reviewImg,
        },
        {
            id: '#1290976',
            name: "Jay M.",
            email: 'helenm@gmail.com',
            country: 'india',
            countryImg: India,
            totalorder: "15",
            totalspent: 1290.00,
            dislikes: 21,
            img: reviewImg,
        },
        {
            id: '#1290976',
            name: "Helen M.",
            email: 'helenm@gmail.com',
            country: 'india',
            countryImg: India,
            totalorder: "15",
            totalspent: 1290.00,
            dislikes: 21,
            img: reviewImg,

        },
        {
            id: '#1290976',
            name: "Helen M.",
            email: 'helenm@gmail.com',
            country: 'india',
            countryImg: India,
            totalorder: "15",
            totalspent: 1290.00,
            dislikes: 21,
            img: reviewImg,
        },
        {
            id: '#1290976',
            name: "Helen M.",
            email: 'helenm@gmail.com',
            country: 'india',
            countryImg: India,
            totalorder: "15",
            totalspent: 1290.00,
            dislikes: 21,
            img: reviewImg,
        },
    ]

    useEffect(() => {
        setLoading(true)
        const allcustomer = async () => {
            try {
                const response = await axios.get(`${baseUrl}/get_Customers_Spend`);
                if (response.data.status) {
                    setCustomers(response.data.customers)
                }
            } catch (err) {
                console.log(err);
            }
        };
        allcustomer();
        setLoading(false)
    }, [userlist]);



    const perpageoption = [
        { label: '5' }, { label: '10' }, { label: '15' }, { label: '20' }
    ];

    const [itemsPerPage, setItemsPerPage] = useState(perpageoption[0].label);
    const [currentPage, setCurrentPage] = useState(1);

    const searchResults = customerss.filter(customersdata => {
        const fullName = (customersdata.first_name + ' ' + customersdata.last_name).toLowerCase();
        const searchTermLower = searchTerm.toLowerCase();

        return fullName.includes(searchTermLower) ||
            customersdata.customer_id.toString().toLowerCase().includes(searchTermLower);
    });

    useEffect(() => {
        if (searchTerm) {
          setCurrentPage(1);
        }
      }, [searchTerm]);

    const itemsToDisplay = searchTerm ? searchResults : customerss;


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = itemsToDisplay.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(itemsToDisplay.length / itemsPerPage);


    const getStatusClass = (stockStatus) => {
        switch (stockStatus.toLowerCase()) {
            case 'in stock':
            case 'active':
            case 'live':
                return 'bg-green-200 text-green-600 text-xs';
            case 'out of stock':
            case 'inactive':
            case 'hold':
                return 'bg-red-200 text-red-500 text-xs';
            default:
                return 'bg-gray-400 text-white text-xs';
        }
    };



    const renderStars = (rating) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= rating) {
                stars.push(<FaStar key={i} className="text-yellow-300" />);
            } else if (i === Math.ceil(rating) && !Number.isInteger(rating)) {
                stars.push(<FaStarHalfAlt key={i} className="text-yellow-300" />);
            } else {
                stars.push(<FaRegStar key={i} className="text-yellow-300" />);
            }
        }
        return stars;
    };

    const [openModal, setOpenModal] = useState(false);


    const handleClick = (customer_id) => {
        // clickcustomerID(product_id);
        setOpenModal(true);
    };

    const ctg = [
        { category_color_code: 'bg-cyan-300' },
        { category_color_code: 'bg-purple-300' },
        { category_color_code: 'bg-yellow-100' },
        { category_color_code: 'bg-red-300' },
        { category_color_code: 'bg-green-200' },
        { category_color_code: 'bg-amber-100' },
        { category_color_code: 'bg-blue-200' },
    ];


    return (
        <>
            <h1 className='xl:text-2xl md:text-lg text-xl mt-3 font-semibold capitalize'>Customers</h1>
            <div className='w-full bg-white  rounded-lg mt-5 md:p-6 p-3'>
                <div className="relative flex md:hidden w-full ">
                    <input
                        type="text"
                        placeholder="Search Here"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="bg-gray-200 w-full border-0 rounded-full px-4 py-2 pr-10 focus:outline-none focus:border-gray-400 focus:ring-2 focus:ring-gray-400"
                    />
                    <IoSearchSharp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-black cursor-pointer" />
                </div>
                <div className='flex flex-row  justify-between my-5  pe-0'>
                    <div className='flex flex-row items-center md:w-64 w-24  gap-1'>
                        <span className='text-nowrap mt-2 md:block hidden text-sm capitalize'>items per page</span>
                        <div className='w-16'>
                            <Dropdown
                                label=""
                                options={perpageoption}
                                selectedValues={itemsPerPage}
                                onSelect={setItemsPerPage}
                                onRemove={() => { }}
                                isMultiple={false}
                            />
                        </div>
                    </div>

                    <div className="relative hidden md:flex w-5/12">
                        <input
                            type="text"
                            placeholder="Search Here"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="bg-gray-200 w-full border-0 rounded-full px-4 py-1 pr-10 focus:outline-none focus:border-gray-400 focus:ring-2 focus:ring-gray-400"
                        />
                        <IoSearchSharp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-black cursor-pointer" />
                    </div>
                </div>
                <div className='overflow-hidden overflow-x-auto mt-6'>
                    <table className="lg:w-full md-w[100%]  table bg-white">
                        <thead className="bg-gray-100 rounded-full">
                            <tr>
                                <th className="py-4 px-4 text-left capitalize text-nowrap">customer</th>
                                <th className="py-4 px-4 text-left capitalize text-nowrap">customer ID</th>
                                <th className="py-4 px-4 text-left capitalize text-nowrap">country</th>
                                <th className="py-4 px-4 text-left capitalize text-nowrap">total spent</th>
                                <th className="py-4 px-4 text-left capitalize text-nowrap">status</th>
                                <th className="py-4 px-4 text-left capitalize text-nowrap">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((item, index) => (
                                <tr key={item.id} className="border-b border-gray-200  hover:bg-gray-200 transition-all duration-200 ease-in-out">
                                    <td className="py-4 px-4 flex items-center gap-4">
                                        {item.image != null && item.image !== '0' ? (
                                            <img className='md:w-16 md:h-16 h-10 w-10 mr-4 bg-gray-100 rounded-full' src={item.image} alt='my-image' />
                                        ) : (
                                            <h1 className={`text-xl capitalize md:w-16 md:h-16 h-10 w-10 text-black ${ctg[index % ctg.length].category_color_code} rounded-full flex justify-center place-items-center`}>{item.first_name.charAt(0)}</h1>
                                        )}
                                        <div className="flex flex-col">
                                            <span className='text-nowrap font-medium text-lg pe-20 lg:pe-0'>{item.first_name}&nbsp;{item.last_name}</span>
                                            <span className='text-nowrap  text-sm text-gray-400 pe-20 lg:pe-0'>{item.email}</span>
                                        </div>
                                    </td>
                                    <td className="py-4 px-4 "><div className="ms-3 text-lg font-medium text-nowrap">{item.unique_id}</div></td>
                                    <td className="py-4 px-4 ">
                                        <div className="flex flex-row gap-2 place-items-center ">
                                            <span className='font-medium text-base'>{item.country_name == null ? 'not added' : item.country_name}</span>
                                        </div>
                                    </td>
                                    <td className="py-4 px-4 "><div className="flex flex-row gap-1 place-items-center font-semibold text-lg text-nowrap">$ {item.total_spend == null ? '0' : item.total_spend}</div></td>
                                    <td className="py-4 px-4 ">
                                        <span className={`px-2 py-1  rounded-lg capitalize font-semibold ${getStatusClass(item.status ? item.status : 'Null')}`}>
                                            {item.status ? item.status : 'null'}
                                        </span>
                                    </td>
                                    <td className="py-4 px-4 ">
                                        <div className="flex flex-row gap-3 text-black text-xl">
                                            <span className='cursor-pointer bg-neutral-300  rounded-lg p-1.5' onClick={() => handleClick(item.customer_id)}><FaEye /></span>
                                            <span className='cursor-pointer bg-neutral-300  rounded-lg p-1.5' onClick={() => updatestatus(item.customer_id, item.status, "customer")}><TbArrowsExchange2 /></span>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-between items-center mt-4">
                    <div>
                        <span>Page {currentPage} of {totalPages}</span>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </div>
            <Productviewmodal openModal={openModal} setOpenModal={setOpenModal} />
        </>


    )
}

export default Customers