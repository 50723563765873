import React, { useEffect, useRef, useState } from 'react'
import { FileInput, Label } from "flowbite-react";
import { MdCloudDownload } from "react-icons/md";
import { AiOutlineDelete } from 'react-icons/ai';
import { RxCross2 } from 'react-icons/rx'
import { Link } from 'react-router-dom'
import Dropdown from '../../Component/utiles/Dropdown';
import { ImageUpload } from '../../Component/utiles/ImageUpload';
import notify from '../../Component/utiles/Notification';

const Addmoreproduct = ({ setMore, edit, value, sizeOptions, colourOptions, setVariants, variants, setLoading }) => {


    // console.log(variants)

    const handleAddMoreVariant = () => {
        setVariants([...variants, {
            product_varient_id: null,
            color: '',
            price: '',
            sizes: [{ size: '', quantity: '', size_id: '', product_varient_size_id: null }],
            var_images: [],
            showUploadSection: false,
        }]);
    };


    const handleRemoveVariant = (index) => {
        const updatedVariants = variants.filter((_, i) => i !== index);
        setVariants(updatedVariants);
    };

    const handleSizeChange = (variantIndex, sizeIndex, newSize) => {
        const updatedVariants = [...variants];
        updatedVariants[variantIndex].sizes[sizeIndex].size = newSize;
        const selectedSize = sizeOptions.find(option => option.label === newSize);
        if (selectedSize) {
            updatedVariants[variantIndex].sizes[sizeIndex].size_id = selectedSize.id;
        }
        setVariants(updatedVariants);
    };

    const handleQuantityChange = (variantIndex, sizeIndex, newQuantity) => {
        const updatedVariants = [...variants];
        updatedVariants[variantIndex].sizes[sizeIndex].quantity = newQuantity;
        setVariants(updatedVariants);
    };


    const handleAddSize = (variantIndex) => {
        const updatedVariants = [...variants];
        updatedVariants[variantIndex].sizes.push({ size: '', quantity: '', product_varient_size_id: null, size_id: '' });
        setVariants(updatedVariants);
    };

    const handleRemoveSizeQuantity = (variantIndex, sizeIndex) => {
        const updatedVariants = [...variants];
        updatedVariants[variantIndex].sizes.splice(sizeIndex, 1);
        setVariants(updatedVariants);
    };

    const handleFileChange = async (variantIndex, e) => {
        setLoading(true);
        const files = Array.from(e.target.files);
        const imagePromises = files.map(async (file) => {
            const reader = new FileReader();
            return new Promise((resolve, reject) => {
                reader.onloadend = () => resolve({ url: reader.result, file });
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        });

        const imagePreviews = await Promise.all(imagePromises);
        const uploadedUrls = await uploadToCloudinary(files);

        const updatedVariants = [...variants];
        updatedVariants[variantIndex].var_images = [
            ...updatedVariants[variantIndex].var_images, // Keep old images
            ...imagePreviews.map((img, index) => ({
                ...img,
                url: uploadedUrls[index] || img.url,
                product_varient_image_id: null,
                uploaded: !!uploadedUrls[index],
            })),
        ];

        updatedVariants[variantIndex].showUploadSection = false;
        setVariants(updatedVariants);
        setLoading(false);
    };

    const uploadToCloudinary = async (files) => {
        const uploadPromises = files.map(async (file) => {
            const data = new FormData();
            data.append("file", file);
            data.append("upload_preset", "testalas");
            data.append("cloud_name", "duw6f8fm8");

            try {
                const res = await fetch('https://api.cloudinary.com/v1_1/duw6f8fm8/image/upload', {
                    method: 'POST',
                    body: data
                });
                const result = await res.json();
                return result.url;
            } catch (error) {
                console.error('Upload Error:', error);
                return null;
            }
        });

        return await Promise.all(uploadPromises);
    };

    const handleRemoveImage = (variantIndex, imageIndex) => {
        const updatedVariants = [...variants];
        updatedVariants[variantIndex].var_images.splice(imageIndex, 1);
        setVariants(updatedVariants);
    };

    return (
        <>
            <div className="flex flex-row justify-between place-items-center">
                <span className="text-lg font-medium">{value}</span>
                <button className="bg-black text-white px-2 py-1 rounded-lg text-base" onClick={handleAddMoreVariant}>Add More</button>
            </div>
            {!edit &&
                variants.slice(1).map((variant, variantIndex) => (
                    <div key={variantIndex + 1} className="mt-4 p-4 border rounded-lg">
                        <div className="flex md:flex-row flex-col mt-2 gap-2 place-items-start">
                            <div className="lg:w-1/2 w-full">
                                {variant.var_images.length > 0 ? (
                                    <div>
                                        <div className="mb-5">
                                            <label htmlFor={`dropzone-file-${variantIndex + 1}`} className="bg-black text-white rounded-lg text-sm font-semibold px-2 py-1 cursor-pointer">
                                                Add More Images
                                            </label>
                                            <input
                                                type="file"
                                                id={`dropzone-file-${variantIndex + 1}`}
                                                className="hidden"
                                                multiple
                                                onChange={(e) => handleFileChange(variantIndex + 1, e)}
                                            />
                                        </div>
                                        <div className="grid grid-cols-4 h-64 gap-2 overflow-y-auto mt-2">
                                            {variant.var_images.map((image, index) => (
                                                <div key={index} className='relative'>
                                                    <img src={image.url} alt={`Uploaded ${index}`} className="h-32 rounded-lg" />
                                                    <button
                                                        type="button"
                                                        onClick={() => handleRemoveImage(variantIndex + 1, index)}
                                                        className="absolute top-2 right-2 bg-red-500 text-white rounded-full text-xl p-1"
                                                    >
                                                        <AiOutlineDelete />
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <Label
                                        htmlFor={`dropzone-file-${variantIndex + 1}`}
                                        className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100"
                                    >
                                        <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                            <MdCloudDownload className='text-3xl text-gray-400' />
                                            <p className="mb-2 text-sm text-gray-500">
                                                <span className="font-semibold">Click to upload</span> or drag and drop
                                            </p>
                                            <p className="text-xs text-gray-500">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                        </div>
                                        <FileInput
                                            id={`dropzone-file-${variantIndex + 1}`}
                                            className="hidden"
                                            multiple
                                            onChange={(e) => handleFileChange(variantIndex + 1, e)}
                                        />
                                    </Label>
                                )}
                            </div>
                            <div className="grid grid-cols-1 md:px-4 gap-5 mb-5 lg:w-1/2 w-full">
                                <div className="flex flex-row gap-5">
                                    <div className="w-2/5">
                                        <Dropdown
                                            label="Color"
                                            color={true}
                                            options={colourOptions}
                                            selectedValues={variant.color}
                                            onSelect={(newColor) => {
                                                const updatedVariants = [...variants];
                                                updatedVariants[variantIndex + 1].color = newColor;
                                                setVariants(updatedVariants);
                                            }}
                                            isMultiple={false}
                                            search={true}
                                        />
                                    </div>

                                    <div className="w-2/5">
                                        <span className="text-lg font-medium">Price</span>
                                        <input
                                            className="w-full mt-2 mb-1 py-2 rounded-lg focus:ring-0 focus:border-black border-gray-300"
                                            placeholder="Enter Price"
                                            type="text"
                                            value={variant.price}
                                            onChange={(e) => {
                                                const updatedVariants = [...variants];
                                                updatedVariants[variantIndex + 1].price = e.target.value;
                                                setVariants(updatedVariants);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-3">
                                    {variant.sizes.map((sizeEntry, sizeIndex) => (
                                        <div key={sizeIndex} className="flex flex-row gap-5">
                                            <div className="w-2/5">
                                                <Dropdown
                                                    label="Size"
                                                    options={sizeOptions}
                                                    selectedValues={sizeEntry.size}
                                                    onSelect={(newSize) => handleSizeChange(variantIndex + 1, sizeIndex, newSize)}
                                                    isMultiple={false}
                                                    search={true}
                                                />
                                            </div>
                                            <div className="w-2/5">
                                                <span className="text-lg font-medium">Quantity</span>
                                                <input
                                                    className="w-full mt-2 mb-1 rounded-lg py-2 focus:ring-0 focus:border-black border-gray-300"
                                                    placeholder="Enter Quantity"
                                                    type="text"
                                                    value={sizeEntry.quantity}
                                                    onChange={(e) => handleQuantityChange(variantIndex + 1, sizeIndex, e.target.value)}
                                                />
                                            </div>
                                            <div className="h-full flex place-items-end pb-1">
                                                {sizeIndex > 0 && (
                                                    <button
                                                        className="text-white rounded-lg px-2 py-1 text-xl font-medium capitalize bg-red-500 h-10"
                                                        onClick={() => handleRemoveSizeQuantity(variantIndex + 1, sizeIndex)}
                                                    >
                                                        <AiOutlineDelete />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                    <button className="text-blue-500 ms-auto" onClick={() => handleAddSize(variantIndex + 1)}>
                                        + Add Size
                                    </button>
                                </div>
                            </div>
                        </div>

                        {variantIndex + 1 > 0 && (
                            <button
                                className="bg-red-500 text-white px-4 py-2 rounded-lg mt-3 font-medium"
                                onClick={() => handleRemoveVariant(variantIndex + 1)}
                            >
                                Remove Variant
                            </button>
                        )}
                    </div>
                ))
            }
            {edit &&
                variants.map((variant, variantIndex) => (
                    <div key={variantIndex} className="mt-4 p-4 border rounded-lg">
                        <div className="flex md:flex-row flex-col mt-2 gap-2 place-items-start">
                            <div className="lg:w-1/2 w-full">
                                {variant.var_images.length > 0 ? (
                                    <div>
                                        <div className="mb-5">
                                            <label htmlFor={`dropzone-file-${variantIndex}`} className="bg-black text-white rounded-lg text-sm font-semibold px-2 py-1 cursor-pointer">
                                                Add More Images
                                            </label>
                                            <input
                                                type="file"
                                                id={`dropzone-file-${variantIndex}`}
                                                className="hidden"
                                                multiple
                                                onChange={(e) => handleFileChange(variantIndex, e)}
                                            />
                                        </div>
                                        <div className="grid grid-cols-4 h-64 gap-2 overflow-y-auto mt-2">
                                            {variant.var_images.map((image, index) => (
                                                <div key={index} className='relative'>
                                                    <img src={image.url} alt={`Uploaded ${index}`} className="h-32 rounded-lg" />
                                                    <button
                                                        type="button"
                                                        onClick={() => handleRemoveImage(variantIndex, index)}
                                                        className="absolute top-2 right-2 bg-red-500 text-white rounded-full text-xl p-1"
                                                    >
                                                        <AiOutlineDelete />
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <Label
                                        htmlFor={`dropzone-file-${variantIndex}`}
                                        className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100"
                                    >
                                        <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                            <MdCloudDownload className='text-3xl text-gray-400' />
                                            <p className="mb-2 text-sm text-gray-500">
                                                <span className="font-semibold">Click to upload</span> or drag and drop
                                            </p>
                                            <p className="text-xs text-gray-500">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                        </div>
                                        <FileInput
                                            id={`dropzone-file-${variantIndex}`}
                                            className="hidden"
                                            multiple
                                            onChange={(e) => handleFileChange(variantIndex, e)}
                                        />
                                    </Label>
                                )}
                            </div>
                            <div className="grid grid-cols-1 md:px-4 gap-5 mb-5 lg:w-1/2 w-full">
                                <div className="flex flex-row gap-5">
                                    <div className="w-2/5">
                                        <Dropdown
                                            label="Color"
                                            color={true}
                                            options={colourOptions}
                                            selectedValues={variant.color}
                                            onSelect={(newColor) => {
                                                const updatedVariants = [...variants];
                                                updatedVariants[variantIndex].color = newColor;
                                                setVariants(updatedVariants);
                                            }}
                                            isMultiple={false}
                                            search={true}
                                        />
                                    </div>

                                    <div className="w-2/5">
                                        <span className="text-lg font-medium">Price</span>
                                        <input
                                            className="w-full mt-2 mb-1 py-2 rounded-lg focus:ring-0 focus:border-black border-gray-300"
                                            placeholder="Enter Price"
                                            type="text"
                                            value={variant.price}
                                            onChange={(e) => {
                                                const updatedVariants = [...variants];
                                                updatedVariants[variantIndex].price = e.target.value;
                                                setVariants(updatedVariants);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-3">
                                    {variant.sizes.map((sizeEntry, sizeIndex) => (
                                        <div key={sizeIndex} className="flex flex-row gap-5">
                                            <div className="w-2/5">
                                                <Dropdown
                                                    label="Size"
                                                    options={sizeOptions}
                                                    selectedValues={sizeEntry.size}
                                                    onSelect={(newSize) => handleSizeChange(variantIndex, sizeIndex, newSize)}
                                                    isMultiple={false}
                                                    search={true}
                                                />
                                            </div>
                                            <div className="w-2/5">
                                                <span className="text-lg font-medium">Quantity</span>
                                                <input
                                                    className="w-full mt-2 mb-1 rounded-lg py-2 focus:ring-0 focus:border-black border-gray-300"
                                                    placeholder="Enter Quantity"
                                                    type="text"
                                                    value={sizeEntry.quantity}
                                                    onChange={(e) => handleQuantityChange(variantIndex, sizeIndex, e.target.value)}
                                                />
                                            </div>
                                            <div className="h-full flex place-items-end pb-1">
                                                {sizeIndex > 0 && (
                                                    <button
                                                        className="text-white rounded-lg px-2 py-1 text-xl font-medium capitalize bg-red-500 h-10"
                                                        onClick={() => handleRemoveSizeQuantity(variantIndex, sizeIndex)}
                                                    >
                                                        <AiOutlineDelete />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                    <button className="text-blue-500 ms-auto" onClick={() => handleAddSize(variantIndex)}>
                                        + Add Size
                                    </button>
                                </div>
                            </div>
                        </div>

                        {variantIndex > 0 && (
                            <button
                                className="bg-red-500 text-white px-4 py-2 rounded-lg mt-3 font-medium"
                                onClick={() => handleRemoveVariant(variantIndex)}
                            >
                                Remove Variant
                            </button>
                        )}
                    </div>
                ))
            }

        </>
    )
}

export default Addmoreproduct