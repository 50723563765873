import React from 'react'
import Tickets from './Tickets'
import { FaChevronRight } from 'react-icons/fa6';

const Help = ({supportData , tickets, toggleSection , openSection}) => {
    return (
        <>
            <div className="">
                <div className="w-full bg-white rounded-b-lg  md:px-6 md:pb-6 p-3">
                    <h1 className='md:text-xl text-base font-semibold'>Select Issue Category</h1>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-3 mt-5 ">
                        {supportData.map((item, index) => (
                            <div key={index} className={`flex flex-col border border-[#C0BDBD] rounded-lg py-4 px-3 ${openSection === index ? 'h-full' : 'h-fit'}`}>
                                <div
                                    className={`flex flex-row place-items-center justify-between cursor-pointer  `}
                                    onClick={() => toggleSection(index)} >
                                    <h1 className='lg:text-lg md:text-base text-sm font-medium'>{item.title}</h1>
                                    <FaChevronRight className={`transition-all duration-300 ease-in-out ${openSection === index ? 'rotate-90' : 'rotate-0'}`} />
                                </div>
                                <div className={`transition-all duration-300 ease-in-out ${openSection === index ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0 overflow-hidden '}`}>
                                    {item.sub_data.slice(0, 3).map((list, index) => (
                                        <div key={index} className={`flex flex-col mt-5 border-b border-[#C0BDBD] pb-3 `}>
                                            <div className="flex flex-row place-items-center justify-between text-sm cursor-pointer">
                                                <span className='md:text-base text-xs'>{list.title}</span>
                                                <FaChevronRight />
                                            </div>
                                        </div>
                                    ))}
                                    <h1 className='md:text-xl text-base font-semibold mt-4 cursor-pointer'>View All</h1>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <Tickets tickets={tickets} />
            </div>
        </>
    )
}

export default Help