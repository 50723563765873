import React, { useEffect, useState } from 'react';
import bgImage from '../Assets/Images/banner-bg-shoe.png';
import Navbar from '../LandingPage/Navbar';
import { MdDelete } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import { UserState } from "../../Context/userProvider";
import axios from 'axios';
import notify from "../utiles/Notification";
import Loader from '../utiles/Loader';
import { useCart } from '../../Context/CartContext';
import { MdOutlineShoppingCart } from "react-icons/md";
import Footer from '../LandingPage/Footer';




const MyCart = () => {
  const baseUrl = process.env.REACT_APP_BASEURL;
  const { user, logout, countryCode } = UserState();
  const [isLoading, setIsLoading] = useState(false);
  const [cart, setCart] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const { updateCartCount } = useCart();




  const getAllProducts = async (type) => {

    if (!user) {
      notify('danger', 'Error', 'Please login to see product to cart');
      return
    }
    setIsLoading(true);

    try {
      const response = await axios.post(`${baseUrl}/get_cart`, {
        customer_id: user.customer_id,
        countryCode: countryCode
      });

      if (response.data.status) {
        setCart(response.data.cartAllData);
        setCartTotal(response.data.total_amount);
        updateCartCount(response.data.cartCount);
        setIsLoading(false);
      } else {
        setCart([]);
        if (type === 1) {
          notify('danger', 'Error', `${response.data.message}`);
        }
        updateCartCount(0)
        setCartTotal(0)
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error during status update:', error);
      setIsLoading(false);
      notify('danger', 'Error', 'There was an error. Please try again.');
    }
  };

  useEffect(() => {
    getAllProducts(1);
  }, [!countryCode])

  const handleIncrement = async (id) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/cart_quantity_add`, {
        product_cart_id: id,
      });

      if (response.data.status) {
        await getAllProducts(0);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        notify('danger', 'Error', `${response.data.message}`);

      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error during status update:', error);
      notify('danger', 'Error', 'There was an error. Please try again.');

    }
  };

  const handleDecrement = async (id) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/cart_quantity_subtract`, {
        product_cart_id: id,
      });

      if (response.data.status) {
        await getAllProducts(0);
        setIsLoading(false);

      } else {
        setIsLoading(false);
        notify('danger', 'Error', `${response.data.message}`);

      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error during status update:', error);
      notify('danger', 'Error', 'There was an error. Please try again.');
    }
  }

  const deleteProduct = async (type, id) => {
    setIsLoading(true);
    let payload
    if (type === 'All') {
      payload = {
        customer_id: user.customer_id,
      }
      setCartTotal(0);
      updateCartCount(0);
    } else if (type === 'Single') {
      payload = {
        product_cart_id: id
      }
    }
    try {
      const response = await axios.post(`${baseUrl}/delete_cart_product`, payload);

      if (response.data.status) {
        await getAllProducts(0);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        notify('danger', 'Error', `${response.data.message}`);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error during status update:', error);
      notify('danger', 'Error', 'There was an error. Please try again.');
    }
  }

  const symbol = cart.length > 0 ? cart[0].symbol : '$';

  const navigate = useNavigate();
  const [qty, setQty] = useState([]);

  const checkout = async () => {
    setIsLoading(true)
    try {
      const response = await axios.post(
        `${baseUrl}/checkout`,
        {
          customer_id: user.customer_id,
          countryCode: countryCode
        },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        });
      if (response.data.status) {
        navigate('/checkout');
      } else {
        notify('danger', 'Error', `Quantity Out of Stock`);
        setQty(response.data.out_of_stock_products)
      }
    } catch (err) {
      console.log('Error fetching customer data:', err);
    }
    setIsLoading(false)
  };

  return (
    <>
      {isLoading && <Loader />}
      <Navbar />
      <div
        className="relative bg-cover bg-center h-[400px] flex items-center justify-center opacity-90"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative text-center text-white">
          <h1 className="text-5xl font-bold">SHOPPING CART</h1>
          <p className="mt-2">
            <Link to="/">Home</Link> <span className="mx-2">›</span> <a href="#">My Cart</a>
          </p>
        </div>
      </div>
      {cart.length > 0 &&
        <div className="container mx-auto px-4 py-8 flex lg:flex-row flex-col gap-5">
          <div className=" mb-4 lg:w-3/4 w-full">
            <table className="min-w-full bg-white hidden md:block">
              <thead className="bg-gray-100 rounded-xl">
                <tr>
                  <th className="py-4 px-4 text-left">Product</th>
                  <th className="py-4 px-4 text-left">Price</th>
                  <th className="py-4 px-4 text-left">Colour</th>
                  <th className="py-4 px-4 text-left">Quantity</th>
                  <th className="py-4 px-4 text-left">Total</th>
                </tr>
              </thead>
              <tbody>
                {cart.map(item => (
                  <tr key={item.id} className={`${qty.some(q => q.product_id === item.product_id) ? 'bg-red-200' : 'bg-white'}  border-b border-gray-200`}>
                    <td className="py-4 px-4  flex items-center gap-4">
                      <div className="text-xl cursor-pointer text-gray-400">
                        <MdDelete onClick={() => deleteProduct('Single', item.product_cart_id)} />
                      </div>
                      <img src={item.images} alt={item.product_name} className="w-16 h-16 mr-4 bg-gray-100 rounded-lg" />
                      <span className='flex flow-row gap-5 place-items-center'>{item.product_name}
                        <span className='text-xl font-medium text-red-500'>{`${qty.some(q => q.product_id === item.product_id) ? `Availabel Stock ${qty.find(q => q.product_id === item.product_id).available_stock}` : ''}`}</span>
                      </span>
                    </td>
                    <td className="py-4 px-4 text-nowrap">{symbol ? symbol : '$'}&nbsp;{item.price}</td>
                    <td className="py-4 px-4 ">{item.colour}</td>
                    <td className="py-4 px-4 ">
                      <div className="flex items-center">
                        <button className="px-2 bg-black text-white rounded-md" onClick={() => handleDecrement(item.product_cart_id)}>-</button>
                        <span className="px-2 mx-3">{item.added_quantity}</span>
                        <button className="px-2 bg-black text-white rounded-md" onClick={() => handleIncrement(item.product_cart_id)}>+</button>
                      </div>
                    </td>
                    <td className="py-4 px-4 text-nowrap">{symbol ? symbol : '$'}&nbsp;{(item.price * item.added_quantity.toFixed(2))}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="block md:hidden">
              {cart.map(item => (
                <div key={item.id} className={`${qty.some(q => q.product_id === item.product_id) ? 'bg-red-200' : 'bg-white'} px-4 border-b border-gray-200  mb-4`}>
                  <div className="flex mb-2">
                    <img src={item.images} alt={item.product_name} className="w-24 h-24 bg-gray-100 mt-3" />
                    <div className='ml-4 w-full'>
                      <div className="flex justify-between">
                        <p className='text-md text-gray-400'>Category</p>
                        <div className="text-xl cursor-pointer text-gray-400">
                          <MdDelete onClick={() => deleteProduct('Single', item.product_cart_id)} />
                        </div>
                      </div>
                      <div>
                        <p className='text-lg font-medium'>{item.product_name}</p>
                      </div>
                      <div className="flex items-center justify-between mt-3">
                        <div><p className='text-lg font-medium'>{symbol ? symbol : '$'}&nbsp;{item.price}</p></div>
                        <div className="flex items-center">
                          <button className="px-2 bg-black text-white rounded-md" onClick={() => handleDecrement(item.product_cart_id)}>-</button>
                          <span className="px-2">{item.added_quantity}</span>
                          <button className="px-2 bg-black text-white rounded-md" onClick={() => handleIncrement(item.product_cart_id)}>+</button>
                        </div>
                      </div>
                      <span className='text-base font-medium text-red-500'>{`${qty.some(q => q.product_id === item.product_id) ? `Availabel Stock ${qty.find(q => q.product_id === item.product_id).available_stock}` : ''}`}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-between space-x-4 my-8 md:mb-0 ">
              <button className="bg-black text-sm text-white py-2 px-4 rounded-lg font-medium hover:bg-white hover:text-black hover:border border-black">CONTINUE SHOPPING</button>
              <div className='flex gap-4'>
                <button className="bg-white text-sm border border-black py-2 px-4 rounded-lg font-medium hover:bg-black hover:text-white" onClick={() => deleteProduct('All', user.customer_id)}>CLEAR CART</button>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/4 bg-gray-100 py-6 px-8 rounded-xl h-fit lg:sticky static md:top-56 top-0 xl:top-44">
            <h2 className="text-xl font-bold mb-4">Cart Total</h2>
            <div className="flex justify-between mb-2">
              <span className='text-gray-500'>Cart Total Amount</span>
              <span className='text-gray-500'>{symbol ? symbol : '$'}&nbsp;{(cartTotal).toFixed(2)}</span>
            </div>
            <div className="flex justify-between font-bold border-t border-gray-300 pt-2">
              <span className='text-black-500 text-xl'>Total</span>
              <span className='text-black-500 text-xl'>{symbol ? symbol : '$'}&nbsp;{(cartTotal).toFixed(2)}</span>
            </div>
            <div className="flex justify-end mt-6">
              <button onClick={checkout} className="bg-black text-sm text-white py-2 px-4 rounded-lg font-medium">Proceed To Check Out</button>
            </div>
          </div>
        </div>
      }
      {cart.length == 0 &&
        <div className="flex flex-col items-center justify-center min-h-[400px] p-8 bg-gray-50 rounded-lg">
          <div className="bg-gray-100 p-6 rounded-full mb-6">
            <MdOutlineShoppingCart className="w-12 h-12 text-gray-400" />
          </div>
          <h2 className="text-2xl font-semibold text-gray-900 mb-2">Your cart is empty</h2>
          <p className="text-gray-500 text-center max-w-sm mb-8">
            Looks like you haven't added anything to your cart yet. Start shopping to fill it up!
          </p>
          <Link to='/' className="px-6 py-3 bg-gray-500 text-white font-medium rounded-lg hover:bg-gray-700 transition-colors duration-200">
            Start Shopping
          </Link>
        </div>

      }
      <Footer />
    </>
  );
};

export default MyCart;
