import React, { useEffect, useRef, useState } from 'react';
import '../Assets/css/Styles.css'
import shoes from '../Assets/Images/ctg_shose.png';
import watch from '../Assets/Images/ctg_watch.png';
import headphne from '../Assets/Images/ctg_headphone.png';
import glass from '../Assets/Images/ctg_glass.png'
import electonics from '../Assets/Images/ctg_electronics.png'
import sportswear from '../Assets/Images/ctg_sportswear.png'
import traveling from '../Assets/Images/ctg_traveling.png'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useCart } from '../../Context/CartContext';

const Category = () => {


    const sliderRef = useRef(null);
    const [isPaused, setIsPaused] = useState(false);
    const { setMainctg } = useCart();


    useEffect(() => {
        const slider = sliderRef.current;
        let startPosition = 0;

        const handleScroll = () => {
            if (slider.scrollLeft >= slider.scrollWidth / 2) {
                slider.scrollLeft = startPosition;
            }
        };

        const scrollInterval = setInterval(() => {
            if (!isPaused) {
                slider.scrollLeft += 1;
                handleScroll();
            }
        }, 10); // Adjust speed by changing the interval value

        return () => clearInterval(scrollInterval);
    }, [isPaused]);


    const ctg = [
        {
            category_color_code: 'bg-cyan-300',
        },
        {
            category_color_code: 'bg-purple-300',
        },
        {
            category_color_code: 'bg-yellow-100',
        },

        {
            category_color_code: ' bg-red-300 ',
        },

        {
            category_color_code: 'bg-green-200',
        },
        {
            category_color_code: ' bg-amber-100',
        },
        {
            category_color_code: ' bg-blue-200',
        },

    ];

    const baseUrl = process.env.REACT_APP_BASEURL


    const [category, setCategory] = useState([]);


    useEffect(() => {
        axios.get(`${baseUrl}/homeCategoriesWithProductCount`)
            .then(response => {
                const categoriess = response.data.categories_array || [];
                setCategory(categoriess);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);


    return (
        <>
            <div className="container mx-auto my-10 px-4 py-8">
                <div className='flex justify-between mb-6'>
                    <h1 className="text-2xl md:text-3xl font-semibold text-gray-900">Categories</h1>
                    {/* <Link to='/all-categories' className='hover-effect text-md md:text-lg text-gray-400 font-medium lg:px-3 md:px-3 px-4'>View All</Link> */}
                </div>
                <div className=''>
                    <div
                        ref={sliderRef}
                        className="h-full rounded-md sliders  overflow-x-auto overflow-hidden w-full bg-white"
                        onMouseEnter={() => setIsPaused(true)}
                        onMouseLeave={() => setIsPaused(false)}>
                        <div className="flex flex-row md:gap-4 gap-2 w-fit overflow-x-auto">
                            {category.concat(category).map((list, index) => (
                                <Link to={'/category/' + list.category_id} onClick={() => setMainctg(list.category_id)} className={`md:h-28 lg:h-32  h-28 pt-2 rounded-md xl:w-[15vw] lg:w-[25vw] md:w-[32vw] w-[45vw] overflow-hidden ${ctg[index % ctg.length].category_color_code} justify-between md:place-items-center place-items-start shadow-lg flex `} key={index}
                                    style={{ backgroundColor: list.category_color_code }}>
                                    <div className='data ps-1 align-text-top md:ps-2 '>
                                        <h2 className='text-ms md:text-1xl lg:text-base font-semibold md:w-32'>{list.category_name}</h2>
                                        <span className='mt-0 text-xs  font-semibold text-nowrap'>({list.product_count} Items)</span>
                                    </div>
                                    <img className="md:h-28 lg:h-32 h-20 xl:ms-4 lg:ms-0 md:ms-0  hover-effect ms-auto" src={list.category_img} alt={list.category_name} />
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Category;
