import React, { useState } from 'react'
import Items from '../LandingPage/Items'
import Advbanner from '../LandingPage/Advbanner'
import Category from '../LandingPage/Category'
import Bigsale from '../LandingPage/Bigsale'
import Signup_newsletter from '../LandingPage/Signup_newsletter'
import Bestseller from './Bestseller'
import Carousel_banner from './Carousel_banner'
import Featuredproduct from './Featuredproduct'
import ProductModal from '../utiles/ProductModal'
import Navbar from '../LandingPage/Navbar'
import Footer from '../LandingPage/Footer';
import Loader from '../utiles/Loader'
import Boardcast from './Boardcast'
import Influencers from './Influencers'

const LandingPage = ({ productData }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const toggleModal = (product = null) => {
    if (!isOpen && product) {
      // Show loader only when opening the modal
      setIsLoading(true);
    }
    setTimeout(() => {
      setIsOpen(!isOpen);
      setIsLoading(false);
    }, 1000);

    setSelectedProduct(product);
  };



  return (
    <>
      <Navbar productData={productData} />
      <Carousel_banner />
      <Items toggleModal={toggleModal} productData={productData} />
      <Category />
      <Influencers setLoading={setIsLoading} />
      <Bigsale />
      <Advbanner />
      <Bestseller toggleModal={toggleModal} productData={productData} />
      <Featuredproduct productData={productData} />
      <Boardcast />
      {/* <Signup_newsletter /> */}
      <ProductModal isOpen={isOpen} toggleModal={toggleModal} product={selectedProduct} setLoading={setIsLoading} />
      <Footer />
      {isLoading && <Loader />}
    </>
  )
}

export default LandingPage



