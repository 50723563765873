import React, { useEffect, useState } from 'react';
import { FaEye, FaFileInvoice } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { UserState } from '../../../../Context/userProvider';
import axios from 'axios';
import { Modal, Tabs } from "flowbite-react";
import { IoBagHandleSharp } from "react-icons/io5";
import { TbListDetails } from "react-icons/tb";

const Myorders = ({ setLoading }) => {
    const baseUrl = process.env.REACT_APP_BASEURL

    const [activeTab, setActiveTab] = useState('all');
    const [data, setData] = useState([]);
    const [orders, setOrders] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [orderDetails, setOrderdetails] = useState({});

    const { user, logout, geoLocation, countryCode } = UserState();
    useEffect(() => {
        const order = async () => {
            setLoading(true)
            try {
                const response = await axios.post(`${baseUrl}/get_customer_allorder`, { customer_id: user.customer_id })
                if (response.data.status) {
                    let orders_data;
                    if (response.data.OrderAllData) {
                        setData(response.data.OrderAllData)
                        orders_data = response.data.OrderAllData.map(list => ({
                            order_unique_id: list.order_details.order_unique_id,
                            product_name: list.product_details.length > 0 && list.product_details[0].product_name,
                            order_items: list.product_details.length > 0 && list.product_details.length,
                            size: list.product_details.length > 0 && list.product_details[0].size_name,
                            color: list.product_details.length > 0 && list.product_details[0].colour,
                            quantity: list.product_details.length > 0 && list.product_details[0].quantity,
                            image: list.product_details.length > 0 && list.product_details[0].images,
                            total: list.order_details.amount,
                            status: list.order_details.order_status,
                        }))
                    }
                    setOrders(orders_data)
                }
            } catch (err) {
                console.log(err)
            }
            setLoading(false)
        };
        order();
    }, [user]);


    const tabs = [{ name: 'all' }, { name: 'pending' }, { name: 'confirm' }, { name: 'completed' }, { name: 'canceled' }]

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const getFilteredOrders = () => {
        if (activeTab === 'all') return orders;
        if (activeTab === 'canceled') {
            return orders.filter(order => order.status.toLowerCase().includes('cancel'));
        }
        return orders.filter(order => order.status.toLowerCase() === activeTab);
    };

    console.log(orders)


    const getStatusClass = (stockStatus) => {
        switch (stockStatus.toLowerCase()) {
            case 'in stock':
            case 'active':
            case 'completed':
                return 'bg-green-200 text-green-600 text-xs';
            case 'canceled':
            case 'cancel pending':
                return 'bg-red-200 text-red-500 text-xs';

            case 'pending':
                return 'bg-yellow-200 text-yellow-500 text-xs';
            case 'confirm':
                return 'bg-green-200 text-green-500 text-xs';
            default:
                return 'bg-gray-400 text-white text-xs';
        }
    };

    const openmodal = async (id) => {
        const onedata = data.find(list => list.order_details.order_unique_id == id);
        setOrderdetails(onedata)
        setOpenModal(true);
    }

    const closemodal = () => {
        setOrderdetails({});
        setOpenModal(false);
    }

    return (
        <>
            <div>
                <h1 className='md:text-2xl text-xl font-medium capitalize'>My Orders</h1>
                <div className='mt-4'>
                    <div className='flex space-x-4 border-b overflow-x-auto'>
                        {tabs.map((list, index) => (
                            <button key={index}
                                onClick={() => handleTabChange(list.name)}
                                className={`py-2 px-4 ${activeTab === list.name ? 'border-b-2 border-black text-black font-semibold' : 'text-gray-500'} transition-colors text-sm  duration-200 capitalize`}
                            >
                                {list.name}
                            </button>
                        ))}
                    </div>
                    <div className='mt-6'>
                        {getFilteredOrders().length === 0 ? (
                            <p>No orders found.</p>
                        ) : (
                            getFilteredOrders().map(item => (
                                <div key={item.id} className="bg-white mb-4 pb-4 border-b-2 border-gray-300">
                                    <div className="flex flex-row">
                                        <div className='md:w-3/12 xl:w-40 w-28 me-2 h-full'>
                                            <img src={item.image} alt={item.name} className="md:w-36 md:h-40 w-28 h-28 bg-gray-100 rounded-lg mr-4" />
                                        </div>
                                        <div className='flex-col flex gap-1 md:gap-2 md:w-9/12 xl:w-10/12 w-9/12 '>
                                            <div className='flex flex-row justify-between'>
                                                <p className='md:text-xl text-base text-wrap font-medium line-clamp-1'>{item.product_name}</p>
                                                <div className=' ms-auto text-base cursor-pointer' onClick={() => openmodal(item.order_unique_id)}>
                                                    <FaEye />
                                                </div>
                                                {item.status && item.status.toLowerCase() === 'completed' && (
                                                    <FaFileInvoice className='md:hidden block ms-auto text-xl' />
                                                )}
                                            </div>
                                            <div className='flex flex-row gap-4 my-0.5'>
                                                <p className='text-gray-500 text-xs md:text-sm'>Size : {item.size}</p>
                                                <p className='text-gray-500 text-xs md:text-sm'>Color : {item.color}</p>
                                                <p className='text-gray-500 text-xs md:text-sm'>Qty : {item.quantity}</p>
                                            </div>
                                            <div className='flex flex-row gap-4 place-items-center mb-2 md:mb-0'>
                                                <span className={`text-xs  rounded-lg px-2 py-1  font-medium capitalize w-fit ${getStatusClass(item.status)}`}>{item.status}</span>
                                                <span className='bg-gray-200 text-xs  rounded-lg px-2 py-1 capitalize'>Order Items : {item.order_items}</span>
                                                {item.status && item.status.toLowerCase().includes('cancel') && (
                                                    <p className='md:text-xl text-base xl:hidden md:block hidden text-black font-medium'>$ {item.total}</p>
                                                )}
                                            </div>
                                            <div className='flex justify-between items-center'>
                                                {item.status && item.status.toLowerCase().includes('cancel') && (
                                                    <p className='md:text-xl text-base text-black font-medium xl:block block md:hidden'>$ {item.total}</p>
                                                )}

                                                {/* {item.status && item.status.toLowerCase() === 'confirm' && ( */}
                                                <>
                                                    <p className='md:text-xl text-base text-black font-medium '>$ {item.total}</p>
                                                    <Link to='/tracking-order' className='text-black px-3 h-fit items-end text-nowrap py-2 text-xs border-black border-2 font-semibold rounded-md shadow-sm hover:text-white hover:bg-black transition-all duration-300 ease-in-out'>Track Order</Link>
                                                </>
                                                {/* )} */}
                                                {item.status && item.status.toLowerCase() === 'completed' && (
                                                    <>
                                                        <p className='md:text-xl text-base text-black font-medium '>$ {item.total}</p>

                                                        <div className='flex flex-row'>
                                                            <button className='text-black px-3 me-2 h-fit text-xs items-end md:block hidden text-nowrap py-2 border-black border-2 font-semibold rounded-md shadow-sm hover:text-white hover:bg-black transition-all duration-300 ease-in-out'>Download Invoice</button>
                                                            <button className='text-black px-3 h-fit text-xs items-end text-nowrap py-2 border-black border-2 font-semibold rounded-md shadow-sm hover:text-white hover:bg-black transition-all duration-300 ease-in-out'>Leave Review</button>
                                                        </div>
                                                    </>
                                                )}
                                                {item.status && item.status.toLowerCase().includes('cancel') && (
                                                    <div className='flex xl:flex-row md:flex-col'>
                                                        <button className='text-black px-3 me-2 h-fit text-xs items-end md:hidden block text-nowrap py-2 border-black border-2 font-semibold rounded-md shadow-sm hover:text-white hover:bg-black transition-all duration-300 ease-in-out'>Details</button>
                                                        <p className='text-gray-500 text-xs md:text-sm md:block hidden '>Order Id : {item.orderid}</p>
                                                        <p className='text-gray-500 text-xs md:text-sm md:block hidden xl:mx-5'>Order Date : {item.orderdate}</p>
                                                        <p className='text-gray-500 text-xs md:text-sm md:block hidden'>Cancel Order : {item.orderdate}</p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
            <Modal size='4xl' show={openModal} onClose={closemodal}>
                <Modal.Header>Order Details</Modal.Header>
                <Modal.Body>
                    <Tabs aria-label="Tabs with icons" variant="underline">
                        <Tabs.Item active title="Order Items" icon={IoBagHandleSharp}>
                            <table className="w-full bg-white hidden md:block">
                                <thead className="bg-gray-100 rounded-xl">
                                    <tr>
                                        <th className="py-4 px-4 text-left">Product</th>
                                        <th className="py-4 px-4 text-left">Price</th>
                                        <th className="py-4 px-4 text-left">Colour</th>
                                        <th className="py-4 px-4 text-left">Size</th>
                                        <th className="py-4 px-4 text-left">Quantity</th>
                                        <th className="py-4 px-4 text-left">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderDetails.product_details && orderDetails.product_details.map(item => (
                                        <tr key={item.product_id} className={`bg-white  border-b border-gray-200`}>
                                            <td className="py-4 px-4  flex items-center gap-4">
                                                <img src={item.images} alt={item.product_name} className="w-16 h-16 mr-4 bg-gray-100 rounded-lg" />
                                                <span className='flex flow-row gap-5 place-items-center w-56 line-clamp-2'>{item.product_name}</span>
                                            </td>
                                            <td className="py-4 px-4 text-nowrap ">{item.symbol != null ? item.symbol : '$'}&nbsp;{item.price}</td>
                                            <td className="py-4 px-4 ">{item.colour}</td>
                                            <td className="py-4 px-4 text-nowrap">{item.size_name}</td>
                                            <td className="py-4 px-4 ">
                                                <div className="flex items-center">
                                                    <span className="px-2 mx-3">{item.quantity}</span>
                                                </div>
                                            </td>
                                            <td className="py-4 px-4 text-nowrap">{item.symbol != null ? item.symbol : '$'}&nbsp;{(item.price * item.quantity.toFixed(2))}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="block md:hidden">
                                {orderDetails.product_details && orderDetails.product_details.map(item => (
                                    <div key={item.product_id} className={`bg-white px-4 border-b border-gray-200  mb-4`}>
                                        <div className="flex mb-2">
                                            <img src={item.images} alt={item.product_name} className="w-24 h-24 bg-gray-100 mt-3" />
                                            <div className='ml-4 w-full'>
                                                <div className="flex justify-between">
                                                    <p className='text-md text-gray-400'>Category</p>
                                                </div>
                                                <div>
                                                    <p className='text-lg font-medium'>{item.product_name}</p>
                                                </div>
                                                <div className="flex items-center justify-between mt-3">
                                                    <div><p className='text-lg font-medium'>{item.symbol != null ? item.symbol : '$'}&nbsp;{item.price}</p></div>
                                                    <div className="flex items-center">
                                                        <span className="px-2">Qty : {item.quantity}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Tabs.Item>
                        <Tabs.Item active title="Order Details" icon={TbListDetails}>
                            {orderDetails.order_details &&
                                <div className="flex flex-col gap-3">
                                    <div className="flex flex-row">
                                        <span className='w-1/4'>Order ID</span>
                                        <span>: {orderDetails.order_details.order_unique_id}</span>
                                    </div>
                                    <div className="flex flex-row">
                                        <span className='w-1/4'>Order Status</span>
                                        <span>: {orderDetails.order_details.order_status}</span>
                                    </div>
                                    <div className="flex flex-row">
                                        <span className='w-1/4'>Order Date</span>
                                        <span>: {orderDetails.order_details.inserted_date}</span>
                                    </div>
                                    {orderDetails.coupon_data &&
                                        <>
                                            <div className="flex flex-row">
                                                <span className='w-1/4'>Amount</span>
                                                <span>: {orderDetails.coupon_data.symbol ? orderDetails.coupon_dat.symbol : '$'} {Number(orderDetails.order_details.amount) + Number(orderDetails.coupon_data.amount)}</span>
                                            </div>
                                            <div className="flex flex-row">
                                                <span className='w-1/4'>Coupon Discount</span>
                                                <span>: {orderDetails.coupon_data.symbol ? orderDetails.coupon_dat.symbol : '$'} {orderDetails.coupon_data.amount}</span>
                                            </div>
                                        </>
                                    }
                                    <div className="flex flex-row">
                                        <span className='w-1/4'>Total Amount</span>
                                        <span>: {orderDetails.order_details.symbol ? orderDetails.order_details.symbol : '$'} {orderDetails.order_details.amount}</span>
                                    </div>
                                    <div className="flex flex-row">
                                        <span className='w-1/4'>Address</span>
                                        <span>: {orderDetails.customerAdress.address1} ,{orderDetails.customerAdress.address2} ,{orderDetails.customerAdress.locality} ,{orderDetails.customerAdress.city} ,{orderDetails.customerAdress.state_subdivision_name} ,{orderDetails.customerAdress.country_name}</span>
                                    </div>
                                </div>
                            }
                        </Tabs.Item>
                    </Tabs>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Myorders;
