import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import Dashboard from './Pages/Dashboard';
import ProductManage from './Pages/ProductManage';
import Ordermanage from './Pages/Ordermanage';
import Addproduct from './Pages/Addproduct';
import Editproduct from './Pages/Editproduct';
import Coupon from './Pages/Coupon';
import Ratingreview from './Pages/Ratingreview';
import Customers from './Pages/Customers';
import Categories from './Pages/Categories';
import Addcategory from './Pages/Addcategory';
import Editcategory from './Pages/Editcategory';
import profile from '../Component/Assets/Images/rating2.png'
import Infulancers from './Pages/Infulancers/Infulancers';
import Infulancersprofile from './Pages/Infulancers/Infulancersprofile';
import Adminprofile from './Pages/Adminprofile';
import Transactions from './Pages/Transactions';
import Settings from './Pages/Settings';
import Offers from './Pages/Offers';
import Inquiries from './Pages/Inquiries';
import { UserState } from '../Context/userProvider';
import ProtectedRoute from '../Admin/ProtectedRoutes';
import ReturnOrder from './Pages/ReturnOrder';
import Support from './Pages/Support';
import axios from 'axios';
import Manager from './Pages/Manager';
import Supervisor from './Pages/SuperVisor';
import Loader from '../Component/utiles/Loader';
import Supplierseller from './Pages/Supplier-seller/Supplierseller';
import Supplierinfluencer from './Pages/Supplier-influencer/Supplierinfluencer';
import Supplierprofile from './Pages/Supplier/Supplierprofile';
import Supplier from './Pages/Supplier/Supplier';
import Supplierinfluprofile from './Pages/Supplier-influencer/Supplierinfluprofile';
import Warehouses from './Pages/Warehouses';
import notify from '../Component/utiles/Notification';

const Home = () => {

    const baseUrl = process.env.REACT_APP_BASEURL

    const [categoryoption, setCategoryoption] = useState([]);
    const [allctgdata, setAllctgdata] = useState([]);
    const [getctgdata, setGetctgdata] = useState(0);
    const [getuserdata, setGetuserdata] = useState(0);
    const [userlist, setUserlist] = useState(0);

    const [product_id, setProductID] = useState(0);

    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState('');
    const [sizes, setSizes] = useState([]);
    const [sizesdata, setSizesdata] = useState([]);
    const [colordata, setColordata] = useState([]);
    const [colours, setColours] = useState([]);
    const [alluserdata, setAllUserdata] = useState([]);

    const ctgoptions = [
        { label: 'Not Found Category' },
    ];

    useEffect(() => {
        if (product_id) {
            clickProductID(product_id);
        }
    }, [product_id]);

    const { user, logout } = UserState();

    const navigate = useNavigate();

    // useEffect(() => {
    //     if (!user || !user.role) {
    //         navigate('/admin/login');
    //     }
    // }, [user, navigate]);


    const [isExpanded, setIsExpanded] = useState(true);

    const toggleMenu = () => {
        setIsExpanded(!isExpanded);
        // setResize(prev => prev + 1);

    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1024) {
                setIsExpanded(false);
            } else {
                setIsExpanded(true);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const sellers = [
        {
            name: 'Gautam Bhesaniya',
            img: profile,
            id: '79879',
            email: 'alasseller@gmail.com',
        },
        {
            name: 'Gautam Bhesaniya',
            img: profile,
            id: '79879',
            email: 'alasseller@gmail.com',
        },
        {
            name: 'Gautam Bhesaniya',
            img: profile,
            id: '79879',
            email: 'alasseller@gmail.com',
        },
        {
            name: 'Gautam Bhesaniya',
            img: profile,
            id: '79879',
            email: 'alasseller@gmail.com',
        },
        {
            name: 'Gautam Bhesaniya',
            img: profile,
            id: '79879',
            email: 'alasseller@gmail.com',
        },
        {
            name: 'Gautam Bhesaniya',
            img: profile,
            id: '79879',
            email: 'alasseller@gmail.com',
        },
        {
            name: 'Gautam Bhesaniya',
            img: profile,
            id: '79879',
            email: 'alasseller@gmail.com',
        },
        {
            name: 'Gautam Bhesaniya',
            img: profile,
            id: '79879',
            email: 'alasseller@gmail.com',
        },
        {
            name: 'Gautam Bhesaniya',
            img: profile,
            id: '79879',
            email: 'alasseller@gmail.com',
        },
    ]

    useEffect(() => {
        const allctg = async () => {
            try {
                const response = await axios.get(`${baseUrl}/get_categories`);
                if (response.data.status) {
                    const categories = response.data.category.map(categoryValue => ({
                        id: categoryValue.category_id,
                        label: categoryValue.category_name,
                        status: categoryValue.status
                    }));
                    setCategoryoption(categories);
                    setAllctgdata(response.data.category)
                } else {
                    setCategoryoption(ctgoptions)
                }
            } catch (err) {
                console.log(err);
            }
        };

        allctg();
    }, [getctgdata]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setLoading(true);
                const response = await axios.post(`${baseUrl}/user_data`,
                    { user_id: user.user_id },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
                setUserData(response.data.data[0]);
                setLoading(false)
            } catch (err) {
                console.log(err);
            }
        };

        fetchUserData();
    }, [getuserdata]);



    useEffect(() => {
        const allsizes = async () => {
            try {
                const response = await axios.get(`${baseUrl}/product_sizes`);
                if (response.data.status) {
                    const sizesOption = response.data.sizes.map(sizesvalue => ({
                        id: sizesvalue.size_id,
                        label: sizesvalue.size_name,
                    }));
                    setSizesdata(response.data.sizes)
                    setSizes(sizesOption)
                } else {
                    setSizes(sizeOptions)
                }
            } catch (err) {
                console.log(err);
            }
        };

        allsizes();
    }, []);
    useEffect(() => {
        const allcolours = async () => {
            try {
                const response = await axios.get(`${baseUrl}/product_colours`);
                if (response.data.status) {
                    const coloroption = response.data.colurs.map(colorsvalue => ({
                        id: colorsvalue.colour_id,
                        label: colorsvalue.colour_name,
                        hexa: colorsvalue.hexa
                    }));
                    setColours(coloroption)
                    setColordata(response.data.colurs)
                } else {
                    setColours(sizeOptions)
                }
            } catch (err) {
                console.log(err);
            }
        };

        allcolours();
    }, []);

    const sizeOptions = [
        { label: 'Not Found Sizes' },
    ];

    const [productdata, setProductdata] = useState('');
    const [varientsdata, setVarientsdata] = useState([]);
    const [mainproduct, setMainproduct] = useState({});
    const [fields, setFields] = useState([{ product_about_id: '', field: '', value: '' }]);

    const [variants, setVariants] = useState([{
        product_varient_id: null,
        color: '',
        price: '',
        sizes: [{ size: '', quantity: '', size_id: '', product_varient_size_id: '' }],
        var_images: [],
        showUploadSection: false,
    }]);
    const [existingImages, setExistingImages] = useState([{
        image_url: '', product_images_id: ''
    }]);


    const clickProductID = async (id) => {
        setLoading(true)
        try {
            const response = await axios.post(`${baseUrl}/get_idwise_productdata`,
                { product_id: id },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
            setProductdata(response.data.product);
            setVarientsdata(response.data.varientsdata || null);
            const product = response.data.product || null;
            setMainproduct(product);
            let info;
            if (response.data.about != null) {
                info = response.data.about.map(list => ({
                    product_about_id: list.product_about_id,
                    field: list.field,
                    value: list.value
                }))
            } else {
                info = [{ product_about_id: null, field: '', value: '' }]
            }
            setFields(info);
            let initialVariants;
            if (response.data.varientsdata != null) {
                initialVariants = response.data.varientsdata.map(variantData => ({
                    product_varient_id: variantData.varient.product_varient_id,
                    color: variantData.varient.colour,
                    price: variantData.varient.total_amount,
                    sizes: variantData.product_varient_size.map(size => ({
                        size: size.size_name,
                        quantity: size.stock,
                        size_id: size.size_id,
                        product_varient_size_id: size.product_varient_size_id
                    })),
                    var_images: variantData.varient_images.map(image => ({
                        url: image.images,
                        product_varient_image_id: image.product_varient_image_id
                    })) || [],
                    showUploadSection: false
                }));
                // console.log("hello")
            } else {
                initialVariants = [{
                    product_varient_id: null,
                    color: '',
                    price: '',
                    sizes: [{ size: '', quantity: '', size_id: '' }],
                    var_images: [],
                    showUploadSection: false
                }];
            }

            setVariants(initialVariants);

            const fetchedImages = response.data.productdata?.product_images || [];
            const imageUrls = fetchedImages.map(image => ({
                image_url: image.images,
                product_images_id: image.product_images_id
            }));
            setExistingImages(imageUrls);
        } catch (err) {
            console.log(err);
        }
        setLoading(false)
    };

    useEffect(() => {
        setLoading(true);
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${baseUrl}/get_user_list`);
                setAllUserdata(response.data.users);
            } catch (err) {
                console.log(err);
            }
        };

        fetchUserData();
        setLoading(false)
    }, [userlist]);


    const editProduct = {
        productdata,
        setMainproduct,
        varientsdata,
        mainproduct,
        variants,
        setVariants,
        existingImages,
        setExistingImages,
        fields,
        setFields
    };



    const updatestatus = async (id, status, type) => {
        setLoading(true)

        let updatedstatus;
        if (status === "Active") {
            updatedstatus = "Inactive";
        } else {
            updatedstatus = "Active";
        }
        // alert(updatedstatus)

        try {
            const response = await axios.post(`${baseUrl}/update_user_status`, {
                type: type,
                user_id: id,
                status: updatedstatus
            });
            if (response.data.status) {
                setUserlist(prev => prev + 1);
                notify('success', 'Success', `Status update to ${updatedstatus}`);
            } else {
                notify('error', 'Error', `${response.data.message}`);
            }
        } catch (err) {
            notify('error', 'Error', `Something went Wrong`);
            console.log(err);
        }
        setLoading(false)
    }


    return (
        <>
            <Navbar isExpanded={isExpanded} toggleMenu={toggleMenu} userData={userData} />
            <div className="flex h-[93vh] overflow-hidden">
                <div
                    className={`text-black lg:block hidden flex-shrink-0 transition-all duration-300 ease-in-out h-full  ${isExpanded ? "w-64" : "w-20"} overflow-y-auto`}
                >
                    <Sidebar isExpanded={isExpanded} toggleMenu={toggleMenu} />
                </div>
                {isExpanded && (
                    <>
                        <div
                            className="fixed inset-0 bg-black bg-opacity-50 z-10 lg:hidden transition-all duration-300"
                            onClick={toggleMenu}
                        ></div>
                        <div
                            className={` text-black bg-white lg:hidden block z-50 fixed flex-shrink-0 transition-all duration-300 ease-in-out h-full ${isExpanded ? "w-64" : "w-20"} overflow-y-auto`}
                        >
                            <Sidebar isExpanded={isExpanded} toggleMenu={toggleMenu} />

                        </div>
                    </>
                )}
                <div className="flex-grow bg-gray-100 overflow-auto">
                    <div className="md:p-6 p-3">
                        <Routes>
                            <Route path='/dashboard' element={<Dashboard />} />
                            <Route path='products' element={<ProductManage {...editProduct} setLoading={setLoading} clickProductID={clickProductID} />} />
                            <Route path='products/add-product' element={<Addproduct categoryoption={categoryoption} setLoading={setLoading} sizes={sizes} colours={colours} />} />
                            <Route path='products/edit-product/:id' element={<Editproduct {...editProduct} categoryoption={categoryoption} setLoading={setLoading} sizes={sizes} colours={colours} setProductID={setProductID} />} />
                            <Route path='orders' element={<Ordermanage setLoading={setLoading}/>} />
                            <Route path='return-orders' element={<ReturnOrder />} />
                            <Route path='transactions' element={<Transactions />} />
                            <Route path='inquiries' element={<Inquiries />} />
                            <Route path='coupons' element={<Coupon setLoading={setLoading} />} />
                            <Route path='rating-review' element={<Ratingreview setLoading={setLoading} />} />
                            <Route path='offers' element={<Offers setLoading={setLoading} />} />
                            <Route path='support/*' element={<Support />} />
                            <Route path='categories' element={<Categories allctgdata={allctgdata} setGetctgdata={setGetctgdata} />} />
                            <Route path='categories/add-category' element={<Addcategory />} />
                            <Route path='categories/edit-category/:id' element={<Editcategory setGetctgdata={setGetctgdata} />} />
                            <Route path='customers' element={<Customers updatestatus={updatestatus} userlist={userlist} setLoading={setLoading} />} />
                            <Route path='supplier' element={<Supplier alluserdata={alluserdata} updatestatus={updatestatus} />} />
                            <Route path='supplier/profile/:id' element={<Supplierprofile clickProductID={clickProductID} {...editProduct} setLoading={setLoading} />} />
                            <Route path='supplier-seller' element={<Supplierseller alluserdata={alluserdata} updatestatus={updatestatus} />} />
                            <Route path='supplier-influencers' element={<Supplierinfluencer alluserdata={alluserdata} updatestatus={updatestatus} />} />
                            <Route path='supplier-influencers/profile/:id' element={<Supplierinfluprofile clickProductID={clickProductID} {...editProduct} setLoading={setLoading} />} />
                            <Route path='infulancers' element={<Infulancers alluserdata={alluserdata} updatestatus={updatestatus} />} />
                            <Route path='infulancers/profile/:id' element={<Infulancersprofile setLoading={setLoading} />} />
                            <Route path='manager' element={<Manager />} />
                            <Route path='supervisor' element={<Supervisor />} />
                            <Route path='warehouses' element={<Warehouses />} />
                            <Route path='my-profile' element={<Adminprofile userData={userData} setGetuserdata={setGetuserdata} setLoading={setLoading} />} />
                            <Route path='settings' element={<Settings setLoading={setLoading} sizesdata={sizesdata} colordata={colordata} />} />

                        </Routes>
                        <Outlet />
                    </div>
                </div>
            </div >
            {loading && <Loader />
            }
        </>
    );
}

export default Home;
