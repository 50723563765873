
import img from '../Assets/Images/shoe.png';



const returnOrders = [
    {
        product: "Lorem Ipsum, sometimes",
        orderId: "#10",
        createdDate: "20-08-2024",
        returnBy: "courier return (RTO)",
        quantity: 2,
        returnReason: "NA",
        img: img,
        status: "in transit"
    },
    {
        product: "Lorem Ipsum, sometimes",
        orderId: "#45835",
        createdDate: "20-08-2024",
        returnBy: "Customer Return",
        quantity: 2,
        returnReason: "This can be due to errors in shipping, factors like mishandling",
        img: img,
        status: "out of delivery"
    },
    {
        product: "Lorem Ipsum, sometimes",
        orderId: "#45835",
        createdDate: "20-08-2024",
        returnBy: "Customer Return",
        quantity: 2,
        returnReason: "This can be due to errors in picking, packing",
        img: img,
        status: "delivered"
    },
    {
        product: "Lorem Ipsum, sometimes",
        orderId: "#45835",
        createdDate: "20-08-2024",
        returnBy: "courier return (RTO)",
        quantity: 2,
        returnReason: "NA",
        img: img,
        status: "in transit"
    },
    {
        product: "Lorem Ipsum, sometimes",
        orderId: "#45835",
        createdDate: "20-08-2024",
        returnBy: "Customer Return",
        quantity: 2,
        returnReason: "This can be due to errors in shipping, factors like mishandling",
        img: img,
        status: "out of delivery"
    },
    {
        product: "Lorem Ipsum, sometimes",
        orderId: "#45835",
        createdDate: "20-08-2024",
        returnBy: "Customer Return",
        quantity: 2,
        returnReason: "This can be due to errors in picking, packing",
        img: img,
        status: "delivered"
    },
    {
        product: "Lorem Ipsum, sometimes",
        orderId: "#45835",
        createdDate: "20-08-2024",
        returnBy: "courier return (RTO)",
        quantity: 2,
        returnReason: "NA",
        img: img,
        status: "in transit"
    },
    {
        product: "Lorem Ipsum, sometimes",
        orderId: "#45835",
        createdDate: "20-08-2024",
        returnBy: "Customer Return",
        quantity: 2,
        returnReason: "This can be due to errors in shipping, factors like mishandling",
        img: img,
        status: "out of delivery"
    },
    {
        product: "Lorem Ipsum, sometimes",
        orderId: "#45835",
        createdDate: "20-08-2024",
        returnBy: "Customer Return",
        quantity: 2,
        returnReason: "This can be due to errors in picking, packing",
        img: img,
        status: "delivered"
    },
    {
        product: "Lorem Ipsum, sometimes",
        orderId: "#45835",
        createdDate: "20-08-2024",
        returnBy: "courier return (RTO)",
        quantity: 2,
        returnReason: "NA",
        img: img,
        status: "lost"
    },
    {
        product: "Lorem Ipsum, sometimes",
        orderId: "#45835",
        createdDate: "20-08-2024",
        returnBy: "Customer Return",
        quantity: 2,
        returnReason: "This can be due to errors in shipping, factors like mishandling",
        img: img,
        status: "in transit"
    },
    {
        product: "Lorem Ipsum, sometimes",
        orderId: "#45835",
        createdDate: "20-08-2024",
        returnBy: "Customer Return",
        quantity: 2,
        returnReason: "This can be due to errors in picking, packing",
        img: img,
        status: "out of delivery"
    },
    {
        product: "Lorem Ipsum, sometimes",
        orderId: "#45835",
        createdDate: "20-08-2024",
        returnBy: "Customer Return",
        quantity: 2,
        returnReason: "This can be due to errors in shipping, factors like mishandling",
        img: img,
        status: "in transit"
    },
    {
        product: "Lorem Ipsum, sometimes",
        orderId: "#45835",
        createdDate: "20-08-2024",
        returnBy: "Customer Return",
        quantity: 2,
        returnReason: "This can be due to errors in picking, packing",
        img: img,
        status: "out of delivery"
    },
    {
        product: "Lorem Ipsum, sometimes",
        orderId: "#45835",
        createdDate: "20-08-2024",
        returnBy: "courier return (RTO)",
        quantity: 2,
        returnReason: "NA",
        img: img,
        status: "delivered"
    },
    {
        product: "Lorem Ipsum, sometimes",
        orderId: "#45835",
        createdDate: "20-08-2024",
        returnBy: "Customer Return",
        quantity: 2,
        returnReason: "This can be due to errors in picking, packing",
        img: img,
        status: "lost"
    },
    {
        product: "Lorem Ipsum, sometimes",
        orderId: "#45835",
        createdDate: "20-08-2024",
        returnBy: "courier return (RTO)",
        quantity: 2,
        returnReason: "NA",
        img: img,
        status: "in transit"
    },
    {
        product: "Lorem Ipsum, sometimes",
        orderId: "#45835",
        createdDate: "20-08-2024",
        returnBy: "courier return (RTO)",
        quantity: 2,
        returnReason: "NA",
        img: img,
        status: "out of delivery"
    }
];


export default returnOrders;