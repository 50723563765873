import React from 'react'
import { Link } from 'react-router-dom'
import error from '../Assets/Images/404.png'

const Errorpage = () => {
    return (
        <div className="h-[100vh] flex justify-center place-items-center">
            <div className="flex flex-col justify-center  xl:gap-10 gap-6 place-items-center">
                <img src={error} alt="" className='md:h-96 md:w-96 h-64 w-64'/>
                <span className='xl:text-4xl md:text-2xl text-lg font-bold '>Page Not Found</span>
                <span className='xl:text-sm text-xs font-semibold text-gray-500 '>Sorry We Can’t Find The Page You Are Looking For </span>
                <Link to='/' className='bg-black xl:text-xl md:text-lg font-semibold xl:p-4 p-3 capitalize text-white w-fit rounded-lg'>Back to home</Link>
            </div>
        </div>
    )
}

export default Errorpage