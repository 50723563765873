import React, { useEffect, useState } from 'react'
import Dropdown from '../../Component/utiles/Dropdown';
import { IoSearchSharp } from "react-icons/io5";
import Pagination from '../../Component/utiles/Pagination';
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbUserEdit } from "react-icons/tb";
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import { Modal, Tabs, Label, TextInput, Progress, Tooltip } from "flowbite-react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Loader from '../../Component/utiles/Loader';
import { HiUserCircle } from "react-icons/hi";
import { AiOutlineBank } from "react-icons/ai";
import notify from '../../Component/utiles/Notification';
import { FaLocationDot } from "react-icons/fa6";
import { useCountries, useStates } from '../../Component/utiles/CountryStateCity';


const Supervisor = () => {

    const baseUrl = process.env.REACT_APP_BASEURL


    const [searchTerm, setSearchTerm] = useState('');
    const [countryID, setCountryID] = useState('');
    const { countryOptions, error: countryError } = useCountries();
    const { stateOptions, error: stateError } = useStates(countryID);
    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [supervisor, setSupervisor] = useState([]);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [cpasswordVisible, setcPasswordVisible] = useState(false);
    const [progressPercentage, setProgressPercentage] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const[superUpdateId ,setSuperUpdateId] = useState();
    const [supervisorInfo, setSupervisorInfo] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        password: '',
        confirmPassword: '',
        role: 'supervisor'
    });
    const [supervisorBankDetails, setSupervisorBankdetails] = useState({
        accountHoldername: '',
        bankAccountNo: '',
        reenterBankAccountNo: '',
        IFSCcode: '',
    })
    const [addressData, setAddressData] = useState({
        address: '',
        country: '',
        state: '',
        locality: '',
        pincode: '',
        city: '',
    });

    const calculateProgress = () => {
        const profileFilled = supervisorInfo.firstName && supervisorInfo.lastName && supervisorInfo.email && supervisorInfo.mobile && supervisorInfo.password && supervisorInfo.confirmPassword;
        const bankDetailsFilled = supervisorBankDetails.accountHoldername && supervisorBankDetails.bankAccountNo && supervisorBankDetails.reenterBankAccountNo && supervisorBankDetails.IFSCcode;
        const addressFilled = addressData.address && addressData.country && addressData.state && addressData.city && addressData.pincode;

        if (profileFilled && bankDetailsFilled && addressFilled) {
            return 100;
        } else if (profileFilled && bankDetailsFilled) {
            return 66;
        } else if (profileFilled) {
            return 33;
        }
        return 0;
    };
    useEffect(() => {
        setProgressPercentage(calculateProgress());
    }, [supervisorInfo, supervisorBankDetails, addressData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAddressData((prev) => ({ ...prev, [name]: value }));
    };

    const allSupervisor = async () => {
        setIsLoading(true)
        try {
            const response = await axios.get(`${baseUrl}/get_all_supervisor`);
            if (response.data.status) {
                setSupervisor(response.data.supervisors)
                setIsLoading(false)
            }
        } catch (err) {
            console.log(err);
            setIsLoading(false)
        }
    };

    useEffect(() => {
        allSupervisor();
    }, []);



    const perpageoption = [
        { label: '5' }, { label: '10' }, { label: '15' }, { label: '20' }
    ];


    const [itemsPerPage, setItemsPerPage] = useState(perpageoption[0].label);
    const [currentPage, setCurrentPage] = useState(1);

    const searchResults = supervisor.filter(customersdata => {
        // const fullName = (customersdata.first_name + ' ' + customersdata.last_name).toLowerCase();
        const searchTermLower = searchTerm.toLowerCase();

        // return fullName.includes(searchTermLower) ||
            return customersdata.name.toString().toLowerCase().includes(searchTermLower) ||
            customersdata.unique_id.toString().toLowerCase().includes(searchTerm.toLowerCase())
            // customersdata.unique_id.toString().toLowerCase().includes(searchTermLower);
    });

    const itemsToDisplay = searchTerm ? searchResults : supervisor;


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = itemsToDisplay.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(itemsToDisplay.length / itemsPerPage);

    const handelCreate = () => {
        setIsEditing(false)
        setSupervisorInfo({
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            password: '',
            confirmPassword: '',
        });
        setSupervisorBankdetails({
            accountHoldername: '',
            bankAccountNo: '',
            reenterBankAccountNo: '',
            IFSCcode: '',
        })
         setAddressData({
            address: '',
            country: '',
            state: '',
            locality: '',
            pincode: '',
            city: '',
        });
    
        setOpenModal(true)
    }
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const togglecPasswordVisibility = () => {
        setcPasswordVisible(!cpasswordVisible);
    };

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{6,}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const notifyError = (message) => {
        notify('danger', 'Error', message);
    };

    const validateManagerDetails = (supervisorInfo, supervisorBankDetails, addressData) => {
        if (!supervisorInfo.firstName) {
            notifyError('First Name is required');
            return false;
        }

        if (!supervisorInfo.lastName) {
            notifyError('Last Name is required');
            return false;
        }

        if (!supervisorInfo.email) {
            notifyError('Email is required');
            return false;
        }

        if (!emailRegex.test(supervisorInfo.email)) {
            notifyError('Invalid Email Format');
            return false;
        }

        if (!supervisorInfo.mobile) {
            notifyError('Mobile Number is required');
            return false;
        }
        if(!isEditing){
        if (!supervisorInfo.password) {
            notifyError('Password is required');
            return false;
        }

        if (!supervisorInfo.confirmPassword) {
            notifyError('Confirm Password is required');
            return false;
        }

        if (supervisorInfo.password !== supervisorInfo.confirmPassword) {
            notifyError('Confirm Password does not match');
            return false;
        }

        if (!passwordRegex.test(supervisorInfo.password)) {
            notifyError(
                'Password must be at least 6 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character'
            );
            return false;
        }
        }
        if (!supervisorBankDetails.accountHoldername) {
            notifyError('Please Enter Account Holdername');
            return false;
        }

        if (!supervisorBankDetails.bankAccountNo) {
            notifyError('Please Enter Bank Account Number');
            return false;
        }

        if (!supervisorBankDetails.reenterBankAccountNo) {
            notifyError('Please Re-Enter Bank Account Number');
            return false;
        }

        if (supervisorBankDetails.bankAccountNo !== supervisorBankDetails.reenterBankAccountNo) {
            notifyError('Bank Account Number does not match');
            return false;
        }

        if (!supervisorBankDetails.IFSCcode) {
            notifyError('Please Enter Bank IFSC Code');
            return false;
        }
        if (!addressData.address) {
            notifyError('Address is required');
            return false;
        }
    
        if (!addressData.country) {
            notifyError('Country is required');
            return false;
        }
    
        if (!addressData.state) {
            notifyError('State is required');
            return false;
        }
    
        if (!addressData.city) {
            notifyError('City is required');
            return false;
        }
    
        if (!addressData.pincode) {
            notifyError('Pincode is required');
            return false;
        }
        if (!/^\d{6}$/.test(addressData.pincode)) {
            notifyError('Invalid Pincode format. It should be 6 digits.');
            return false;
        }
    

        return true;
    };

    const handelAddManager = async () => {
        const isValid = validateManagerDetails(supervisorInfo, supervisorBankDetails, addressData);

        if (!isValid) {
            return;
        }

        const payload = {
            manager_info: {
                ...supervisorInfo,
                ...addressData
            },
            bank_details: supervisorBankDetails,
        };
        setIsLoading(true)
        try {
            let response;
            if (isEditing) {
                response = await axios.post(`${baseUrl}/update_user_details`, {
                    ...payload,
                    user_id: superUpdateId
                });
            } else {
                response = await axios.post(`${baseUrl}/register_new_supervisor`, payload);
            }
            if (response.data.status) {
                notify('success', 'Success', `${response.data.message}`);
                setOpenModal(false);
                setIsLoading(false)
                setIsEditing(false);
                await allSupervisor()
            } else {
                notify('danger', 'Error', `${response.data.message}`);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error during registration:', error);
            notify('danger', 'Error', 'There was an error during registration. Please try again.');
            setIsLoading(false);
        }
    };

    const handelUpdate = async (superID) => {
        setIsLoading(true);
        setSuperUpdateId(superID);
        try {
            const response = await axios.post(`${baseUrl}/user_data`, {
                user_id: superID
            });
            //  alert(response.data.status)
            if (response.data.status) {
                const userData = response.data.data[0];
                const fullName = userData.name.split(' ')
                setSupervisorInfo({
                    firstName: fullName[0],
                    lastName: fullName[1] || '',
                    email: userData.email,
                    mobile: userData.mobile,
                    password: '',
                    confirmPassword: '',
                    role: 'supervisor'
                });
                const bankDetails = response.data.bankdetails
                setSupervisorBankdetails({
                    accountHoldername: bankDetails.account_holder_name,
                    bankAccountNo: bankDetails.account_number,
                    reenterBankAccountNo: bankDetails.account_number,
                    IFSCcode: bankDetails.ifsc_code,
                });
                setAddressData({
                    address: userData.address,
                    country: userData.country,
                    state: userData.state,
                    locality: userData.locality,
                    pincode: userData.pin_code,
                    city: userData.city,
                });
                setCountryID(userData.country)
                setIsEditing(true);
                setOpenModal(true);
            } else {
                notify('danger', 'Error', response.data.message);
            }
        } catch (error) {
            console.error('Error during registration:', error);
            notify('danger', 'Error', 'There was an error during registration. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };
    const getStatusClass = (Status) => {
        switch (Status) {
            case 'Inactive':
                return 'bg-red-200 text-red-500 text-xs';
            default:
                return 'bg-green-200 text-green-600 text-xs';
        }

    }
    const handleChangeStatus = async (id, currentStatus) => {
        setIsLoading(true);
        try {
            const nextStatus = currentStatus === 'Active' ? 'Inactive' : 'Active';
    
            const response = await axios.post(`${baseUrl}/update_user_status`, {
                user_id: id,
                status: nextStatus
            });
    
            if (response.data.status) {
                notify('success', 'Success', `${response.data.message}`);
                await allSupervisor();
                setIsLoading(false);
            } else {
                notify('danger', 'Error', `${response.data.message}`);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error during status update:', error);
            notify('danger', 'Error', 'There was an error updating the status. Please try again.');
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading && <Loader />}
            <h1 className='xl:text-2xl md:text-lg text-xl mt-3 font-semibold capitalize'>Supervisor</h1>
            <div className='w-full bg-white  rounded-lg mt-5 md:p-6 p-3'>
                <div className="relative flex md:hidden w-full ">
                    <input
                        type="text"
                        placeholder="Search Here"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="bg-gray-200 w-full border-0 rounded-full px-4 py-2 pr-10 focus:outline-none focus:border-gray-400 focus:ring-2 focus:ring-gray-400"
                    />
                    <IoSearchSharp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-black cursor-pointer" />
                </div>
                <div className='flex flex-row  justify-between my-5  pe-0'>
                    <div className='flex flex-row items-center md:w-64 w-24  gap-1'>
                        <span className='text-nowrap mt-2 md:block hidden text-sm capitalize'>items per page</span>
                        <div className='w-16'>
                            <Dropdown
                                label=""
                                options={perpageoption}
                                selectedValues={itemsPerPage}
                                onSelect={setItemsPerPage}
                                onRemove={() => { }}
                                isMultiple={false}
                            />
                        </div>
                    </div>

                    <div className="relative hidden md:flex w-5/12">
                        <input
                            type="text"
                            placeholder="Search Here"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="bg-gray-200 w-full border-0 rounded-full px-4 py-1 pr-10 focus:outline-none focus:border-gray-400 focus:ring-2 focus:ring-gray-400"
                        />
                        <IoSearchSharp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-black cursor-pointer" />
                    </div>
                    <button onClick={handelCreate} className='text-white flex justify-center items-center font-semibold text-nowrap bg-black ms-5 md:px-5 md:text-base text-sm md:py-2 py-2 px-3  hover:text-black hover:bg-white hover:border border rounded-lg hover:border-black transition-all duration-200 capitalize'>add &nbsp;<span className='md:block hidden'>supervisor</span></button>
                </div>
                <div className='overflow-hidden overflow-x-auto mt-6'>
                    <table className="lg:w-full md-w[100%]  table bg-white">
                        <thead className="bg-gray-100 rounded-full">
                            <tr>
                                <th className="py-4 px-4 text-left capitalize text-nowrap">Name</th>
                                <th className="py-4 px-4 text-left capitalize text-nowrap">Supervisor ID</th>
                                <th className="py-4 px-4 text-left capitalize text-nowrap">Mobile no.</th>
                                <th className="py-4 px-4 text-left capitalize text-nowrap">Status</th>

                                <th className="py-4 px-4 text-left capitalize text-nowrap">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map(item => (
                                <tr key={item.id} className="border-b border-gray-200  hover:bg-gray-200 transition-all duration-200 ease-in-out">
                                    <td className="py-4 px-4 flex items-center gap-4">
                                        <div className="flex flex-col">
                                            <span className='text-nowrap font-medium text-lg pe-20 lg:pe-0'>{item.name}</span>
                                            <span className='text-nowrap  text-sm text-gray-400 pe-20 lg:pe-0'>{item.email}</span>
                                        </div>
                                    </td>
                                    <td className="py-4 px-4 "><div className="ms-3 text-lg font-medium text-nowrap">{item.unique_id}</div></td>
                                    <td className="py-4 px-4 ">
                                        <div className="flex flex-row gap-2 place-items-center ">
                                            <span className='font-medium text-base'>+{item.mobile}</span>
                                        </div>
                                    </td>
                                    <td className="py-4 px-4 ">
                                        <div className="flex flex-row gap-2 place-items-center ">
                                            <span className={`px-2 py-1  rounded-lg capitalize font-semibold ${getStatusClass(item.status)}`}>
                                                {item.status}
                                            </span>
                                        </div>

                                    </td>
                                    <td className="py-4 px-4 ">
                                        <div className="flex flex-row gap-3 text-black text-xl">
                                        <Tooltip content="Update Supervisor Details">
                                            <div className='cursor-pointer bg-neutral-300  rounded-lg p-1.5' onClick={() => handelUpdate(item.user_id)}><TbUserEdit /></div>
                                            </Tooltip>
                                            <Tooltip content="Change Status">
                                            <label className="inline-flex items-center me-5 cursor-pointer w-full">
                                                <input type="checkbox" className="sr-only peer"
                                                    checked={item.status === 'Active'}
                                                    onChange={() => handleChangeStatus(item.user_id, item.status)}
                                                />
                                                <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600"></div>
                                            </label>
                                            </Tooltip>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-between items-center mt-4">
                    <div>
                        <span>Page {currentPage} of {totalPages}</span>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </div>


            <Modal show={openModal} onClose={() => setOpenModal(false)}>
                <Modal.Header>{isEditing ? 'Update Supervisor' : 'Add Supervisor'}</Modal.Header>
                <Modal.Body>
                    {!isEditing && (
                       <Progress className='mb-4' progress={progressPercentage} size="sm" color="gray" />
                    )}
                    <Tabs aria-label="Tabs with icons" variant="underline">
                        <Tabs.Item active title="Profile" icon={HiUserCircle}>
                            <div className="w-full space-y-5">
                                <div className="flex md:flex-row flex-col place-items-center gap-5 w-full">
                                    <div className="flex flex-col gap-2 w-full">
                                        <h1 className='text-base font-semibold capitalize text-left'>First Name <span className="text-red-500">*</span></h1>
                                        <input type="text" className='w-full rounded-md border border-gray-300 px-2  focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your First Name'
                                            value={supervisorInfo.firstName}
                                            onChange={(e) => setSupervisorInfo({ ...supervisorInfo, firstName: e.target.value })}
                                        />
                                    </div>
                                    <div className="flex flex-col gap-2 w-full">
                                        <h1 className='text-base font-semibold capitalize text-left'>Last Name <span className="text-red-500">*</span></h1>
                                        <input type="text" className='w-full rounded-md border border-gray-300 px-2  focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your Last Name'
                                            value={supervisorInfo.lastName}
                                            onChange={(e) => setSupervisorInfo({ ...supervisorInfo, lastName: e.target.value })}
                                        />

                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 w-full">
                                    <h1 className='text-base font-semibold capitalize text-left'>Email <span className="text-red-500">*</span></h1>
                                    <input type="email" className='w-full rounded-md border border-gray-300 px-2  focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your E-mail'
                                        value={supervisorInfo.email}
                                        onChange={(e) => setSupervisorInfo({ ...supervisorInfo, email: e.target.value })}
                                    />
                                </div>
                                <div className="flex flex-col gap-2 w-full">
                                    <h1 className='text-base font-semibold capitalize text-left'>Mobile <span className="text-red-500">*</span></h1>
                                    <PhoneInput
                                        country={'in'}
                                        value={supervisorInfo.mobile}
                                        countryCodeEditable={false}
                                        enableSearch={true}
                                        onChange={(val) => setSupervisorInfo({ ...supervisorInfo, mobile: val })}
                                        placeholder="Enter Mobile Number"
                                        inputClass="input-mobile mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-400 focus:ring-1"
                                    />
                                </div>
                                {!isEditing && (
                                    <>
                                <div className="flex flex-col gap-2 w-full">
                                    <h1 className='text-base font-semibold capitalize text-left'>password <span className="text-red-500">*</span></h1>
                                    <div className="flex flex-row border-gray-300 border place-items-center rounded-md overflow-hidden">
                                        <input type={passwordVisible ? "text" : "password"} className='w-full  px-2  no-arrows  border-0 focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your Password'
                                            value={supervisorInfo.password}
                                            onChange={(e) => setSupervisorInfo({ ...supervisorInfo, password: e.target.value })}
                                        />
                                        <div className="pe-2 cursor-pointer" onClick={togglePasswordVisibility}>
                                            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 w-full">
                                    <h1 className='text-base font-semibold capitalize text-left'>confirm password <span className="text-red-500">*</span></h1>
                                    <div className="flex flex-row border-gray-300 border place-items-center rounded-md overflow-hidden">
                                        <input type={cpasswordVisible ? "text" : "password"} className='w-full  px-2  no-arrows  border-0 focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your Confirm Password'
                                            value={supervisorInfo.confirmPassword}
                                            onChange={(e) => setSupervisorInfo({ ...supervisorInfo, confirmPassword: e.target.value })}
                                        />
                                        <div className="pe-2 cursor-pointer" onClick={togglecPasswordVisibility}>
                                            {cpasswordVisible ? <FaEyeSlash /> : <FaEye />}
                                        </div>
                                    </div>
                                </div>
                                </>
                                )}
                            </div>

                        </Tabs.Item>
                        <Tabs.Item title="Bank Details" icon={AiOutlineBank}>
                            <div className="w-full space-y-5">
                                <div className="flex flex-col gap-2 w-full">
                                    <h1 className='text-base font-semibold capitalize text-left'>Account Holder Name <span className="text-red-500">*</span></h1>
                                    <input type="text" className='w-full rounded-md border border-gray-300 px-2  focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your Account Holder Name'
                                        value={supervisorBankDetails.accountHoldername}
                                        onChange={(e) => setSupervisorBankdetails({ ...supervisorBankDetails, accountHoldername: e.target.value })}
                                    />
                                </div>
                                <div className="flex flex-col gap-2 w-full">
                                    <h1 className='text-base font-semibold capitalize text-left'>Bank Account Number <span className="text-red-500">*</span></h1>
                                    <input type="text" className='w-full rounded-md border border-gray-300 px-2  focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your Bank Account Number'
                                        value={supervisorBankDetails.bankAccountNo}
                                        onChange={(e) => setSupervisorBankdetails({ ...supervisorBankDetails, bankAccountNo: e.target.value })}
                                    />
                                </div>
                                <div className="flex flex-col gap-2 w-full">
                                    <h1 className='text-base font-semibold capitalize text-left'>Re-enter Bank Account Number <span className="text-red-500">*</span></h1>
                                    <div className="flex flex-row border-gray-300 border place-items-center rounded-md overflow-hidden">
                                        <input type={passwordVisible ? "text" : "password"} className='w-full  px-2  no-arrows  border-0 focus:ring-0 py-1.5 focus:outline-0' placeholder=' Re-enter Bank Account Number'
                                            value={supervisorBankDetails.reenterBankAccountNo}
                                            onChange={(e) => setSupervisorBankdetails({ ...supervisorBankDetails, reenterBankAccountNo: e.target.value })}
                                        />
                                        <div className="pe-2 cursor-pointer" onClick={togglePasswordVisibility}>
                                            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 w-full">
                                    <h1 className='text-base font-semibold capitalize text-left'>BANK Unique Code <span className="text-red-500">*</span></h1>
                                    <input type="text" className='w-full rounded-md border border-gray-300 px-2 no-arrows focus:border-black  focus:ring-0 py-1.5 focus:outline-0' placeholder='Enter Your Bank Code'
                                        value={supervisorBankDetails.IFSCcode}
                                        onChange={(e) => setSupervisorBankdetails({ ...supervisorBankDetails, IFSCcode: e.target.value })}
                                        // onChange={handleIFSCChange}
                                        style={{ textTransform: 'uppercase' }}
                                        pattern="[A-Z0-9]*"
                                    />
                                </div>
                            </div>
                        </Tabs.Item>
                        <Tabs.Item title="Address" icon={FaLocationDot}>
                            <div className="mb-4">
                                <Label value="Address" />
                                <TextInput
                                    className="w-full"
                                    name="address"
                                    value={addressData.address}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='flex gap-5'>
                                <div className="mb-4 w-3/4">
                                    <Label htmlFor="country" value="Country" />
                                    <select
                                        id="country"
                                        name="country"
                                        className="w-full p-2 border border-gray-300 rounded-md"
                                        onChange={(e) => {
                                            setCountryID(e.target.value);
                                            handleChange(e);
                                        }}
                                        value={addressData.country}
                                    >
                                        <option value="">Select Country</option>
                                        {countryOptions.map((country) => (
                                            <option key={country.id} value={country.id}>
                                                {country.label}
                                            </option>
                                        ))}
                                    </select>
                                    {countryError && <p className="text-red-600">{countryError}</p>}
                                </div>
                                <div className="mb-4 w-3/4">
                                    <Label htmlFor="state" value="State" />
                                    <select
                                        id="state"
                                        name="state"
                                        className="w-full p-2 border border-gray-300 rounded-md"
                                        disabled={!countryID}
                                        value={addressData.state}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select State</option>
                                        {stateOptions.map((state) => (
                                            <option key={state.id} value={state.id}>
                                                {state.label}
                                            </option>
                                        ))}
                                    </select>
                                    {stateError && <p className="text-red-600">{stateError}</p>}
                                </div>
                            </div>
                            <div className='flex gap-5'>
                                <div className="mb-4 w-3/4">
                                    <Label value="Locality" />
                                    <TextInput
                                        className="w-full"
                                        name="locality"
                                        value={addressData.locality}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-4 w-3/4">
                                    <Label value="City" />
                                    <TextInput
                                        className="w-full"
                                        name="city"
                                        value={addressData.city}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="mb-4 w-1/2">
                                <Label value="Pincode" />
                                <TextInput
                                    className="w-full"
                                    name="pincode"
                                    value={addressData.pincode}
                                    onChange={handleChange}
                                    onKeyPress={(e) => {
                                        if (!/[0-9]/.test(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            </div>
                        </Tabs.Item>

                    </Tabs>
                </Modal.Body>
                <Modal.Footer className='flex justify-end'>
                    <button type="button" onClick={handelAddManager} class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                        {isEditing ? 'Update Supervisor' : 'Add Supervisor'}
                    </button>
                    <button type="button" onClick={() => setOpenModal(false)} class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>

        </>


    )
}

export default Supervisor