import React, { useEffect, useState } from 'react';
import ProductImage from '../../Component/Assets/Images/shoe.png';
import { MdDelete } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { RiPrinterFill } from "react-icons/ri";
import Pagination from '../../Component/utiles/Pagination';
import { IoSearchSharp } from "react-icons/io5";
import { UserState } from '../../Context/userProvider';
import axios from 'axios';
import notify from '../../Component/utiles/Notification';

const Ordermanage = ({ setLoading }) => {

  const { user, logout } = UserState();

  const baseUrl = process.env.REACT_APP_BASEURL

  const [selectedStatus, setSelectedStatus] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [mypurches, setMypurches] = useState([]);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const orders = async () => {
      setLoading(true)
      try {
        const response = await axios.post(`${baseUrl}/seller_order`, { user_id: user.user_id, role: user.role });
        if (response.data.status) {
          setMypurches(response.data.products)
        }
      } catch (err) {
        console.log(err)
      }
      setLoading(false)
    }
    orders();
  }, [refresh])


  const tabs = ['all', 'Pending', 'Confirm', 'delivered', 'Cancel'];

  const handleTabClick = (status) => {
    setSelectedStatus(status);
    setCurrentPage(1);
  };

  const filterByStatus = (purchase) => {
    if (selectedStatus === 'all') return true;
    if (selectedStatus === 'canceled') {
      return purchase.order_status === 'Cancel' || purchase.order_status === 'cancel pending';
    }
    return purchase.order_status === selectedStatus;
  };

  const filteredPurchases = mypurches.filter(filterByStatus);

  const searchResults = filteredPurchases.filter(purchase =>
    purchase.product_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    purchase.order_id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const itemsToDisplay = searchTerm ? searchResults : filteredPurchases;


  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = itemsToDisplay.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(itemsToDisplay.length / itemsPerPage);

  useEffect(() => {
    if (searchTerm) {
      setCurrentPage(1);
    }
  }, [searchTerm]);

  const getStatusClass = (status) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return 'bg-green-200 text-green-600 text-xs';
      case 'pending':
        return 'bg-yellow-200 text-yellow-500 text-xs';
      case 'cancel':
      case 'cancel pending':
        return 'bg-red-200 text-red-500 text-xs';
      case 'confirm':
        return 'bg-blue-200 text-blue-600 text-xs';
      default:
        return 'bg-gray-400 text-white text-xs';
    }
  }


  const changeStatus = async (id, type) => {
    // alert(id)
    try {
      const response = await axios.post(`${baseUrl}/seller_confirms_order`, {
        type: type,
        order_unique_id: id
      });
      if (response.data.status) {
        notify('success', 'Success', `Order ${type} Successfully`);
        setRefresh(prev => prev + 1)
      } else {
        notify('success', 'Success', `${response.data.message}`);
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <h1 className='xl:text-2xl md:text-lg text-xl mt-4 font-semibold'>Order Management</h1>
      <div className='w-full my-4 md:p-6 p-3 bg-white rounded-lg'>
        <div className="relative flex xl:w-5/12 md:w-7/12 w-full">
          <input
            type="text"
            placeholder="Search Here"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="bg-gray-200 w-full border-0 rounded-full px-4 py-2 pr-10 focus:outline-none focus:border-gray-400 focus:ring-2 focus:ring-gray-400"
          />
          <IoSearchSharp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-black cursor-pointer" />
        </div>
        <div className="flex justify-start mt-5 overflow-x-auto overflow-hidden pb-3">
          <div className="tabs tabs-boxed">
            {tabs.map((status) => (
              <a
                key={status}
                className={`px-4 py-2 transition-all duration-200 ease-in-out font-medium cursor-pointer ${selectedStatus === status ? 'border-b capitalize text-lg border-black text-black' : 'text-[#A4A4A4] text-sm'}`}
                onClick={() => handleTabClick(status)}
              >
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </a>
            ))}
          </div>
        </div>
        <div className='overflow-x-auto w-full mt-6'>
          <table className="min-w-full bg-white">
            <thead className="bg-gray-100 rounded-full">
              <tr>
                <th className="py-4 px-4 text-left">Product</th>
                <th className="py-4 px-4 text-left">Product ID</th>
                <th className="py-4 px-4 text-left">Price</th>
                <th className="py-4 px-4 text-left">Order ID</th>
                <th className="py-4 px-4 text-left">Quantity</th>
                <th className="py-4 px-4 text-left">Date</th>
                <th className="py-4 px-4 text-left">Status</th>
                <th className="py-4 px-4 text-left">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item) => (
                <tr key={item.id} className="border-b border-gray-200 hover:bg-gray-200 transition-all duration-200 ease-in-out">
                  <td className="py-4 px-4 flex items-center gap-4">
                    <img src={item.product_img} alt={item.product_name} className="w-16 h-16 mr-4 bg-gray-100 rounded-lg" />
                    <span className='text-nowrap pe-20 w-36 line-clamp-1'>{item.product_name}</span>
                  </td>
                  <td className="py-4 px-4 text-nowrap">{item.product_id}</td>
                  <td className="py-4 px-4 text-nowrap">$ {item.price}</td>
                  <td className="py-4 px-4 text-nowrap">{item.order_id}</td>
                  <td className="py-4 px-4">
                    <div className="flex items-center">
                      <span className="px-2 mx-3">{item.quantity}</span>
                    </div>
                  </td>
                  <td className="py-4 px-4 text-nowrap">{item.order_date}</td>
                  <td className="p-4 text-nowrap">
                    <span className={`px-2 py-1 rounded-lg capitalize font-semibold ${getStatusClass(item.order_status)}`}>
                      {item.order_status}
                    </span>
                  </td>
                  <td className="py-4 px-4 text-nowrap">
                    {user?.role === "superadmin" ? (
                      <div className='flex flex-row items-center gap-5 text-xl'>
                        {user?.role === "superadmin" && (
                          <div className='cursor-pointer hover:scale-110 duration-200 transition-all px-3 py-2 text-lg rounded-md bg-neutral-300'>
                            <MdDelete className='cursor-pointer' />
                          </div>
                        )}
                        <div className='px-3 cursor-pointer hover:scale-110 duration-200 transition-all py-2 text-lg rounded-md bg-neutral-300'>
                          <IoMdEye />
                        </div>
                      </div>
                    ) : (
                      item.order_status === 'Pending' || item.order_status === 'confirm' ? (
                        <div className='flex flex-row items-center gap-5 text-md'>
                          {item.order_status === 'Pending' && (
                            <div>
                              <button
                                className='bg-blue-500 text-white me-3 cursor-pointer px-3 py-1 rounded-full hover:bg-white hover:border-blue-500 hover:border border hover:text-blue-500 transition-all duration-200 ease-in-out'
                                onClick={() => changeStatus(item.order_id, 'Confirm')}
                              >
                                Confirm
                              </button>
                              <button
                                className='cursor-pointer text-white bg-red-500 px-3 hover:bg-white hover:text-red-500 hover:border-red-500 border transition-all duration-200 ease-in-out py-1 rounded-full'
                                onClick={() => changeStatus(item.order_id, 'Cancel')}
                              >
                                Cancel
                              </button>
                            </div>
                          )}
                          {item.order_status === 'confirm' && (
                            <div className='flex flex-row gap-3'>
                              <div className='px-3 py-2 text-lg rounded-md bg-neutral-300'>
                                <RiPrinterFill />
                              </div>
                              <div className='px-3 py-2 text-lg rounded-md bg-neutral-300'>
                                <IoMdEye />
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className='flex flex-row items-center gap-5 text-xl'>
                          {user?.role === "superadmin" && (
                            <div className='cursor-pointer hover:scale-110 duration-200 transition-all px-3 py-2 text-lg rounded-md bg-neutral-300'>
                              <MdDelete className='cursor-pointer' />
                            </div>
                          )}
                          <div className='px-3 cursor-pointer hover:scale-110 duration-200 transition-all py-2 text-lg rounded-md bg-neutral-300'>
                            <IoMdEye />
                          </div>
                        </div>
                      ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='flex justify-between mt-4'>
          <div>
            <span>Page {currentPage} of {totalPages}</span>
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        </div>
      </div>
    </>
  );
}

export default Ordermanage;
















