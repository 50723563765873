import React from 'react'
import { AiOutlineDelete } from 'react-icons/ai';
import { FileInput, Label } from "flowbite-react";
import { MdCloudDownload } from "react-icons/md";
import notify from '../../Component/utiles/Notification';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const Editcategorycomponent = ({ category, setCategory, open1, open2, open3, open4, id, setRefresh, setRefresh2, setLoading }) => {

    const baseUrl = process.env.REACT_APP_BASEURL
    const navigate = useNavigate();


    const handleFileChange = async (e) => {
        setLoading(true);
        const file = e.target.files[0]; // Get the first file selected
        if (!file) {
            notify('danger', 'Error', 'Please Select an Image');
            return;
        }

        if (file.type === 'image/jpeg' || file.type === 'image/png') {
            const data = new FormData();
            data.append("file", file);
            data.append("upload_preset", "testalas");
            data.append("cloud_name", "duw6f8fm8");

            try {
                const res = await fetch('https://api.cloudinary.com/v1_1/duw6f8fm8/image/upload', {
                    method: 'POST',
                    body: data
                });
                const result = await res.json();

                if (result.url) {
                    setCategory(prevState => ({
                        ...prevState,
                        category_image: result.url
                    }));
                } else {
                    notify('danger', 'Error', 'Failed to upload image');
                }
            } catch (error) {
                console.log('Upload Error:', error);
                notify('danger', 'Error', 'Image Upload Failed');
            }
        } else {
            notify('danger', 'Error', 'Please Select a valid Image (JPEG/PNG)');
        }
        setLoading(false);
    };

    const handleUpdate = async () => {
        let updateData = {};
        let url = '';
        let errorMessage = 'Please Enter Category Name';

        // Determine which update function to execute based on the open1, open2, or open3 flag
        if (open1) {
            if (!category.category_name) {
                notify('danger', 'Error', errorMessage);
                return;
            } else if (!category.category_image) {
                notify('danger', 'Error', 'Please Select Category Image');
                return;
            } else if (!category.description) {
                notify('danger', 'Error', 'Please Enter Category Description');
                return;
            }

            updateData = {
                category_id: id,
                category_name: category.category_name,
                home: category.home,
                category_image: category.category_image,
                status: category.category_status,
                description: category.description
            };
            url = `${baseUrl}/update_main_category`;

        } else if (open2) {
            if (!category.sub_category_1_name) {
                notify('danger', 'Error', errorMessage);
                return;
            }

            updateData = {
                sub_category_1_id: category.sub_category_1_id,
                sub_category_1_name: category.sub_category_1_name,
                status: category.status
            };
            url = `${baseUrl}/update_sub_category1`;

        } else if (open3) {
            if (!category.sub_category_2_name) {
                notify('danger', 'Error', errorMessage);
                return;
            }

            updateData = {
                sub_category_2_id: category.sub_category_2_id,
                sub_category_2_name: category.sub_category_2_name,
                status: category.status
            };
            url = `${baseUrl}/update_sub_category2`;
        } else if (open4) {
            if (!category.sub_category_3_name) {
                notify('danger', 'Error', errorMessage);
                return;
            }

            updateData = {
                sub_category_3_id: category.sub_category_3_id,
                sub_category_3_name: category.sub_category_3_name,
                status: category.status
            };
            url = `${baseUrl}/update_sub_category3`;
        }
        setLoading(true);
        try {
            const response = await axios.post(url, updateData, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.data.status) {
                notify('success', 'Success', 'Category Updated Successfully');
                if (open3) {
                    setCategory({
                        sub_category_2_id: '',
                        sub_category_2_name: '',
                        status: ''
                    })
                }
                if (open2 || open3 || open4) {
                    setRefresh('Select Category');
                    setRefresh2(prev => prev + 1);
                }
                if (open1 || open4) {
                    navigate('../categories')
                    setRefresh2(prev => prev + 1);
                }
            } else {
                notify('danger', 'Error', response.data.message);
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    };



    return (
        <>
            {open1 &&
                <div className="">
                    <div className='mb-4'>
                        <span className="text-lg font-medium">Description</span>
                        <textarea className='w-full rounded-lg focus:border-black focus:ring-0 border-gray-300 mt-2' name='description'
                            onChange={(e) => setCategory({ ...category, description: e.target.value })} value={category.description}
                            rows={10}></textarea>
                    </div>
                    {category.category_image == '' ? (
                        <div className="flex items-center justify-center w-full">
                            <Label
                                htmlFor="dropzone-file"
                                className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                            >
                                <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                    <MdCloudDownload className='text-3xl text-gray-400' />
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                        <span className="font-semibold">Click to upload</span> or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                </div>
                                <FileInput id="dropzone-file" className="hidden" onChange={handleFileChange} />
                            </Label>
                        </div>
                    ) : (
                        <div className='relative h-64 w-96'>
                            <img src={category.category_image} alt={category.category_name} className='h-64 border border-black w-96 rounded-lg' />
                            <button
                                type="button"
                                className="absolute top-2 right-2 bg-red-500 text-white rounded-full text-xl p-1"
                                onClick={() => setCategory({ ...category, category_image: '' })}
                            >
                                <AiOutlineDelete />
                            </button>
                        </div>

                    )}
                </div>
            }
            {open2 &&
                <div className="">
                    <div className='flex flex-row place-items-center'>
                        <div className="w-full">
                            <span className="text-lg font-medium capitalize">Category Title </span>
                            <input
                                className="w-full mt-2 mb-1 rounded-lg py-2 focus:ring-0 focus:border-black border-gray-300"
                                placeholder="Enter Category Title"
                                type="text"
                                name="category_name"
                                value={category.sub_category_1_name}
                                onChange={(e) => setCategory({ ...category, sub_category_1_name: e.target.value })}
                            />
                        </div>
                        <label className="inline-flex items-center me-5 cursor-pointer w-full mt-6">
                            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 px-3">Change Status</span>
                            <input type="checkbox" className="sr-only peer"
                                checked={category.status === 'Active'}
                                onChange={(e) => setCategory({ ...category, status: e.target.checked ? 'Active' : 'Inactive' })}
                            />
                            <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600"></div>
                        </label>
                    </div>
                </div>
            }
            {open3 &&
                <div className="">
                    <div className='flex flex-row place-items-center'>
                        <div className="w-full">
                            <span className="text-lg font-medium capitalize">Category Title </span>
                            <input
                                className="w-full mt-2 mb-1 rounded-lg py-2 focus:ring-0 focus:border-black border-gray-300"
                                placeholder="Enter Category Title"
                                type="text"
                                name="category_name"
                                value={category.sub_category_2_name}
                                onChange={(e) => setCategory({ ...category, sub_category_2_name: e.target.value })}
                            />
                        </div>
                        <label className="inline-flex items-center me-5 cursor-pointer w-full mt-6">
                            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 px-3">Change Status</span>
                            <input type="checkbox" className="sr-only peer"
                                checked={category.status === 'Active'}
                                onChange={(e) => setCategory({ ...category, status: e.target.checked ? 'Active' : 'Inactive' })}
                            />
                            <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600"></div>
                        </label>
                    </div>
                </div>
            }
            {open4 &&
                <div className="">
                    <div className='flex flex-row place-items-center'>
                        <div className="w-full">
                            <span className="text-lg font-medium capitalize">Category Title </span>
                            <input
                                className="w-full mt-2 mb-1 rounded-lg py-2 focus:ring-0 focus:border-black border-gray-300"
                                placeholder="Enter Category Title"
                                type="text"
                                name="category_name"
                                value={category.sub_category_3_name}
                                onChange={(e) => setCategory({ ...category, sub_category_3_name: e.target.value })}
                            />
                        </div>
                        <label className="inline-flex items-center me-5 cursor-pointer w-full mt-6">
                            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 px-3">Change Status</span>
                            <input type="checkbox" className="sr-only peer"
                                checked={category.status === 'Active'}
                                onChange={(e) => setCategory({ ...category, status: e.target.checked ? 'Active' : 'Inactive' })}
                            />
                            <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600"></div>
                        </label>
                    </div>
                </div>
            }
            <div className='mt-20 flex justify-evenly items-center'>
                <button onClick={() => handleUpdate()} className='text-white bg-black px-5 text-base py-2 hover:text-black hover:bg-white hover:border border rounded-lg hover:border-black transition-all duration-200'>Update Category</button>
            </div>
        </>
    )
}

export default Editcategorycomponent