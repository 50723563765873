import React, { useState, useRef, useEffect } from 'react';
import { Label, Radio, Tooltip, Modal, Button, Blockquote } from "flowbite-react";
import { AiOutlineDelete } from 'react-icons/ai';
import Dropdown from '../../Component/utiles/Dropdown';
import { Link, useNavigate } from 'react-router-dom';
import { RiErrorWarningFill } from "react-icons/ri";
import { MdOutlineFileUpload } from "react-icons/md";
import { HiOutlineArrowSmDown } from "react-icons/hi";
import ctg_img from '../../Component/Assets/Images/ctg_clothes.png'
import axios from 'axios';
import { FaInfoCircle } from "react-icons/fa";
import useSubcategories from '../Component/Categoriesoption';
import { UserState } from '../../Context/userProvider';
import notify from '../../Component/utiles/Notification';
import Addmoreproduct from '../Component/Addmoreproduct';

const AddProduct = ({ categoryoption, setLoading, sizes, colours }) => {

    const { user, logout } = UserState();
    const editProduct = false;

    const baseUrl = process.env.REACT_APP_BASEURL
    const navigate = useNavigate();
    const [more, setMore] = useState(false);

    const [fields, setFields] = useState([{ product_about_id: '', field: '', value: '' }]);

    const [entries, setEntries] = useState([{ size_id: '', size: '', quantity: '' }]);

    // const multiple = true;
    // const { images, handleFileChange, handleRemoveImage } = ImageUpload(multiple, setLoading);

    const [category, setCategory] = useState('Select Category');
    const [categoryID, setCategoryID] = useState('');

    const [category_2, setCategory_2] = useState('Select Category');
    const [subcategoryID, setSubcategoryID] = useState('');
    const [subcategoryoption, setSubcategoryoption] = useState([]);

    const [category_3, setCategory_3] = useState('Select Category');
    const [subcategory3ID, setSubcategory3ID] = useState('');
    const [subcategoryoption3, setSubcategoryoption3] = useState([]);


    const [category_4, setCategory_4] = useState('Select Category');
    const [subcategory4ID, setSubcategory4ID] = useState('');
    const [subcategoryoption4, setSubcategoryoption4] = useState([]);

    const [colour, setColour] = useState('select color');
    const [stockStatus, setStockStatus] = useState('Select Stock Status');
    const [brand, setBrand] = useState('Select Brand');
    const [openModal, setOpenModal] = useState(false);


    const handleFileChange = async (variantIndex, e) => {
        setLoading(true);
        const files = Array.from(e.target.files);
        const imagePromises = files.map(async (file) => {
            const reader = new FileReader();
            return new Promise((resolve, reject) => {
                reader.onloadend = () => resolve({ url: reader.result, file });
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        });

        const imagePreviews = await Promise.all(imagePromises);
        const uploadedUrls = await uploadToCloudinary(files);

        const updatedVariants = [...variants];
        updatedVariants[variantIndex].var_images = [
            ...updatedVariants[variantIndex].var_images, // Keep old images
            ...imagePreviews.map((img, index) => ({
                ...img,
                url: uploadedUrls[index] || img.url,
                product_varient_image_id: null,
                uploaded: !!uploadedUrls[index],
            })),
        ];

        updatedVariants[variantIndex].showUploadSection = false;
        setVariants(updatedVariants);
        setLoading(false);
    };

    const uploadToCloudinary = async (files) => {
        const uploadPromises = files.map(async (file) => {
            const data = new FormData();
            data.append("file", file);
            data.append("upload_preset", "testalas");
            data.append("cloud_name", "duw6f8fm8");

            try {
                const res = await fetch('https://api.cloudinary.com/v1_1/duw6f8fm8/image/upload', {
                    method: 'POST',
                    body: data
                });
                const result = await res.json();
                return result.url;
            } catch (error) {
                console.error('Upload Error:', error);
                return null;
            }
        });

        return await Promise.all(uploadPromises);
    };


    const ctgoptions = [
        { label: 'Not Found Category' },
    ];

    const brandoptions = [
        { label: 'Nike' },
        { label: 'Adidas' },
    ];

    const stockStatusOptions = [
        { label: 'hold' },
        { label: 'live' },
    ];

    useSubcategories(
        `${baseUrl}/subCategories`,
        { category_id: categoryID },
        setSubcategoryoption,
        ctgoptions,
        'sub_categories_array',
        categoryID,
        'sub_category_1_id',
        'sub_category_1_name',
        setLoading,
    );

    useSubcategories(
        `${baseUrl}/subSubCategories`,
        { sub_category_1: subcategoryID },
        setSubcategoryoption3,
        ctgoptions,
        'sub_sub_categories_array',
        subcategoryID,
        'sub_category_2_id',
        'sub_category_2_name',
        setLoading,
    );

    useSubcategories(
        `${baseUrl}/subSubSubCategories`,
        { sub_category_2: subcategory3ID },
        setSubcategoryoption4,
        ctgoptions,
        'sub_sub_sub_categories_array',
        subcategory3ID,
        'sub_category_3_id',
        'sub_category_3_name',
        setLoading,
    );


    const handleselectcategory = (val) => {
        const selectedCategory = categoryoption.find(option => option.label === val);
        if (selectedCategory) {
            setCategory_2('Select Category')
            setCategory_3('Select Category')
            setCategory_4('Select Category')
            setCategory(selectedCategory.label)
            setCategoryID(selectedCategory.id);
            setPayload((prevState) => ({
                ...prevState,
                category_id: selectedCategory.id

            }));
        }
    };

    const handleselectsubcategory = (val) => {
        const selectedsubCategory = subcategoryoption.find(option => option.label === val);
        if (selectedsubCategory) {
            setCategory_3('Select Category')
            setCategory_2(selectedsubCategory.label)
            setSubcategoryID(selectedsubCategory.id);
            setPayload((prevState) => ({
                ...prevState,
                sub_category_1_id: selectedsubCategory.id,
            }));
        }
    };
    const handleselectsubcategory3 = (val) => {
        const selectedsubCategory3 = subcategoryoption3.find(option => option.label === val);
        if (selectedsubCategory3) {
            setCategory_4('Select Category')
            setCategory_3(selectedsubCategory3.label)
            setSubcategory3ID(selectedsubCategory3.id);
            setPayload((prevState) => ({
                ...prevState,
                sub_category_2_id: selectedsubCategory3.id,
            }));
        }
    };

    const handleselectsubcategory4 = (val) => {
        const selectedsubCategory4 = subcategoryoption4.find(option => option.label === val);
        if (selectedsubCategory4) {
            setCategory_4(selectedsubCategory4.label)
            setSubcategory4ID(selectedsubCategory4.id);
            setPayload((prevState) => ({
                ...prevState,
                sub_category_3_id: selectedsubCategory4.id,
            }));
        }
    };

    const handleSizeChange = (variantIndex, sizeIndex, newSize) => {
        const updatedVariants = [...variants];
        updatedVariants[variantIndex].sizes[sizeIndex].size = newSize;
        const selectedSize = sizes.find(option => option.label === newSize);
        if (selectedSize) {
            updatedVariants[variantIndex].sizes[sizeIndex].size_id = selectedSize.id;
        }
        setVariants(updatedVariants);
    };

    const handleAddMore = () => {
        setEntries([...entries, { size_id: '', size: '', quantity: '' }]);
    };

    const handleRemove = (index) => {
        const updatedEntries = entries.filter((_, i) => i !== index);
        setEntries(updatedEntries);
    };

    const fileInputRef = useRef(null);

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const [payload, setPayload] = useState({
        product_name: '',
        sub_text: '',
        description: '',
        category_id: '',
        sub_category_1_id: '',
        sub_category_2_id: '',
        sub_category_3_id: '',
        net_weight: '',
        manage_by: '',
        discount: '',
        influincer_percentage: '',
        tax_percentage: '',
        coupen_code: '',
        coupen_code_discount: '',
        brand_name: '',
        user_id: '',
        stock_status: '',
    })

    const [variants, setVariants] = useState([{
        product_varient_id: null,
        color: '',
        // total_amount: '',
        price: '',
        sizes: [{ size: '', quantity: '', size_id: '' }],
        var_images: [],
        showUploadSection: true,
    }]);

    const handleaddproduct = async () => {
        const newPayload = {
            ...payload,
            brand_name: brand,
            stock_status: stockStatus,
            user_id: user.user_id,
            // img_url: images,
        };


        if (category === 'Select Category') {
            notify('danger', 'Error', 'Please Select Category 1');
            return;
        }
        if (category_2 === 'Select Category') {
            if (subcategoryoption[0].label !== 'Not Found Category') {
                notify('danger', 'Error', 'Please Select Category 2');
                return;
            }
        }
        if (category_3 === 'Select Category') {
            if (subcategoryoption3[0].label !== 'Not Found Category') {
                notify('danger', 'Error', 'Please Select Category 3');
                return;
            }
        }
        if (category_4 === 'Select Category') {
            if (subcategoryoption4[0].label !== 'Not Found Category') {
                notify('danger', 'Error', 'Please Select Category 4');
                return;
            }
        }
        const requiredFields = [
            { field: payload.product_name, message: 'Please Enter Product Name' },
            { field: payload.sub_text, message: 'Please Enter Sub Text' },
            { field: payload.description, message: 'Please Enter Product Description' },
            { field: payload.net_weight, message: 'Please Enter Net Weight' },
            { field: payload.discount, message: 'Please Enter Discount' },
            { field: payload.manage_by, message: 'Please Select The Delivery Type' }
        ];

        for (let { field, message } of requiredFields) {
            if (!field) {
                notify('danger', 'Error', message);
                return false;
            }
        }
        if (payload.brand_name === 'Select Brand') {
            notify('danger', 'Error', 'Please Select a Brand');
            return false;
        }
        if (payload.stock_status === 'Select Stock Status') {
            notify('danger', 'Error', 'Please Select Stock Status');
            return false;
        }

        for (let entry of fields) {
            if (!entry.field) {
                notify('danger', 'Error', 'Please Enter The Field Name');
                return false;
            }
            if (!entry.value) {
                notify('danger', 'Error', `Please Enter the Value of ${entry.field} This Field`);
                return false;
            }
        }
        if (more) {
            const colorSet = new Set();

            for (let entry of variants) {
                if (entry.var_images.length == 0) {
                    notify('danger', 'Error', `Please upload at least one image of ${entry.color} color variant`);
                    return false;
                }

                if (!entry.color) {
                    notify('danger', 'Error', 'Please select the Color');
                    return false;
                }

                if (colorSet.has(entry.color)) {
                    notify('danger', 'Error', 'Duplicate colors found in variants. Please select different colors.');
                    return false;
                } else {
                    colorSet.add(entry.color);
                }

                if (!entry.price) {
                    notify('danger', 'Error', `Please Enter the Price of ${entry.color} color variant`);
                    return false;
                }

                if (!entry.sizes || entry.sizes.length === 0) {
                    notify('danger', 'Error', 'Please add at least one size');
                    return false;
                }

                for (let sizeEntry of entry.sizes) {
                    if (!sizeEntry.size || sizeEntry.size.trim() === '') {
                        notify('danger', 'Error', `Please select the Size of ${entry.color} color variant`);
                        return false;
                    }
                    if (sizeEntry.quantity <= 0) {
                        notify('danger', 'Error', `Quantity must be greater than zero of ${entry.color} color variant`);
                        return false;
                    }
                }
            }
        }

        setLoading(true);
        try {

            const response = await axios.post(`${baseUrl}/add_product_seller`,
                {
                    product_info: newPayload,
                    varient_data: variants,
                    add_info: fields
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
            if (response.data.status) {
                notify('success', 'Success', `Product Added Successfully`);
                navigate('../products')
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }


    const handleQuantityChange = (variantIndex, sizeIndex, newQuantity) => {
        const updatedVariants = [...variants];
        updatedVariants[variantIndex].sizes[sizeIndex].quantity = newQuantity;
        setVariants(updatedVariants);
    };


    const handleAddSize = (variantIndex) => {
        const updatedVariants = [...variants];
        updatedVariants[variantIndex].sizes.push({ size: '', quantity: '', product_varient_size_id: null, size_id: '' });
        setVariants(updatedVariants);
    };

    const handleRemoveSizeQuantity = (variantIndex, sizeIndex) => {
        const updatedVariants = [...variants];
        updatedVariants[variantIndex].sizes.splice(sizeIndex, 1);
        setVariants(updatedVariants);
    };

    const handleCheckboxChange = (isChecked) => {
        setMore(isChecked);

        if (isChecked) {
            setVariants([...variants, {
                product_varient_id: null,
                color: '',
                price: '',
                sizes: [{ size: '', quantity: '', size_id: '', product_varient_size_id: null }],
                var_images: [],
                showUploadSection: false,
            }]);
        } else {
            if (variants.length > 1) {
                setVariants(variants.slice(0, -1));
            }
        }
    };


    const handleRemoveImage = (variantIndex, imageIndex) => {
        const updatedVariants = [...variants];
        updatedVariants[variantIndex].var_images.splice(imageIndex, 1);
        setVariants(updatedVariants);
    };


    const value = 'Add Color Varient';

    const navbar = [
        {
            name: "insert",
            title: "insert",
            options: ["special_char"],
        },
        {
            name: "format",
            title: "format",
            options: [
                "bold",
                "italic",
                "underline",
                "superscript",
                "subscript",
                "font",
                "font_size",
                "alignment",
            ],
        },
        "|",
        "select_all",
        "|",
        "link",
    ];

    const toolbar = [
        "undo",
        "redo",
        "|",
        "format",
        "|",
        "bold",
        "italic",
        "underline",
        "superscript",
        "subscript",
        "|",
        "alignLeft",
        "alignCenter",
        "alignRight",
        "alignJustify",
        "|",
        "indent",
        "outdent",
        "|",
        "orderedList",
        "unorderedList",
        "|",
        "removeFormat",
        "|",
        "textColor",
        "backgroundColor",
        "|",
        "ltr",
        "rtl",
        "|",
    ];


    const handleAddMorefields = () => {
        setFields([...fields, { product_about_id: null, field: '', value: '' }]);
    };

    const handleRemovefields = (index) => {
        const newFields = fields.filter((_, i) => i !== index);
        setFields(newFields);
    };

    const handleInputChangefields = (index, event) => {
        const newFields = [...fields];
        newFields[index][event.target.name] = event.target.value;
        setFields(newFields);
    };


    return (
        <>
            <div className="flex flex-row gap-4">
                <h1 className={`xl:text-2xl md:text-lg text-xl mt-3 font-semibold capitalize`}>Select Category</h1>
            </div>
            <div className=" md:w-1/2 w-full rounded-lg  grid md:grid-cols-2 grid-cols-1 gap-5">
                <Dropdown
                    label=""
                    options={categoryoption}
                    search={true}
                    selectedValues={category}
                    onSelect={handleselectcategory}
                    onRemove={() => { }}
                    isMultiple={false}
                />
                {category !== 'Select Category' && subcategoryoption[0].label !== 'Not Found Category' && (
                    <Dropdown
                        label=""
                        options={subcategoryoption}
                        search={true}
                        selectedValues={category_2}
                        onSelect={handleselectsubcategory}
                        onRemove={() => { }}
                        isMultiple={false}
                    />
                )}
                {category_2 !== 'Select Category' && subcategoryoption3[0].label !== 'Not Found Category' && (
                    <Dropdown
                        label=""
                        options={subcategoryoption3}
                        search={true}
                        selectedValues={category_3}
                        onSelect={handleselectsubcategory3}
                        onRemove={() => { }}
                        isMultiple={false}
                    />
                )}
                {category_3 !== 'Select Category' && subcategoryoption4[0].label !== 'Not Found Category' && (
                    <Dropdown
                        label=""
                        options={subcategoryoption4}
                        selectedValues={category_4}
                        search={true}
                        onSelect={handleselectsubcategory4}
                        onRemove={() => { }}
                        isMultiple={false}
                    />
                )}
            </div>
            <div className="flex xl:flex-row flex-col gap-5 w-full mt-2 mb-7">
                <div className="flex md:flex-row flex-col xl:w-1/2 w-full gap-5 mt-5 ">
                    <div className="space-y-5 rounded-2xl p-5 md:w-1/2 w-full bg-white">
                        <div className="flex flex-row place-items-center gap-3 py-6 px-5 bg-[#FFF8BA] border-2 border-[#FFE601] rounded-2xl">
                            <RiErrorWarningFill className='text-[#EFD700] text-3xl' />
                            <span className='text-sm'>Follow guidelines to reduce quality check failure</span>
                        </div>
                        <div className="">
                            <h1 className='text-base font-medium'>General Guidelines</h1>
                            <ul className='list-decimal ps-5 text-sm'>
                                <li>Upload the products from the same category that you have chosen</li>
                            </ul>
                        </div>
                        <div className="">
                            <h1 className='text-base font-medium'>Image Guidelines</h1>
                            <ul className='list-decimal ps-5 text-sm'>
                                <li>Images with text/Watermark are not acceptable in primary images.</li>
                                <li>Product image should not have any text</li>
                                <li>Please add solo product image without any props</li>
                            </ul>
                        </div>
                    </div>
                    <div className="space-y-5 rounded-2xl  md:w-1/2 w-full bg-white">
                        <div className={`grid grid-cols-1 place-items-center ${category != 'Select Category' ? 'border-b' : ''} gap-1 border-b-[#9B9B9B]`}>
                            <h1 className='text-center text-base font-medium '>{category != 'Select Category' && category} </h1>
                            {category != 'Select Category' && <span><HiOutlineArrowSmDown /></span>}

                            <h1 className='text-center text-base font-medium '>{category_2 != 'Select Category' && subcategoryoption[0].label !== 'Not Found Category' && category_2}</h1>
                            {category_2 != 'Select Category' && subcategoryoption3[0].label !== 'Not Found Category' && <span><HiOutlineArrowSmDown /></span>}

                            <h1 className='text-center text-base font-medium '>{category_3 != 'Select Category' && subcategoryoption3[0].label !== 'Not Found Category' && category_3}</h1>
                            {category_3 != 'Select Category' && subcategoryoption4[0].label !== 'Not Found Category' && <span><HiOutlineArrowSmDown /></span>}

                            <h1 className='text-center text-base font-medium '>{category_4 != 'Select Category' && category_4}</h1>
                        </div>
                        <div className="flex flex-col justify-center place-items-center">
                            <img src={ctg_img} className='h-40 w-40 ' alt="" />
                            <h1 className='text-base font-medium px-12 mt-3'>Please provide only front image for each product</h1>
                            {variants.map((variant, variantIndex) => (
                                variantIndex === 0 &&
                                <div className="" key={variantIndex}>
                                    <label htmlFor={`dropzone-file-${variantIndex}`} className='flex flex-row place-items-center gap-3 cursor-pointer rounded-xl bg-[#2377FC] text-white px-4 py-3 my-5' >
                                        <MdOutlineFileUpload className='text-3xl' />
                                        <span>Add Product Images</span>
                                    </label>
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        id={`dropzone-file-${variantIndex}`}
                                        className="hidden"
                                        multiple
                                        onChange={(e) => handleFileChange(variantIndex, e)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {variants.map((variant, variantIndex) => (
                    variantIndex === 0 && variant.var_images.length > 0 &&

                    <div key={variantIndex} className="mt-4 p-4 border rounded-lg bg-white">
                        <div className="flex md:flex-row flex-col mt-2 gap-2 place-items-start">
                            <div className="lg:w-1/2 w-full">
                                <div className="grid grid-cols-4 h-64 gap-2 overflow-y-auto mt-2">
                                    {variant.var_images.map((image, index) => (
                                        <div key={index} className='relative'>
                                            <img src={image.url} alt={`Uploaded ${index}`} className="h-32 rounded-lg" />
                                            <button
                                                type="button"
                                                onClick={() => handleRemoveImage(variantIndex, index)}
                                                className="absolute top-2 right-2 bg-red-500 text-white rounded-full text-xl p-1"
                                            >
                                                <AiOutlineDelete />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

            </div>
            <div className="flex flex-row justify-between place-items-center">
                <h1 className={`xl:text-2xl md:text-lg text-xl  font-semibold capitalize  text-black}`}>Add Product</h1>
            </div>
            <div className="w-full bg-white rounded-lg mt-5 md:p-6 p-3">
                <div className="grid xl:grid-cols-2 md:grid-cols-3 grid-cols-1 gap-7">
                    <div className='md:col-span-2 xl:col-span-1'>
                        <span className="text-lg font-medium">Product name </span>
                        <input
                            className="w-full mt-2 mb-1 rounded-lg py-2 md:py-2 focus:ring-0 focus:border-black border-gray-300"
                            placeholder="Enter Product Name"
                            type="text"
                            name='product_name'
                            value={payload.product_name}
                            onChange={(e) => setPayload({ ...payload, product_name: e.target.value })}
                        />
                        <span className="text-gray-500">
                            Do not exceed 20 characters when entering the product name.
                        </span>
                    </div>
                    <div className="">
                        <span className="text-lg font-medium">Sub text</span>
                        <input
                            className="w-full mt-2 mb-1 py-2 md:py-2 rounded-lg focus:ring-0 focus:border-black border-gray-300"
                            placeholder="Enter Sub Text"
                            type="text"
                            name='sub_text'
                            value={payload.sub_text}
                            onChange={(e) => setPayload({ ...payload, sub_text: e.target.value })}
                        />
                        <span className="text-gray-500">
                            Do not exceed 15 characters when entering the product sub text.
                        </span>
                    </div>
                    <div className="">
                        <span className="text-lg font-medium">Discount (%)</span>
                        <input
                            className="w-full mt-2 mb-1 py-2 md:py-2 rounded-lg focus:ring-0 focus:border-black border-gray-300"
                            placeholder="Enter Discount"
                            type="text"
                            name='discount'
                            value={payload.discount}
                            onChange={(e) => setPayload({ ...payload, discount: e.target.value })}
                        />
                    </div>
                    <div className="">
                        <span className="text-lg font-medium">Net Weight (gms) </span>
                        <input
                            className="w-full mt-2 mb-1 py-2 md:py-2 rounded-lg focus:ring-0 focus:border-black border-gray-300"
                            placeholder="Enter Net Weight"
                            type="text"
                            name='net_weight'
                            value={payload.net_weight}
                            onChange={(e) => setPayload({ ...payload, net_weight: e.target.value })}
                        />
                    </div>
                    <div className="flex flex-row gap-5 md:col-span-3 xl:col-span-1 md:order-2 xl:order-1">
                        {variants.map((variant, variantIndex) => (
                            variantIndex === 0 &&
                            <div key={variantIndex} className="w-full rounded-lg">
                                <div className="grid  grid-cols-1 gap-5 mb-5 w-full">
                                    <div className="flex md:flex-row flex-col gap-7">
                                        <div className="md:w-2/5 w-full">
                                            <div className="flex flex-row gap-2 lg:place-items-start justify-between xl:place-items-center">
                                                <span className="text-lg font-medium">color</span>
                                                <label className="inline-flex items-center cursor-pointer text-nowrap ">
                                                    <span className=" text-sm font-medium text-gray-900 dark:text-gray-300 pe-3">More Colors</span>
                                                    <input type="checkbox" className="sr-only peer"
                                                        checked={more}
                                                        onChange={(e) => handleCheckboxChange(e.target.checked)}
                                                    />
                                                    <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600"></div>
                                                </label>
                                            </div>
                                            <Dropdown
                                                label=""
                                                color={true}
                                                options={colours}
                                                selectedValues={variant.color}
                                                onSelect={(newColor) => {
                                                    const updatedVariants = [...variants];
                                                    updatedVariants[variantIndex].color = newColor;
                                                    setVariants(updatedVariants);
                                                }}
                                                isMultiple={false}
                                                search={true}
                                            />
                                        </div>

                                        <div className="md:w-2/5 w-full">
                                            <span className="text-lg font-medium">Price</span>
                                            <input
                                                className="w-full mt-2 mb-1 py-2 rounded-lg focus:ring-0 focus:border-black border-gray-300"
                                                placeholder="Enter Price"
                                                type="text"
                                                value={variant.price}
                                                onChange={(e) => {
                                                    const updatedVariants = [...variants];
                                                    updatedVariants[variantIndex].price = e.target.value;
                                                    setVariants(updatedVariants);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-3">
                                        {variant.sizes.map((sizeEntry, sizeIndex) => (
                                            <div key={sizeIndex} className="flex flex-row gap-7">
                                                <div className="w-2/5">
                                                    <Dropdown
                                                        label="Size"
                                                        options={sizes}
                                                        selectedValues={sizeEntry.size}
                                                        onSelect={(newSize) => handleSizeChange(variantIndex, sizeIndex, newSize)}
                                                        isMultiple={false}
                                                        search={true}
                                                    />
                                                </div>
                                                <div className="w-2/5">
                                                    <span className="text-lg font-medium">Quantity</span>
                                                    <input
                                                        className="w-full mt-2 mb-1 rounded-lg py-2 focus:ring-0 focus:border-black border-gray-300"
                                                        placeholder="Enter Quantity"
                                                        type="text"
                                                        value={sizeEntry.quantity}
                                                        onChange={(e) => handleQuantityChange(variantIndex, sizeIndex, e.target.value)}
                                                    />
                                                </div>
                                                {sizeIndex == 0 &&
                                                    <button className="text-blue-500 ms-auto text-nowrap" onClick={() => handleAddSize(variantIndex)}>
                                                        + Add Size
                                                    </button>
                                                }
                                                {sizeIndex > 0 &&
                                                    <div className="h-full flex place-items-end pb-1">
                                                        <button
                                                            className="text-white rounded-lg px-2 py-1 text-xl font-medium capitalize bg-red-500 h-10"
                                                            onClick={() => handleRemoveSizeQuantity(variantIndex, sizeIndex)}
                                                        >
                                                            <AiOutlineDelete />
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="md:order-1 xl:order-2">
                        <Dropdown
                            label="Stock status"
                            options={stockStatusOptions}
                            selectedValues={stockStatus}
                            onSelect={setStockStatus}
                            onRemove={() => { }}
                            isMultiple={false}
                        />
                    </div>
                    <div className={`flex flex-col order-4 md:col-span-2 xl:col-span-1 ${fields.length > 3 ? 'md:h-64 h-56' : 'h-full'}  overflow-hidden overflow-y-auto`}>
                        <div className="flex flex-row justify-between mb-2 sticky top-0 bg-white">
                            <span className="text-lg font-medium">Additional Information</span>
                            <button className='bg-black text-white px-2 py-1 rounded-md capitalize' onClick={handleAddMorefields}>Add More</button>
                        </div>
                        {fields.map((list, index) => (
                            <div className="flex flex-row gap-3 mb-3" key={index}>
                                <div className="">
                                    <span className="text-base font-medium">Filed</span>
                                    <input
                                        className="w-full mt-2 mb-1 py-1 md:py-2 rounded-lg focus:ring-0 focus:border-black border-gray-300"
                                        placeholder="Enter Filed"
                                        type="text"
                                        name='field'
                                        value={list.field}
                                        onChange={(e) => handleInputChangefields(index, e)}
                                    />
                                </div>
                                <div className="">
                                    <span className="text-base font-medium">Value</span>
                                    <input
                                        className="w-full mt-2 mb-1 py-1 md:py-2 rounded-lg focus:ring-0 focus:border-black border-gray-300"
                                        placeholder="Enter Value"
                                        type="text"
                                        name='value'
                                        value={list.value}
                                        onChange={(e) => handleInputChangefields(index, e)}
                                    />
                                </div>
                                {index != 0 &&
                                    <button
                                        className="bg-red-500 text-white px-2 py-2 h-fit rounded-md capitalize mt-8"
                                        onClick={() => handleRemovefields(index)}
                                    >
                                        Remove
                                    </button>
                                }
                            </div>
                        ))}
                    </div>
                    <div className="order-3">
                        <span className="text-lg font-medium">Delivery Manage by</span>
                        <fieldset className="flex max-w-md flex-col gap-4 mt-2">
                            <div className="flex items-center gap-2 ">
                                <Radio id="alas" value="1" className='focus:ring-0'
                                    name='manage_by'
                                    onChange={(e) => setPayload({ ...payload, manage_by: e.target.value })} />
                                <Label htmlFor="alas" className='flex gap-3'>Managed By Alas<span className='mt-1'>
                                    <Tooltip content="All the prodcut delivery managed by alas end.">
                                        <FaInfoCircle />
                                    </Tooltip>
                                </span></Label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Radio id="self" value="0" className='focus:ring-0'
                                    name='manage_by'
                                    onChange={(e) => setPayload({ ...payload, manage_by: e.target.value })} />
                                <Label htmlFor="self" className='flex gap-3'>Managed By Self<span className='mt-1'>
                                    <Tooltip content="All the product delivery managed by your end.">
                                        <FaInfoCircle />
                                    </Tooltip>
                                </span></Label>
                            </div>
                        </fieldset>
                    </div>

                </div>
                <div className='mt-4'>
                    {more &&
                        <Addmoreproduct setMore={setMore} editProduct={editProduct} value={value} sizeOptions={sizes} colourOptions={colours} setVariants={setVariants} variants={variants} setLoading={setLoading} />
                    }
                </div>
                <div className='mt-4 flex flex-col'>
                    <span className="text-lg font-medium">Description</span>
                    <textarea className='w-full rounded-lg mt-2 focus:border-black border-gray-300 focus:ring-0' rows={8} name="description"
                        value={payload.description}
                        onChange={(e) => setPayload({ ...payload, description: e.target.value })} ></textarea>
                </div>
                <div className='mt-10 flex justify-center place-items-center gap-3'>
                    <button className='text-black bg-white px-5 text-base py-2 hover:text-white hover:bg-black hover:border border-black border rounded-lg hover:border-white transition-all duration-200'>Save Darft</button>
                    <button className='text-white bg-black px-5 text-base py-2 hover:text-black hover:bg-white hover:border border rounded-lg hover:border-black transition-all duration-200' onClick={handleaddproduct}>Add Product</button>
                </div>
            </div>
            <Modal show={openModal} onClose={() => setOpenModal(false)}>
                <Modal.Header>Terms of Service</Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            With less than a month to go before the European Union enacts new consumer privacy laws for its citizens,
                            companies around the world are updating their terms of service agreements to comply.
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setOpenModal(false)}>I accept</Button>
                    <Button color="gray" onClick={() => setOpenModal(false)}>
                        Decline
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddProduct;
