import React, { useState } from 'react';
import { IoMdNotifications } from "react-icons/io";
import logo from '../Component/Assets/Images/adminlogo.png';
import profile from '../Component/Assets/Images/rating2.png';
import { RxHamburgerMenu } from "react-icons/rx";
import { Link, useNavigate } from 'react-router-dom';
import { UserState } from '../Context/userProvider';
import Loader from '../Component/utiles/Loader';


const Navbar = ({ toggleMenu, userData }) => {

  const { user, logout } = UserState() || {};

  // console.log(user)

  const navigate = useNavigate();

  const [profiledropdown, setProfileDropdown] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);



  const handleLogout = () => {
    logout();
    setIsLoading(true);
    navigate('/admin/login');
    setDropdownOpen(false);
    setIsLoading(false);
    setProfileDropdown(!profiledropdown);
  };
  const toggleProfileDropdown = () => {
    setProfileDropdown(!profiledropdown);
  };

  return (
    <>
      <div className='w-full flex flex-row sticky bg-white z-50 top-0 md:shadow-lg shadow-lg h-[7vh]'>
        <div className='w-20 flex justify-center items-center'>
          <RxHamburgerMenu onClick={toggleMenu} className='cursor-pointer text-xl xl:text-2xl' />
        </div>
        <div className={`transition-all duration-500 w-2/12 py-2 flex md:items-center items-start overflow-hidden`}>
          <Link to="/admin/dashboard">
            <img src={logo} className='md:h-14 h-12 transition-all duration-500 md:ms-10 ms-0' alt='logo' />
          </Link>
        </div>
        <div className={`w-10/12 transition-all duration-500 md:px-5 px-0 flex flex-row items-center justify-end`}>
          <div className="w-full ">
            <IoMdNotifications className='xl:text-xl md:text-lg text-base me-7 cursor-pointer text-neutral-500 ms-auto' />
          </div>
          <div className=' cursor-pointer ms-auto md:me-10 flex-row flex items-center' onClick={toggleProfileDropdown}>
            <img className='lg:h-14 md:h-12 h-8 rounded-full' src={userData.user_image} alt='my-image' />
            <div className='flex flex-col ms-3'>
              <span className='xl:text-xl md:text-lg font-medium text-nowrap md:block hidden'>{userData.name}</span>
              <span className='text-base md:block hidden capitalize'>{user?.role}</span>
            </div>
          </div>
          {profiledropdown && (
            <div className="absolute top-14 right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20 border-2">
              <div className="py-2">
                <Link to='my-profile' className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left' onClick={toggleProfileDropdown}>My Profile</Link>
                <button
                  onClick={handleLogout}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                >
                  Logout
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {isLoading && <Loader />}
    </>
  );
}

export default Navbar;
