import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { RiAccountCircleLine, RiSearchLine, RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import { FiShoppingCart } from "react-icons/fi";
import Logo from "../Assets/Images/logo.png"
import Login from '../Pages/Login';
import SignUp from '../Pages/SignUp';
import Loader from '../utiles/Loader'
import '../Assets/css/Styles.css';
import { FaBars, FaHeart, FaShoppingCart, FaTimes, FaChevronDown, FaAngleDoubleRight, FaAngleDown, FaUser, FaSignOutAlt } from 'react-icons/fa';
import { CiHeart } from "react-icons/ci";
import { IoPersonOutline } from "react-icons/io5";
import { UserState } from '../../Context/userProvider'
import { MdArrowDropDown } from "react-icons/md";
import ForgotPass from '../Pages/ForgotPass';
import axios from 'axios';
import { IoLocationOutline } from "react-icons/io5";
import { useCart } from '../../Context/CartContext';
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import CartSidebar from '../Pages/CartSidebar';
import notify from '../utiles/Notification';


const Navbar = ({ productData }) => {

  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [categoryHovered, setCategoryHovered] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [cartTotal, setCartTotal] = useState(0);
  const { cartCount, updateCartCount, setMainctg, cart, setCart } = useCart();
  const [carts, setCarts] = useState([]);
  const [query, setQuery] = useState('');
  const { user, logout, geoLocation, countryCode } = UserState()


  useEffect(() => {
    if (!user && isCartOpen && cart && cart.length > 0) {
      const Total = cart.reduce((total, item) => {
        return total + (item.price * item.added_quantity);
      }, 0);
      setCartTotal(Total);
      setCarts(cart);
    }
  }, [isCartOpen, cart, productData]);


  let timeoutId = null;

  const handleMouseEnter = (index) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setDropdownIndex(index);
  };

  const handleMouseLeave = () => {
    timeoutId = setTimeout(() => {
      setDropdownIndex(null);
    }, 100);
  };


  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);

  const toggleCategories = () => {
    setIsCategoriesOpen(!isCategoriesOpen);
  };

  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isForgotPassOpen, setIsForgotPassOpen] = useState(false);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('English');
  const [profiledropdown, setProfileDropdown] = useState(false);


  useEffect(() => {
    if (isLoginOpen || isSignUpOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isLoginOpen, isSignUpOpen]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    setIsCategoriesOpen(false);
  };

  const toggleLogin = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setIsLoginOpen(!isLoginOpen);
    }, 1000);
  };

  const openSignUp = () => {
    setIsLoginOpen(false);
    setIsSignUpOpen(true);
  };
  const openForgotPass = () => {
    // console.log("hello")
    setIsLoginOpen(false);
    setIsForgotPassOpen(true);
  };
  const openLogin = () => {
    setIsLoginOpen(true);
    setIsSignUpOpen(false);
  };
  const closeSignUp = () => {
    setIsSignUpOpen(false);
  };

  const closeLogin = () => {
    setIsLoginOpen(false);
  };

  const closeForgotPass = () => {
    setIsForgotPassOpen(false);
  };

  const options = ['English', 'Spanish', 'French'];

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const toggleProfileDropdown = () => {
    setProfileDropdown(!profiledropdown);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setDropdownOpen(false);
  };
  const handleLogout = () => {
    logout();
    updateCartCount(0)
    setDropdownOpen(false);
  };

  const [category, setCategory] = useState([]);
  const baseUrl = process.env.REACT_APP_BASEURL


  useEffect(() => {
    axios.get(`${baseUrl}/home_category_with_subcategory`)
      .then(response => {
        const categoriess = response.data.categories_array || [];
        setCategory(categoriess);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const getAllProducts = async (type) => {

    if (!user) {
      // notify('danger', 'Error', 'Please login to see product to cart');
      return
    }
    setIsLoading(true);

    try {
      const response = await axios.post(`${baseUrl}/get_cart`, {
        customer_id: user.customer_id,
        countryCode: countryCode
      });

      if (response.data.status) {
        console.log(carts)
        setCarts(response.data.cartAllData);
        setCartTotal(response.data.total_amount);
        updateCartCount(response.data.cartCount);
        setIsLoading(false);
      } else {
        setCarts([]);
        if (type === 1) {
          notify('danger', 'Error', `${response.data.message}`);
        }
        updateCartCount(0)
        setCartTotal(0)
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error during status update:', error);
      setIsLoading(false);
      notify('danger', 'Error', 'There was an error. Please try again.');
    }
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
    // if (!isCartOpen) {
    getAllProducts(0);
    // }
  };

  const deleteProduct = async (type, id, product_id) => {
    if (!user) {
      const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
      const updatedCart = storedCart.filter(item => item.product_id !== product_id);

      setCart(updatedCart);
      setCarts(updatedCart);
      localStorage.setItem('cart', JSON.stringify(updatedCart));
      const total = updatedCart.reduce((sum, item) => {
        return sum + (item.less_price ? item.less_price : item.price * item.added_quantity);
      }, 0);
      setCartTotal(total);
      return;
    }
    setIsLoading(true);
    let payload
    if (type === 'All') {
      payload = {
        customer_id: user.customer_id,
      }
      setCartTotal(0);
      updateCartCount(0);
    } else if (type === 'Single') {
      payload = {
        product_cart_id: id
      }
    }
    try {
      const response = await axios.post(`${baseUrl}/delete_cart_product`, payload);

      if (response.data.status) {
        await getAllProducts(0);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        notify('danger', 'Error', `${response.data.message}`);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error during status update:', error);
      notify('danger', 'Error', 'There was an error. Please try again.');
    }
  }


  const [suggestions, setSuggestions] = useState([]);

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setQuery(value);
    const queryForApi = value.replace(/\s+/g, '-');

    if (value.length > 3) {
      try {
        const response = await axios.get(`${baseUrl}/get_search_suggestion/${queryForApi}`);
        setSuggestions(response.data);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    } else {
      setSuggestions([]);
    }
  }

  return (
    <>
      <div className='bg-black'>
        <div className='md:container mx-auto'>
          <div className=' py-2 px-2 flex items-center justify-between'>
            <div className='text-white ml-4 hidden lg:block'>
              <p>First Five Order on 30% Discount</p>
            </div>
            <div className='text-white ml-4'>
              <p>Mid-season Sale up to 70% Off. Shop Now!</p>
            </div>
            <div className='text-white ml-4 hidden lg:block'>
              <p>FAQ</p>
            </div>
          </div>
        </div>
      </div>

      <div className='mx-auto sticky top-0 z-40 bg-red-500'>
        <nav className="bg-white shadow-md mb-0 py-2 md:py-4 md:px-6">
          <div className="container mx-auto flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <div className="flex items-center md:hidden">
                <button className='flex justify-center items-center' onClick={toggleSidebar}>
                  <FaBars size={24} />
                </button>
              </div>
              <Link to='/' className='pb-1'>
                <img src={Logo} alt="Logo" className="md:w-40 w-24 transition-transform duration-300 hover:scale-105" />
              </Link>
            </div>

            {user && !user.role && (
              <div className="hidden md:flex items-center space-x-2 cursor-pointer group">
                <div className="p-2 rounded-full bg-gray-100 group-hover:bg-gray-200 transition-colors duration-300">
                  <IoLocationOutline className="w-6 h-6 text-gray-600" />
                </div>
                <div className="group-hover:text-gray-800 transition-colors duration-300">
                  <p className="text-sm text-gray-500">Deliver to <span className='capitalize'>{user.first_name}</span></p>
                  <p className="text-md font-semibold">{geoLocation.city} {geoLocation.postal}</p>
                </div>
              </div>
            )}

            <div className="hidden md:flex w-1/3 relative">
              <input
                type="text"
                value={query}
                onChange={handleInputChange}
                placeholder="Search Products"
                className="w-full border-2 border-gray-300 rounded-full px-4 py-2 pr-10 focus:outline-none focus:border-blue-400 focus:ring-2 focus:ring-blue-200 transition-all duration-300"
              />
              <RiSearchLine className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-gray-400 hover:text-gray-600 cursor-pointer transition-colors duration-300" />
              {suggestions.length > 0 && (
                <div className="absolute top-full mt-2 w-full bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-y-auto z-50">
                  {suggestions.map((suggestion) => (
                    <Link
                      key={suggestion.product_id}
                      to={`/search/${suggestion.product_name}`}
                      // onClick={() => setSuggestions([])}
                      className="block px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    >
                      {suggestion.product_name}
                    </Link>
                  ))}
                </div>
              )}
            </div>

            <div className="hidden md:flex space-x-4">
              <Link to='/become-influencer' className='text-sm bg-gradient-to-r from-purple-600 to-blue-600 text-white rounded-full px-5 py-2 font-medium hover:from-purple-700 hover:to-blue-700 transition-all duration-300 ease-in shadow-md hover:shadow-lg'>
                Become an Influencer
              </Link>
              <Link to='/become-seller' className='text-sm border-2 border-gray-800 text-gray-800 rounded-full px-5 py-2 font-medium hover:bg-gray-800 hover:text-white transition-all duration-300 ease-in'>
                Become a Supplier
              </Link>
            </div>

            <div className="flex items-center space-x-6">
              <button onClick={toggleCart} className="relative group cursor-pointer">
                <FiShoppingCart className="text-2xl text-gray-600 group-hover:text-gray-800 transition-colors duration-300" />
                {cartCount > 0 && (
                  <div className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center group-hover:bg-red-600 transition-colors duration-300">
                    {cartCount}
                  </div>
                )}
              </button>
              {user && !user.role ? (
                <>

                  <div className="relative" onMouseEnter={() => setIsHovered(true)} >
                    <button className="flex items-center space-x-2 text-gray-600 hover:text-gray-800 transition-colors duration-300">
                      <img src={`https://flagsapi.com/${geoLocation.country}/flat/32.png`} alt="Country Flag" className="w-6 h-6 rounded-full" />
                      <span className='capitalize text-sm font-medium'>{user?.first_name}</span>
                      <FaAngleDown className={`text-gray-400 transition-transform duration-300 ${isHovered ? 'rotate-180' : ''}`} />
                    </button>

                    <div onMouseLeave={() => setIsHovered(false)} className={`absolute top-full right-0 mt-2 w-56 bg-white rounded-lg shadow-xl z-40 border border-gray-200 overflow-hidden transition-all duration-300 ${isHovered ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-2 pointer-events-none'}`}>
                      <Link to='/my-profile' className='flex items-center px-4 py-3 text-sm text-gray-700 hover:bg-gray-200 transition-colors duration-300'>
                        <FaUser className="mr-3 text-gray-400" />
                        My profile
                      </Link>
                      <button onClick={handleLogout} className="flex items-center w-full px-4 py-3 text-sm text-left text-gray-700 hover:bg-gray-200 transition-colors duration-300">
                        <FaSignOutAlt className="mr-3 text-gray-400" />
                        Logout
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <button className="text-gray-800 hover:text-gray-600 font-medium transition-colors duration-300" onClick={toggleLogin}>
                  Login
                </button>
              )}
            </div>
          </div>
        </nav>
        <div className="relative px-2 py-2 hidden w-full bg-white mt-0">
          <input
            type="text"
            placeholder="Search Products"
            className="bg-gray-200 w-full border-0 rounded-full px-6 py-2 pr-10 focus:outline-none focus:border-gray-400 focus:ring-2 focus:ring-gray-400"
          />
          <RiSearchLine className="absolute right-6 top-1/2 transform -translate-y-1/2 text-lg text-black cursor-pointer" />
        </div>
        <nav className="bg-black text-white">
          <div className="justify-between container mx-auto items-center py-3 md:flex hidden">
            <div className="flex items-center md:hidden">
              <button onClick={toggleSidebar}>
                <FaBars size={24} />
              </button>
            </div>
            <div className="hidden md:flex md:items-center md:space-x-1 lg:space-x-2 xl:space-x-4 px-3 bg-black">
              {category.map((list, index) => (
                <div
                  key={index}
                  className="relative group"
                  onMouseEnter={() => handleMouseEnter(list.category_id)}
                  onMouseLeave={handleMouseLeave}
                >
                  <a
                    href="#home"
                    className="inline-block px-3 py-2 text-sm lg:text-base font-medium text-white hover:text-gray-300 transition-colors duration-200 ease-in-out"
                  >
                    {list.category_name}
                  </a>
                  <div className="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-purple-500 to-pink-500 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></div>
                  {/* <div className="absolute top-0 left-0 w-full h-full bg-white/10 transform scale-y-0 group-hover:scale-y-100 transition-transform duration-300 ease-in-out rounded-md"></div> */}
                </div>
              ))}
            </div>
            <Link to='/pages/press_release' className='text-white text-xl font-semibold ms-auto'>News</Link>
            <div className="flex items-center space-x-4">
              <div className="relative inline-block text-left">


                {dropdownOpen && (
                  <div className="origin-top-right absolute z-20 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-0">
                    <div className="py-1">
                      {options.map((option, index) => (
                        <button
                          key={index}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                          onClick={() => handleOptionClick(option)}
                        >
                          {option}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>


          <div
            className={`fixed inset-0 bg-black bg-opacity-75 z-50 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}
          >
            <div
              className={`fixed inset-y-0 left-0 bg-white text-black w-3/4 z-50 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}
            >
              <div className="p-6 flex shadow-md justify-between border-b border-gray-300">
                <p className='text-xl font-medium'>Menu</p>
                <button onClick={toggleSidebar} className="text-black">
                  <FaTimes size={24} />
                </button>
              </div>
              <div className="flex flex-col px-6 mt-8">
                <Link to="/" className=" py-3 border-b border-gray-300" onClick={toggleSidebar}>Home</Link>
                <a
                  href="#categories"
                  className=" py-3 border-b border-gray-300 flex justify-between items-center"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleCategories();

                  }}
                >
                  Categories
                  <span className={`${isCategoriesOpen ? 'rotate-180' : ''} text-2xl duration-500 transition-all ease-in-out`}>
                    <MdArrowDropDown />
                  </span>
                </a>
                {isCategoriesOpen && (
                  <div className="pl-4 duration-500 transition-all ease-in-out">
                    {
                      category.map((list, index) => (
                        <a href="#home" className="py-2 block" onClick={toggleSidebar} key={index}>{list.category_name}</a>
                      ))
                    }
                  </div>
                )}
                <Link to="/pages/about-us" className=" py-3 border-b border-gray-300" onClick={toggleSidebar}>About</Link>
                <a href="#delivery" className=" py-3 border-b border-gray-300" onClick={toggleSidebar}>Delivery</a>
                <Link to="/pages/contact-us" className=" py-3 border-b border-gray-300" onClick={toggleSidebar}>Contact</Link>
                <Link to='/become-influencer' className='text-base bg-black mt-3 text-nowrap  text-white rounded-md px-5 py-2 capitalize border border-white font-medium hover:bg-white hover:text-black hover:border  transition-all duration-200 ease-in hover:border-black'>Become a influencers</Link>
                <Link to='/become-seller' className='text-base text-black border mt-5 text-nowrap border-black rounded-md px-5 py-2 capitalize font-medium hover:text-white hover:bg-black transition-all duration-200 ease-in'>Become a supplier</Link>
              </div>
            </div>
            {/* Overlay to close the sidebar */}
            {isOpen && (
              <div className="absolute inset-0" onClick={toggleSidebar}></div>
            )}
          </div>
        </nav >
        {dropdownIndex && (
          <div
            className="container absolute mx-auto left-0 right-0 bg-white text-gray-800 shadow-xl rounded-b-xl overflow-hidden transition-all duration-300 ease-in-out transform"
            onMouseEnter={() => handleMouseEnter(dropdownIndex)}
            onMouseLeave={handleMouseLeave}
          >
            {category.map((list) => (
              list.category_id === dropdownIndex && (
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6" key={list.category_id}>
                  {list.category_array.slice(0, 10).map((item, index) => (
                    item.sub_category_array.length > 0 &&
                    <div className="flex flex-col space-y-3 p-6" key={index}>
                      <h2 className="text-lg font-semibold text-indigo-600 border-b border-indigo-200 pb-2">
                        {item.sub_category_name}
                      </h2>
                      <div className="flex flex-col space-y-2">
                        {item.sub_category_array.slice(0, 5).map((data, i) => (
                          <div
                            className="group flex items-center space-x-2 hover:bg-indigo-50 rounded-md transition-colors duration-200 ease-in-out p-1"
                            key={i}
                            onMouseEnter={() => setCategoryHovered(true)}
                            onMouseLeave={() => setCategoryHovered(false)}

                          >
                            <span className={`text-indigo-500 transition-opacity duration-200 ${categoryHovered ? 'opacity-100' : 'opacity-0'}`}>
                              <FaAngleDoubleRight />
                            </span>
                            <Link
                              to={'/category/' + data.sub_category_2_id}
                              onClick={() => setMainctg(0)}
                              className="text-sm text-gray-600 hover:text-indigo-700  transition-all duration-200"

                            >
                              {data.sub_category_2_name}
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )
            ))}
          </div>
        )
        }
      </div >



      {isLoginOpen && <Login onClose={closeLogin} onSignUpClick={openSignUp} onisForgotPassClick={openForgotPass} />}
      {isForgotPassOpen && <ForgotPass onClose={closeForgotPass} onisForgotPassClick={openForgotPass} />}
      {isSignUpOpen && <SignUp onClose={closeSignUp} onLoginClick={openLogin} />}
      {isLoading && <Loader />}
      <CartSidebar isOpen={isCartOpen} onClose={() => setIsCartOpen(false)} cart={carts} cartTotal={cartTotal} deleteProduct={deleteProduct} />
    </>
  );
};

export default Navbar;
