    import { useState } from 'react';

export const ImageUpload = (multiple, setLoading) => {
    const [images, setImages] = useState([]); 

    const handleFileChange = async (e) => {
        setLoading(true);
        const files = Array.from(e.target.files);

        if (!multiple) {
            const file = files[0];
            if (!file) return;
            const reader = new FileReader();
            reader.onloadend = () => {
                setImages([{ url: reader.result, file }]); 
            };
            reader.readAsDataURL(file);
            await uploadToCloudinary([file]); 
        } else {
            const filePromises = files.map(file => {
                const reader = new FileReader();
                return new Promise((resolve, reject) => {
                    reader.onloadend = () => resolve({ url: reader.result, file });
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                });
            });
            const imagePreviews = await Promise.all(filePromises);
            setImages(prevImages => [...prevImages, ...imagePreviews]); 
            await uploadToCloudinary(files); 
        }
    };

    const uploadToCloudinary = async (files) => {
        // setLoading(true);
        const uploadPromises = files.map(async (file) => {
            const data = new FormData();
            data.append("file", file);
            data.append("upload_preset", "testalas");
            data.append("cloud_name", "duw6f8fm8");

            try {
                const res = await fetch('https://api.cloudinary.com/v1_1/duw6f8fm8/image/upload', {
                    method: 'POST',
                    body: data
                });
                const result = await res.json();
                return result.url;
            } catch (error) {
                console.error('Upload Error:', error);
                return null;
            }
        });

        const uploadResults = await Promise.all(uploadPromises);
        setImages(prevImages => prevImages.map((img, index) => ({
            ...img,
            url: uploadResults[index] || img.url,
            uploaded: !!uploadResults[index],
        })));
        setLoading(false);
    };

    // Handle removing an image
    const handleRemoveImage = (index) => {
        setImages(prevImages => prevImages.filter((_, i) => i !== index));
     // alert(index)
    };

    return {
        images,
        handleFileChange,
        handleRemoveImage
    };
};
