import React, { useState, useRef, useEffect } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import Dropdown from '../../Component/utiles/Dropdown';
import Categoryaddmodals from '../Component/Categoryaddmodals';
import axios from 'axios';
import Loader from '../../Component/utiles/Loader';
import notify from '../../Component/utiles/Notification';

const useSubcategories = (url, body, setState, fallback, arrayKey, reqid, resid, resname, setLoading, again) => {

    useEffect(() => {
        const fetchSubcategories = async () => {
            setLoading(true);
            try {
                const response = await axios.post(url, body, {
                    headers: { 'Content-Type': 'application/json' }
                });

                if (response.data.status) {
                    const subcategories = response.data[arrayKey].map(categoryValue => ({
                        id: categoryValue[resid],
                        label: categoryValue[resname],
                        status: categoryValue.status
                    }));
                    setState(subcategories);
                    setLoading(false);
                } else {
                    setState(fallback);
                    setLoading(false);
                }
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        };

        fetchSubcategories();
    }, [reqid, again]);
};

const Addcategory = () => {

    const baseUrl = process.env.REACT_APP_BASEURL

    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [images, setImages] = useState([]);

    const [isToggleCheckedCat_1, setIsToggleCheckedCat_1] = useState(false);
    const [isToggleCheckedCat_2, setIsToggleCheckedCat_2] = useState(false);
    const [isToggleCheckedCat_3, setIsToggleCheckedCat_3] = useState(false);
    const [isToggleCheckedCat_4, setIsToggleCheckedCat_4] = useState(false);

    const [category, setCategory] = useState('Select Category');
    const [categoryID, setCategoryID] = useState('');

    const [category_2, setCategory_2] = useState('Select Category');
    const [subcategoryID, setSubcategoryID] = useState('');
    const [subcategoryoption, setSubcategoryoption] = useState([]);

    const [category_3, setCategory_3] = useState('Select Category');
    const [subcategory3ID, setSubcategory3ID] = useState('');
    const [subcategoryoption3, setSubcategoryoption3] = useState([]);


    const [category_4, setCategory_4] = useState('Select Category');
    const [subcategoryoption4, setSubcategoryoption4] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState('');
    const [refresh3, setRefresh3] = useState('');
    const [refresh4, setRefresh4] = useState('');

    const ctgoptions = [
        { label: 'Not Found Category' },
    ];

    const main_ctg = [category, categoryID, category_2, subcategoryID, category_3, subcategory3ID]


    const [categoryoption, setCategoryoption] = useState([]);

    useEffect(() => {
        const allctg = async () => {
            try {
                const response = await axios.get(`${baseUrl}/allCategories`);
                if (response.data.status) {
                    const categories = response.data.all_categories_array.map(categoryValue => ({
                        id: categoryValue.category_id,
                        label: categoryValue.category_name,
                        status: categoryValue.status
                    }));
                    setCategoryoption(categories);
                } else {
                    setCategoryoption(ctgoptions)
                }
            } catch (err) {
                console.log(err);
            }
        };

        allctg();
    }, [refresh]);



    useSubcategories(
        `${baseUrl}/subCategories`,
        { category_id: categoryID },
        setSubcategoryoption,
        ctgoptions,
        'sub_categories_array',
        categoryID,
        'sub_category_1_id',
        'sub_category_1_name',
        setLoading,
        refresh2
    );

    useSubcategories(
        `${baseUrl}/subSubCategories`,
        { sub_category_1: subcategoryID },
        setSubcategoryoption3,
        ctgoptions,
        'sub_sub_categories_array',
        subcategoryID,
        'sub_category_2_id',
        'sub_category_2_name',
        setLoading,
        refresh3
    );

    useSubcategories(
        `${baseUrl}/subSubSubCategories`,
        { sub_category_2: subcategory3ID },
        setSubcategoryoption4,
        ctgoptions,
        'sub_sub_sub_categories_array',
        subcategory3ID,
        'sub_category_3_id',
        'sub_category_3_name',
        setLoading,
        refresh4
    );


    const handleselectcategory = (val) => {
        const selectedCategory = categoryoption.find(option => option.label === val);
        if (selectedCategory) {
            setCategory_2('Select Category')
            setCategory_3('Select Category')
            setCategory_4('Select Category')
            setCategory(selectedCategory.label)
            setCategoryID(selectedCategory.id);
        }
        if (selectedCategory.status === 'Active') {
            setIsToggleCheckedCat_1(true)
        } else {
            setIsToggleCheckedCat_1(false)
        }
    };

    const handleselectsubcategory = (val) => {
        const selectedsubCategory = subcategoryoption.find(option => option.label === val);
        if (selectedsubCategory) {
            setCategory_3('Select Category')
            setCategory_2(selectedsubCategory.label)
            setSubcategoryID(selectedsubCategory.id);
        }
        if (selectedsubCategory.status === 'Active') {
            setIsToggleCheckedCat_2(true)
        } else {
            setIsToggleCheckedCat_2(false)
        }

    };
    const handleselectsubcategory3 = (val) => {
        const selectedsubCategory3 = subcategoryoption3.find(option => option.label === val);
        if (selectedsubCategory3) {
            setCategory_4('Select Category')
            setCategory_3(selectedsubCategory3.label)
            setSubcategory3ID(selectedsubCategory3.id);
        }
        if (selectedsubCategory3.status === 'Active') {
            setIsToggleCheckedCat_3(true)
        } else {
            setIsToggleCheckedCat_3(false)
        }
    };
    const handleselectsubcategory4 = (val) => {
        const selectedsubCategory4 = subcategoryoption4.find(option => option.label === val);
        setCategory_4(selectedsubCategory4.label);
        if (selectedsubCategory4.status === 'Active') {
            setIsToggleCheckedCat_4(true)
        } else {
            setIsToggleCheckedCat_4(false)
        }
    };



    const [openctg, setOpenctg] = useState({
        ctg1: true,
        ctg2: false,
        ctg3: false,
        ctg4: false,
    });

    const openNewctg = (id) => {
        setOpenctg({
            ctg1: false,
            ctg2: false,
            ctg3: false,
            ctg4: false,
            [id]: true,
        });
    };
    const closeNewctg = (id) => {
        setOpenctg((prevState) => ({
            ...prevState,
            [id]: false,
        }));
    };

    const handleChangeCat_1 = async (e) => {
        setIsToggleCheckedCat_1(e.target.checked);
        let newStatus;
        if (e.target.checked === false) {
            newStatus = 'Inactive'
        } else {
            newStatus = 'Active'
        }
        try {
            const response = await axios.post(`${baseUrl}/category_status_update`, {
                category_id: categoryID,
                status: newStatus,
            });

            if (response.data.status) {
                setIsToggleCheckedCat_1(e.target.checked);
                notify('success', 'Status Update', `Category status changed to ${newStatus}`);
                setRefresh(prev => prev + 1);
            } else {
                notify('danger', 'Error', 'Failed to update category status');
            }
        } catch (error) {
            notify('danger', 'Error', 'Something went wrong!');
        }
    }

    const handleChangeCat_2 = async (e) => {
        setIsToggleCheckedCat_2(e.target.checked);
        let newStatus;
        if (e.target.checked === false) {
            newStatus = 'Inactive'
        } else {
            newStatus = 'Active'
        }
        try {
            const response = await axios.post(`${baseUrl}/sub_categories_status_update`, {
                sub_category_1_id: subcategoryID,
                status: newStatus,
            });

            if (response.data.status) {
                setIsToggleCheckedCat_2(e.target.checked);
                notify('success', 'Status Update', `Category status changed to ${newStatus}`);
                setRefresh2(prev => prev + 1);
            } else {
                notify('danger', 'Error', 'Failed to update category status');
            }
        } catch (error) {
            notify('danger', 'Error', 'Something went wrong!');
        }
    }
    const handleChangeCat_3 = (e) => {
        setIsToggleCheckedCat_3(e.target.checked);
    }
    const handleChangeCat_4 = (e) => {
        setIsToggleCheckedCat_4(e.target.checked);
    }
    return (
        <>
            {loading && <Loader />}
            <h1 className="xl:text-2xl md:text-lg text-xl mt-4 font-semibold">Add Category</h1>
            <div className="flex lg:flex-row flex-col lg:gap-9 md:gap-5 gap-3 mt-4 lg:m-5 md:m-2">
                <div className="lg:w-7/12 w-full bg-white rounded-lg md:p-6 p-3">
                    <div className="flex flex-row justify-between gap-5">
                        <h1 className='text-lg font-medium capitalize'>Select Category </h1>
                    </div>
                    <div className="flex flex-col md:flex-row gap-5">
                        <div className='w-2/4'>
                            <Dropdown
                                label=""
                                options={categoryoption}
                                search={true}
                                selectedValues={category}
                                onSelect={handleselectcategory}
                                onRemove={() => { }}
                                isMultiple={false}
                            />
                        </div>
                        <div>
                            <button className='text-sm bg-black text-nowrap  px-3 py-2.5 mt-2 h-fit rounded-lg text-white' onClick={() => openNewctg('ctg1')}>Add New </button>
                        </div>
                        {category !== 'Select Category' && (
                            <div className='mt-3'>
                                <label className="inline-flex items-center me-5 cursor-pointer">
                                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 px-3">Change Status</span>
                                    <input type="checkbox" className="sr-only peer"
                                        checked={isToggleCheckedCat_1}
                                        onChange={handleChangeCat_1}
                                    />
                                    <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600"></div>
                                </label>
                            </div>
                        )}
                    </div>
                    {category !== 'Select Category' && (
                        <div className="">
                            <div className="flex flex-row justify-between gap-5 mt-4">
                                <h1 className='text-lg font-medium capitalize'>select category under {category}</h1>
                            </div>
                            <div className="flex flex-col md:flex-row gap-5">
                                <div className='w-2/4'>
                                    <Dropdown
                                        label=""
                                        options={subcategoryoption}
                                        search={true}
                                        selectedValues={category_2}
                                        onSelect={handleselectsubcategory}
                                        onRemove={() => { }}
                                        isMultiple={false}
                                    />
                                </div>
                                <div>
                                    <button className='text-sm bg-black text-nowrap  px-3 py-2.5 mt-2 h-fit rounded-lg text-white' onClick={() => openNewctg('ctg2')}>Add New </button>
                                </div>
                                {category_2 !== 'Select Category' && (
                                    <div className='mt-3'>
                                        <label className="inline-flex items-center me-5 cursor-pointer">
                                            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 px-3">Change Status</span>

                                            <input type="checkbox" className="sr-only peer"
                                                checked={isToggleCheckedCat_2}
                                                onChange={handleChangeCat_2}
                                            />
                                            <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600"></div>
                                        </label>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {category_2 !== 'Select Category' && (
                        <div className="">
                            <div className="flex flex-row justify-between gap-5 mt-4">
                                <h1 className='text-lg font-medium capitalize'>select category under {category_2}</h1>
                            </div>
                            <div className="flex flex-col md:flex-row gap-5">
                                <div className='w-2/4'>
                                    <Dropdown
                                        label=""
                                        options={subcategoryoption3}
                                        search={true}
                                        selectedValues={category_3}
                                        onSelect={handleselectsubcategory3}
                                        onRemove={() => { }}
                                        isMultiple={false}
                                    />
                                </div>
                                <div>
                                    <button className='text-sm bg-black text-nowrap  px-3 py-2.5 mt-2 h-fit rounded-lg text-white' onClick={() => openNewctg('ctg3')}>Add New </button>
                                </div>
                                {category_3 !== 'Select Category' && (
                                    <div className='mt-3'>
                                        <label className="inline-flex items-center me-5 cursor-pointer">
                                            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 px-3">Change Status</span>
                                            <input type="checkbox" className="sr-only peer"
                                                checked={isToggleCheckedCat_3}
                                                onChange={handleChangeCat_3}
                                            />
                                            <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600"></div>
                                        </label>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {category_3 !== 'Select Category' && (
                        <div className="">
                            <div className="flex flex-row justify-between gap-5 mt-4">
                                <h1 className='text-lg font-medium capitalize'>select category under {category_3}</h1>
                            </div>
                            <div className="flex flex-col md:flex-row gap-5">
                                <div className='w-2/4'>
                                    <Dropdown
                                        label=""
                                        options={subcategoryoption4}
                                        selectedValues={category_4}
                                        search={true}
                                        onSelect={handleselectsubcategory4}
                                        onRemove={() => { }}
                                        isMultiple={false}
                                    />
                                </div>
                                <div>
                                    <button className='text-sm bg-black text-nowrap  px-3 py-2.5 mt-2 h-fit rounded-lg text-white' onClick={() => openNewctg('ctg4')}>Add New </button>
                                </div>
                                {category_4 !== 'Select Category' && (
                                    <div className="mt-3">
                                        <label className="inline-flex items-center me-5 cursor-pointer">
                                            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 px-3">Change Status</span>
                                            <input type="checkbox" className="sr-only peer"
                                                checked={isToggleCheckedCat_4}
                                                onChange={handleChangeCat_4}
                                            />
                                            <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600"></div>
                                        </label>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className='md:w-full lg:w-5/12 bg-white rounded-lg md:p-6 p-3'>
                    {openctg.ctg1 &&
                        <Categoryaddmodals closeNewctg={() => closeNewctg('ctg1')} ctg1={true} setRefresh={setRefresh} />
                    }
                    {openctg.ctg2 &&
                        <Categoryaddmodals closeNewctg={() => closeNewctg('ctg2')} ctg2={true} maincategory={main_ctg} setRefresh={setRefresh2} />
                    }
                    {openctg.ctg3 &&
                        <Categoryaddmodals closeNewctg={() => closeNewctg('ctg3')} ctg3={true} maincategory={main_ctg} subctg={category_2} setRefresh={setRefresh3} />
                    }
                    {openctg.ctg4 &&
                        <Categoryaddmodals closeNewctg={() => closeNewctg('ctg4')} ctg4={true} maincategory={main_ctg} subctg={category_2} subsubctg={category_3} setRefresh={setRefresh4} />
                    }
                </div>
            </div>
        </>
    )
}

export default Addcategory