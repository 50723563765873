import React, { useEffect, useRef, useState } from 'react'
import { MdDelete } from 'react-icons/md';
import { RxCross2 } from 'react-icons/rx'
import { Link } from 'react-router-dom';
import notify from '../../Component/utiles/Notification';
import { FaArrowRightLong } from "react-icons/fa6";
import { FileInput, Label } from "flowbite-react";
import { MdCloudDownload } from "react-icons/md";
import axios from 'axios';
import Loader from '../../Component/utiles/Loader';

const Categoryaddmodals = ({ closeNewctg, ctg1, ctg2, ctg3, ctg4, maincategory, subctg, subsubctg, setRefresh }) => {

    const baseURL = process.env.REACT_APP_BASEURL

    const [loading, setLoading] = useState()

    const [pic, setPic] = useState('')
    const [newCtg, setNewCtg] = useState({ category_name: '', home: 0, category_image: pic, description: '' });



    const postDetails = (pics) => {
        setLoading(true)
        if (pics === undefined) {
            notify('danger', 'Error', 'Please Select an Image')
            setLoading(false)
            return;
        }

        if (pics.type === 'image/jpeg' || pics.type === 'image/png') {
            const data = new FormData()
            data.append("file", pics)
            data.append("upload_preset", "testalas")
            data.append("cloud_name", "duw6f8fm8")
            fetch('https://api.cloudinary.com/v1_1/duw6f8fm8/image/upload', {
                method: "post",
                body: data
            }).then(res => res.json())
                .then(data => {
                    setPic(data.url.toString());
                    setNewCtg(prevState => ({
                        ...prevState,
                        category_image: data.url.toString()
                    }));
                    setLoading(false)
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                })
        } else {
            notify('error', 'Error', 'Please Select an Image')
            setLoading(false)
        }
    }


    

    const [subCategories, setSubCategories] = useState([{ title: '', status: true }]);


    const handlesubAddMore = () => {
        setSubCategories([...subCategories, { title: '', status: true }]);
    };

    const handlesubChange = (index, event) => {
        const { name, type, checked, value } = event.target;
        const updatedSubCategories = [...subCategories];
        if (type === 'checkbox') {
            updatedSubCategories[index][name] = checked;
        } else {
            updatedSubCategories[index][name] = value;
        }
        setSubCategories(updatedSubCategories);
    };

    const handlesubDelete = (index) => {
        const updatedSubCategories = subCategories.filter((_, i) => i !== index);
        setSubCategories(updatedSubCategories);
    };


    const [subsubCategories, setsubsubCategories] = useState([{
        title: '',
        status: true,
    }]);

    const handlesubsubAddMore = () => {
        setsubsubCategories([
            ...subsubCategories,
            {
                title: '',
                status: true,
            },
        ]);
    };

    const handlesubsubDelete = (indexToDelete) => {
        setsubsubCategories(subsubCategories.filter((_, index) => index !== indexToDelete));
    };

    const handlesubsubInputChange = (index, event) => {
        const { name, type, checked, value } = event.target;
        const updatedSubsubCategories = [...subsubCategories];
        if (type === 'checkbox') {
            updatedSubsubCategories[index][name] = checked;
        } else {
            updatedSubsubCategories[index][name] = value;
        }
        setsubsubCategories(updatedSubsubCategories);
    };


    const [subsubsubCategories, setsubsubsubCategories] = useState([{
        title: '',
        status: true,
    }]);

    const handlesubsubsubAddMore = () => {
        setsubsubsubCategories([
            ...subsubsubCategories,
            {
                title: '',
                status: true,
            },
        ]);
    };

    const handlesubsubsubDelete = (indexToDelete) => {
        setsubsubsubCategories(subsubsubCategories.filter((_, index) => index !== indexToDelete));
    };

    const handlesubsubsubInputChange = (index, event) => {
        const { name, type, checked, value } = event.target;
        const updatedSubsubsubCategories = [...subsubsubCategories];
        if (type === 'checkbox') {
            updatedSubsubsubCategories[index][name] = checked;
        } else {
            updatedSubsubsubCategories[index][name] = value;
        }
        setsubsubsubCategories(updatedSubsubsubCategories);
    };




    const getcategory1 = async () => {
        if (!newCtg.category_name) {
            notify('danger', 'Error', 'Please Enter Category Name');
            return;
        } else if (!newCtg.category_image) {
            notify('danger', 'Error', 'Please Select  Category Image');
            return;
        } else if (!newCtg.description) {
            notify('danger', 'Error', 'Please Enter Category Description');
            return;
        }

        try {
            const response = await axios.post(`${baseURL}/add_main_category`,
                {
                    category_name: newCtg.category_name,
                    home: newCtg.home,
                    category_image: newCtg.category_image,
                    description: newCtg.description
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
            if (response.data.status) {
                notify('success', 'Success', 'Category Added Successfully');
                setRefresh(prev => prev + 1);
            } else {
                notify('danger', 'Error', response.data.message);
            }

        } catch (err) {
            console.log(err)
        }

    }

    const getcategory2 = async () => {
        const hasEmptyTitle = subCategories.some(sub => sub.title.trim() === '');

        if (hasEmptyTitle) {
            notify('danger', 'Error', 'Please Enter Category Name');
            return;
        }

        try {
            const response = await axios.post(`${baseURL}/add_sub_categories`,
                {
                    category_id: maincategory[1],
                    sub_categories: subCategories
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
            if (response.data.status) {
                notify('success', 'Success', 'Category Added Successfully');
                setSubCategories([{ title: '', status: true }]);
                setRefresh(prev => prev + 1);
            } else {
                notify('danger', 'Error', response.data.message);
            }

        } catch (err) {
            console.log(err)
        }

    }
    const getcategory3 = async () => {
        if (subctg == 'Select Category') {
            notify('danger', 'Error', 'Please Select Sub Category');
            return;
        }
        try {
            const response = await axios.post(`${baseURL}/add_sub2_categories`,
                {
                    sub_category_1_id: maincategory[3],
                    sub_categories: subsubCategories
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
            if (response.data.status) {
                notify('success', 'Success', 'Category Added Successfully');
                setsubsubCategories([{ title: '', status: true }]);
                setRefresh(prev => prev + 1);
            } else {
                notify('danger', 'Error', response.data.message);
            }

        } catch (err) {
            console.log(err)
        }
        // const jsonString = JSON.stringify(subsubCategories, null, 2);
        // alert(jsonString);
    }
    const getcategory4 = async () => {
        if (subsubctg == 'Select Category') {
            notify('danger', 'Error', 'Please Select Sub Sub Category');
            return;
        }
        try {
            const response = await axios.post(`${baseURL}/add_sub3_categories`,
                {
                    sub_category_2_id: maincategory[5],
                    sub_categories: subsubsubCategories
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
            if (response.data.status) {
                notify('success', 'Success', 'Category Added Successfully');
                setsubsubsubCategories([{ title: '', status: true }]);
                setRefresh(prev => prev + 1);
            } else {
                notify('danger', 'Error', response.data.message);
            }

        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            {loading && <Loader />}
            <div className="md:w-full my-auto bg-white">
                <div className="flex flex-row place-items-center">
                    {maincategory && <h1 className='py-2 px-4 w-fit'><span className='text-lg font-semibold '>{maincategory[0]}</span></h1>}
                    {subctg && subctg !== 'Select Category' &&
                        <div className="flex flex-row place-items-center">
                            <FaArrowRightLong />
                            <h1 className='py-2 px-4'><span className='text-lg font-semibold'>{subctg}</span></h1>
                        </div>
                    }
                    {subsubctg && subsubctg !== 'Select Category' &&
                        <div className="flex flex-row place-items-center">
                            <FaArrowRightLong />
                            <h1 className='py-2 px-4'><span className='text-lg font-semibold'>{subsubctg}</span></h1>
                        </div>
                    }
                </div>
                {ctg1 &&
                    <div className="">
                        <div className="flex flex-row justify-between  place-items-center">
                            <div className="flex flex-row justify-between">
                                <span className="text-lg font-medium capitalize ">Category Name</span>
                            </div>
                        </div>
                        <div className="flex flex-row gap-3 justify-start place-items-center w-full">
                            <div className="w-full flex flex-row place-items-center gap-2">
                                <input
                                    className="w-full mt-2 mb-1 rounded-lg focus:ring-0 focus:border-black border-gray-300 px-2 py-2 border"
                                    placeholder="Category Name"
                                    name='category_name'
                                    value={newCtg.title}
                                    onChange={(e) => setNewCtg({ ...newCtg, category_name: e.target.value })}
                                />
                            </div>
                            <div className="flex flex-row gap-5 w-full">
                                <label className="inline-flex items-center me-5 cursor-pointer">
                                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 px-3 capitalize">home</span>
                                    <input type="checkbox" className="sr-only peer"
                                        checked={newCtg.home === 1}
                                        onChange={(e) => setNewCtg({ ...newCtg, home: e.target.checked ? 1 : 0 })}
                                    />
                                    <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600"></div>
                                </label>
                                {/*<label>
                                                Active
                                                <input
                                                    type="checkbox"
                                                    className='ms-2 focus:ring-0 checked:bg-black'
                                                    name="status"
                                                    checked={subCategory.status}
                                                    onChange={(e) => handlesubChange(index, e)}
                                                />
                                            </label>*/}
                            </div>
                        </div>
                        <div className='mt-2'>
                            <span className="text-lg font-medium">Description</span>
                            <textarea className='w-full rounded-lg focus:border-black focus:ring-0 border-gray-300 mt-1' name='description' value={newCtg.description}
                                onChange={(e) => setNewCtg({ ...newCtg, description: e.target.value })}
                                rows={10}></textarea>
                        </div>
                        <div className="flex flex-col  w-full">
                            <span className="text-lg font-medium mb-1">Upload Image</span>
                            <Label
                                htmlFor="dropzone-file"
                                className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                            >
                                <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                    <MdCloudDownload className='text-3xl text-gray-400' />
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                        <span className="font-semibold">Click to upload</span> or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                </div>
                                <FileInput id="dropzone-file" className="hidden" onChange={(e) => { postDetails(e.target.files[0]) }} />
                            </Label>
                        </div>
                    </div>
                }
                {ctg2 &&
                    <div className="p-5">
                        <div className="flex flex-row justify-between">
                            <div className="flex flex-row justify-between">
                                <span className="text-lg font-medium capitalize ">Add {subCategories.length > 1 ? 'categories' : 'category'} </span>
                            </div>
                            <button
                                className='text-sm text-nowrap bg-black text-white rounded-lg px-2 py-2 font-medium  mt-2' onClick={handlesubAddMore}>
                                Add More
                            </button>
                        </div>
                        {subCategories.map((subCategory, index) => (
                            <div className="grid grid-cols-2 gap-3 justify-center place-items-center w-full" key={index}>
                                <div className="w-full flex flex-row place-items-center gap-2">
                                    <span>{index + 1}.</span>
                                    <input
                                        className="w-full mt-2 mb-1 rounded-lg focus:ring-0 focus:border-black border-gray-300"
                                        placeholder="Enter Sub Category Title"
                                        type="text"
                                        name="title"
                                        value={subCategory.title}
                                        onChange={(e) => handlesubChange(index, e)}
                                    />
                                </div>
                                <div className="flex flex-row gap-5">
                                    {subCategories.length > 1 && (
                                        <div className="flex items-center justify-center">
                                            <button
                                                className='text-red-500 font-semibold text-xl'
                                                onClick={() => handlesubDelete(index)}>
                                                <MdDelete />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                }
                {ctg3 &&
                    <div className="p-5">
                        <div className="flex flex-row justify-between">
                            <div className="flex flex-row justify-between">
                                <span className="text-lg font-medium capitalize ">Add {subsubCategories.length > 1 ? 'categories' : 'category'} </span>
                            </div>
                            <button
                                className='text-sm text-nowrap bg-black text-white rounded-lg px-2 py-2 font-medium  mt-2'
                                onClick={handlesubsubAddMore}>
                                Add More
                            </button>
                        </div>
                        {subsubCategories.map((item, index) => (
                            <div key={item.id} className="grid grid-cols-2 gap-2 justify-center place-items-center w-full mt-4">
                                <div className="w-full flex flex-row place-items-center gap-2">
                                    <span>{index + 1}.</span>
                                    <input
                                        className="w-full mt-2 mb-1 rounded-lg focus:ring-0 focus:border-black border-gray-300"
                                        placeholder="Enter Sub Category Title"
                                        type="text"
                                        name="title"
                                        value={item.title}
                                        onChange={(e) => handlesubsubInputChange(index, e)}
                                    />
                                </div>
                                <div className="flex flex-row gap-5">
                                    {subsubCategories.length > 1 && (
                                        <div className="flex items-center justify-center">
                                            <button
                                                className="text-red-500 font-semibold text-xl"
                                                onClick={() => handlesubsubDelete(index)}
                                            >
                                                <MdDelete />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                }
                {ctg4 &&
                    <div className="p-5">
                        <div className="flex flex-row justify-between">
                            <div className="flex flex-row justify-between">
                                <span className="text-lg font-medium capitalize ">Add {subsubsubCategories.length > 1 ? 'categories' : 'category'} </span>
                            </div>
                            <button
                                className='text-sm text-nowrap bg-black text-white rounded-lg px-2 py-2 font-medium  mt-2'
                                onClick={handlesubsubsubAddMore}>
                                Add More
                            </button>
                        </div>
                        {subsubsubCategories.map((item, index) => (
                            <div key={item.id} className="grid grid-cols-2 gap-2 justify-center place-items-center w-full mt-4">
                                <div className="w-full flex flex-row place-items-center gap-2 mt-1">
                                    <span>{index + 1}.</span>
                                    <input
                                        className="w-full mt-2 mb-1 rounded-lg focus:ring-0 focus:border-black border-gray-300"
                                        placeholder="Sub Sub Sub Category Title"
                                        type="text"
                                        name="title"
                                        value={item.title}
                                        onChange={(e) => handlesubsubsubInputChange(index, e)}
                                    />
                                </div>
                                <div className="flex flex-row gap-5">
                                    {subsubsubCategories.length > 1 && (
                                        <div className="flex items-center justify-center">
                                            <button
                                                className="text-red-500 font-semibold text-xl"
                                                onClick={() => handlesubsubsubDelete(index)}
                                            >
                                                <MdDelete />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                }
                <div className="flex flex-row mt-4 place-items-center justify-end pe-4 gap-3 border-gray-300 ">
                    <button className='border border-black py-2 rounded-lg text-base px-4' onClick={closeNewctg}>Cancel</button>
                    <Link className='text-white w-fit flex justify-center    items-center bg-black px-5 text-base py-2 hover:text-black hover:bg-white hover:border border rounded-lg hover:border-black transition-all duration-200'
                        onClick={() => {
                            if (ctg1) {
                                getcategory1();
                            } else if (ctg2) {
                                getcategory2();
                            } else if (ctg3) {
                                getcategory3();
                            } else if (ctg4) {
                                getcategory4();
                            }
                        }}

                    >Add</Link>
                </div>
            </div>
        </>
    )
}

export default Categoryaddmodals