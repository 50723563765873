import React, { useEffect, useState } from 'react'
import { Modal, Tabs, Label, TextInput, Progress, Tooltip } from "flowbite-react";
import { TbListDetails } from 'react-icons/tb';
import { HiOutlineArrowSmDown } from 'react-icons/hi';
import { FaImages } from "react-icons/fa6";
import { GrMultiple } from "react-icons/gr";
import { FaClipboardUser } from "react-icons/fa6";
import axios from 'axios';
import { Link } from 'react-router-dom';


const Productviewmodal = ({ setLoading, type, openModal, setOpenModal, handleupdate, varientsdata, mainproduct, variants, existingImages }) => {

    const [allImages, setAllImages] = useState([]);
    const [totalStock, setTotalStock] = useState(0);
    const [userData, setUserData] = useState('');


    const baseUrl = process.env.REACT_APP_BASEURL


    useEffect(() => {
        if (type === 'product') {
            const productImages = existingImages.map(image => image.image_url);
            const variantImages = varientsdata.flatMap(variant =>
                variant.varient_images.map(image => image.images)
            );

            const images = [...productImages, ...variantImages];

            setAllImages(images);

            const stock = variants.flatMap(variant =>
                variant.sizes.map(size => Number(size.quantity))
            ).reduce((acc, curr) => acc + curr, 0);

            setTotalStock(stock);
        }
    }, [existingImages, varientsdata, variants, type]);


    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setLoading(true);
                const response = await axios.post(`${baseUrl}/user_data`,
                    { user_id: mainproduct.user_id },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
                setUserData(response.data.data[0]);

                // setProducts(response.data.products)
                setLoading(false)
            } catch (err) {
                console.log(err);
            }
        };

        fetchUserData();
    }, [allImages]);

    let path;
    if (userData.role == 'supplier') {
        path = '/admin/supplier/profile/'
    } else if (userData.role == 'influencer') {
        path = '/admin/infulancers/profile/'
    }


    // console.log(totalStock)

    return (
        <>
            <Modal show={openModal} size="7xl" onClose={() => setOpenModal(false)} >
                <Modal.Header>Product Details</Modal.Header>
                <Modal.Body>
                    {type === "product" &&
                        <Tabs aria-label="Tabs with icons" className="no-focus-ring" variant="underline">
                            <Tabs.Item active title="Product Details" icon={TbListDetails} className="no-focus-ring">
                                <div className="w-full space-y-5">
                                    <div className="grid grid-cols-3 gap-5 w-full h-[50vh] overflow-y-auto">
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold capitalize text-left'>Product Name </h1>
                                            <span className='text-base'>{mainproduct.product_name}</span>
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold capitalize text-left'>Product Sub Text </h1>
                                            <span className='text-base'>{mainproduct.sub_text}</span>
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold capitalize text-left'>Product </h1>
                                            <span className='text-base'>{mainproduct.sub_text}</span>
                                        </div>
                                        <div className="flex flex-col gap-2">
                                            <h1 className='text-base font-semibold capitalize text-left'>Categories</h1>
                                            <div className="flex flex-col mt-1">
                                                {mainproduct.category_name &&
                                                    <span className='text-base'>{mainproduct.category_name}</span>
                                                }
                                                {mainproduct.sub_category_1_name &&
                                                    <span className=''>
                                                        <HiOutlineArrowSmDown />
                                                    </span>
                                                }
                                                {mainproduct.sub_category_1_name &&
                                                    <span className='text-base'>{mainproduct.sub_category_1_name}</span>
                                                }
                                                {mainproduct.sub_category_2_name &&
                                                    <span className=''>
                                                        <HiOutlineArrowSmDown />
                                                    </span>
                                                }
                                                {mainproduct.sub_category_2_name &&
                                                    <span className='text-base'>{mainproduct.sub_category_2_name}</span>
                                                }
                                                {mainproduct.sub_category_3_name &&
                                                    <span className=''>
                                                        <HiOutlineArrowSmDown />
                                                    </span>
                                                }
                                                {mainproduct.sub_category_3_name &&
                                                    <span className='text-base'>{mainproduct.sub_category_3_name}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold capitalize text-left'>Discount</h1>
                                            <span className='text-base'>{mainproduct.discount} %</span>
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold capitalize text-left'>Influincer Percentage</h1>
                                            <span className='text-base'>{mainproduct.influincer_percentage ? mainproduct.influincer_percentage : '0'} %</span>
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold capitalize text-left'>Tax Percentage</h1>
                                            <span className='text-base'>{mainproduct.tax_percentage ? mainproduct.tax_percentage : '0'} %</span>
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold capitalize text-left'>Total Stock</h1>
                                            <span className='text-base'>{totalStock}</span>
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold capitalize text-left'>Stock Status</h1>
                                            <span className='text-base'>{mainproduct.stock_status ? mainproduct.stock_status : 'Hold'}</span>
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold  text-left'>Net Weight (gm)</h1>
                                            <span className='text-base'>{mainproduct.net_weight ? mainproduct.net_weight : '0'}</span>
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold  text-left'>Alas Approval Status</h1>
                                            <span className='text-base'>{mainproduct.status}</span>
                                        </div>
                                    </div>
                                </div>
                            </Tabs.Item>
                            <Tabs.Item active title="Product Variants" icon={GrMultiple} className="no-focus-ring">
                                <div className="w-full space-y-5">
                                    {variants.map((data, index) => (
                                        <div className="grid grid-cols-3" key={index}>
                                            <div className="flex flex-col gap-2 w-full">
                                                <h1 className='text-base font-semibold capitalize text-left'>Product Color</h1>
                                                <span className='text-base'>{data.color}</span>
                                            </div>
                                            <div className="flex flex-col gap-2 w-full">
                                                <h1 className='text-base font-semibold capitalize text-left'>Product Price</h1>
                                                <span className='text-base'>{data.price}</span>
                                            </div>
                                            <div className="flex flex-col gap-2 w-full">
                                                <h1 className='text-base font-semibold capitalize text-left'>Product Size & Quantity</h1>
                                                <span className='text-base'>
                                                    {data.sizes.map((list, i) => (
                                                        <div className="grid grid-cols-2 gap-2">
                                                            <span key={i}>
                                                                Size - {list.size}
                                                            </span>
                                                            <span key={i}>
                                                                Qty - {list.quantity}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Tabs.Item>
                            <Tabs.Item active title="Product images" icon={FaImages} className="no-focus-ring">
                                <div className="w-full space-y-5">
                                    <div className="grid grid-cols-3 h-[50vh] overflow-y-auto">
                                        {allImages.map((list, index) => (
                                            <a key={index} href={list} target="_blank" ><img src={list} alt={`Uploaded ${index}`} className="h-32  rounded-lg" /></a>
                                        ))}
                                    </div>
                                </div>
                            </Tabs.Item>
                            <Tabs.Item active title={`${userData.role} Profile`} icon={FaClipboardUser} className="no-focus-ring capitalize">
                                <div className="w-full space-y-5 flex flex-row gap-2 place-items-start">
                                    <div className="grid grid-cols-3 w-full gap-5">
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold capitalize text-left'>name</h1>
                                            <span className='text-base'>{userData.name}</span>
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold capitalize text-left'>Email</h1>
                                            <span className='text-base'>{userData.email}</span>
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold capitalize text-left'>Mobile No.</h1>
                                            <span className='text-base'>{userData.mobile}</span>
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold capitalize text-left'>Address</h1>
                                            <span className='text-base'>{userData.address} , {userData.pin_code}</span>
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold capitalize text-left'>country</h1>
                                            <span className='text-base'>{userData.country_name}</span>
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold capitalize text-left'>State</h1>
                                            <span className='text-base'>{userData.state_subdivision_name}</span>
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            <h1 className='text-base font-semibold capitalize text-left'>city</h1>
                                            <span className='text-base'>{userData.city}</span>
                                        </div>
                                    </div>
                                    <div className="w-fit">
                                        <Link to={path + mainproduct.user_id} className='underline text-blue-500 text-nowrap'>More Dateils...</Link>
                                    </div>
                                </div>
                            </Tabs.Item>
                        </Tabs>
                    }
                </Modal.Body>
                <Modal.Footer className='flex justify-end'>
                    {type === "product" &&
                        <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={(e) => handleupdate(mainproduct.product_id, mainproduct.status)}>
                            Update Status
                        </button>
                    }
                    <button type="button" onClick={() => setOpenModal(false)} className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Productviewmodal