import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Dropdown from '../../../Component/utiles/Dropdown';
import { Link } from 'react-router-dom';
import { IoSearchSharp } from 'react-icons/io5';
import Userprofile from '../../Component/Userprofile';
import Pagination from '../../../Component/utiles/Pagination';

const Supplierseller = ({ alluserdata, updatestatus }) => {

    const [searchTerm, setSearchTerm] = useState('');

    const supplierSeller = alluserdata.filter(list => list.role === 'supplier-seller');

    const perpageoption = [
       { label: '10' }, { label: '15' }, { label: '20' }
    ];

    const [itemsPerPage, setItemsPerPage] = useState(perpageoption[0].label);
    const [currentPage, setCurrentPage] = useState(1);

    const searchResults = supplierSeller.filter(user =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.unique_id.toString().toLowerCase().includes(searchTerm.toLowerCase())

    );

    const itemsToDisplay = searchTerm ? searchResults : supplierSeller;


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = itemsToDisplay.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(itemsToDisplay.length / itemsPerPage);

    const ctg = [
        { category_color_code: 'bg-cyan-300' },
        { category_color_code: 'bg-purple-300' },
        { category_color_code: 'bg-yellow-100' },
        { category_color_code: 'bg-red-300' },
        { category_color_code: 'bg-green-200' },
        { category_color_code: 'bg-amber-100' },
        { category_color_code: 'bg-blue-200' },
    ];


    return (
        <>
            <h1 className='xl:text-2xl md:text-lg text-xl mt-3 font-semibold capitalize'>sellers</h1>
            <div className='w-full bg-white rounded-lg  md:p-6 my-5 p-3'>
                <div className='flex flex-row place-items-center justify-between my-5  pe-0'>
                    <div className='flex flex-row items-center md:w-40 w-24  gap-2'>
                        <span className='text-nowrap mt-2 lg:block hidden text-sm capitalize'>items per page</span>
                        <div className='w-16'>
                            <Dropdown
                                label=""
                                options={perpageoption}
                                selectedValues={itemsPerPage}
                                onSelect={setItemsPerPage}
                                onRemove={() => { }}
                                isMultiple={false}
                            />
                        </div>
                    </div>
                    <div className="relative hidden md:flex md:w-6/12 ms-5">
                        <input
                            type="text"
                            placeholder="Search Here"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="bg-gray-200 w-full border-0 rounded-full px-4 py-2 pr-10 focus:outline-none focus:border-gray-400 focus:ring-2 focus:ring-gray-400"
                        />
                        <IoSearchSharp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-black cursor-pointer" />
                    </div>
                    <Link to='add-seller' className='text-white flex justify-center items-center font-semibold text-nowrap bg-black ms-5 md:px-5 md:text-base text-sm md:py-2 py-2 px-3  hover:text-black hover:bg-white hover:border border rounded-lg hover:border-black transition-all duration-200 capitalize'>add &nbsp;<span className='md:block hidden'>seller</span></Link>
                </div>
                <div className="grid xl:grid-cols-5 md:grid-cols-3 grid-cols-1 md:gap-6 gap-5 mt-9">
                    {currentItems.map((list, index) => (
                        <Userprofile user={list} key={index} updatestatus={updatestatus}  bgColor={ctg[index % ctg.length].category_color_code}/>
                    ))}
                </div>
                <div className="flex justify-between items-center mt-4">
                    <div>
                        <span>Page {currentPage} of {totalPages}</span>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </div>
        </>
    )
}

export default Supplierseller