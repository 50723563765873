import React, { useEffect, useState } from 'react'
import Dropdown from '../../Component/utiles/Dropdown';
import { IoSearchSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';
import Pagination from '../../Component/utiles/Pagination';
import axios from 'axios';
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiSolidEdit } from "react-icons/bi";
import { TbArrowsExchange2 } from 'react-icons/tb';

const Inquiries = () => {

  const baseUrl = process.env.REACT_APP_BASEURL

  const [searchTerm, setSearchTerm] = useState('');

  const customerRecords = [
    {
      id: 1,
      customerName: "John Doe",
      email: "johndoe@example.com",
      phoneNumber: "123-456-7890",
      message: "Inquiry about product availability.",
      date: "2024-08-10",
      replyBy: "Admin1",
      status: "successful",
    },
    {
      id: 2,
      customerName: "Jane Smith",
      email: "janesmith@example.com",
      phoneNumber: "987-654-3210",
      message: "Requesting a product quote.",
      date: "2024-08-11",
      replyBy: null,
      status: "pending",
    },
    {
      id: 3,
      customerName: "Michael Brown",
      email: "michaelbrown@example.com",
      phoneNumber: "555-123-4567",
      message: "Issue with recent order.",
      date: "2024-08-12",
      replyBy: "Admin3",
      status: "successful",
    },
    {
      id: 4,
      customerName: "Emily Davis",
      email: "emilydavis@example.com",
      phoneNumber: "444-987-6543",
      message: "Feedback on customer service.",
      date: "2024-08-13",
      replyBy: null,
      status: "pending",
    },
    {
      id: 5,
      customerName: "William Johnson",
      email: "williamjohnson@example.com",
      phoneNumber: "333-654-7891",
      message: "Query about product returns.",
      date: "2024-08-14",
      replyBy: "Admin2",
      status: "successful",
    },
    {
      id: 6,
      customerName: "Olivia Williams",
      email: "oliviawilliams@example.com",
      phoneNumber: "222-987-1234",
      message: "Compliment on product quality.",
      date: "2024-08-15",
      replyBy: null,
      status: "pending",
    },
    {
      id: 7,
      customerName: "James Miller",
      email: "jamesmiller@example.com",
      phoneNumber: "111-456-7892",
      message: "Request for bulk order discount.",
      date: "2024-08-16",
      replyBy: "Admin1",
      status: "successful",
    },
    {
      id: 8,
      customerName: "Sophia Martinez",
      email: "sophiamartinez@example.com",
      phoneNumber: "666-123-7894",
      message: "Problem with payment gateway.",
      date: "2024-08-17",
      replyBy: null,
      status: "pending",
    },
    {
      id: 9,
      customerName: "Liam Garcia",
      email: "liamgarcia@example.com",
      phoneNumber: "777-654-1238",
      message: "Request for order status update.",
      date: "2024-08-18",
      replyBy: "Admin3",
      status: "successful",
    },
    {
      id: 10,
      customerName: "Ava Rodriguez",
      email: "avarodriguez@example.com",
      phoneNumber: "888-789-4560",
      message: "Inquiry about new arrivals.",
      date: "2024-08-19",
      replyBy: null,
      status: "pending",
    },
    {
      id: 11,
      customerName: "Noah Lee",
      email: "noahlee@example.com",
      phoneNumber: "999-321-6547",
      message: "Request for shipping details.",
      date: "2024-08-20",
      replyBy: "Admin2",
      status: "successful",
    },
    {
      id: 12,
      customerName: "Isabella Perez",
      email: "isabellaperez@example.com",
      phoneNumber: "101-654-3219",
      message: "Complaint about product damage.",
      date: "2024-08-21",
      replyBy: null,
      status: "pending",
    },
    {
      id: 13,
      customerName: "Mason White",
      email: "masonwhite@example.com",
      phoneNumber: "202-789-6542",
      message: "Request for exchange policy.",
      date: "2024-08-22",
      replyBy: "Admin1",
      status: "successful",
    },
    {
      id: 14,
      customerName: "Charlotte Harris",
      email: "charlotteharris@example.com",
      phoneNumber: "303-456-9875",
      message: "Feedback on website usability.",
      date: "2024-08-23",
      replyBy: null,
      status: "pending",
    },
    {
      id: 15,
      customerName: "Elijah Walker",
      email: "elijahwalker@example.com",
      phoneNumber: "404-123-6547",
      message: "Request for a custom order.",
      date: "2024-08-24",
      replyBy: "Admin3",
      status: "successful",
    },
    {
      id: 16,
      customerName: "Mia Hall",
      email: "miahall@example.com",
      phoneNumber: "505-789-1236",
      message: "Issue with gift card redemption.",
      date: "2024-08-25",
      replyBy: null,
      status: "pending",
    },
    {
      id: 17,
      customerName: "Lucas Allen",
      email: "lucasallen@example.com",
      phoneNumber: "606-321-7898",
      message: "Query about shipping time.",
      date: "2024-08-26",
      replyBy: "Admin2",
      status: "successful",
    },
    {
      id: 18,
      customerName: "Amelia Young",
      email: "ameliayoung@example.com",
      phoneNumber: "707-456-1239",
      message: "Request for product customization.",
      date: "2024-08-27",
      replyBy: null,
      status: "pending",
    },
    {
      id: 19,
      customerName: "Ethan King",
      email: "ethanking@example.com",
      phoneNumber: "808-789-6541",
      message: "Complaint about late delivery.",
      date: "2024-08-28",
      replyBy: "Admin1",
      status: "successful",
    },
    {
      id: 20,
      customerName: "Harper Wright",
      email: "harperwright@example.com",
      phoneNumber: "909-123-7890",
      message: "Request for product replacement.",
      date: "2024-08-29",
      replyBy: null,
      status: "pending",
    }
  ];

  const [customerRecordss, setcustomerRecords] = useState([]);

  useEffect(() => {
    const allinquiry = async () => {
      try {
        const response = await axios.get(`${baseUrl}/getContactFormData`);
        if (response.data.status) {
          setcustomerRecords(response.data.data)
        }
      } catch (err) {
        console.log(err);
      }
    };
    allinquiry();
  }, []);

  const [selectedStatus, setSelectedStatus] = useState('all');

  const tabs = ['all', 'pending', 'close'];

  const handleTabClick = (status) => {
    setSelectedStatus(status);
    setCurrentPage(1); // Reset to first page when status changes
  };

  const filterByStatus = (purchase) => {
    if (selectedStatus === 'all') return true;
    return purchase.status === selectedStatus;
  };

  const filteredrecord = customerRecordss.filter(filterByStatus);


  const perpageoption = [
    { label: '5' }, { label: '10' }, { label: '15' }, { label: '20' }
  ];

  const [itemsPerPage, setItemsPerPage] = useState(perpageoption[0].label);
  const [currentPage, setCurrentPage] = useState(1);



  const searchResults = filteredrecord.filter(customersdata => {
    const fullName = (customersdata.first_name + ' ' + customersdata.last_name).toLowerCase();
    const searchTermLower = searchTerm.toLowerCase();


    return fullName.includes(searchTermLower) ||
      customersdata.contact_form_id.toString().toLowerCase().includes(searchTermLower);
  });

  const data = searchTerm ? searchResults : filteredrecord

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  useEffect(() => {
    if (searchTerm) {
      setCurrentPage(1);
    }
  }, [searchTerm]);

  const getStatusClass = (stockStatus) => {
    switch (stockStatus) {
      case 'successful':
        return 'bg-green-200 text-green-600 text-xs';
      case 'pending':
        return 'bg-yellow-200 text-yellow-500 text-xs';
      default:
        return 'bg-gray-400 text-white text-xs';
    }
  }

  const handleupdate = (customerId) => {

  };

  return (
    <>
      <h1 className="xl:text-2xl md:text-lg text-xl mt-4 font-semibold">Inquiries</h1>
      <div className='w-full bg-white rounded-lg mt-5 md:p-6 p-3'>
        <div className="relative flex md:hidden w-full ">
          <input
            type="text"
            placeholder="Search Here"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="bg-gray-200 w-full border-0 rounded-full px-4 py-2 pr-10 focus:outline-none focus:border-gray-400 focus:ring-2 focus:ring-gray-400"
          />
          <IoSearchSharp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-black cursor-pointer" />
        </div>
        <div className='flex flex-row place-items-center justify-between my-5  pe-0'>
          <div className='flex flex-row items-center md:w-40 w-24  gap-2'>
            <span className='text-nowrap mt-2 lg:block hidden text-sm capitalize'>items per page</span>
            <div className='w-16'>
              <Dropdown
                label=""
                options={perpageoption}
                selectedValues={itemsPerPage}
                onSelect={setItemsPerPage}
                onRemove={() => { }}
                isMultiple={false}
              />
            </div>
          </div>
          <div className="relative hidden md:flex md:w-6/12 ms-5">
            <input
              type="text"
              placeholder="Search Here"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="bg-gray-200 w-full border-0 rounded-full px-4 py-2 pr-10 focus:outline-none focus:border-gray-400 focus:ring-2 focus:ring-gray-400"
            />
            <IoSearchSharp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-black cursor-pointer" />
          </div>
        </div>
        <div className="flex justify-start mt-5 overflow-x-auto h-10 overflow-hidden pb-3">
          <div className="tabs tabs-boxed">
            {tabs.map((status) => (
              <a
                key={status}
                className={`px-4 py-2 transition-all duration-200 ease-in-out font-medium cursor-pointer ${selectedStatus === status ? 'border-b capitalize text-lg border-black text-black' : 'text-gray-900 text-sm'}`}
                onClick={() => handleTabClick(status)}
              >
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </a>
            ))}
          </div>
        </div>
        <div className='overflow-hidden overflow-x-auto mt-6'>
          <table className="lg:w-full md-w[100%]  table bg-white">
            <thead className="bg-gray-100 rounded-full">
              <tr>
                <th className="py-4 px-4 text-left capitalize">ID</th>
                <th className="py-4 px-4 text-left capitalize">customer name</th>
                <th className="py-4 px-4 text-left">Email ID</th>
                <th className="py-4 px-4 text-left capitalize text-nowrap">Phone Number</th>
                <th className="py-4 px-4 text-left capitalize">message</th>
                <th className="py-4 px-4 text-left capitalize">Inquiry Date</th>
                <th className="py-4 px-4 text-left capitalize">Reply Date</th>
                <th className="py-4 px-4 text-left capitalize">reply by</th>
                <th className="py-4 px-4 text-left capitalize">Status</th>
                <th className="py-4 px-4 text-left capitalize">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map(item => (
                <tr key={item.id} className="border-b border-gray-200 hover:bg-gray-200 transition-all duration-200 ease-in-out">
                  <td className="py-4 px-4 text-nowrap">{item.contact_form_id}</td>
                  <td className="py-4 px-4 ">
                    <span className='text-nowrap pe-10 truncate md:pe-20 lg:pe-0 text-sm'>{item.first_name}&nbsp;{item.last_name}</span>
                  </td>
                  <td className="py-4 px-4 text-nowrap">{item.email}</td>
                  <td className="py-4 px-4 text-nowrap">{item.mobile}</td>
                  <td className="py-4 px-4">
                    <div className="flex items-center">
                      <span className="">{item.message}</span>
                    </div>
                  </td>
                  <td className="py-4 px-4 text-nowrap">{item.get_date}</td>
                  <td className="py-4 px-4 text-nowrap">{item.replay_date}</td>
                  <td className="py-4 px-4 text-nowrap">{item.replyBy ? item.replyBy : '-------'}</td>
                  <td className="py-4 px-4 text-nowrap">
                    <span className={`px-2 py-1  rounded-lg capitalize font-semibold ${getStatusClass(item.status)}`}>
                      {item.status}
                    </span>
                  </td>
                  <td className="py-4 px-4 ">
                    <div className="flex flex-row gap-3 text-black text-xl">
                      <span className='cursor-pointer bg-neutral-300  rounded-lg p-1.5' onClick={() => handleupdate(item.contact_form_id)}><TbArrowsExchange2 /></span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between items-center mt-4">
          <div>
            <span>Page {currentPage} of {totalPages}</span>
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
    </>
  )
}

export default Inquiries