import React, { useEffect, useRef, useState } from 'react'
import { ImageUpload } from '../../Component/utiles/ImageUpload';
import { FileInput, Label } from "flowbite-react";
import Dropdown from '../../Component/utiles/Dropdown';
import { AiOutlineDelete } from 'react-icons/ai';
import { MdCloudDownload } from "react-icons/md";
import { RxCross2 } from 'react-icons/rx'
import notify from '../../Component/utiles/Notification';
import axios from 'axios';
import { FiEdit } from 'react-icons/fi';


const Addeditcoupan = ({ closeaddcoupan, setLoading, setRefresh, onedata }) => {

    const baseUrl = process.env.REACT_APP_BASEURL

    const [data, setData] = useState({
        coupon_id: '', coupon_name: '', coupon_image_url: '', coupon_code: '', amount: '', status: 'Inactive', start_date: '', expire_date: '', cart_min_amount: ''
    });

    useEffect(() => {
        if (onedata) {
            setData({
                coupon_id: onedata.coupon_id,
                coupon_name: onedata.coupon_name,
                coupon_image_url: onedata.coupon_image,
                coupon_code: onedata.coupon_code,
                amount: onedata.amount,
                status: onedata.status,
                start_date: onedata.start_date,
                expire_date: onedata.expire_date,
                cart_min_amount: onedata.cart_min_amount
            });
        }
    }, [onedata])


    const multiple = false;
    const { images, handleFileChange, handleRemoveImage } = ImageUpload(multiple, setLoading);

    useEffect(() => {
        if (images.length > 0) {
            setData((prev) => ({
                ...prev,
                coupon_image_url: images[0].url
            }))
        }
    }, [images])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleCheckboxChange = (isChecked) => {
        const newStatus = isChecked ? 'Active' : 'Inactive';
        setData((prev) => ({ ...prev, status: newStatus }));
    };

    const removeimg = (index) => {
        handleRemoveImage(index)
        setData((prev) => ({ ...prev, coupon_image_url: '' }));
    }

    const fileInputRef = useRef(null);

    const openimage = () => {
        fileInputRef.current.click();
    }


    const Addcoupan = async () => {
        if (!data.coupon_image_url) {
            notify('danger', 'Error', 'Coupon Image is required');
            return true;
        }
        if (!data.coupon_name) {
            notify('danger', 'Error', 'Coupon name is required');
            return true;
        }
        if (!data.coupon_code) {
            notify('danger', 'Error', 'Coupon code is required');
            return true;
        }
        if (!data.cart_min_amount) {
            notify('danger', 'Error', 'Minimum amount is required');
            return true;
        }
        if (!data.amount) {
            notify('danger', 'Error', 'Amount is required');
            return true;
        }
        if (!data.start_date) {
            notify('danger', 'Error', 'Start date is required');
            return true;
        }
        if (!data.expire_date) {
            notify('danger', 'Error', 'End date is required');
            return true;
        }
        if (data.start_date && data.expire_date && new Date(data.start_date) >= new Date(data.expire_date)) {
            notify('danger', 'Error', 'End date must be after start date');
            return true;
        }

        setLoading(true);
        if (onedata) {
            try {
                const response = await axios.post(`${baseUrl}/update_coupon`, {
                    ...data
                });
                if (response.data.status) {
                    notify('success', 'Success', `Coupon Update Successfully`);
                    setRefresh((prev) => prev + 1);
                    closeaddcoupan();
                }
            } catch (err) {
                console.log(err)
            }

        } else {
            try {
                const response = await axios.post(`${baseUrl}/add_coupon`, {
                    ...data
                });
                if (response.data.status) {
                    notify('success', 'Success', `Coupon Added Successfully`);
                    setRefresh((prev) => prev + 1);
                    closeaddcoupan();
                }
            } catch (err) {
                console.log(err)
            }
        }
        setLoading(false);
    }

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-70">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden md:flex flex-col md:w-2/3 w-3/4" data-aos="fade-top" data-aos-duration="1000">
                <div className="md:w-full my-auto overflow-y-auto md:h-full h-[70vh]">
                    <div className="flex flex-row place-items-center justify-between sticky top-0 border-b bg-white border-gray-300 h-20 px-4">
                        <h1 className='text-xl font-semibold'>{onedata ? 'Update' : 'Add'} Coupon</h1>
                        <div className='text-lg'>
                            <RxCross2 className='cursor-pointer ' onClick={closeaddcoupan} />
                        </div>
                    </div>
                    {onedata ? (
                        <div className="flex justify-center place-items-start mt-2">
                            <img className='h-44 w-44' src={data.coupon_image_url} alt='my-image' />

                            <input
                                type="file"
                                ref={fileInputRef}
                                accept="image/*"
                                className="hidden"
                                onChange={handleFileChange}
                            />
                            <button className='text-white bottom-0 bg-black  rounded-lg p-2 text-base' onClick={openimage}>
                                <FiEdit />
                            </button>
                        </div>
                    ) : (
                        <div className='flex items-center flex-col p-5'>
                            <Label
                                htmlFor="dropzone-file"
                                className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                            >
                                <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                    <MdCloudDownload className='text-3xl text-gray-400' />
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                        <span className="font-semibold">Click to upload</span> or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                </div>
                                <FileInput id="dropzone-file" className="hidden" multiple={multiple} onChange={handleFileChange} />
                            </Label>
                            <div className='images mt-2 flex flex-row items-start w-full flex-wrap gap-2'>
                                <div className='mt-2 flex flex-wrap gap-2'>
                                    {images.map((image, index) => (
                                        <div key={index} className='relative'>
                                            <img src={image.url} alt={`Uploaded ${index}`} className="h-32  rounded-lg" />
                                            <button
                                                type="button"
                                                onClick={() => removeimg(index)}
                                                className="absolute top-2 right-2 bg-red-500 text-white rounded-full text-xl p-1"
                                            >
                                                <AiOutlineDelete />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-5 p-5">
                        <div className="flex flex-col">
                            <span className='text-base font-medium'>Name</span>
                            <input
                                name='coupon_name'
                                className='w-full py-2 mt-3 rounded-md focus:ring-0 border-gray-300 focus:border-black'
                                type='text'
                                placeholder='Enter Name'
                                value={data.coupon_name}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='flex w-full md:flex-row flex-col gap-5'>
                            <div className='w-full'>
                                <span className='text-base font-medium '>Discount Amount $</span>
                                <input
                                    name='amount'
                                    className='w-full py-2 mt-3 mb-5 rounded-md focus:ring-0 border-gray-300 focus:border-black no-arrows'
                                    type='number'
                                    placeholder='Enter Minimum Amount'
                                    value={data.amount}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='w-full'>
                                <span className='text-base font-medium '>Reuired Amount $</span>
                                <input
                                    name='cart_min_amount'
                                    className='w-full py-2 mt-3 mb-5 rounded-md focus:ring-0 border-gray-300 focus:border-black no-arrows'
                                    type='number'
                                    placeholder='Enter Minimum Amount'
                                    value={data.cart_min_amount}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className='flex w-full md:flex-row flex-col gap-5'>
                            <div className="flex flex-col">
                                <span className='text-base font-medium '>Code</span>
                                <input
                                    name='coupon_code'
                                    className='w-full py-2 mt-3 mb-5 rounded-md focus:ring-0 border-gray-300 focus:border-black'
                                    type='text'
                                    placeholder='Enter Coupon Code'
                                    value={data.coupon_code}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="flex flex-col">
                                <span className="text-base font-medium">status</span>
                                <label className="inline-flex items-center cursor-pointer text-nowrap mt-4">
                                    <span className=" text-sm font-medium text-gray-900 dark:text-gray-300 pe-3">{data.status}</span>
                                    <input type="checkbox" className="sr-only peer"
                                        checked={data.status === 'Active'}
                                        onChange={(e) => handleCheckboxChange(e.target.checked)}
                                    />
                                    <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600"></div>
                                </label>
                            </div>
                        </div>
                        <div className='flex w-full md:flex-row flex-col gap-5'>
                            <div className='w-full'>
                                <span className='text-base font-medium'>Start Date</span>
                                <input
                                    name='start_date'
                                    className='w-full py-2 mt-3 rounded-md focus:ring-0 border-gray-300 focus:border-black'
                                    type='date'
                                    value={data.start_date}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='w-full'>
                                <span className='text-base font-medium'>End Date</span>
                                <input
                                    name='expire_date'
                                    className='w-full py-2 mt-3 rounded-md focus:ring-0 border-gray-300 focus:border-black'
                                    type='date'
                                    value={data.expire_date}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </div >
                <div className="flex flex-row h-20 place-items-center justify-end pe-4 gap-3 border-t border-gray-300 ">
                    <button className='border border-black py-2 rounded-lg text-base px-4' onClick={closeaddcoupan}>Cancel</button>
                    <button className='text-white bg-black px-5 text-base py-2 hover:text-black hover:bg-white hover:border border rounded-lg hover:border-black transition-all duration-200' onClick={Addcoupan}> {onedata ? 'Update' : 'Add'} Coupon</button>
                </div>
            </div >
        </div >
    )
}

export default Addeditcoupan