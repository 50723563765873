import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import Wing from "../Assets/Images/pngwing_shose.png";
import Wing2 from "../Assets/Images/product1png.png";
import { IoShareSocial } from "react-icons/io5";
import { FaRegHeart } from "react-icons/fa";
import Similar_product from "./Similar_product";
import { Modal } from "flowbite-react";
import { Link, useParams } from "react-router-dom";
import Navbar from '../LandingPage/Navbar';
import Footer from '../LandingPage/Footer';
import { IoIosArrowUp } from "react-icons/io";
import Dropdown from "../utiles/Dropdown";
import reviewImg from '../Assets/Images/rating2.png';
import { FaStar, FaRegStar, FaStarHalfAlt } from 'react-icons/fa';
import { FaRegThumbsUp } from "react-icons/fa6";
import specialoffers from "../Assets/Images/specialoffer_review.png";
import { type } from "@testing-library/user-event/dist/type";
import axios from "axios";
import { UserState } from "../../Context/userProvider";
import notify from "../utiles/Notification";
import { useCart } from '../../Context/CartContext';
import { FaCamera } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Swal from 'sweetalert2'
import FormattedDate from '../utiles/FormatedDate';
import { FaHeart, FaShare, FaTruck, FaUndo, FaShieldAlt, FaCheck } from 'react-icons/fa';






const Product_Page = ({ toggleModal, setLoading }) => {

    const { id } = useParams();
    const baseUrl = process.env.REACT_APP_BASEURL;
    const { updateCartCount, cart, setCart } = useCart();
    const [products, setProduct] = useState({});
    const [variants, setVariants] = useState([]);
    const [selectedVariant, setSelectedVariant] = useState(null);
    const [selectedSize, setSelectedSize] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const { user, logout, countryCode } = UserState();
    const [selected, setSelected] = useState({
        color: '', size: '', variant_id: '', product_id: '', size_id: ''
    })
    const [newReview, setNewReview] = useState({
        rating: 5,
        comment: '',
        images: []
    })
    const [previewUrls, setPreviewUrls] = useState([]);
    const [addinfo, setAddinfo] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [review, setReview] = useState([])
    const fetchProducts = async () => {
        setLoading(true)
        try {
            const response = await axios.post(`${baseUrl}/get_one_product`, {
                product_id: id,
                countryCode: countryCode
            });
            if (response.data.status) {
                setProduct(response.data.products);
                setVariants(response.data.variants)
                setAddinfo(response.data.about || [])
                setSelectedVariant(null)
                setSelectedSize(null)
                setReview(response.data.reviews || [])
            }
            setLoading(false)
        } catch (err) {
            console.log(err);
            setLoading(false)
        }
    };


    useEffect(() => {
        fetchProducts();
    }, [id, countryCode]);


    useEffect(() => {
        const setvariant = async () => {
            if (variants && variants.length > 0 && variants[0].sizeStock.length > 0) {
                setSelectedVariant(variants[0]);
                setSelectedSize(variants[0].sizeStock[0].size_name);
                setSelected({
                    color: variants[0].colour,
                    size: variants[0].sizeStock[0].size_name,
                    variant_id: variants[0].product_varient_id,
                    product_id: products.product_id,
                    size_id: variants[0].sizeStock[0].size_id
                });
            }
        };

        setvariant();
    }, [variants]);

    const numericId = Number(id);




    const handleColorSelect = (variant) => {
        setSelected({
            size: variant.sizeStock[0].size,
            size_id: variant.sizeStock[0].size_id,
            color: variant.colour,
            variant_id: variant.product_varient_id,
            product_id: products.product_id,
        });
        setSelectedVariant(variant);
        setSelectedSize(variant.sizeStock[0].size);
        setCurrentSlide(0);
    };

    const sizeselect = (a, b) => {
        setSelectedSize(a);
        setSelected((prev) => ({
            ...prev,
            size: a,
            size_id: b
        }));

    }

    const getAllUniqueSizes = () => {
        const allSizes = [];
        const sizeSet = new Set();

        variants.forEach((variant) => {
            variant.sizeStock.forEach((sizeObj) => {
                if (!sizeSet.has(sizeObj.size_name) && sizeObj.stock && parseInt(sizeObj.stock) > 0) {
                    sizeSet.add(sizeObj.size_name);
                    allSizes.push(sizeObj.size_name);
                }
            });
        });

        return allSizes;
    };


    const getSizesForSelectedVariant = () => {
        if (!selectedVariant) return getAllUniqueSizes();

        return selectedVariant.sizeStock
            .filter((sizeObj) => sizeObj.stock && parseInt(sizeObj.stock) > 0)
            .map((sizeObj) => ({
                size: sizeObj.size_name,
                size_id: sizeObj.size_id
            }));
    };

    const getAllImages = () => {
        return variants.reduce((allImages, variant) => {
            return [...allImages, ...variant.images];
        }, []);
    };


    const getImagesForSelectedVariant = () => {
        if (selectedVariant) {
            return selectedVariant.images;
        }
        return getAllImages();
    };

    const availableSizes = getSizesForSelectedVariant();
    const imagesToDisplay = getImagesForSelectedVariant();

    const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
    const [isZoomed, setIsZoomed] = useState(false);
    const [startX, setStartX] = useState(null);

    const [currentSlide, setCurrentSlide] = useState(0);
    const slidesContainerRef = useRef(null);
    const [imageKey, setImageKey] = useState(0);
    const totalSlides = imagesToDisplay.length;
    const mainImageRef = useRef(null);



    const goToSlide = (index) => {
        setCurrentSlide(index);
        setImageKey((prevKey) => prevKey + 1);
        if (slidesContainerRef.current) {
            slidesContainerRef.current.children[index].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    };

    const goToPrevSlide = () => {
        const prevSlide = (currentSlide - 1 + totalSlides) % totalSlides;
        goToSlide(prevSlide);
    };

    const goToNextSlide = () => {
        const nextSlide = (currentSlide + 1) % totalSlides;
        goToSlide(nextSlide);
    };

    const [activeTab, setActiveTab] = useState("description");

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const [ratingStats, setRatingStats] = useState({
        totalRatings: 0,
        averageRating: 0,
        totalRatingValue: 0,
        ratingCounts: [0, 0, 0, 0, 0],
    });


    useEffect(() => {
        if (!review || review.length === 0) {
            setRatingStats({
                totalRatings: 0,
                totalRatingValue: 0,
                averageRating: 0,
                ratingCounts: [0, 0, 0, 0, 0],
            });
        } else {
            const totalRatings = review.length;
            const totalRatingValue = review.reduce((sum, review) => sum + Number(review.rating), 0);
            const averageRating = totalRatingValue / totalRatings;

            const ratingCounts = [0, 0, 0, 0, 0];
            review.forEach(review => {
                const roundedRating = Math.floor(Number(review.rating));
                ratingCounts[roundedRating - 1] += 1;
            });

            setRatingStats({
                totalRatings,
                totalRatingValue,
                averageRating,
                ratingCounts,
            });
        }
    }, [review]);




    const renderStars = (rating) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= rating) {
                stars.push(<FaStar key={i} className="text-yellow-400" />);
            } else if (i === Math.ceil(rating) && !Number.isInteger(rating)) {
                stars.push(<FaStarHalfAlt key={i} className="text-yellow-400" />);
            } else {
                stars.push(<FaRegStar key={i} className="text-yellow-400" />);
            }
        }
        return stars;
    };

    const [openReplies, setOpenReplies] = useState({});

    const toggleReplies = (index) => {
        setOpenReplies(prevState => ({ ...prevState, [index]: !prevState[index] }));
    };

    const tabs = [{ name: 'description' }, { name: 'reviews' }];

    const reviewoption = [
        { label: 'newest' },
        { label: '1 day ago' },
    ];

    const [reviewvalue, setreviewvalue] = useState(reviewoption[0].label);


    const handleTouchStart = (e) => {
        setStartX(e.touches[0].clientX);
    };

    const handleTouchMove = (e) => {
        if (startX !== null) {
            const currentX = e.touches[0].clientX;
            const diffX = startX - currentX;

            if (diffX > 50) {
                goToNextSlide();
                setStartX(null);
            } else if (diffX < -50) {
                goToPrevSlide();
                setStartX(null);
            }
        }
    };

    const handleMouseDown = (e) => {
        setStartX(e.clientX);
    };

    const handleMouseMove = (e) => {
        if (startX !== null) {
            const currentX = e.clientX;
            const diffX = startX - currentX;

            if (diffX > 50) {
                goToNextSlide();
                setStartX(null);
            } else if (diffX < -50) {
                goToPrevSlide();
                setStartX(null);
            }
        }
    };

    const handleMouseUp = () => {
        setStartX(null);
    };

    useEffect(() => {
        const mainImageElement = mainImageRef.current;
        if (mainImageElement) {
            mainImageElement.addEventListener('touchstart', handleTouchStart);
            mainImageElement.addEventListener('touchmove', handleTouchMove);
            mainImageElement.addEventListener('mousedown', handleMouseDown);
            mainImageElement.addEventListener('mousemove', handleMouseMove);
            mainImageElement.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            if (mainImageElement) {
                mainImageElement.removeEventListener('touchstart', handleTouchStart);
                mainImageElement.removeEventListener('touchmove', handleTouchMove);
                mainImageElement.removeEventListener('mousedown', handleMouseDown);
                mainImageElement.removeEventListener('mousemove', handleMouseMove);
                mainImageElement.removeEventListener('mouseup', handleMouseUp);
            }
        };
    }, [startX]);


    const handleMouseEnter = () => {
        setIsZoomed(true);
    };

    const handleMouseLeave = () => {
        setIsZoomed(false);
    };

    const handleMouseMoveZoom = (e) => {
        const rect = mainImageRef.current.getBoundingClientRect();
        const x = ((e.clientX - rect.left) / rect.width) * 100;
        const y = ((e.clientY - rect.top) / rect.height) * 100;
        setZoomPosition({ x, y });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getPriceAndAmount = () => {
        if (selectedVariant) {
            return {
                totalAmount: selectedVariant.total_amount,
                price: selectedVariant.price,
                symbol: selectedVariant.symbol
            };
        } else {
            const firstVariant = variants[0] || {};
            return {
                totalAmount: firstVariant.total_amount,
                price: firstVariant.price,
                symbol: firstVariant.symbol
            };
        }
    };

    const { totalAmount, price, symbol } = getPriceAndAmount();

    const AddToCart = async (product, variants) => {
        setLoading(true);

        if (!user) {
            if (cart && cart.length > 0) {
                const isProductInCart = cart.some(item => item.product_id === product.product_id);

                if (isProductInCart) {
                    notify('success', 'Success', `Product Already In Cart`);
                    setLoading(false);
                    return;
                };
            }
            setCart((prev) => [
                ...(Array.isArray(prev) ? prev : []),
                {
                    product_id: product.product_id,
                    product_name: product.product_name,
                    added_quantity: 1,
                    product_varient_id: variants,
                    price: selectedVariant.price,
                    images: selectedVariant.images[0],
                }
            ]);
            notify('success', 'Success', `Product Added to Cart`);
            setLoading(false);
            return
        }

        try {
            const response = await axios.post(`${baseUrl}/add_cart`, {
                product_id: product.product_id,
                customer_id: user.customer_id,
                product_varient_id: variants.variant_id,
                size_id: variants.size_id,
            });

            if (response.data.status) {
                notify('success', 'Success', `${response.data.message}`);
                updateCartCount(response.data.cartCount);
            } else {
                notify('danger', 'Error', `${response.data.message}`);

            }
        } catch (error) {
            console.error('Error during status update:', error);
            notify('danger', 'Error', 'There was an error. Please try again.');

        }

        setLoading(false);
    }

    const handleOpeneModal = async () => {
        setLoading(true);
        if (!user) {
            notify('danger', 'Error', 'Please login to add review');
            setLoading(false);
            return
        }
        try {
            const response = await axios.post(`${baseUrl}/customer_add_review`, {
                customer_id: user.customer_id,
                product_id: id
            });

            if (response.data.status) {
                previewUrls.forEach(url => URL.revokeObjectURL(url));

                if (response.data.review_data) {
                    const { review, images } = response.data.review_data;
                    const imgURLs = images.map(img => img.img_video)
                    setNewReview({
                        rating: review.rating,
                        comment: review.comment,
                        images: imgURLs
                    });
                    setPreviewUrls(imgURLs);
                } else {
                    setNewReview({
                        rating: 5,
                        comment: '',
                        images: []
                    });
                    setPreviewUrls([]);
                }

                setOpenModal(true);
                setLoading(false);
            } else {
                notify('danger', 'Error', `${response.data.message}`);
                setLoading(false)
            }
        } catch (error) {
            console.error('Error during status update:', error);
            notify('danger', 'Error', 'There was an error. Please try again.');
            setLoading(false)
        }
    };




    const handleImageChange = async (e) => {
        const files = Array.from(e.target.files);
        const validFiles = files.filter(file =>
            file.type === 'image/jpeg' ||
            file.type === 'image/jpg' ||
            file.type === 'image/png'
        );

        if (validFiles.length > 0) {
            setIsUploading(true);

            try {
                const newPreviewUrls = validFiles.map(file => URL.createObjectURL(file));
                setPreviewUrls(prev => [...prev, ...newPreviewUrls]);

                const uploadPromises = validFiles.map(async (file) => {
                    const data = new FormData();
                    data.append("file", file);
                    data.append("upload_preset", "testalas");
                    data.append("cloud_name", "duw6f8fm8");

                    const res = await fetch('https://api.cloudinary.com/v1_1/duw6f8fm8/image/upload', {
                        method: 'POST',
                        body: data
                    });

                    if (!res.ok) throw new Error('Upload failed');

                    const result = await res.json();
                    return result.url;
                });

                const cloudinaryUrls = await Promise.all(uploadPromises);

                const successfulUrls = cloudinaryUrls.filter(url => url !== null);

                setNewReview(prev => ({
                    ...prev,
                    images: [...prev.images, ...successfulUrls]
                }));

            } catch (error) {
                console.error('Upload Error:', error);
                alert('Failed to upload one or more images. Please try again.');
            } finally {
                setIsUploading(false);
            }
        }
    };

    const removeImage = (index) => {
        URL.revokeObjectURL(previewUrls[index]);
        setPreviewUrls(prev => prev.filter((_, i) => i !== index));

        setNewReview(prev => ({
            ...prev,
            images: prev.images.filter((_, i) => i !== index)
        }));
    };


    const handelAddReview = async () => {
        setLoading(true);
        if (!newReview.comment) {
            notify('danger', 'Error', "Description cannot be empty");
            setLoading(false);
            return;
        }
        try {
            const response = await axios.post(`${baseUrl}/customer_add_review_data`, {
                customer_id: user.customer_id,
                product_id: id,
                review: newReview
            });

            if (response.data.status) {
                await fetchProducts()
                setOpenModal(false);
                setLoading(false);
                notify('success', 'Success', `${response.data.message}`);
            } else {
                notify('danger', 'Error', `${response.data.message}`);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error during status update:', error);
            notify('danger', 'Error', 'There was an error. Please try again.')
            setLoading(false);
        }
    }

    const [modalState, setModalState] = useState({
        openReviewId: null,
        currentImageIndex: 0
    });

    const handlePrevious = (images) => {
        setModalState(prev => ({
            ...prev,
            currentImageIndex: prev.currentImageIndex === 0 ? images.length - 1 : prev.currentImageIndex - 1
        }));
    };

    const handleNext = (images) => {
        setModalState(prev => ({
            ...prev,
            currentImageIndex: prev.currentImageIndex === images.length - 1 ? 0 : prev.currentImageIndex + 1
        }));
    };

    const [modal, setmodal] = useState(false)

    const imageOpenModal = (reviewId, imageIndex) => {
        setmodal(true)
        setModalState({
            openReviewId: reviewId,
            currentImageIndex: imageIndex
        });
    };

    const closeModal = () => {
        setmodal(false)
        setModalState({
            openReviewId: null,
            currentImageIndex: 0
        });
    };

    const deleteReview = async () => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this review!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'Cancel'
            });
            if (result.isConfirmed) {
                setLoading(true);
                const response = await axios.post(`${baseUrl}/delete_customer_poduct_review`, {
                    product_id: id,
                    customer_id: user.customer_id
                });

                if (response.data.status) {
                    await fetchProducts()
                    setLoading(false);
                    notify('success', 'Success', `${response.data.message}`);
                } else {
                    setLoading(false);
                    notify('danger', 'Error', `${response.data.message}`);
                }
            } else {
                Swal.close();
            }
        } catch (error) {
            console.error('Error during status update:', error);
            notify('danger', 'Error', 'There was an error. Please try again.');
        }
    }


    return (
        <>
            <Navbar />
            <div className="container mx-auto pb-10">
                <div className="container mx-auto px-4 md:px-8 py-12">
                    <div className="flex flex-col md:flex-row gap-8">
                        <div className="md:w-1/2 md:sticky lg:top-44 md:top-56 self-start space-y-4">
                            <div className="relative aspect-square bg-gray-100 rounded-lg overflow-hidden" onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                onMouseMove={handleMouseMoveZoom}>
                                <img
                                    loading='lazy'
                                    ref={mainImageRef}
                                    src={imagesToDisplay[currentSlide]}
                                    alt={`Main`}
                                    key={imageKey}
                                    className=" h-full object-cover object-center"
                                    data-aos="fade"
                                />
                                {products.discount !== 0 && (
                                    <span className="absolute top-4 left-4 bg-black text-white text-sm font-bold px-3 py-1 rounded-full">
                                        -{products.discount}% OFF
                                    </span>
                                )}
                            </div>
                            <div className="grid grid-cols-4 gap-4">
                                {imagesToDisplay.slice(0, 4).map((img, index) => (
                                    <button
                                        key={index}
                                        className={`aspect-square bg-gray-100 rounded-md overflow-hidden ${currentSlide === index ? 'ring-2 ring-black' : ''}`}
                                        onClick={() => goToSlide(index)}
                                    >
                                        <img src={img} alt={`Thumbnail ${index}`} className="w-full h-full object-cover object-center" />
                                    </button>
                                ))}
                            </div>
                        </div>

                        <div className="md:w-1/2 space-y-6 relative">
                            <div>
                                <h1 className="text-3xl font-bold text-gray-900">{products.product_name}</h1>
                                <p className="mt-2 text-gray-500">{products.sub_text}</p>
                            </div>
                            {isZoomed && (
                                <div
                                    className="absolute md:block hidden cursor-zoom-in h-1/2 top-1/6 left-0 shadow-2xl  w-full bg-no-repeat rounded-3xl bg-slate-200 z-10 border"
                                    style={{
                                        backgroundImage: `url(${imagesToDisplay[currentSlide]})`,
                                        backgroundPosition: `${zoomPosition.x}% ${zoomPosition.y}%`,
                                        backgroundSize: '230% 200%',
                                    }}
                                ></div>
                            )}
                            <div className="flex items-center">
                                <div className="flex items-center">
                                    {renderStars(ratingStats.averageRating)}
                                </div>
                                <span className="ml-2 text-gray-600">{ratingStats.averageRating} ({ratingStats.totalRatings} reviews)</span>
                            </div>

                            <div className="flex items-center space-x-4">
                                <span className="text-3xl font-bold text-gray-900">{symbol ? symbol : '$'}&nbsp;{price}</span>
                                {products.discount !== 0 && (
                                    <span className="text-xl text-gray-400 line-through">{symbol ? symbol : '$'}&nbsp;{totalAmount}</span>
                                )}
                            </div>

                            <div className="space-y-4">
                                <div>
                                    <h3 className="text-sm font-medium text-gray-900">Color</h3>
                                    <div className="mt-2 flex space-x-2">
                                        {variants.map((variant, index) => (
                                            <button
                                                key={index}
                                                className={`w-8 h-8 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black ${selectedVariant?.colour === variant.colour ? 'ring-2 ring-black' : ''}`}
                                                style={{ backgroundColor: variant.colour }}
                                                onClick={() => handleColorSelect(variant)}
                                            ></button>
                                        ))}
                                    </div>
                                </div>

                                <div>
                                    <h3 className="text-sm font-medium text-gray-900">Size</h3>
                                    <div className="mt-2 flex flex-wrap gap-2">
                                        {availableSizes.map((size, index) => (
                                            <button
                                                key={index}
                                                className={`px-3 py-1 text-sm font-medium rounded-md ${selectedSize === size.size ? 'bg-black text-white' : 'bg-gray-200 text-gray-900'}`}
                                                onClick={() => sizeselect(size.size, size.size_id)}
                                            >
                                                {size.size}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="flex space-x-4">
                                <button
                                    className="flex-1 bg-black text-white py-3 px-6 rounded-lg font-medium hover:bg-gray-800 transition-colors"
                                    onClick={() => AddToCart(products, selected)}
                                >
                                    Add to Cart
                                </button>
                                <button className="flex-1 bg-white text-black border border-black py-3 px-6 rounded-lg font-medium hover:bg-gray-100 transition-colors">
                                    Buy Now
                                </button>
                            </div>

                            <div className="flex space-x-4">
                                <button className="p-3 rounded-lg border border-gray-300 hover:bg-gray-100 transition-colors">
                                    <FaHeart className="w-6 h-6 text-gray-600" />
                                </button>
                                <button className="p-3 rounded-lg border border-gray-300 hover:bg-gray-100 transition-colors">
                                    <FaShare className="w-6 h-6 text-gray-600" />
                                </button>
                            </div>

                            <div className="mt-8 border-t pt-8">
                                <h3 className="text-lg font-semibold mb-4">About this product</h3>
                                <ul className="space-y-2">
                                    <li className="flex items-start">
                                        <FaCheck className="w-5 h-5 text-green-500 mr-2 mt-1" />
                                        <span>High-quality materials for durability and comfort</span>
                                    </li>
                                    <li className="flex items-start">
                                        <FaCheck className="w-5 h-5 text-green-500 mr-2 mt-1" />
                                        <span>Versatile design suitable for various occasions</span>
                                    </li>
                                    <li className="flex items-start">
                                        <FaCheck className="w-5 h-5 text-green-500 mr-2 mt-1" />
                                        <span>Easy to clean and maintain</span>
                                    </li>
                                    <li className="flex items-start">
                                        <FaCheck className="w-5 h-5 text-green-500 mr-2 mt-1" />
                                        <span>Available in multiple colors to match your style</span>
                                    </li>
                                    <li className="flex items-start">
                                        <FaCheck className="w-5 h-5 text-green-500 mr-2 mt-1" />
                                        <span>Ethically manufactured with sustainable practices</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="mt-8 border-t pt-8">
                                {addinfo.length > 0 &&
                                    addinfo.map((list, index) => (
                                        <div className="flex flex-row" key={index}>
                                            <h1 className="font-semibold text-base w-1/4">{list.field}</h1>
                                            <span className="text-base">{list.value}</span>
                                        </div>
                                    ))
                                }
                            </div>

                            <div className="mt-8 border-t pt-8">
                                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                                    <div className="flex flex-col items-center text-center">
                                        <FaUndo className="w-8 h-8 text-gray-600 mb-2" />
                                        <span className="text-sm">14 days Return & Exchange</span>
                                    </div>
                                    <div className="flex flex-col items-center text-center">
                                        <FaTruck className="w-8 h-8 text-gray-600 mb-2" />
                                        <span className="text-sm">Free Delivery</span>
                                    </div>
                                    <div className="flex flex-col items-center text-center">
                                        <FaShieldAlt className="w-8 h-8 text-gray-600 mb-2" />
                                        <span className="text-sm">Top Brand</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="productDiscription">
                    <div className="mt-16 space-y-8">
                        <section className="bg-gray-50 p-6 rounded-lg">
                            <h2 className="text-2xl font-semibold mb-4">Product Description</h2>

                        </section>

                        <section>
                            <div className="flex justify-between">
                                <h2 className="text-2xl font-semibold mb-4">Customer Reviews</h2>
                                <button type="button" onClick={handleOpeneModal} className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">
                                    Add Review
                                </button>
                            </div>
                            <div className="flex items-start gap-8 p-6 bg-white rounded-lg md:w-1/2 w-full">
                                <div className="flex flex-col items-center justify-center">
                                    <div className="text-4xl font-bold">
                                        {ratingStats.averageRating}
                                        <span className="text-3xl ml-1">★</span>
                                    </div>
                                    <p className="text-sm text-gray-500 mt-1">
                                        {ratingStats.totalRatingValue} Ratings &
                                        <br />
                                        {ratingStats.totalRatings} Reviews
                                    </p>
                                </div>
                                <div className="flex-1 space-y-2 ">
                                    {[5, 4, 3, 2, 1].map((star, index) => (
                                        <div key={index} className="flex items-center gap-2">
                                            <span className="w-8 text-sm text-gray-600">
                                                {star}★
                                            </span>
                                            <div className="flex-1 h-2 bg-gray-200 rounded-full overflow-hidden">
                                                <div
                                                    className="h-full bg-green-600 rounded-full"
                                                    style={{
                                                        width: ratingStats.totalRatings > 0
                                                            ? `${(ratingStats.ratingCounts[star - 1] / ratingStats.totalRatings) * 100}%`
                                                            : '0%',
                                                    }}
                                                />
                                            </div>
                                            <span className="w-16 text-sm text-gray-600">
                                                {ratingStats.ratingCounts[star - 1]}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="grid md:grid-cols-2 gap-4">
                                {review.map((r) => (
                                    <div key={r.customerId} className="p-4 rounded-lg shadow bg-gray-100">
                                        <div className="flex items-center justify-between mb-2">
                                            <div className="flex items-center">
                                                <span className="font-medium capitalize">
                                                    {r.firstName} {r.lastName}
                                                </span>
                                                <div className="flex mx-2">
                                                    {[...Array(5)].map((_, i) => (
                                                        <FaStar key={i} className={`w-5 h-5 pr-1 ${i < r.rating ? 'text-yellow-400 fill-current' : 'text-gray-300'}`} />
                                                    ))}
                                                </div>
                                            </div>
                                            {r.customerId === user.customer_id && (
                                                <div className="inline-flex">
                                                    <button className=" text-gray-500 hover:text-red-600 hover:bg-red-50 rounded-full transition-colors duration-200" onClick={() => deleteReview(r.customerId)}>
                                                        <MdDelete className="w-6 h-6" />
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                        <p className="text-gray-600">{r.comment}</p>
                                        <div className="flex space-x-1 mt-2">
                                            {r.images.map((img, index) => (
                                                <img
                                                    key={img.product_review_content_id}
                                                    src={img.img_video}
                                                    alt={`Review image ${index + 1}`}
                                                    className="w-20 h-20 object-cover rounded-sm cursor-pointer"
                                                    onClick={() => imageOpenModal(r.customerId, index)}
                                                />
                                            ))}
                                        </div>
                                        <div className="flex items-center justify-end">
                                            <span className="text-xs text-gray-500">
                                                <FormattedDate date={`${r.review_date} ${r.review_time}`} className="text-xs text-gray-500" />
                                            </span>
                                        </div>
                                        {modal &&
                                            modalState.openReviewId === r.customerId && (
                                                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
                                                    <div className="flex w-full max-w-6xl">
                                                        <div className="bg-black rounded-lg md:w-6/12 w-full relative">


                                                            <div className="relative aspect-square">
                                                                {r.images.length > 0 &&
                                                                    <img
                                                                        src={r.images[modalState.currentImageIndex].img_video}
                                                                        alt={`Image ${modalState.currentImageIndex + 1}`}
                                                                        className="w-full h-full object-contain"
                                                                    />
                                                                }
                                                                {r.images.length > 1 && (
                                                                    <>
                                                                        <button
                                                                            onClick={() => handlePrevious(r.images)}
                                                                            className="absolute left-4 top-1/2 -translate-y-1/2 p-2 bg-white rounded-full shadow-lg hover:bg-gray-100"
                                                                        >
                                                                            <FaChevronLeft className="md:w-6 md:h-6 w-3 h-3" />
                                                                        </button>
                                                                        <button
                                                                            onClick={() => handleNext(r.images)}
                                                                            className="absolute right-4 top-1/2 -translate-y-1/2 p-2 bg-white rounded-full shadow-lg hover:bg-gray-100"
                                                                        >
                                                                            <FaChevronRight className="md:w-6 md:h-6 w-3 h-3" />
                                                                        </button>
                                                                    </>
                                                                )}
                                                            </div>

                                                            {r.images.length > 1 && (
                                                                <div className="flex gap-2 p-4 overflow-x-auto">
                                                                    {r.images.map((img, index) => (
                                                                        <button
                                                                            key={img.product_review_content_id}
                                                                            onClick={() => setModalState(prev => ({
                                                                                ...prev,
                                                                                currentImageIndex: index
                                                                            }))}
                                                                            className={`flex-shrink-0 ${modalState.currentImageIndex === index ? 'ring-2 ring-blue-500' : ''
                                                                                }`}
                                                                        >
                                                                            <img
                                                                                src={img.img_video}
                                                                                alt={`Thumbnail ${index + 1}`}
                                                                                className="w-16 h-16 object-cover rounded-sm"
                                                                            />
                                                                        </button>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="bg-white rounded-lg md:w-4/12 hidden p-6  md:block">
                                                            <div className="flex justify-end">
                                                                <button
                                                                    onClick={closeModal}
                                                                    className="p-2 hover:bg-gray-100 rounded-full"
                                                                >
                                                                    <RxCross2 className="w-6 h-6 text-black" />
                                                                </button>
                                                            </div>
                                                            <div className="space-y-4">
                                                                <div className="flex items-center justify-between">
                                                                    <div>
                                                                        <h2 className="text-xl font-semibold text-green-600">
                                                                            {r.rating}★
                                                                        </h2>
                                                                        <p className="text-gray-600 mt-1">
                                                                            {r.comment}
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className="flex items-center space-x-2 text-sm text-gray-500">
                                                                    <span className="font-medium capitalize">{r.firstName} {r.lastName}</span>
                                                                    <span>•</span>
                                                                    <span>{r.date}</span>
                                                                </div>
                                                                <div className="flex items-center space-x-2">
                                                                    <span className="px-2 py-1 bg-gray-100 rounded-full text-xs">
                                                                        Certified Buyer
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                ))}
                            </div>
                        </section>
                    </div>
                </div>

                <Modal show={openModal} onClose={() => setOpenModal(false)}>
                    <Modal.Header>Ratings & Reviews</Modal.Header>
                    <Modal.Body>
                        <div className="pb-5">
                            <label htmlFor="rating" className="block text-xl text-gray-700 mb-2">Rate this product</label>
                            <div className="flex">
                                {[1, 2, 3, 4, 5].map((star) => (
                                    <button
                                        key={star}
                                        type="button"
                                        onClick={() => setNewReview({ ...newReview, rating: star })}
                                        className={`${star <= newReview.rating ? 'text-yellow-400' : 'text-gray-300'} hover:text-yellow-400 mr-2`}
                                    >
                                        <FaStar className="w-6 h-6 fill-current" />
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div className="pb-3">
                            <label htmlFor="rating" className="block text-xl text-gray-700 mb-2">Review this product</label>
                            <textarea
                                id="comment"
                                className="rounded w-full border-gray-300 focus:border-gray-400 focus:ring focus:ring-gray-300"
                                value={newReview.comment}
                                onChange={(e) => setNewReview({ ...newReview, comment: e.target.value })}
                                placeholder="Description..."
                                style={{ resize: "none" }}
                                rows={6}
                            />
                        </div>

                        <div className="flex flex-wrap gap-4">
                            {previewUrls.map((url, index) => (
                                <div
                                    key={index}
                                    className="relative w-20 h-20 rounded-lg overflow-hidden border-2 border-gray-200"
                                >
                                    <img
                                        src={url}
                                        alt={`Preview ${index + 1}`}
                                        className="w-full h-full object-cover"
                                    />
                                    <button
                                        onClick={() => removeImage(index)}
                                        className="absolute top-1 right-1 p-1 bg-white rounded-full shadow-md hover:bg-gray-100"
                                        disabled={isUploading}
                                    >
                                        <RxCross2 className="w-4 h-4 text-gray-600" />
                                    </button>
                                </div>
                            ))}

                            <label className={`flex flex-col items-center justify-center w-20 h-20 border-2 border-gray-300 border-dashed rounded-lg ${isUploading ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer hover:bg-gray-50'} transition-colors`}>
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    {isUploading ? (
                                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-400" />
                                    ) : (
                                        <>
                                            <FaCamera className="w-5 h-5 mb-2 text-gray-400" />
                                            <p className="text-xs text-gray-500">PNG, JPG</p>
                                        </>
                                    )}
                                </div>
                                <input
                                    type="file"
                                    className="hidden"
                                    multiple
                                    accept=".jpg,.jpeg,.png"
                                    onChange={handleImageChange}
                                    disabled={isUploading}
                                />
                            </label>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='flex justify-end'>
                        <button
                            type="button"
                            onClick={handelAddReview}
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                            disabled={isUploading}
                        >
                            {isUploading ? 'Uploading...' : 'Add Review'}
                        </button>
                        <button
                            type="button"
                            onClick={() => setOpenModal(false)}
                            className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                            disabled={isUploading}
                        >
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>

            </div>

            <Similar_product toggleModal={toggleModal} id={products.sub_category_2_id} setLoading={setLoading} />
            <Footer />
        </>
    );
};

export default Product_Page;
