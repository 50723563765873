import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import bgImage from '../Assets/Images/banner-bg-arrivals.png';
import contact from '../Assets/Images/login-banner.jpg';
import { Link } from 'react-router-dom';
import { BiLogoFacebook } from "react-icons/bi";
import { LiaLinkedinIn } from "react-icons/lia";
import { AiOutlineInstagram } from "react-icons/ai";
import axios from 'axios';
import notify from '../utiles/Notification';

const Contact_us = () => {

  const baseUrl = process.env.REACT_APP_BASEURL


    const [mobile, setPhone] = useState('');
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const handlePhoneChange = (mobile) => {
        setPhone(mobile);
    };

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const validateForm = () => {
        const errors = {};

        if (!first_name) {
            errors.first_name = 'First name is required.';
        }

        if (!last_name) {
            errors.last_name = 'Last name is required.';
        }

        if (!email) {
            errors.email = 'Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email is invalid.';
        }

        if (!mobile) {
            errors.mobile = 'Mobile number is required.';
        } else if (mobile.length < 10) {
            errors.mobile = 'Mobile number is invalid.';
        }

        if (!message) {
            errors.message = 'Message is required.';
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        const formData = {
            first_name,
            last_name,
            email,
            mobile,
            message
        };

        setLoading(true); 

        try {
            const response = await axios.post(`${baseUrl}/contact_form`,
                { ...formData }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            // Handle response
            if (response.data.status) {
                // setSuccess('');
                notify('success', 'Success', 'Message sent successfully!');
                // Clear form fields
                setFirstName('');
                setLastName('');
                setEmail('');
                setPhone('');
                setMessage('');
                setErrors({});

            } else {
                setErrors('Failed to send message.');
            }
        } catch (error) {
            setErrors('Please fill in all the fields');
        } finally {
            setLoading(false); 
        }
    };

    const [contactData, setContactData] = useState([]);

    useEffect(() => {
        axios.get(`${baseUrl}/contact`)
            .then(response => {
                const aboutArray = response.data.contact_array;
                if (aboutArray && aboutArray.length > 0) {
                    setContactData(aboutArray[0]);  
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    return (
        <>

            <div
                className="relative bg-cover bg-center h-[500px] flex items-center justify-center opacity-90"
                style={{ backgroundImage: `url(${bgImage})` }}
            >
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="relative text-center text-white">
                    <h1 className="text-5xl font-bold uppercase">contact us</h1>
                    <p className="mt-2">
                        <Link to="/">Home</Link> <span className="mx-2">›</span> <Link to='/pages/contact-us' className='capitalize'>contact us</Link>
                    </p>
                </div>
            </div>

            <div className='container mx-auto md:px-10 px-5 xl:px-0'>
                <div className="flex md:flex-row flex-col md:gap-10 md:my-10 gap-5 my-5">
                    <div className="md:w-1/2 w-full flex justify-end place-items-start ">
                        <img src={contactData.image} alt="" className='xl:h-[50vh] md:h-[40hv] w-full xl:w-96' />
                    </div>
                    <div className="md:w-1/2 w-full flex justify-center flex-col place-items-start gap-3">
                        <h1 className='lg:text-3xl md:text-2xl text-xl font-semibold uppercase'>{contactData.heading}</h1>
                        <span className='text-gray-400 font-medium lg:text-base text-sm'>{contactData.sub_heading}</span>
                        <div className="flex lg:flex-row flex-col  xl:w-3/4 w-full gap-2">
                            <div className="flex flex-col lg:w-1/2 w-full">
                                <span className='lg:text-lg text-base capitalize font-semibold'>address</span>
                                <span className='text-gray-400 font-medium lg:text-base text-sm'>{contactData.address}</span>
                            </div>
                            <div className="flex flex-col lg:w-1/2 w-full">
                                <span className='lg:text-lg text-base capitalize font-semibold'>Phone</span>
                                <span className='text-gray-400 font-medium lg:text-base text-sm'>{contactData.phone}</span>
                            </div>
                        </div>
                        <div className="flex lg:flex-row flex-col  xl:w-3/4 w-full gap-2">
                            <div className="flex flex-col lg:w-1/2 w-full">
                                <span className='lg:text-lg text-base capitalize font-semibold'>email</span>
                                <span className='text-gray-400 font-medium lg:text-base text-sm'>{contactData.email}</span>
                            </div>
                            <div className="flex flex-col lg:w-1/2 w-full">
                                <span className='lg:text-lg text-base capitalize font-semibold'>Additional Information</span>
                                <span className='text-gray-400 font-medium lg:text-base text-sm'>{contactData.info}</span>
                            </div>
                        </div>
                        <span className='text-lg capitalize font-semibold'>Social</span>
                        <div className="flex flex-row gap-3 text-lg">
                            <a href="#" className='w-7 h-7 rounded-full flex justify-center place-items-center text-slate-400 border border-slate-400 ' target='_blank'><BiLogoFacebook /></a>
                            <a href="#" className='w-7 h-7 rounded-full flex justify-center place-items-center text-slate-400 border border-slate-400 ' target='_blank'><LiaLinkedinIn /></a>
                            <a href="#" className='w-7 h-7 rounded-full flex justify-center place-items-center text-slate-400 border border-slate-400 ' target='_blank'><AiOutlineInstagram /></a>
                        </div>
                    </div>
                </div>
                <div className='lg:flex gap-10 md:place-items-center'>
                    <div className='lg:w-6/12 space-y-10 mb-5 lg:mb-0 md:mx-auto'>
                        <div className='space-y-3 text-center'>
                            <h1 className='lg:text-3xl md:text-2xl text-xl font-semibold uppercase'>LEAVE US A MESSAGE</h1>
                            <p className='text-gray-400 font-medium lg:text-base text-sm'>Our friendly team would love to hear from you!</p>
                        </div>
                        <form onSubmit={handleSubmit} className='space-y-5'>
                            <div className='flex gap-5 justify-between'>
                                <div className='w-full'>
                                    <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                                        First Name
                                    </label>
                                    <input
                                        type="text"
                                        placeholder='Enter First Name'
                                        id="firstName"
                                        className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-400 focus:ring-1 md:text-sm text-sm"
                                        value={first_name}
                                        onChange={handleFirstNameChange}
                                    />
                                    {errors.first_name && <p className="text-red-500 text-xs mt-1">{errors.first_name}</p>}
                                </div>
                                <div className='w-full'>
                                    <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                                        Last Name
                                    </label>
                                    <input
                                        type="text"
                                        placeholder='Enter Last Name'
                                        id="lastName"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-400 focus:ring-1 md:text-sm text-sm"
                                        value={last_name}
                                        onChange={handleLastNameChange}
                                    />
                                    {errors.last_name && <p className="text-red-500 text-xs mt-1">{errors.last_name}</p>}
                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-semibold text-gray-700">E-Mail Address</label>
                                <input
                                    type="email"
                                    placeholder="E-Mail Address"
                                    value={email}
                                    onChange={handleEmailChange}
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-400 focus:ring-1 md:text-sm text-sm"
                                />
                                {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
                            </div>
                            <div>
                                <label className="block text-sm font-semibold text-gray-700 mb-2">Mobile Number</label>
                                <PhoneInput
                                    country={'in'}
                                    value={mobile}
                                    countryCodeEditable={false}
                                    enableSearch={true}
                                    onChange={handlePhoneChange}
                                    placeholder="Enter Mobile Number"
                                    inputClass="input-mobile mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-400 focus:ring-1 md:text-sm text-sm"
                                />
                                {errors.mobile && <p className="text-red-500 text-xs mt-1">{errors.mobile}</p>}
                            </div>
                            <div>
                                <label className="block text-sm font-semibold text-gray-700 mb-2">Message</label>
                                <textarea
                                    placeholder="Message"
                                    value={message}
                                    rows={7}
                                    onChange={handleMessageChange}
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-400 focus:ring-1 md:text-sm text-sm"
                                />
                                {errors.message && <p className="text-red-500 text-xs mt-1">{errors.message}</p>}
                            </div>
                            {errors.submit && <p className="text-red-500 text-center mt-4">{errors.submit}</p>}
                            <div className='flex justify-center'>
                                <button
                                    type="submit"
                                    className="md:w-1/4 w-1/2 py-2 px-4 bg-black text-white font-semibold rounded-md shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2"
                                    disabled={loading} // Disable button while loading
                                >
                                    {loading ? 'Sending...' : 'Send Message'}
                                </button>
                            </div>
                        </form>
                        {/* {error && <p className='text-red-500 text-center capitalize'>{error}</p>}
                        {success && <p className='text-green-500 text-center'>{success}</p>}*/}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact_us
