import React, { useEffect, useState } from 'react'
import reviewImg from '../../Component/Assets/Images/rating2.png';
import Dropdown from '../../Component/utiles/Dropdown';
import { IoSearchSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { FaStar, FaRegStar, FaStarHalfAlt } from 'react-icons/fa';
import Pagination from '../../Component/utiles/Pagination';
import axios from 'axios';
import { TbArrowsExchange2 } from 'react-icons/tb';
import notify from '../../Component/utiles/Notification';


const Ratingreview = ({ setLoading }) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [ratingreview, setRatingreview] = useState([]);
    const [refresh, setRefresh] = useState(0)

    const baseUrl = process.env.REACT_APP_BASEURL

    useEffect(() => {
        setLoading(true)
        const fetchAllReviews = async () => {
            try {
                const response = await axios.get(`${baseUrl}/getRecentProductReviews`);
                if (response.data.status) {
                    setRatingreview(response.data.reviews);
                }
            } catch (err) {
                console.log(err);
            }
        };
        setLoading(false)
        fetchAllReviews();
    }, [refresh]);


    const perpageoption = [
        { label: '5' }, { label: '10' }, { label: '15' }, { label: '20' }
    ];

    const [itemsPerPage, setItemsPerPage] = useState(perpageoption[0].label);
    const [currentPage, setCurrentPage] = useState(1);

    const searchResults = ratingreview.filter(reviewrating =>
        reviewrating.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        reviewrating.review_rating.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const display = searchTerm ? searchResults : ratingreview


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = display.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(display.length / itemsPerPage);

    useEffect(() => {
        if (searchTerm) {
            setCurrentPage(1);
        }
    }, [searchTerm]);

    const renderStars = (rating) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= rating) {
                stars.push(<FaStar key={i} className="text-yellow-300" />);
            } else if (i === Math.ceil(rating) && !Number.isInteger(rating)) {
                stars.push(<FaStarHalfAlt key={i} className="text-yellow-300" />);
            } else {
                stars.push(<FaRegStar key={i} className="text-yellow-300" />);
            }
        }
        return stars;
    };


    const getStatusClass = (stockStatus) => {
        switch (stockStatus.toLowerCase()) {
            case 'in stock':
            case 'active':
            case 'live':
                return 'bg-green-200 text-green-600 text-xs';
            case 'out of stock':
            case 'inactive':
            case 'hold':
                return 'bg-red-200 text-red-500 text-xs';
            default:
                return 'bg-gray-400 text-white text-xs';
        }
    };

    const statusupdate = async (id, getstatus) => {
        let status;
        if (getstatus === 'Active') {
            status = 'Inactive'
        } else {
            status = 'Active'
        }
        setLoading(true)
        try {
            const response = await axios.post(`${baseUrl}/product_reviews_status_update`, {
                product_review_id: id,
                status: status
            });
            if (response.data.status) {
                notify('success', 'Success', `Status Update to ${status}`);
                setRefresh((prev) => prev + 1);
            }
        } catch (err) {
            console.log(err)
        }
        setLoading(false)
    }



    return (
        <>
            <h1 className='xl:text-2xl md:text-lg text-xl mt-3 font-semibold capitalize'>Ratings And Reviews</h1>
            <div className='w-full bg-white  rounded-lg mt-5 md:p-6 p-3'>
                <div className="relative flex md:hidden w-full ">
                    <input
                        type="text"
                        placeholder="Search Here"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="bg-gray-200 w-full border-0 rounded-full px-4 py-2 pr-10 focus:outline-none focus:border-gray-400 focus:ring-2 focus:ring-gray-400"
                    />
                    <IoSearchSharp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-black cursor-pointer" />
                </div>
                <div className='flex flex-row  justify-between my-5  pe-0'>
                    <div className='flex flex-row items-center md:w-64 w-24  gap-1'>
                        <span className='text-nowrap mt-2 md:block hidden text-sm capitalize'>items per page</span>
                        <div className='w-16'>
                            <Dropdown
                                label=""
                                options={perpageoption}
                                selectedValues={itemsPerPage}
                                onSelect={setItemsPerPage}
                                onRemove={() => { }}
                                isMultiple={false}
                            />
                        </div>
                    </div>

                    <div className="relative hidden md:flex w-5/12">
                        <input
                            type="text"
                            placeholder="Search Here"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="bg-gray-200 w-full border-0 rounded-full px-4 py-1 pr-10 focus:outline-none focus:border-gray-400 focus:ring-2 focus:ring-gray-400"
                        />
                        <IoSearchSharp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-black cursor-pointer" />
                    </div>
                </div>
                <div className='overflow-hidden overflow-x-auto mt-6'>
                    <table className="lg:w-full md-w[100%]  table bg-white">
                        <thead className="bg-gray-100 rounded-full">
                            <tr>
                                <th className="py-4 px-4 text-left capitalize w-fit">customer</th>
                                <th className="py-4 px-4 text-left  capitalize">comment</th>
                                <th className="py-4 px-4 text-left capitalize">rating</th>
                                <th className="py-4 px-4 text-left capitalize">status</th>
                                <th className="py-4 px-4 text-left capitalize">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map(item => (
                                <tr key={item.product_review_id} className="border-b border-gray-200  hover:bg-gray-200 transition-all duration-200 ease-in-out">
                                    <td className="py-4 px-4">
                                        <div className="flex items-center gap-4 pe-20">
                                            <img src={item.customer_image} alt={item.first_name} className="w-16 h-16 mr-4 bg-gray-100 rounded-full" />
                                            <span className='text-nowrap font-medium w-fit text-base '>{item.first_name}&nbsp;{item.last_name}</span>
                                        </div>
                                    </td>
                                    <td className="py-4 px-4 text-sm w-full">{item.review_comment}</td>
                                    <td className="py-4 px-4"><div className="flex flex-row gap-1 place-items-center">{renderStars(item.review_rating)}</div></td>
                                    <td className="py-4 px-4">
                                        <span className={`px-2 py-1  rounded-lg capitalize font-semibold ${getStatusClass(item.review_status)}`}>
                                            {item.review_status}
                                        </span>
                                    </td>
                                    <td className="p-4">
                                        <div className='flex flex-row items-center gap-5 text-xl'>
                                            <Link className='cursor-pointer hover:scale-110 duration-200 transition-all px-3 py-2 text-lg rounded-md bg-neutral-300'>
                                                <FiEdit />
                                            </Link>
                                            <Link onClick={() => statusupdate(item.product_review_id, item.review_status)} className='px-3 cursor-pointer hover:scale-110 duration-200 transition-all py-2 text-lg rounded-md bg-neutral-300'>
                                                <TbArrowsExchange2 />
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-between items-center mt-4">
                    <div>
                        <span>Page {currentPage} of {totalPages}</span>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </div>
        </>

    )
}

export default Ratingreview