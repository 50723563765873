import React, { useEffect, useRef, useState } from 'react'
import { MdCloudDownload, MdOutlineFileUpload } from "react-icons/md";
import { AiOutlineDelete } from 'react-icons/ai';
import { ImageUpload } from '../../../Component/utiles/ImageUpload';
import axios from 'axios';
import notify from '../../../Component/utiles/Notification';
import Pagination from '../../../Component/utiles/Pagination';
import { FiEdit } from 'react-icons/fi';
import Dropdown from '../../../Component/utiles/Dropdown';
import { Modal, Label, FileInput } from "flowbite-react";


const Banners = ({ setLoading }) => {

    const [openModal, setOpenModal] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [edit, setEdit] = useState(false);
    const [bannerdata, setBannerdata] = useState([{
        banner_id: '', banner: '', status: '', type: '', label: ''
    }]);

    const multiple = bannerdata.banner_id === null ? true : false;
    const { images, handleFileChange, handleRemoveImage } = ImageUpload(multiple, setLoading);

    const baseUrl = process.env.REACT_APP_BASEURL

    const [banners, setBanner] = useState([]);

    const fileInputRef = useRef(null);

    const handleClick = () => {
        fileInputRef.current.click();
    };


    useEffect(() => {
        setBannerdata(prev => ({
            ...prev,
            banner: images.length > 0 ? images[0].url : null,
        }));
    }, [images]);

    useEffect(() => {
        setLoading(true);
        const allcolours = async () => {
            try {
                const response = await axios.get(`${baseUrl}/getBanners`);
                if (response.data.status) {
                    setBanner(response.data.banners)
                }
            } catch (err) {
                console.log(err);
            }
        };
        setLoading(false);
        allcolours();
    }, [openModal, refresh]);


    const typeoption = [
        { label: 'slider' },
        { label: 'discount' },
        { label: 'checkout' },
        { label: 'cart' },
    ];

    const perpageoption = [
        { label: '5' }, { label: '10' }, { label: '15' }, { label: '20' }
    ]

    const [itemsPerPage, setItemsPerPage] = useState(perpageoption[0].label);
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = banners.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(banners.length / itemsPerPage);

    const getStatusClass = (stockStatus) => {
        switch (stockStatus.toLowerCase()) {
            case 'in stock':
            case 'active':
            case 'live':
                return 'bg-green-200 text-green-600 text-xs';
            case 'out of stock':
            case 'inactive':
            case 'hold':
                return 'bg-red-200 text-red-500 text-xs';
            default:
                return 'bg-gray-400 text-white text-xs';
        }
    };

    const handleopenClick = (banner_id) => {

        const bannerData = banners.find((list) => list.banner_id === banner_id);
        if (bannerData) {
            setBannerdata({
                banner_id: bannerData.banner_id,
                banner: bannerData.banner,
                status: bannerData.status,
                type: bannerData.type,
                label: bannerData.type
            });
        }
        setEdit(true)
        setOpenModal(true);
    };

    const close = () => {
        setBannerdata({
            banner_id: '',
            banner: '',
            status: '',
            type: ''
        });
        setOpenModal(false);
    }


    const updatebanner = async () => {

        let url;
        if (edit) {
            url = `update_banners`;
        } else {
            url = 'add_banners';
        }
        setLoading(true);
        try {
            const response = await axios.post(`${baseUrl}/${url}`,
                {
                    bannerdata
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
            if (response.data.status) {
                notify('success', 'Success', `Banner ${edit ? 'Update' : 'Add'} successfull`);
                setOpenModal(false);
            } else {
                notify('danger', 'Error', response.data.message);

            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    return (
        <>
            <h1 className="xl:text-2xl md:text-lg text-xl mt-4 font-semibold capitalize">Banners</h1>
            <div className='w-full bg-white rounded-lg mt-5 p-6'>
                <div className='flex flex-row justify-between my-5 p-0'>
                    <div className='flex flex-row items-center md:w-48 w-20 gap-5'>
                        <span className='text-nowrap mt-2 md:block hidden'>items per page</span>
                        <Dropdown
                            label=""
                            options={perpageoption}
                            selectedValues={itemsPerPage}
                            onSelect={setItemsPerPage}
                            onRemove={() => { }}
                            isMultiple={false}
                        />
                    </div>
                    <button className="w-fit flex flex-row place-items-center gap-3  rounded-xl bg-[#2377FC] text-white px-4 py-3 my-5" onClick={() => setOpenModal(true)}>
                        <MdOutlineFileUpload className='text-3xl' />
                        Add Banners
                    </button>
                </div>
                <div className='overflow-hidden overflow-x-auto'>
                    <table className="min-w-full table bg-white">
                        <thead className="bg-gray-100 rounded-full">
                            <tr>
                                <th className="py-4 px-4 text-left">ID</th>
                                <th className="py-4 px-4 text-left">Banner</th>
                                <th className="py-4 px-4 text-left">Type</th>
                                <th className="py-4 px-4 text-left">Status</th>
                                <th className="py-4 px-4 text-left">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map(item => (
                                <tr key={item.banner_id} className="border-b border-gray-200 hover:bg-gray-200 transition-all duration-200 ease-in-out">
                                    <td className="py-4 px-4 font-normal text-sm capitalize">{item.banner_id}</td>
                                    <td className="py-4 px-4">
                                        <img src={item.banner} alt={item.type} className="w-44 h-20 mr-4 bg-gray-100 rounded-lg" />
                                    </td>
                                    <td className="py-4 px-4 font-normal text-sm capitalize">{item.type}</td>
                                    <td className="py-4 px-4">
                                        <span className={`px-2 py-1  rounded-lg capitalize font-semibold ${getStatusClass(item.status)}`}>
                                            {item.status}
                                        </span>
                                    </td>
                                    <td className="p-4">
                                        <div className='flex flex-row items-center gap-5 text-xl'>
                                            <button onClick={() => handleopenClick(item.banner_id)} className='cursor-pointer hover:scale-110 duration-200 transition-all px-3 py-2 text-lg rounded-md bg-neutral-300'>
                                                <FiEdit />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-between items-center mt-4">
                    <div>
                        <span>Page {currentPage} of {totalPages}</span>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </div>
            <Modal show={openModal} size="4xl" onClose={() => setOpenModal(false)} >
                <Modal.Header>{edit ? 'Banner Details' : 'Add Banner'}</Modal.Header>
                <Modal.Body>
                    <div className="flex flex-col">
                        {bannerdata.banner ? (
                            <div className="relative">
                                <button onClick={handleClick} className='absolute right-2 top-2 cursor-pointer duration-200 transition-all px-3 py-2 text-lg rounded-md bg-blue-500 text-white'>
                                    <FiEdit />
                                </button>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    multiple={multiple}
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    className="hidden"
                                />
                                <img src={bannerdata.banner} className='h-60 w-full rounded-lg' alt="" />
                            </div>
                        ) : (
                            <Label
                                htmlFor={`dropzone-file-`}
                                className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100"
                            >
                                <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                    <MdCloudDownload className='text-3xl text-gray-400' />
                                    <p className="mb-2 text-sm text-gray-500">
                                        <span className="font-semibold">Click to upload</span> or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                </div>
                                <FileInput
                                    id={`dropzone-file-`}
                                    className="hidden"
                                    multiple={multiple}
                                    onChange={handleFileChange}
                                />
                            </Label>
                        )}
                        <div className="grid grid-cols-3 mt-3 gap-3">
                            <label className="inline-flex items-center cursor-pointer w-fit">
                                <span className="text-sm font-medium text-gray-900 dark:text-gray-300 px-3">Status</span>
                                <input type="checkbox" className="sr-only peer"
                                    checked={bannerdata.status === 'Active'}
                                    onChange={(e) => setBannerdata({ ...bannerdata, status: e.target.checked ? 'Active' : 'Inactive' })}
                                />
                                <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600"></div>
                            </label>
                            <div className="flex flex-row text-nowrap place-items-center    ">
                                <span className="text-sm font-medium text-gray-900 dark:text-gray-300 px-3 ">Type :- </span>
                                <Dropdown
                                    label=""
                                    options={typeoption}
                                    selectedValues={bannerdata.label}
                                    onSelect={(selectedType) => {
                                        setBannerdata((prev) => ({
                                            ...prev,
                                            type: selectedType,
                                            label: selectedType
                                        }));
                                    }}
                                    onRemove={() => { }}
                                    isMultiple={false}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='flex justify-end'>
                    <button type="button" onClick={updatebanner} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                        {edit ? 'Update' : 'Add'} Banner
                    </button>
                    <button type="button" onClick={close} className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>
        </>

    )
}

export default Banners