import React, { useEffect, useState } from 'react'
import { IoSearchSharp } from "react-icons/io5";
import { FaCaretUp } from "react-icons/fa6";
import img from '../../Component/Assets/Images/shoe.png';
import Pagination from '../../Component/utiles/Pagination';
import Dropdown from '../../Component/utiles/Dropdown';
import Overview from '../Component/Overview';
import ReturnTracking from '../Component/ReturnTracking';
import returnOrders from '../../Component/utiles/Return';
import ClaimTracking from '../Component/ClaimTracking';


const ReturnOrder = () => {

  const tabs = ['overview', 'Return Traking', 'Claim Traking'];

  const claimTrackingData = [
    {
      img: img,
      product: 'Lorem Ipsum, sometimes',
      orderId: '#10',
      createdDate: '20-8-2024',
      issue: 'I have not received my Return/RTO shipment',
      status: 'open',
      lastUpdate: 'Ticket ID: 8702339353433\nDear Supplier, Greetings from Meesho! View More'
    },
    {
      img: img,
      product: 'Lorem Ipsum, sometimes',
      orderId: '#45835',
      createdDate: '20-8-2024',
      issue: 'I have received wrong return',
      status: 'rejected',
      lastUpdate: 'Ticket ID: 8702339353433\nDear Supplier, Greetings from Meesho! View More'
    },
    {
      img: img,
      product: 'Lorem Ipsum, sometimes',
      orderId: '#45835',
      createdDate: '20-8-2024',
      issue: 'I have not received my Return/RTO shipment',
      status: 'approved',
      lastUpdate: 'Ticket ID: 8702339353433\nDear Supplier, Greetings from Meesho! View More'
    },
    {
      img: img,
      product: 'Lorem Ipsum, sometimes',
      orderId: '#45835',
      createdDate: '20-8-2024',
      issue: 'I have received wrong return',
      status: 'open',
      lastUpdate: 'Ticket ID: 8702339353433\nDear Supplier, Greetings from Meesho! View More'
    },
    {
      img: img,
      product: 'Lorem Ipsum, sometimes',
      orderId: '#45835',
      createdDate: '20-8-2024',
      issue: 'I have not received my Return/RTO shipment',
      status: 'rejected',
      lastUpdate: 'Ticket ID: 8702339353433\nDear Supplier, Greetings from Meesho! View More'
    },
    {
      img: img,
      product: 'Lorem Ipsum, sometimes',
      orderId: '#45835',
      createdDate: '20-8-2024',
      issue: 'I have received wrong return',
      status: 'approved',
      lastUpdate: 'Ticket ID: 8702339353433\nDear Supplier, Greetings from Meesho! View More'
    },
    {
      img: img,
      product: 'Lorem Ipsum, sometimes',
      orderId: '#45835',
      createdDate: '20-8-2024',
      issue: 'I have not received my Return/RTO shipment',
      status: 'open',
      lastUpdate: 'Ticket ID: 8702339353432\nDear Supplier, Greetings from Meesho! View More'
    },
    {
      img: img,
      product: 'Lorem Ipsum, sometimes',
      orderId: '#45835',
      createdDate: '20-8-2024',
      issue: 'I have received wrong return',
      status: 'rejected',
      lastUpdate: 'Ticket ID: 8702339353433\nDear Supplier, Greetings from Meesho! View More'
    },
    {
      img: img,
      product: 'Lorem Ipsum, sometimes',
      orderId: '#10',
      createdDate: '20-8-2024',
      issue: 'I have not received my Return/RTO shipment',
      status: 'approved',
      lastUpdate: 'Ticket ID: 8702339353433\nDear Supplier, Greetings from Meesho! View More'
    },
    {
      img: img,
      product: 'Lorem Ipsum, sometimes',
      orderId: '#45835',
      createdDate: '20-8-2024',
      issue: 'I have received wrong return',
      status: 'open',
      lastUpdate: 'Ticket ID: 8702339353433\nDear Supplier, Greetings from Meesho! View More'
    }
  ];


  const overViewdata = [
    {
      title: 'Total Return',
      total: '50 Order',
      percentage: "20",
      text: "You Delivered Total 200 Order",
    },
    {
      title: 'Customer Return',
      total: '30 Order',
      percentage: "20",
      text: "You Delivered Total 200 Order",
    },
    {
      title: 'RTO Return',
      total: '30 Order',
      percentage: "20",
      text: "You Delivered Total 200 Order",
    },
    {
      title: 'Total Approved Claim ',
      total: '499.00 €',
      percentage: "20",
      text: "We Approved 4 Out of 9 Claim Raised By You",
    },
  ]

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const [currentPage, setCurrentPage] = useState(1);


  const [searchTerm, setSearchTerm] = useState('');

  const handleTabClick = (Tab) => {
    setSelectedTab(Tab);
    setCurrentPage(1);
  };

  const searchResults = returnOrders.filter(productitem =>
    productitem.product.toLowerCase().includes(searchTerm.toLowerCase()) ||
    productitem.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
    productitem.returnBy.toLowerCase().includes(searchTerm.toLowerCase()) ||
    productitem.orderId.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const perpageoption = [
    { label: '5' }, { label: '10' }, { label: '15' }, { label: '20' }
  ];

  const [itemsPerPage, setItemsPerPage] = useState(perpageoption[0].label);

  const itemsToDisplay = searchTerm ? searchResults : returnOrders;


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = itemsToDisplay.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(itemsToDisplay.length / itemsPerPage);

  useEffect(() => {
    if (searchTerm) {
      setCurrentPage(1);
    }
  }, [searchTerm]);



  const overViwesData = {
    returnOrders,
    overViewdata,
    searchResults,
    itemsPerPage,
    searchTerm,
    currentItems,
    currentPage,
    totalPages,
    setCurrentPage,
    claimTrackingData,
  };



  return (
    <>
      <h1 className='xl:text-2xl md:text-lg text-xl mt-3 font-semibold capitalize'>Return/RTO Orders</h1>
      <div className='w-full bg-white rounded-lg mt-5 md:p-6 p-3'>
        <div className="flex flex-row justify-between md:hidden place-items-center">
          <div className='flex-row items-center md:w-40 w-24 flex  gap-2'>
            <span className='text-nowrap mt-2 lg:block hidden text-sm capitalize'>items per page</span>
            <div className='w-16'>
              <Dropdown
                label=""
                options={perpageoption}
                selectedValues={itemsPerPage}
                onSelect={setItemsPerPage}
                onRemove={() => { }}
                isMultiple={false}
              />
            </div>
          </div>
          <div className="relative flex xl:w-5/12 md:w-7/12 w-full ">
            <input
              type="text"
              placeholder="Search Here"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="bg-gray-200 w-full border-0 rounded-full px-4 py-2 pr-10 focus:outline-none focus:border-gray-400 focus:ring-2 focus:ring-gray-400"
            />
            <IoSearchSharp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-black cursor-pointer" />
          </div>
        </div>
        <div className="flex flex-row place-items-center justify-between">
          <div className="flex justify-start mt-5 overflow-x-auto text-nowrap overflow-hidden pb-3">
            <div className="tabs tabs-boxed h-10 text-nowrap">
              {tabs.map((tabs) => (
                <a
                  key={tabs}
                  className={`px-4 py-2 transition-all duration-200 ease-in-out font-medium cursor-pointer ${selectedTab === tabs ? 'border-b capitalize text-base md:text-lg border-black text-black' : 'text-[#A4A4A4] text-sm'}`}
                  onClick={() => handleTabClick(tabs)}
                >
                  {tabs.charAt(0).toUpperCase() + tabs.slice(1)}
                </a>
              ))}
            </div>
          </div>
          <div className='hidden flex-row items-center md:w-40 w-24 md:flex  gap-2'>
            <span className='text-nowrap mt-2 lg:block hidden text-sm capitalize'>items per page</span>
            <div className='w-16'>
              <Dropdown
                label=""
                options={perpageoption}
                selectedValues={itemsPerPage}
                onSelect={setItemsPerPage}
                onRemove={() => { }}
                isMultiple={false}
              />
            </div>
          </div>
          <div className="relative hidden xl:w-5/12 md:w-7/12 w-full md:flex">
            <input
              type="text"
              placeholder="Search Here"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="bg-gray-200 w-full border-0 rounded-full px-4 py-2 pr-10 focus:outline-none focus:border-gray-400 focus:ring-2 focus:ring-gray-400"
            />
            <IoSearchSharp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-black cursor-pointer" />
          </div>

        </div>
        <div className="mt-5">
          {selectedTab == tabs[0] &&
            <Overview {...overViwesData} />
          }
          {selectedTab == tabs[1] &&
            <ReturnTracking {...overViwesData} />
          }
          {selectedTab == tabs[2] &&
            <ClaimTracking {...overViwesData} />
          }
        </div>
      </div>
    </>
  )
}

export default ReturnOrder