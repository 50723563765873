import React, { useEffect, useState } from 'react'
import Checkout_bg from '../Assets/Images/Checkout_bg.png'
import Navbar from '../LandingPage/Navbar'
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../LandingPage/Footer';
import { UserState } from '../../Context/userProvider';
import axios from 'axios';
import { useCountries, useStates } from '../utiles/CountryStateCity';
import Dropdown from '../utiles/Dropdown';
import { MdLocalShipping } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { MdOutlinePayment } from "react-icons/md";
import notify from '../utiles/Notification';
import { Label, Modal, TextInput } from 'flowbite-react';
import { loadStripe } from '@stripe/stripe-js';


const Checkout_page = ({ setLoading, }) => {

    const baseUrl = process.env.REACT_APP_BASEURL

    const [openModal, setOpenModal] = useState(false);
    const [cartItems, setCartitems] = useState([]);
    const [couponcode, setCouponcode] = useState();
    const [address, setAddress] = useState({});
    const [couponData, setCouponData] = useState({});


    const navigate = useNavigate();

    // useEffect(() => {
    //     if (cartItems.length == 0) {
    //         notify('danger', 'Error', `Please Add Product in your Cart`);
    //         navigate('/')
    //         return;
    //     }
    // }, [cartItems]);


    const paymentMethod = [
        {
            name: "Online Payment"
        },
        {
            name: 'COD',
        },
    ]

    const [cardData, setCardData] = useState({
        card_type: paymentMethod[0].name, card_number: '', card_exp: '', card_cvc: ''
    })

    const [selectedPayment, setSelectedPayment] = useState(paymentMethod[0].name);
    const [data, setData] = useState({});


    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        companyName: '',
        streetAddress: '',
        townCity: '',
        postcodeZip: '',
        orderNotes: '',
        email: '',
        mobile: '',
        gender: '',
        image: '',
        state: '',
        country: '',
        state_name: '',
        country_name: '',
    });

    const { user, logout, geoLocation, countryCode } = UserState();

    useEffect(() => {
        const fetchCustomerData = async () => {
            setLoading(true)
            try {
                const response = await axios.post(
                    `${baseUrl}/checkout`,
                    {
                        customer_id: user.customer_id,
                        countryCode: countryCode
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
                if (response.data.status) {
                    const foundAddress = response.data.delivery_address.find(list => list.address_type === 'home');
                    setAddress(foundAddress)
                    setData(response.data)
                    setCartitems(response.data.cartItems)
                } else {
                    console.log('Failed to fetch customer data');
                }
            } catch (err) {
                console.log('Error fetching customer data:', err);
            }
            setLoading(false)
        };

        fetchCustomerData();
    }, [!geoLocation?.country]);


    const appliCoupon = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${baseUrl}/customer_apply_coupon`,
                {
                    coupon_code: couponcode,
                    customer_id: user.customer_id,
                    countryCode: countryCode
                });
            if (response.data.status) {
                setCouponData(response.data)
            } else {
                // setCouponData('')
                notify('danger', 'Error', `${response.data.message}`);
            }
        } catch (err) {
            console.log(err)
        }
        setLoading(false);
    }

    const selectAddress = (id) => {
        const Add_data = data.delivery_address.find(list => list.customers_delivery_address_id === id);
        setAddress(Add_data)
        setOpenModal(false);
    }

    const checkout = async () => {
        let url;
        const stripePromise = loadStripe("pk_test_51Oma0oHv8tw3Pk25d2VPv1ilLFSBBYysoPtRjh6F01FOFUX5COqN4HVNUCwuudmlqUlrSoVuCooHQewpUfDUQSWJ00vmyt0jYB");
        const successUrl = window.location.origin + "/success";
        const cancelUrl = window.location.origin + "/cancel";

        if (cardData.card_type === paymentMethod[0].name) {
            //     if (!cardData.card_number) {
            //         notify('danger', 'Error', `Please Enter Card Number`);
            //         return;
            //     }
            //     if (!cardData.card_exp) {
            //         notify('danger', 'Error', `Please Enter Card Expiry`);
            //         return;
            //     }
            //     if (!cardData.card_cvc) {
            //         notify('danger', 'Error', `Please Enter Card CVC`);
            //         return;
            //     }
            url = 'create-payment-intent'
        } else {
            url = 'place_order'
        }
        setLoading(true);
        try {
            const response = await axios.post(`${baseUrl}/${url}`, {
                currency: 'usd',
                customer_id: user.customer_id,
                customer_delivery_address_id: address.customers_delivery_address_id,
                payment_method: selectedPayment,
                coupon_id: couponData ? couponData.coupon_id : null,
                success_url: successUrl, // Send dynamic success URL
                cancel_url: cancelUrl,
            });

            if (cardData.card_type === paymentMethod[0].name) {

                const stripe = await stripePromise;
                await stripe.redirectToCheckout({ sessionId: response.data.id });
            }
            if (response.data.status) {
                notify('success', 'Success', 'Order Place Successfully');
                navigate('/my-profile/my-orders')
            }
        } catch (err) {
            console.log(err)
        }
        setLoading(false);

    }


    const total = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(couponData.status ? data.total_amount - couponData.coupon_amount : data.total_amount);

    // const PlaceOrderDiv = ({ couponcode, setCouponcode, data, couponData, appliCoupon, checkout }) => {

    //     return (
    //         <>
    //             <div className='w-full'>
    //                 <h2 className="text-xl font-bold mb-4 md:p-4 p-2 bg-gray-100 rounded-xl">Coupon</h2>
    //                 <p className=" text-gray-500 font-normal">Enter Your Coupon Code if You Have One</p>
    //                 <div className="flex flex-row my-2 place-items-center gap-3 w-full">
    //                     <input
    //                         value={couponcode}
    //                         onChange={(e) => setCouponcode(e.target.value)}
    //                         type="text" className="w-2/3 px-4 py-2 border border-gray-400 rounded-lg my-2 focus:outline-none focus:border-gray-300 focus:ring-black focus:ring-1" placeholder='Enter Coupon Code' />
    //                     <button onClick={appliCoupon} className="bg-white text-sm text-nowrap border border-black py-2.5 px-4 rounded-lg  font-medium  hover:bg-black hover:text-white">Apply Coupon</button>
    //                 </div>
    //             </div>
    //             <div className="border md:py-6 py-3 md:px-8 px-4 rounded-xl lg:mt-0 mt-10">
    //                 <h2 className="text-xl font-bold mb-4">Cart Total</h2>
    //                 <div className="flex justify-between mb-2">
    //                     <span className='text-gray-500'>Total Items</span>
    //                     <span className='text-gray-500'>{data.cartCount}</span>
    //                 </div>
    //                 {couponData.coupon_amount &&
    //                     <div className="flex justify-between mb-2">
    //                         <span className='text-gray-500'>Coupon Discount</span>
    //                         <span className='text-gray-500'>{couponData.symbol}&nbsp;{couponData.coupon_amount}</span>
    //                     </div>
    //                 }
    //                 <div className="flex justify-between font-bold border-t border-gray-300 pt-2">
    //                     <span className='text-black-500 text-xl'>Total</span>
    //                     <span className='text-black-500 text-xl'>{data.symbol}&nbsp;{total}</span>
    //                 </div>
    //                 <div className="flex justify-center place-items-center">
    //                     <button
    //                         onClick={checkout}
    //                         className="bg-black text-white  py-2 px-4 rounded-md mt-4 place-items-center">
    //                         Place Order
    //                     </button>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // }

    return (
        <>
            <Navbar />
            <div
                className="relative bg-cover bg-center h-[500px] flex items-center justify-center "
                style={{ backgroundImage: `url(${Checkout_bg})` }}
            >
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="relative text-center text-white">
                    <h1 className="text-5xl font-bold uppercase">Checkout</h1>
                    <p className="mt-2">
                        <Link to="/">Home</Link> <span className="mx-2">›</span> <span>Check Out</span>
                    </p>
                </div>
            </div>
            <div className="container mx-auto py-10 flex lg:flex-row gap-5 flex-col px-5 xl:px-0 h-full">
                <div className="lg:w-2/3 w-full">
                    <div className="mb-10">
                        <table className="min-w-full bg-white hidden md:block">
                            <thead className="bg-gray-100 rounded-xl">
                                <tr>
                                    <th className="py-4 px-4 text-left">Product</th>
                                    <th className="py-4 px-4 text-left">Price</th>
                                    <th className="py-4 px-4 text-left">Colour</th>
                                    <th className="py-4 px-4 text-left">Quantity</th>
                                    <th className="py-4 px-4 text-left">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {cartItems.map(item => (
                                    <tr key={item.id} className={`bg-white  border-b border-gray-200`}>
                                        <td className="py-4 px-4  flex items-center gap-4">
                                            <img src={item.images} alt={item.product_name} className="w-16 h-16 mr-4 bg-gray-100 rounded-lg" />
                                            <span className='flex flow-row gap-5 place-items-center'>{item.product_name}</span>
                                        </td>
                                        <td className="py-4 px-4 text-nowrap">{item.symbol != null ? item.symbol : '$'}&nbsp;{item.price}</td>
                                        <td className="py-4 px-4 ">{item.colour}</td>
                                        <td className="py-4 px-4 ">
                                            <div className="flex items-center">
                                                <span className="px-2 mx-3">{item.added_quantity}</span>
                                            </div>
                                        </td>
                                        <td className="py-4 px-4 text-nowrap">{item.symbol}&nbsp;{(item.price * item.added_quantity.toFixed(2))}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="block md:hidden">
                            {cartItems.map(item => (
                                <div key={item.id} className={`bg-white px-4 border-b border-gray-200  mb-4`}>
                                    <div className="flex mb-2">
                                        <img src={item.images} alt={item.product_name} className="w-24 h-24 bg-gray-100 mt-3" />
                                        <div className='ml-4 w-full'>
                                            <div className="flex justify-between">
                                                <p className='text-md text-gray-400'>Category</p>
                                            </div>
                                            <div>
                                                <p className='text-lg font-medium'>{item.product_name}</p>
                                            </div>
                                            <div className="flex items-center justify-between mt-3">
                                                <div><p className='text-lg font-medium'>{item.symbol != '' ? item.symbol : '$'}&nbsp;{item.price}</p></div>
                                                <div className="flex items-center">
                                                    <span className="px-2">{item.added_quantity}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex flex-row gap-5">
                        <div className="md:w-1/2 lg:w-full w-full">
                            <div className="flex flex-col ">
                                <div className="flex flex-row gap-2 place-items-center">
                                    <div className="text-xl">
                                        <MdLocalShipping />
                                    </div>
                                    <h2 className="text-xl font-semibold">Shipping Details</h2>
                                </div>
                                <div className="rounded-xl border border-gray-400 mt-2 p-3 flex flex-row place-items-start">
                                    <div className="w-4/5">
                                        <span className='flex flex-row place-items-start gap-3 text-neutral-500 font-medium text-wrap w-full'>
                                            <span className='text-lg'><FaLocationDot /></span>
                                            {address && address.address1}, {address && address.address2}, {address && address.city}, {address && address.state_subdivision_name}, {address && address.country_name}
                                        </span>
                                    </div>
                                    <div className="w-1/5 text-end">
                                        <button className='font-medium text-base ' onClick={() => setOpenModal(true)}>Change</button>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col mt-5">
                                <div className="flex flex-row gap-2 place-items-center">
                                    <div className="text-xl">
                                        <MdOutlinePayment />
                                    </div>
                                    <h2 className="text-xl font-semibold">Payment Method</h2>
                                </div>
                                <div className="flex flex-row gap-4 mt-2">
                                    {paymentMethod.map((list, index) => (
                                        <label key={index}>
                                            <input
                                                className='me-2 focus:ring-0 checked:bg-black'
                                                type="radio"
                                                checked={selectedPayment === list.name}
                                                onChange={() => {
                                                    setSelectedPayment(list.name);
                                                    setCardData(({ card_type: list.name }));
                                                }}
                                            />
                                            {list.name}
                                        </label>
                                    ))}
                                </div>
                                {selectedPayment === paymentMethod[0].name &&
                                    <>
                                        {/* <div className="mt-5 lg:w-1/2 w-full">
                                        <div className="flex flex-col gap-2 ">
                                            <span>Card Number</span>
                                            <input type="number" name="card_number" className="no-arrows focus:ring-0 rounded-md focus:border-black border-gray-400" placeholder='Enter Card Number'
                                                value={cardData.card_number} onChange={(e) => setCardData({ ...cardData, card_number: e.target.value })}
                                            />
                                        </div>
                                        <div className="grid grid-cols-2 gap-5 mt-5">
                                            <div className="flex flex-col gap-2">
                                                <span>Expiry</span>
                                                <input type="number" name="card_exp" className="no-arrows focus:ring-0 rounded-md focus:border-black border-gray-400" placeholder='MM/YY'
                                                    value={cardData.card_exp} onChange={(e) => setCardData({ ...cardData, card_exp: e.target.value })}
                                                />
                                            </div>
                                            <div className="flex flex-col gap-2">
                                                <span>Card CVC</span>
                                                <input type="number" name="card_cvc" className="no-arrows focus:ring-0 rounded-md focus:border-black border-gray-400" placeholder='Enter CVC'
                                                    value={cardData.card_cvc} onChange={(e) => setCardData({ ...cardData, card_cvc: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                        <ul className="mt-5 space-y-2">
                                            <li><strong>Accepted Cards:</strong> We accept all major credit and debit cards.</li>
                                            <li><strong>Secure Payment:</strong> Your information is encrypted for safety.</li>
                                            <li><strong>Easy Checkout:</strong> Simply enter your card details at checkout.</li>
                                            <li><strong>Immediate Confirmation:</strong> Get instant confirmation of your purchase.</li>
                                            <li><strong>Customer Support:</strong> Our team is available to assist with any questions.</li>
                                        </ul>
                                    </>
                                }
                                {/* {selectedPayment === paymentMethod[1].name &&
                                    <div className="mt-5 lg:w-1/2 w-full">
                                        <div className="flex flex-col gap-2 ">
                                            <span>Card Number</span>
                                            <input type="number" name="card_number" className="no-arrows focus:ring-0 rounded-md focus:border-black border-gray-400" placeholder='Enter Card Number'
                                                value={cardData.card_number} onChange={(e) => setCardData({ ...cardData, card_number: e.target.value })}
                                            />
                                        </div>
                                        <div className="grid grid-cols-2 gap-5 mt-5">
                                            <div className="flex flex-col gap-2">
                                                <span>Expiry</span>
                                                <input type="number" name="card_exp" className="no-arrows focus:ring-0 rounded-md focus:border-black border-gray-400" placeholder='MM/YY'
                                                    value={cardData.card_exp} onChange={(e) => setCardData({ ...cardData, card_exp: e.target.value })}
                                                />
                                            </div>
                                            <div className="flex flex-col gap-2">
                                                <span>Card CVC</span>
                                                <input type="number" name="card_cvc" className="no-arrows focus:ring-0 rounded-md focus:border-black border-gray-400" placeholder='Enter CVC'
                                                    value={cardData.card_cvc} onChange={(e) => setCardData({ ...cardData, card_cvc: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                } */}

                                {selectedPayment === paymentMethod[1].name &&
                                    <ul className="mt-5 space-y-2">
                                        <li><strong>Place Your Order:</strong> Select the COD option during checkout. No need to pay online.</li>
                                        <li><strong>Order Processing:</strong> Once your order is confirmed, we prepare it for shipment.</li>
                                        <li><strong>Delivery:</strong> Our delivery partner will bring the package to your address.</li>
                                        <li><strong>Pay Upon Delivery:</strong> You can pay the total amount in cash (or by card, depending on availability) when your package arrives.</li>
                                        <li><strong>Order Confirmation:</strong> After you make the payment, your order will be marked as complete.</li>
                                    </ul>
                                }
                            </div>
                        </div>
                        <div className="md:w-1/2 w-full lg:hidden md:block hidden">
                            <div className='w-full'>
                                <h2 className="text-xl font-bold mb-4 md:p-4 p-2 bg-gray-100 rounded-xl">Coupon</h2>
                                <p className=" text-gray-500 font-normal">Enter Your Coupon Code if You Have One</p>
                                <div className="flex flex-row my-2 place-items-center gap-3 w-full">
                                    <input
                                        value={couponcode}
                                        onChange={(e) => setCouponcode(e.target.value)}
                                        type="text" className="w-2/3 px-4 py-2 border border-gray-400 rounded-lg my-2 focus:outline-none focus:border-gray-300 focus:ring-black focus:ring-1" placeholder='Enter Coupon Code' />
                                    <button onClick={appliCoupon} className="bg-white text-sm text-nowrap border border-black py-2.5 px-4 rounded-lg  font-medium  hover:bg-black hover:text-white">Apply Coupon</button>
                                </div>
                            </div>
                            <div className="border md:py-6 py-3 md:px-8 px-4 rounded-xl lg:mt-0 mt-10">
                                <h2 className="text-xl font-bold mb-4">Cart Total</h2>
                                <div className="flex justify-between mb-2">
                                    <span className='text-gray-500'>Total Items</span>
                                    <span className='text-gray-500'>{data.cartCount}</span>
                                </div>
                                {couponData.coupon_amount &&
                                    <div className="flex justify-between mb-2">
                                        <span className='text-gray-500'>Coupon Discount</span>
                                        <span className='text-gray-500'>{couponData.symbol}&nbsp;{couponData.coupon_amount}</span>
                                    </div>
                                }
                                <div className="flex justify-between font-bold border-t border-gray-300 pt-2">
                                    <span className='text-black-500 text-xl'>Total</span>
                                    <span className='text-black-500 text-xl'>{data.symbol}&nbsp;{total}</span>
                                </div>
                                <div className="flex justify-center place-items-center">
                                    <button
                                        onClick={checkout}
                                        className="bg-black text-white  py-2 px-4 rounded-md mt-4 place-items-center">
                                        Place Order
                                    </button>
                                </div>
                            </div>
                            {/* <PlaceOrderDiv couponcode={couponcode} setCouponcode={setCouponcode} data={data} couponData={couponData} appliCoupon={appliCoupon} checkout={checkout} /> */}
                        </div>
                    </div>
                </div>
                <div className="md:w-1/2 lg:w-1/3 w-full">
                    <div className="lg:sticky static md:top-56 top-0 xl:top-44 md:hidden lg:block block">
                        <div className='w-full'>
                            <h2 className="text-xl font-bold mb-4 md:p-4 p-2 bg-gray-100 rounded-xl">Coupon</h2>
                            <p className=" text-gray-500 font-normal">Enter Your Coupon Code if You Have One</p>
                            <div className="flex flex-row my-2 place-items-center gap-3 w-full">
                                <input
                                    value={couponcode}
                                    onChange={(e) => setCouponcode(e.target.value)}
                                    type="text" className="w-2/3 px-4 py-2 border border-gray-400 rounded-lg my-2 focus:outline-none focus:border-gray-300 focus:ring-black focus:ring-1" placeholder='Enter Coupon Code' />
                                <button onClick={appliCoupon} className="bg-white text-sm text-nowrap border border-black py-2.5 px-4 rounded-lg  font-medium  hover:bg-black hover:text-white">Apply Coupon</button>
                            </div>
                        </div>
                        <div className="border md:py-6 py-3 md:px-8 px-4 rounded-xl lg:mt-0 mt-10">
                            <h2 className="text-xl font-bold mb-4">Cart Total</h2>
                            <div className="flex justify-between mb-2">
                                <span className='text-gray-500'>Total Items</span>
                                <span className='text-gray-500'>{data.cartCount}</span>
                            </div>
                            {couponData.coupon_amount &&
                                <div className="flex justify-between mb-2">
                                    <span className='text-gray-500'>Coupon Discount</span>
                                    <span className='text-gray-500'>{couponData.symbol}&nbsp;{couponData.coupon_amount}</span>
                                </div>
                            }
                            <div className="flex justify-between font-bold border-t border-gray-300 pt-2">
                                <span className='text-black-500 text-xl'>Total</span>
                                <span className='text-black-500 text-xl'>{data.symbol}&nbsp;{total}</span>
                            </div>
                            <div className="flex justify-center place-items-center">
                                <button
                                    onClick={checkout}
                                    className="bg-black text-white  py-2 px-4 rounded-md mt-4 place-items-center">
                                    Place Order
                                </button>
                            </div>
                        </div>
                        {/* <PlaceOrderDiv couponcode={couponcode} setCouponcode={setCouponcode} data={data} couponData={couponData} appliCoupon={appliCoupon} checkout={checkout} /> */}
                    </div>
                </div>
            </div >
            <Modal show={openModal} onClose={() => setOpenModal(false)}>
                <Modal.Header>Change Address</Modal.Header>
                <Modal.Body>
                    <div className="space-y-3">
                        {data.status &&
                            (data.delivery_address.map((list, index) => (
                                <div key={index} className="rounded-xl border  border-gray-400 hover:border-black transition-all duration-300 ease-in-out hover:scale-105 p-3 flex flex-col place-items-start cursor-pointer" onClick={() => selectAddress(list.customers_delivery_address_id)}>
                                    <h1 className='capitalize ms-7 text-lg font-medium mb-2'>{list.address_type} Address</h1>
                                    <span className='flex flex-row place-items-start gap-3 text-neutral-500 font-medium text-wrap w-full'>
                                        <span className='text-lg'><FaLocationDot /></span>
                                        {list.address1}, {list.address2}, {list.city}, {list.state_subdivision_name}, {list.country_name}
                                    </span>
                                </div>
                            )))
                        }
                    </div>
                </Modal.Body>
            </Modal>
            <Footer />
        </>
    )
}

export default Checkout_page
