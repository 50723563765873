import React, { useEffect, useState } from 'react'
import Pagination from '../../Component/utiles/Pagination';


const ClaimTracking = ({ claimTrackingData, itemsPerPage, searchTerm }) => {

    const tabs = ['all', 'open', 'approved', 'rejected'];

    const [selectedStatus, setSelectedStatus] = useState(tabs[0]);
    const [currentPage, setCurrentPage] = useState(1);


    const handleTabClick = (status) => {
        setSelectedStatus(status);
        setCurrentPage(1);
    };
    const filterByStatus = (purchase) => {
        if (selectedStatus === 'all') return true;
        return purchase.status === selectedStatus;
    };

    const filteredRTO = claimTrackingData.filter(filterByStatus);

    const searchResults = filteredRTO.filter(productitem =>
        productitem.product.toLowerCase().includes(searchTerm.toLowerCase()) ||
        productitem.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
        productitem.lastUpdate.toLowerCase().includes(searchTerm.toLowerCase()) ||
        productitem.orderId.toLowerCase().includes(searchTerm.toLowerCase())

    );


    const itemsToDisplay = searchTerm ? searchResults : filteredRTO;


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = itemsToDisplay.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(itemsToDisplay.length / itemsPerPage);


    useEffect(() => {
        if (searchTerm) {
            setCurrentPage(1);
        }
    }, [searchTerm]);

    return (
        <>
            <div className="flex justify-start mt-3 overflow-x-auto flex-row pt-3  ">
                <div className="tabs tabs-boxed flex h-10 flex-row  w-fit">
                    {tabs.map((status) => (
                        <a
                            key={status}
                            className={`h-full transition-all flex justify-center place-items-center px-4 duration-200 ease-in-out font-medium cursor-pointer rounded-t-lg ${selectedStatus === status ? 'border-x border-t text-base border-black text-black' : 'border-black border-b text-[#A4A4A4] text-sm'}`}
                            onClick={() => handleTabClick(status)}>
                            {status.charAt(0).toUpperCase() + status.slice(1)}
                        </a>
                    ))}
                </div>
                <div className="w-full "><div className="w-full border-black border-b h-full pt-9"></div></div>
            </div>
            <div className='overflow-hidden overflow-x-auto mt-6'>
                <table className="lg:w-full md-w[100%]  table bg-white">
                    <thead className="bg-gray-100 rounded-full">
                        <tr>
                            <th className="py-4 px-4 text-left capitalize">Product</th>
                            <th className="py-4 px-4 text-left capitalize text-nowrap">Order ID</th>
                            <th className="py-4 px-4 text-left capitalize text-nowrap">created date</th>
                            <th className="py-4 px-4 text-left capitalize">Issue</th>
                            <th className="py-4 px-4 capitalize text-center text-nowrap">Claim Status</th>
                            <th className="py-4 px-4 capitalize text-center">Last Updates</th>
                            <th className="py-4 px-4 text-left capitalize">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map(item => (
                            <tr key={item.id} className="border-b border-gray-200 hover:bg-gray-200 transition-all duration-200 ease-in-out">
                                <td className="py-4 px-4 flex place-items-center gap-4">
                                    <img src={item.img} alt={item.product} className="w-16 h-16 mr-4 bg-gray-100 rounded-lg" />
                                    <span className='text-nowrap pe-10 truncate md:pe-20 lg:pe-0 text-sm'>{item.product}</span>
                                </td>
                                <td className="py-4 px-4 text-nowrap">{item.orderId}</td>
                                <td className="py-4 px-4">
                                    {item.createdDate}
                                </td>
                                <td className="py-4 px-4">{item.issue}</td>
                                <td className="py-4 px-4 ">
                                    <div className="flex justify-center place-items-center">
                                        {item.status}
                                    </div>
                                </td>
                                <td className="py-4 px-4 text-center whitespace-pre-wrap">{item.lastUpdate}</td>
                                <td className="py-4 px-4 "><span className='px-3 py-1 border cursor-pointer border-black capitalize rounded-lg'>view</span></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="flex justify-between items-center mt-4">
                <div>
                    <span>Page {currentPage} of {totalPages}</span>
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(page) => setCurrentPage(page)}
                />
            </div>
        </>
    )
}

export default ClaimTracking